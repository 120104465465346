import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  // fireConfigTimeAppointmentFailMessage,
  fireConfigTimeAppointmentSuccessMessage,
  getMessageFromError,
  fireDeleteSpecialistSuccessMessage,
} from '../../helpers/utils';

import * as customSetting from './customSetting.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

function* handleCreateCustomSetting(action: any) {
  try {
    const getUuid = yield call(() =>
      authorizedRequest.get(
        `/v1/systemsetting?v=full&q=appointmentscheduling.timeSlot.get.condition.time.minutes`,
      ),
    );

    const res = yield call(() =>
      authorizedRequest.post(`/v1/systemsetting/${getUuid.results[0].uuid}`, {
        propertyValue: action.payload.propertyValue,
      }),
    );

    fireConfigTimeAppointmentSuccessMessage();
    // yield put(customSetting.handleGetTimeCustomSucceeded(getUuid));
    yield put(customSetting.handleCustomSettingSucceeded(res));
    yield call(handleGetTimeCustom, null);
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customSetting.handleCustomSettingFailed(error));
  }
}

function* handleGetTimeCustom(action: any) {
  try {
    const api = authorizedRequest.get(
      `/v1/systemsetting?v=full&q=appointmentscheduling.timeSlot.get.condition.time.minutes`,
    );
    const getTime = yield call(() => api);
    yield put(
      customSetting.handleGetTimeCustomsSucceeded(getTime?.results[0].value),
    );
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customSetting.handleGetTimeCustomsFailed(error));
  }
}
function* handleDeleteSpecialist(action) {
  try {
    const { id, handleSuccess } = action.payload;
    const res = yield call(() => authorizedRequest.delete(`/v1/concept/${id}`));

    fireDeleteSpecialistSuccessMessage();
    yield put(customSetting.handleDeleteSpecialistSucceeded(res));

    if (handleSuccess) {
      handleSuccess();
    }
  } catch (error) {
    yield put(customSetting.handleDeleteSpecialistFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleAddSpecialist(action) {
  const { data, handleSuccess } = action.payload;
  try {
    const response = yield call(() =>
      authorizedRequest.post('/v1/concept', data),
    );
    yield put(customSetting.handleAddSpecialistSucceeded(response));
    if (handleSuccess) {
      handleSuccess();
    }
    message.success('Thêm mới chuyên khoa thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customSetting.handleAddSpecialistFailed(error));
  }
}
function* getSpecialistRequested(action) {
  try {
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/concept', { params }),
    );
    yield put(customSetting.getSpecialistCustomsSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customSetting.getSpecialistCustomsFailed(error));
  }
}
function* handleEditSpecialist(action) {
  const { handleSuccess } = action.payload;
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `/v1/concept/${action.payload.url.uuidConcept}/name/${action.payload.url.uuidName}`,
        action.payload.data,
      ),
    );
    yield put(customSetting.handleEditSpecialistSucceeded(response));
    if (handleSuccess) {
      handleSuccess();
    }
    message.success('Chỉnh sửa chuyên khoa thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customSetting.handleEditSpecialistFailed(error));
  }
}
export default function* customSettingSaga() {
  yield all([
    yield takeLatest(
      customSetting.handleDeleteSpecialistRequested,
      handleDeleteSpecialist,
    ),
    yield takeLatest(
      customSetting.handleCustomSettingRequested,
      handleCreateCustomSetting,
    ),
    yield takeLatest(
      customSetting.handleGetTimeCustomsRequested,
      handleGetTimeCustom,
    ),

    yield takeLatest(
      customSetting.getSpecialistCustomsRequested,
      getSpecialistRequested,
    ),

    yield takeLatest(
      customSetting.handleAddSpecialistRequested,
      handleAddSpecialist,
    ),
    yield takeLatest(
      customSetting.handleEditSpecialistRequested,
      handleEditSpecialist,
    ),
  ]);
}
