import React, { FC } from 'react';
import { Row, Col } from 'antd';
import _ from 'lodash';
import RequestImage from 'components/Appointment/RequestImage/RequestImage';
import QuestionCircle from '../../../../assets/images/common/question.svg';
import './ReasonCheck.scss';

interface Props {
  initValues: any;
  appointment: any;
  dataProps: any;
}

const ReasonCheck: FC<Props> = ({ initValues, dataProps }) => {
  return (
    <>
      <div className="reasonCheck-Contaier">
        <div className="reasonCheck-content">
          <Row className="reasonCheck-title ">
            <span className="pl-6">Lý do khám bệnh</span>
          </Row>
          <hr />
          <Row className="row-1">
            <Col xs={24}>
              <div className="reason-check-section">
                <div className="reason-check-section__title">
                  <span>Lý do khám</span>
                </div>
                <div className="reason-check-section__content">
                  <div className="reason-check-section__content_inside">
                    {initValues?.reason}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="row-2">
            <Col xs={24}>
              <div className="description-section">
                <div className="description-section__title">
                  <span>Mô tả chi tiết</span>
                </div>
                <div className="description-section__content">
                  <div className="description-section__content_inside">
                    {initValues?.reasonDetail}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="row-3">
            <div className="question-section">
              <p className="question-label">Câu hỏi cho bác sỹ</p>
              {initValues?.questionsByPatient?.length ? (
                initValues?.questionsByPatient?.map((item, key) => {
                  return (
                    <div key={key}>
                      <div className="question-section__title">
                        <span style={{ fontSize: '20px' }}>
                          <div className="wrap-question-circle pt-2 ">
                            <img src={QuestionCircle} alt="" />
                          </div>
                        </span>
                        <span className="pt-2 pl-2">Câu hỏi {key + 1}</span>
                      </div>
                      <div className="question-section__content">
                        <div className="question-section__content_inside">
                          <span className="text-have-color-#555555">
                            {item}
                          </span>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              )}
            </div>
          </Row>
          {_.get(
            dataProps,
            'detail.dataCloned.detail.oldExaminationImages.length',
            0,
          ) > 0 && (
            <Col className="row-4">
              <p className="olderResultTitle">Kết quả khám trước đây</p>
              <RequestImage
                images={dataProps.detail.dataCloned.detail.oldExaminationImages}
              />
            </Col>
          )}
        </div>
      </div>
    </>
  );
};

export default ReasonCheck;
