import React, { FC } from 'react';
import { FileExcelOutlined, FileTextOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface CProps {
  getCsvFile: () => void;
  getExcelFile: () => void;
}

const ExportFilePromotion: FC<CProps> = ({ getCsvFile, getExcelFile }) => {
  return (
    <div className=" flex justify-end pb-4 px-4">
      <Tooltip title="Excel">
        <FileExcelOutlined
          onClick={getExcelFile}
          style={{ fontSize: 36, color: '#1890ff' }}
        />
      </Tooltip>
      <Tooltip title="Csv">
        <FileTextOutlined
          onClick={getCsvFile}
          style={{ fontSize: 36, color: '#1890ff' }}
        />
      </Tooltip>
    </div>
  );
};
export default ExportFilePromotion;
