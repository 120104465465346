import React, { FC } from 'react';
import classes from './Appointment.module.scss';

interface Props {
  title?: string;
}

const AppointmentCard: FC<Props> = ({ children, title }) => {
  return (
    <div>
      {title && (
        <div className={classes.examination_title}>
          <span>{title}</span>
        </div>
      )}

      <div>{children}</div>
    </div>
  );
};
export default AppointmentCard;
