import React, { FC } from 'react';
import { Modal, Button } from 'antd';
import './ModalConfirmCustomer.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface CProps {
  show: boolean;
  onHide: () => void;
}

const ModalConfirmCustomer: FC<CProps> = ({ show, onHide }) => {
  return (
    <Modal
      title={null}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={480}
      footer={null}
    >
      <div className="modal-confirm">
        <p>
          <ExclamationCircleOutlined style={{ color: '#faad14' }} />
          <span>Bạn có chắc chắn muốn xóa giao dịch này không?</span>
        </p>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary">Có</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalConfirmCustomer;
