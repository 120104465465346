import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { ServiceModel } from 'models/store/service.model';

export const {
  getServiceRequested,
  getServiceSucceeded,
  getServiceFailed,
  handleCreateServiceRequested,
  handleCreateServiceSucceeded,
  handleCreateServiceFailed,
  handleUpdateServiceRequested,
  handleUpdateServiceSucceeded,
  handleUpdateServiceFailed,
  handleDisplayModalEdit,
  handleDisplayModalAdd,
  handleLockedServiceRequested,
  handleLockedServiceSucceeded,
  handleLockedServiceFailed,
  getDetailServiceRequested,
  getDetailServiceSucceeded,
  getDetailServiceFailed,
  handleCreateLogoServiceRequested,
  handleCreateLogoServiceSucceeded,
  handleCreateLogoServiceFailed,
} = createActions(
  {
    GET_SERVICE_REQUESTED: (payload: QueryString) => payload,
    GET_SERVICE_SUCCEEDED: (payload: ServiceModel) => payload,
    GET_SERVICE_FAILED: (error: any) => error,
    HANDLE_CREATE_SERVICE_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_SERVICE_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_SERVICE_FAILED: (error: any) => error,
    HANDLE_UPDATE_SERVICE_REQUESTED: (payload: QueryString) => payload,
    HANDLE_UPDATE_SERVICE_SUCCEEDED: (payload: ServiceModel) => payload,
    HANDLE_UPDATE_SERVICE_FAILED: (error: any) => error,
    HANDLE_DISPLAY_MODAL_EDIT: (error: any) => error,
    HANDLE_DISPLAY_MODAL_ADD: (error: any) => error,
    HANDLE_LOCKED_SERVICE_REQUESTED: (payload: any) => payload,
    HANDLE_LOCKED_SERVICE_SUCCEEDED: (payload: any) => payload,
    HANDLE_LOCKED_SERVICE_FAILED: (error: any) => error,
    GET_DETAIL_SERVICE_REQUESTED: (payload: QueryString) => payload,
    GET_DETAIL_SERVICE_SUCCEEDED: (payload: ServiceModel) => payload,
    GET_DETAIL_SERVICE_FAILED: (error: any) => error,
    HANDLE_CREATE_LOGO_SERVICE_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_LOGO_SERVICE_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_LOGO_SERVICE_FAILED: (error: any) => error,
  },
  {
    prefix: 'service',
  },
);
