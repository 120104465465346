import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
// import { HolidayModel } from 'models/store/holiday.model';

export const {
  getRatingRequested,
  getRatingSucceeded,
  getRatingFailed,
} = createActions(
  {
    GET_RATING_REQUESTED: (payload: QueryString) => payload,
    // GET_RATING_SUCCEEDED: (payload: HolidayModel) => payload,
    GET_RATING_SUCCEEDED: payload => payload,
    GET_RATING_FAILED: (error: any) => error,
  },
  {
    prefix: 'rating',
  },
);
