export const TYPE_STATUS_VERSION_VALUE = {
  DOCTOR: 'DOCTOR',
  PATIENT: 'PATIENT',
};
export const OPTIONS_LIST_STATUS_VERSION = [
  {
    label: 'Doctor App',
    value: TYPE_STATUS_VERSION_VALUE.DOCTOR,
  },
  {
    label: 'Patient App',
    value: TYPE_STATUS_VERSION_VALUE.PATIENT,
  },
];
