import { CloudUploadOutlined, MinusCircleFilled } from '@ant-design/icons';
import { Button, Col, message, Row, Upload } from 'antd';
import RequestImage from 'components/Appointment/RequestImage/RequestImage';
import config from 'configs';
import { APPOINTMENT_STATUS } from 'constants/Appointment';
import authorizedRequest from 'helpers/request/authorizedRequest';
import * as _ from 'lodash';
import StoreState from 'models/store';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { actions as appointmentActions } from 'store/appointment';
import { OTHER_CLS_CODE, OTHER_CLS_DISPLAY } from './request.const';
import './Requested.scss';
import RequestForm from './RequestForm/RequestForm';

message.config({
  duration: 1,
  maxCount: 1,
});

const Scroll = require('react-scroll');

const { scroller } = Scroll;

const Requested: FC<any> = props => {
  const { dataProps, isEditAvailability } = props;
  const dispatch = useDispatch();
  const params1 = useParams();
  const appointmentId = _.get(params1, 'id');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<any>();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [editSelected, setEditSelected] = useState<any>({});
  const [listImage, setListImage] = useState<any>([]);
  const [listCls, setListCls] = useState<any>([]);
  const [uploadIndex, setUploadIndex] = useState([]);
  const [isStatusUpload, statusUpload] = useState<boolean>(false);
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  useEffect(() => {
    dispatch(appointmentActions.getClsConceptOrder());
  }, []);

  const arrDiagnostic =
    dataProps && dataProps?.detail?.dataCloned?.detail?.diagnostic;
  const status = dataProps && dataProps?.detail?.detail?.status;
  const arrRequest =
    dataProps &&
    dataProps?.detail?.encounters[0]?.orders?.filter(
      item => item.type === 'clsorder',
    );

  const uuidProvider = dataProps && dataProps?.detail?.provider?.uuid;
  const uuidEncounter = dataProps && dataProps?.detail?.encounters[0]?.uuid;
  const uuidPerson = dataProps && dataProps?.detail?.arrPetient?.person?.uuid;

  const listImages = useCallback(
    id => {
      return dataProps.detail
        ? dataProps.detail.dataCloned.detail.images.filter(
            (i: any) => i.orderId === id,
          )
        : [];
    },
    [dataProps.detail],
  );

  const onFinish = (values, clsGroup) => {
    const orderCls = values?.CLS?.map(clsItem => ({
      concept: clsItem,
    }))?.filter(item => item.concept !== OTHER_CLS_CODE);
    const isNonCode = values?.CLS?.includes(OTHER_CLS_CODE);

    const orderSetMembers = isNonCode
      ? [
          ...orderCls,
          {
            concept: OTHER_CLS_CODE,
            conceptNonCode: values?.other,
          },
        ]
      : orderCls;

    const ortherSetMembers = [
      {
        concept: OTHER_CLS_CODE,
        conceptNonCode: values?.otherGroup,
      },
    ];

    const params = {
      encounter: uuidEncounter,
      orderType: '30cb964e-63b2-401e-bc62-a8aee0611eee',
      type: 'clsorder',

      concept: clsGroup?.uuid,
      patient: uuidPerson,
      careSetting: '6f0c9a92-6f24-11e3-af88-005056821db0',

      orderer: uuidProvider,
      orderSet: {
        orderSetMembers: values?.otherGroup
          ? ortherSetMembers
          : orderSetMembers,
      },
    };
    setIsAdd(false);
    setIsEdit(false);
    dispatch(appointmentActions.handleCreateClsOrder(params));
  };

  const onFinishEdit = (values, clsGroup) => {
    if (!arrDiagnostic.length) {
      return message.error(
        'Bác sỹ cần đưa ra chẩn đoán trước sửa chỉ định cận lâm sàng',
      );
    }
    const orderCls = values?.CLS?.map(clsItem => ({
      concept: clsItem,
    }))?.filter(item => item.concept !== OTHER_CLS_CODE);

    const isNonCode = values?.CLS?.includes(OTHER_CLS_CODE);

    const orderSetMembers = isNonCode
      ? [
          ...orderCls,
          {
            concept: OTHER_CLS_CODE,
            conceptNonCode: values?.other,
          },
        ]
      : orderCls;

    const ortherSetMembers = [
      {
        concept: OTHER_CLS_CODE,
        conceptNonCode: values?.otherGroup,
      },
    ];

    const params = {
      concept: clsGroup?.uuid,
      orderSet: {
        orderSetMembers: values?.otherGroup
          ? ortherSetMembers
          : orderSetMembers,
      },
    };
    if (editSelected?.uuid) {
      const body = {
        uuid: editSelected.uuid,
        params,
      };

      dispatch(appointmentActions.handleEditCls(body));
      setIsAdd(false);
      setIsEdit(false);
    }
  };

  useEffect(() => {
    if (
      appointmentReducer?.isAddOrderCls === true ||
      appointmentReducer?.isEditCLSOrder === true
    ) {
      scroller.scrollTo('requested', {
        duration: 500,
        smooth: true,
        containerId: 'wrapper-layout-app',
        offset: -115,
      });
    }
  }, [appointmentReducer?.isAddOrderCls, appointmentReducer?.isEditCLSOrder]);

  const handleUploadImage = async (item, key) => {
    const list = [...item, ...listCls];
    setListCls(list);
    setUploadIndex(key);
  };
  const upload = async item => {
    statusUpload(true);
    const fmData = new FormData();
    const getImageUrl = (uuid: string) => {
      return dataProps.detail
        ? dataProps.detail.dataCloned.detail.images.filter(
            (i: any) => i.orderId === uuid,
          )
        : [];
    };
    const getUploaded = uuid => {
      return `${config.BASE_API_URL}/v1/obs/${uuid}/value?t=${Date.now()}`;
    };
    fmData.append(
      'json',
      JSON.stringify({
        person: item.patient.uuid,
        obsDatetime: moment()
          .utc()
          .format(),
        concept: '7cac8397-53cd-4f00-a6fe-028e8d743f8e',
        status: 'PRELIMINARY',
        interpretation: 'NORMAL',
        encounter: item.encounter.uuid,
        order: item.uuid,
      }),
    );
    for (let i = 0; i < listCls.length; i++) {
      const element = listCls[i];
      fmData.append('files', element);
    }
    const promiseArr = authorizedRequest.post('v1/obs/multi', fmData);
    promiseArr
      .then(async response => {
        const clRes: any = { ...response };
        const listImageCurrent: any = [...listImage];
        const uuidimage = await getImageUrl(clRes[0]?.order?.uuid);

        await listImageCurrent.push({
          fileName: 'jpg image',
          id: clRes[0]?.order?.uuid,
          uid: '-1',
          name: 'jpg image',
          status: 'done',
          type: 'image',
          url: `${getUploaded(uuidimage.at(-1)?.id)}`,
        });
        setListImage(listImageCurrent);
        setListCls([]);
        dispatch(appointmentActions.fetchDetailAppointment(appointmentId));
        message.success('Gửi kết quả tới bác sỹ thành công !', 1);
        statusUpload(false);
      })
      .catch(e => {
        setListCls([]);
        message.error('Gửi kết quả tới bác sỹ thất bại !', 1);
        statusUpload(false);
      });
  };
  const handleRemoveImage = image => {
    const filteredItems = listCls.filter(item => item.uid !== image.uid);
    setListCls(filteredItems);
  };

  const renderUpload = key => {
    return (
      <Upload
        method={undefined}
        disabled={false}
        accept="image/*"
        listType="picture-card"
        className="custom-upload"
        beforeUpload={(file, fileList) => {
          handleUploadImage(fileList, key);
          return false;
        }}
        showUploadList={{
          showRemoveIcon: listCls.length > 0 && true,
          removeIcon: <MinusCircleFilled style={{ color: 'red' }} />,
          showPreviewIcon: true,
        }}
        onRemove={file => {
          handleRemoveImage(file);
        }}
        multiple={true}
      >
        <Button
          className="rounded-md"
          type="primary"
          icon={<CloudUploadOutlined />}
        />
      </Upload>
    );
  };
  return (
    <div className="requested-Contaier">
      <div className="requested-content">
        <Row className="requested-title ">
          <span className="pl-6">Chỉ định cận lâm sàng</span>
        </Row>
        <hr />
        {arrRequest?.length > 0 ? (
          arrRequest?.map((item, key) => {
            return (
              <Row className="row-1" key={key}>
                <Col xs={24} className="pb-3">
                  <div className="requested-section">
                    <div className="requested-section__title ">
                      <span>Chỉ định {key + 1}</span>
                    </div>
                    {!isEdit &&
                      isEditAvailability &&
                      status !== APPOINTMENT_STATUS.WAITING_EXAMINATION &&
                      !listImages(item.uuid).length && (
                        <Button
                          onClick={() => {
                            setEditIndex(key);
                            setIsEdit(true);
                            setEditSelected(item);
                          }}
                          className="m-2 mb-0 rounded-md"
                        >
                          Sửa
                        </Button>
                      )}
                  </div>
                  <div className="requested-section__content">
                    <div className="requested-section__content_inside">
                      {isEdit && key === editIndex ? (
                        <RequestForm
                          onFinish={onFinishEdit}
                          selectDefault={item}
                          loading={appointmentReducer?.isLoadingOrder}
                          onClose={() => {
                            setIsAdd(false);
                            setIsEdit(!isEdit);
                          }}
                        />
                      ) : (
                        <div className="">
                          <div className="descriptionContainer">
                            <p className="text-have-color-#555555 description">
                              ({item.display || OTHER_CLS_DISPLAY}){' '}
                              {item.orderSet?.orderSetMembers
                                ?.map(i => i.display)
                                .join(', ')}
                            </p>
                          </div>
                          <div>
                            <div className="drugOrder-section__subTitle">
                              <span>
                                Kết quả cận lâm sàng {Number(key) + 1}
                              </span>
                            </div>
                            <div>
                              {listImages(item.uuid).length ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    flexFlow: 'wrap',
                                  }}
                                >
                                  <RequestImage
                                    images={listImages(item.uuid)}
                                  />
                                  {status ==
                                    APPOINTMENT_STATUS.WAITING_EXAMINATION &&
                                    renderUpload(key)}
                                </div>
                              ) : (
                                <div>
                                  <div className="no-content">
                                    <span className="no-content__text">
                                      Chưa có kết quả
                                    </span>
                                  </div>
                                  <div>
                                    {status ==
                                      APPOINTMENT_STATUS.WAITING_EXAMINATION &&
                                      renderUpload(key)}
                                  </div>
                                </div>
                              )}
                            </div>
                            {listCls.length > 0 && key === uploadIndex && (
                              <Button
                                className="rounded-md"
                                type="primary"
                                onClick={() => {
                                  upload(item);
                                }}
                                loading={isStatusUpload}
                              >
                                Gửi kết quả tới bác sỹ
                              </Button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <Row className="row-1">
            <Col xs={24}>
              <div className="anamnesis-section">
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {isEditAvailability && !isEdit && (
        <div className="requested-add-container">
          {isAdd ? (
            <RequestForm
              onFinish={onFinish}
              loading={appointmentReducer?.isLoadingOrder}
              onClose={() => {
                setIsAdd(false);
                setIsEdit(false);
              }}
            />
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => {
                  if (!arrDiagnostic.length) {
                    return message.error(
                      'Bác sỹ cần đưa ra chẩn đoán trước khi chỉ định cận lâm sàng',
                    );
                  }
                  setIsAdd(!isAdd);
                }}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Requested;
