import React, { useState, FC } from 'react';
import {
  Modal,
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  Table,
} from 'antd';
import { TransactionModel } from 'models/store/customer.model';

import * as _ from 'lodash';

import {
  OPTIONS_TRANSACTION,
  OPTIONS_FORM_TRANSACTION,
  TYPE_TRANSACTION_VALUE,
} from 'containers/Customer/customer.const';

import './ModalDetailCustomer.scss';
import { formatMoney } from 'helpers/utils';
import TextArea from 'antd/lib/input/TextArea';
import * as STATUS from '../../containers/Doctor/doctor.const';

interface CProps {
  show: boolean;
  onHide: () => void;
  itemTransaction: TransactionModel | any;
  customerCode: string;
  phone: string;
}

const ModalDetailCustomer: FC<CProps> = ({
  show,
  onHide,
  itemTransaction,
  customerCode,
  phone,
}) => {
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [visible] = useState(show);

  const timeClock = itemTransaction?.auditInfo?.dateCreated?.match(
    STATUS.regexTime,
  );

  const timeLatest =
    timeClock !== null ? timeClock[0]?.match(STATUS.regexDate2) : '';

  const dateForm = itemTransaction?.auditInfo?.dateCreated?.replace(
    STATUS.regexDate1,
    '',
  );

  const convertTime = (date: any) => {
    const latestDate =
      date !== null
        ? date
            ?.split('-')
            .reverse()
            .join('/')
        : '';

    const time =
      timeLatest !== null
        ? timeLatest[0]
            ?.split('-')
            .reverse()
            .join(':')
        : '';

    const latestTime = `${time} - ${latestDate}`;
    return latestTime;
  };

  const columns = [
    {
      title: 'Người chỉnh sửa',
      render: (value: any) => {
        return value?.auditInfo?.changedBy?.display;
      },
    },
    {
      title: 'Thời gian',

      render: (value: any) => {
        const dateTable = value?.auditInfo?.dateChanged?.replace(
          STATUS.regexDate1,
          '',
        );
        return convertTime(dateTable);
      },
    },
    {
      title: 'Nội dung chỉnh sửa',
      render: (value: any) => {
        return (
          <span>
            Số tiền: {''}
            {formatMoney(value?.amount)} &#8594;
            {formatMoney(itemTransaction?.amount)}
          </span>
        );
      },
    },
    {
      title: 'Lý do chỉnh sửa',
      render: (value: any) => {
        return value?.changeReason;
      },
    },
  ];

  return (
    <div className="modal-detail-transaction">
      <Modal
        title="Xem chi tiết giao dịch"
        centered
        visible={visible}
        onOk={onHide}
        onCancel={onHide}
        width={650}
        footer={null}
      >
        <Form
          {...formItemLayout}
          name="basic"
          initialValues={{ remember: true }}
          className="modal-detail-customer"
        >
          <Form.Item label="Loại giao dịch">
            <Radio.Group
              value={
                itemTransaction?.transactionType ===
                TYPE_TRANSACTION_VALUE.PATIENT_BOOK_APPOINTMENT
                  ? TYPE_TRANSACTION_VALUE.ADMIN_DEDUCT
                  : itemTransaction?.transactionType
              }
            >
              {OPTIONS_TRANSACTION.map((item: any) => {
                return (
                  <Radio key={item.value} value={item.value}>
                    {item.label}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>

          <Row gutter={[10, 24]} className="mb-6">
            <Col xs={24}>
              <Row>
                <Col xs={8}>Mã khách hàng</Col>
                <Col xs={12}>{customerCode}</Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={8}>Số điện thoại</Col>
                <Col xs={12}>{phone}</Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={8}>Họ và tên KH</Col>
                <Col xs={12}>{itemTransaction?.userName}</Col>
              </Row>
            </Col>
          </Row>

          {itemTransaction.transactionType ===
          TYPE_TRANSACTION_VALUE.ADMIN_ADD ? (
            <Form.Item label="Thời gian">
              <Input value={convertTime(dateForm)} disabled />
            </Form.Item>
          ) : (
            ''
          )}
          {itemTransaction?.transactionType ===
          TYPE_TRANSACTION_VALUE.ADMIN_ADD ? (
            <>
              <Form.Item label="Mã giao dịch">
                <Input disabled value={itemTransaction?.transactionCode} />
              </Form.Item>
              <Form.Item label="Nội dung chuyển khoản">
                <TextArea disabled value={itemTransaction?.note} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item label="Thanh toán cho cuộc hẹn">
                <Input
                  disabled
                  value={_.get(
                    itemTransaction,
                    'appointment.appointmentCode',
                    '',
                  )}
                />
              </Form.Item>

              <Form.Item label="Lý do">
                <TextArea
                  disabled
                  maxLength={300}
                  value={itemTransaction?.note}
                />
              </Form.Item>
            </>
          )}

          <Form.Item label="Số tiền">
            <Row gutter={[10, 0]} align="middle">
              <Col xs={20}>
                <Input disabled value={formatMoney(itemTransaction?.amount)} />
              </Col>
              <Col xs={4}>VND</Col>
            </Row>
          </Form.Item>
          <Form.Item label="Hình thức">
            <Select disabled defaultValue={itemTransaction?.form}>
              {OPTIONS_FORM_TRANSACTION.map((item: any) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className="flex flex-col">
            {itemTransaction?.previousVersion !== null ? (
              <>
                <span className="font-semibold text-opacity-65 !important">
                  Lịch sử chỉnh sửa
                </span>
                <Table
                  className="pt-8 table-modal-detail"
                  columns={columns}
                  dataSource={[itemTransaction?.previousVersion]}
                  pagination={false}
                />
              </>
            ) : (
              ''
            )}
          </div>
          <div className="button-container">
            <Button type="default" onClick={onHide}>
              Đóng
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default ModalDetailCustomer;
