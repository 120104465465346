import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Space,
  Upload,
  Image,
} from 'antd';
import config from 'configs';
import { UploadOutlined } from '@ant-design/icons';
import './ModalEditReferral.scss';
import * as referralAction from '../../store/referral/referral.action';
import * as STATUS from '../../containers/Doctor/doctor.const';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps: any;
}

const ModalEditPromotion: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sDate, setSDate] = useState<any>();
  const [eDate, setEDate] = useState<any>();
  const [fileList, setfileList] = useState<any>([]);
  const [img, setImg] = useState<any>([]);
  const referralReducer = useSelector((state: any) => state.referralReducer);
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const getValStartDate = current => {
    if (eDate && eDate < current) {
      form.setFieldsValue({
        endDate: '',
      });
      setEDate(null);
    }
    setSDate(current);
  };
  const getValEndDate = current => {
    setEDate(current);
  };
  const disabledDate = current => {
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const disabledEndDate = current => {
    return current && current < sDate;
  };

  const onFinish = values => {
    const { endDate, value, referralCode, name, startDate } = values;
    const newStartDate = startDate?.format('YYYY-MM-DD hh:mm:ss');
    const newEndDate = endDate?.format('YYYY-MM-DD hh:mm:ss');

    const nameTrim = `${name}`.trim();
    const codeTrim = `${referralCode}`.trim();
    if (nameTrim.length === 0) {
      return form.setFieldsValue({
        name: '',
      });
    }
    if (codeTrim.length === 0) {
      return form.setFieldsValue({
        referralCode: '',
      });
    }
    const file = { attributeType: '1b1eee4f-fc0b-40a9-955a-659cd92a6894' };

    if (dataProps.attributes[0]?.uuid) {
      Object.assign(file, {
        uuid: dataProps.attributes[0].uuid,
      });
    }
    const body = {
      uuid: dataProps.uuid,
      value,
      name: nameTrim,
      endDate: newEndDate,
      attribute: file,
    };
    if (dataProps.status == 'WAITING') {
      Object.assign(body, {
        startDate: newStartDate,
        referralCode: codeTrim,
      });
    }
    const formData = new FormData();
    formData.append('parent', JSON.stringify(body));
    formData.append('file', fileList[0]);
    dispatch(referralAction.handleUpdateReferralRequested(formData));
  };

  useEffect(() => {
    if (referralReducer?.errorEdit === false) {
      onHide();
      dispatch(referralAction.handleDisplayModalEdit());
    }
  }, [referralReducer]);

  useEffect(() => {
    const getImageUrl = () => {
      return `${config.BASE_API_URL}/v1/referralAttribute/${
        dataProps?.attributes[0]?.uuid
      }/value?t=${Date.now()}`;
    };
    // new File(bits, name[, options]);
    const imageGet = new File(
      [dataProps?.attributes[0]?.resourceVersion],
      dataProps?.attributes[0]?.valueReference,
      { type: 'image/png' },
    );
    const imgDefault = {
      uid: '1',
      name: dataProps?.attributes[0]?.valueReference,
      url: getImageUrl(),
    };
    setfileList([imageGet]);
    setImg([imgDefault]);
    form.setFieldsValue({
      name: dataProps?.name || '',
      referralCode: dataProps.referralCode,
      value: dataProps?.value,
      startDate: moment(dataProps?.startDate),
      endDate: moment(dataProps?.endDate),
    });
    setEDate(moment(dataProps?.startDate));
    setSDate(moment(dataProps?.endDate));
  }, [dataProps]);

  return (
    <Modal
      title={title || 'Sửa chương trình giới thiệu'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={800}
      footer={null}
      className="z-9999"
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        form={form}
        requiredMark={false}
      >
        <Form.Item
          label="Tên chương trình"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="name"
        >
          <Input placeholder="Nhập tên chương trình" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          label="Mã giới thiệu"
          name="referralCode"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            {
              pattern: STATUS.justNumAndChar,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            { whitespace: true, message: 'Nội dung không được để trống' },
          ]}
        >
          <Input
            placeholder="Nhập mã giới thiệu"
            disabled={dataProps.status !== 'WAITING'}
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Form.Item label="Logo">
          <Upload
            onRemove={(file): any => {
              return setfileList([]);
            }}
            beforeUpload={(file): any => {
              setfileList([file]);
              setImg([]);
              return false;
            }}
            listType="picture"
            name="logo"
            maxCount={1}
            multiple={true}
            accept=".jpg , .png, .jpge"
            className="upload-list-inline"
          >
            <Button style={{ marginBottom: 20 }} icon={<UploadOutlined />} />
          </Upload>
          {img[0]?.name && (
            <div>
              <Image width={200} src={img[0].url} />
            </div>
          )}
        </Form.Item>
        <Form.Item label="Số tiền" required>
          <Space>
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền thông tin bắt buộc!',
                },
                {
                  pattern: /^[\d]{0,10}$/,
                  message: 'Vui lòng nhập ít hơn 10 ký tự',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      (getFieldValue('value') >= 0 &&
                        Number.isInteger(getFieldValue('value'))) ||
                      getFieldValue('value') === ''
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Giá trị không hợp lệ!');
                  },
                }),
              ]}
              name="value"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value: any) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                className="input-type mr-2"
                placeholder="Nhập số tiền"
              />
            </Form.Item>
            <div className="">VND</div>
          </Space>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate}
            locale={locale}
            disabled={dataProps.status === 'HAPPENING'}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            onChange={getValStartDate}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            locale={locale}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày kết thúc"
            onChange={getValEndDate}
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-add-container button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button className="exit-button" type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditPromotion;
