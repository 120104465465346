import React, { FC, useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { getExportCodeFile } from 'api/promotion';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Space,
} from 'antd';
import * as STATUS from '../../containers/Doctor/doctor.const';
import './ModalAddPromotion.scss';
import * as promotionAction from '../../store/promotion/promotion.action';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
}

const { Option } = Select;

const ModalAddPromotion: FC<CProps> = ({ show, title, onHide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [type, setType] = useState<string>('');
  const promotionReducer = useSelector((state: any) => state.promotionReducer);
  const [exportFile, setExportFile] = useState<boolean>(false);
  const [discountType, setDiscountType] = useState<string>('REAL_NUMBER');

  const [sDate, setSDate] = useState<any>();
  const [eDate, setEDate] = useState<any>();

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getValStartDate = current => {
    if (eDate && eDate < current) {
      form.setFieldsValue({
        endDate: '',
      });
      setEDate(null);
    }
    setSDate(current);
  };

  const getValEndDate = current => {
    setEDate(current);
  };

  const disabledDate = current => {
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const disabledEndDate = current => {
    if (sDate) return current && current < sDate;
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const onChangeDiscountType = value => {
    setDiscountType(value);
    form.setFieldsValue({
      value: '',
    });
  };

  const onFinish = values => {
    const {
      startDate,
      endDate,
      name,
      value,
      promotionCampaignCode,
      numberOfUses,
      numberOfPromotionCodes,
      promotionTemplateCode,
    } = values;

    const nameTrim = `${name}`.trim();
    const codeTrim = `${promotionCampaignCode}`.trim();
    const promotionTemplateCodeTrim = `${promotionTemplateCode}`.trim();
    if (nameTrim.length === 0) {
      return form.setFieldsValue({
        name: '',
      });
    }
    if (codeTrim.length === 0) {
      return form.setFieldsValue({
        value: '',
      });
    }

    const newStartDate = startDate?.format('YYYY-MM-DD');
    const newEndDate = endDate?.format('YYYY-MM-DD');
    const numberUses = Number(numberOfUses);
    const numberPromotionCodes = Number(numberOfPromotionCodes);

    const initBody = {
      name: nameTrim,
      type,
      value,
      promotionCampaignCode: codeTrim,
      discountType,
      startDate: newStartDate,
      endDate: newEndDate,
      numberOfUses: numberUses,
    };

    const body =
      type === 'ALL'
        ? { ...initBody, promotionTemplateCode: promotionTemplateCodeTrim }
        : {
            ...initBody,
            numberOfPromotionCodes: numberPromotionCodes,
          };
    dispatch(promotionAction.handleCreatePromotionRequested(body));
  };

  useEffect(() => {
    if (promotionReducer?.errorAdd === false && type === 'ALL') {
      onHide();
      dispatch(promotionAction.handleDisplayModalAdd());
    } else if (promotionReducer?.errorAdd === false && type === 'SEPARATE') {
      setExportFile(true);
    }
  }, [promotionReducer]);

  const initialValues = {
    name: '',
    type: '',
    discountType: 'REAL_NUMBER',
    startDate: '',
    endDate: '',
    numberOfUses: '',
  };

  const getFileExcel = () => {
    const params = {
      file: 'excel',
      promotionCampaign: promotionReducer.dataAddPromotion.uuid,
    };

    getExportCodeFile(params).then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Makhuyenmai${moment(
          promotionReducer.dataAddPromotion.dateCreated,
        ).format('YYYY-MM-DD-HH-mm-ss')}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <Modal
      title={title || 'Thêm mới khuyến mại'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={800}
      footer={null}
      className="z-9999"
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initialValues}
        form={form}
        requiredMark={false}
      >
        <Form.Item
          label="Tên sự kiện/ Đợt khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="name"
        >
          <Input
            placeholder="Nhập tên khuyến mại"
            style={{ width: '80%' }}
            disabled={exportFile}
          />
        </Form.Item>
        <Form.Item
          label="Mã sự kiện/ Đợt khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            {
              pattern: STATUS.justNumAndChar,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            {
              min: 3,
              message: 'Vui lòng nhập lớn hơn 3 kí tự',
            },
          ]}
          name="promotionCampaignCode"
        >
          <Input
            placeholder="Nhập mã khuyến mại"
            style={{ width: '80%' }}
            disabled={exportFile}
          />
        </Form.Item>
        <Form.Item
          label="Loại khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
          name="type"
        >
          <Select
            value={type}
            onChange={value => setType(value)}
            style={{ width: '80%' }}
            disabled={exportFile}
          >
            <Option value="">--- Chọn ---</Option>
            <Option value="ALL">Dùng chung</Option>
            <Option value="SEPARATE">Dùng riêng</Option>
          </Select>
        </Form.Item>
        <div className="wrapper-item">
          {type === 'ALL'
            ? type && (
                <Form.Item
                  label="Mã khuyến mại"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thông tin bắt buộc!',
                    },
                    {
                      pattern: STATUS.justNumAndChar,
                      message: 'Vui lòng nhập đúng thông tin!',
                    },
                    {
                      min: 3,
                      message: 'Vui lòng nhập lớn hơn 3 kí tự',
                    },
                  ]}
                  name="promotionTemplateCode"
                >
                  <Input
                    placeholder="Nhập mã khuyến mại"
                    style={{ width: '80%' }}
                  />
                </Form.Item>
              )
            : type && (
                <Form.Item
                  label="Số lượng"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thông tin bắt buộc!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (getFieldValue('numberOfPromotionCodes') > 0 &&
                            Number.isInteger(
                              getFieldValue('numberOfPromotionCodes'),
                            )) ||
                          getFieldValue('numberOfPromotionCodes') === ''
                        ) {
                          return Promise.resolve();
                        }

                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Giá trị không hợp lệ!');
                      },
                    }),
                  ]}
                  name="numberOfPromotionCodes"
                >
                  <InputNumber
                    placeholder="Nhập số lượng "
                    style={{ width: '80%' }}
                    disabled={exportFile}
                  />
                </Form.Item>
              )}
        </div>
        <Form.Item
          label="Số lượt sử dụng"
          // fix bug so luot su dung (update code)
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            // ({ getFieldValue }) => ({
            //   validator(rule, value) {
            //     if (
            //       (getFieldValue('numberOfUses') > 0 &&
            //         Number.isInteger(getFieldValue('numberOfUses'))) ||
            //       getFieldValue('numberOfUses') === ''
            //     ) {
            //       return Promise.resolve();
            //     }

            //     // eslint-disable-next-line prefer-promise-reject-errors
            //     return Promise.reject('Giá trị không hợp lệ!');
            //   },
            // }),
          ]}
          name="numberOfUses"
        >
          <InputNumber
            placeholder="Nhập số lượt sử dụng"
            style={{ width: '80%' }}
            type="number"
            disabled={exportFile}
          />
        </Form.Item>
        <Form.Item
          label="Hình thức khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
          name="discountType"
        >
          <Select
            value={discountType}
            style={{ width: '80%' }}
            onChange={onChangeDiscountType}
            disabled={exportFile}
          >
            <Option value="REAL_NUMBER">Tiền mặt</Option>
            <Option value="PERCENT">Chiết khấu(%)</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Giá trị khuyến mại" required>
          <Space>
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền thông tin bắt buộc!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      (getFieldValue('value') >= 0 &&
                        Number.isInteger(getFieldValue('value'))) ||
                      getFieldValue('value') === ''
                    ) {
                      if (
                        (discountType === 'PERCENT' &&
                          getFieldValue('value') > 100) ||
                        getFieldValue('value') > 9999999999
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Giá trị không hợp lệ!');
                      }
                      return Promise.resolve();
                    }

                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Giá trị không hợp lệ!');
                  },
                }),
              ]}
              name="value"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value: any) =>
                  discountType === 'PERCENT'
                    ? `${value}`
                    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                className="input-type mr-2"
                disabled={exportFile}
              />
            </Form.Item>
            <div className="">{discountType === 'PERCENT' ? '%' : 'VND'}</div>
          </Space>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate}
            locale={locale}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            onChange={getValStartDate}
            showToday={false}
            disabled={exportFile}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            locale={locale}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày kết thúc"
            onChange={getValEndDate}
            style={{ width: '80%' }}
            showToday={false}
            disabled={exportFile}
          />
        </Form.Item>

        <div className="button-add-container button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          {exportFile ? (
            <Button
              className="export-button"
              onClick={getFileExcel}
              type="primary"
            >
              Xuất danh sách mã
            </Button>
          ) : (
            <Button
              className="exit-button"
              type="primary"
              htmlType="submit"
              loading={promotionReducer?.isLoadingAddPromotion}
            >
              Lưu
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddPromotion;
