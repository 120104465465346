import React, { FC, useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Button, Table, Input, Form, Modal, Spin } from 'antd';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatDateDayVN } from 'helpers/utils';
import moment from 'moment';
import ActionTable from '../../components/Share/Table/Action';
import ModalAddPromotion from '../../components/Promotion/ModalAddPromotion';
import ModalEditPromotion from '../../components/Promotion/ModalEditPromotion';
import ModalCodePromotion from '../../components/Promotion/ModalCodePromotion';
import ModalUserPromotion from '../../components/Promotion/ModalUserPromotion';
import * as promotionActions from '../../store/promotion/promotion.action';

interface ParamsPromotionModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  length?: any;
}

const initStartIndex: number = 1;

const Promotion: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { confirm } = Modal;

  const [paramsPromotion, setParamsPromotion] = useState<ParamsPromotionModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
    startDate: null,
    endDate: null,
  });

  const inputSearch = useRef<any>(null);

  const [showAddPromotionModal, setShowAddPromotionModal] = useState<boolean>(
    false,
  );

  const [showCodePromotionModal, setShowCodePromotionModal] = useState<boolean>(
    false,
  );

  const [showEditPromotionModal, setShowEditPromotianModal] = useState<boolean>(
    false,
  );

  const [showUserPromotionModal, setShowUserPromotionModal] = useState<boolean>(
    false,
  );

  const [selectedData, setSelectedData] = useState<any>();
  const promotionReducer = useSelector((state: any) => state.promotionReducer);

  const onOpenModalAddPromotion = () => {
    setShowAddPromotionModal(true);
    delete paramsPromotion?.q;
    delete paramsPromotion?.startDate;
    delete paramsPromotion?.endDate;
    form.setFieldsValue({ startDate: '', endDate: '', q: '' });
    getPromotion();
  };

  const onCloseModalAddPromotion = () => {
    setShowAddPromotionModal(false);
  };

  const onOpenModalCodePromotion = item => {
    setSelectedData(item);
    setShowCodePromotionModal(true);
  };

  const onCloseModalCodePromotion = () => {
    setShowCodePromotionModal(false);
  };

  const onOpenModalUserPromotion = item => {
    setSelectedData(item);
    setShowUserPromotionModal(true);
    setShowCodePromotionModal(false);
  };

  const onCloseModalUserPromotion = () => {
    setShowUserPromotionModal(false);
  };

  const onOpenEditAddPromotion = item => {
    setSelectedData(item);
    setShowEditPromotianModal(true);
  };

  const onCloseModalEditPromotion = () => {
    setShowEditPromotianModal(false);
  };

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsPromotion({
      ...paramsPromotion,
      ...body,
    });
  };

  const onFinish = (value: any) => {
    const { query } = value;
    const queryTrim = query.trim();

    setParamsPromotion({
      ...paramsPromotion,
      q: queryTrim,
      startIndex: initStartIndex,
    });
  };

  // eslint-disable-next-line no-shadow
  const handleLockPromotion = record => {
    const status = _.get(record, 'status', 'LOCKED');

    const setStatus = () => {
      if (status === 'LOCKED') {
        const current = moment();
        const stDate = moment(record.startDate);
        const eDate = moment(record.endDate);

        if (
          current.isSameOrAfter(stDate, 'date') &&
          current.isSameOrBefore(eDate, 'date')
        ) {
          return 'HAPPENING';
        }
        if (current < stDate) {
          return 'WAITING';
        }
        if (current > eDate) {
          return 'ENDED';
        }
      }
      return 'LOCKED';
    };
    const onLock = async () => {
      const uuid = _.get(record, 'uuid', '');
      const newStatus = setStatus();
      const body = {
        uuid,
        params: { status: newStatus },
      };
      // console.log(body, 'log');

      await dispatch(promotionActions.handleLockedPromotionRequested(body));
      await getPromotion();
    };
    confirm({
      title:
        status !== 'LOCKED'
          ? 'Bạn có chắc chắn khoá khuyến mại này không ?'
          : 'Bạn có chắc chắn mở khóa khuyến mại này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onLock(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const getPromotion = () => {
    dispatch(promotionActions.getPromotionRequested(paramsPromotion));
  };

  useEffect(() => {
    getPromotion();
  }, [paramsPromotion]);

  const columns = [
    // {
    //   title: 'STT',
    //   key: '1',

    //   dataIndex: 'key',
    //   render: (value, row, index) => {
    //     if (
    //       paramsPromotion?.startIndex !== undefined &&
    //       paramsPromotion?.limit !== undefined &&
    //       paramsPromotion
    //     ) {
    //       return (
    //         (paramsPromotion?.startIndex - 1) * paramsPromotion?.limit +
    //         index +
    //         1
    //       );
    //     }
    //   },
    // },
    {
      title: 'Ngày tạo',
      key: 'dateCreated',
      render: (value, row, index) => {
        return formatDateDayVN(row?.dateCreated);
      },
    },
    {
      title: 'Tên sự kiện/đợt khuyến mại',
      key: 'name',
      width: '20%',
      render: (value, row, index) => {
        return value.name;
      },
    },
    {
      key: 'Code',
      title: 'Mã sự kiện/đợt khuyến mại',
      width: '20%',
      render: (value, row, index) => {
        return (
          <div style={{ maxWidth: 200 }} className="">
            {value.promotionCampaignCode}
          </div>
        );
      },
    },
    {
      key: 'type',
      title: 'Loại khuyến mại',

      render: (value, row, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div className=" font-semibold">
            {value.type === 'ALL' ? 'Dùng chung' : 'Dùng riêng'}
          </div>
        );
      },
    },
    {
      key: 'startDay',
      title: 'Ngày bắt đầu',
      render: (value, row, index) => {
        return formatDateDayVN(row?.startDate);
      },
    },
    {
      key: 'endDay',
      title: 'Ngày kết thúc',

      render: (value, row, index) => {
        return formatDateDayVN(row?.endDate);
      },
    },
    {
      key: 'status',
      title: 'Trạng thái',

      render: (value, row, index) => {
        switch (value.status) {
          case 'ENDED':
            return 'Đã kết thúc';
          case 'WAITING':
            return 'Chưa diễn ra';
          case 'HAPPENING':
            return 'Đang diễn ra';
          default:
            return 'Đã khóa';
        }
      },
    },
    {
      key: 'Action',
      title: 'Thao tác',
      render: (value: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            hasDelete={false}
            hasDetail={false}
            hasLock={true}
            isLock={value.status === 'LOCKED'}
            isEdit={value.status === 'LOCKED'}
            handleLock={() => handleLockPromotion(value)}
            handleEdit={() => onOpenEditAddPromotion(value)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="doctor-information">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý chiến dịch khuyến mại</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onOpenModalAddPromotion}>
              Thêm mới
            </Button>
          </Col>
        </Row>

        <Form onFinish={onFinish} form={form}>
          <Row style={{ marginTop: 30 }} gutter={[20, 0]}>
            <Col xs={16} className="pr-5">
              <Form.Item name="query">
                <Input
                  ref={inputSearch}
                  placeholder="Tìm kiếm theo tên sự kiện/đợt khuyến mại..."
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: promotionReducer?.isLoadingPromotion,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={promotionReducer?.dataPromotion?.results}
            pagination={{
              current: paramsPromotion.startIndex,
              pageSize: paramsPromotion.limit,
              total: promotionReducer?.dataPromotion?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            rowClassName=" cursor-pointer"
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  onOpenModalCodePromotion(record);
                },
              };
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
      {showAddPromotionModal && (
        <ModalAddPromotion
          show={showAddPromotionModal}
          onHide={onCloseModalAddPromotion}
        />
      )}
      {showEditPromotionModal && (
        <ModalEditPromotion
          show={showEditPromotionModal}
          onHide={onCloseModalEditPromotion}
          dataProps={selectedData}
        />
      )}

      {showCodePromotionModal && (
        <ModalCodePromotion
          show={showCodePromotionModal}
          onHide={onCloseModalCodePromotion}
          onSelect={onOpenModalUserPromotion}
          dataProps={selectedData}
        />
      )}
      {showUserPromotionModal && (
        <ModalUserPromotion
          show={showUserPromotionModal}
          onHide={onCloseModalUserPromotion}
          dataProps={selectedData}
        />
      )}
    </div>
  );
};

export default Promotion;
