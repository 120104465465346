import React, { FC } from 'react';
import LoginForm from 'components/Login/Form';
import Logo from 'assets/images/common/ic_logo@3x.png';

const Login: FC = () => {
  return (
    <div
      className="flex justify-center flex-col items-center align-middle h-full"
      style={{
        // margin: '0',
        // position: 'relative',
        paddingTop: '5%',
        // left: '50%',
        // transform: 'translateY(-50%)',
      }}
    >
      <div className="h-32 flex justify-center items-center ">
        <img
          src={Logo}
          alt="Logo"
          className="object-contain w-full h-full h-16"
        />
      </div>
      <LoginForm />
    </div>
  );
};

export default Login;
