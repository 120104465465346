import authorizedRequest from 'helpers/request/authorizedRequest';
import { isRoleDoctor } from 'helpers/utils';

import config from '../configs/index';

export function listAppointment(query: any) {
  return new Promise((resolve, reject) => {
    const listAllAppointment = authorizedRequest.get(
      '/v1/appointmentscheduling/appointment?v=full&totalCount=true',
      query,
    );
    return resolve(listAllAppointment);
  });
}

export async function startCall(params: { appointmentId: string }) {
  const urlDoctor = `${config.BASE_CALL_URL}/openmrs/ws/rest/v1/doctorcall/${params.appointmentId}`;
  const urlAdmin = `${config.BASE_CALL_URL}/openmrs/ws/rest/v1/visitStatus/${params.appointmentId}/call-detail`;
  const callService = await authorizedRequest.post(
    isRoleDoctor() === true ? urlDoctor : urlAdmin,
  );
  return callService;
}

export function getAppointmentById(id: string, params?: any) {
  return new Promise((resolve, reject) => {
    const detailAppointmentById = authorizedRequest.get(
      `/v1/appointmentscheduling/appointment/${id}?v=full`,
      {
        withCredentials: true,
        params,
      },
    );
    return resolve(detailAppointmentById);
  });
}

export function fetchAllergyApi(patientId: string) {
  return authorizedRequest.get(`/v1/patient/${patientId}/allergy`, {
    params: {
      v: 'full',
      provider: undefined,
    },
  });
}

export function fetchRelationship(patientId: string) {
  return authorizedRequest.get(`/v1/relationship?person=${patientId}&v=full`);
}

export function fetchConditionHistory(patientId: string) {
  return authorizedRequest.get(
    `/emrapi/conditionhistory?patientUuid=${patientId}`,
  );
}

export function getConceptDiagnosisApi(params: { q: string }) {
  return authorizedRequest.get('v1/concept?v=full&class=Diagnosis&limit=100', {
    params: {
      ...params,
      v: 'full',
      totalCount: true,
    },
  });
}

export function createFollowingApi(payload: any) {
  return authorizedRequest.post(
    `v1/appointmentscheduling/appointmentfollow`,
    payload,
  );
}

export function updateFollowingApi(followUuid: string, payload: any) {
  return authorizedRequest.post(
    `v1/appointmentscheduling/appointmentfollow/${followUuid}`,
    payload,
  );
}

export function createObsApi(payload: any) {
  return authorizedRequest.post('v1/obs', payload);
}

export function updateObsApi(id: string, payload: any) {
  return authorizedRequest.post(`v1/obs/${id}`, payload);
}
export function createExaminationApi(form: any) {
  return authorizedRequest.post('v1/obs', form);
}
