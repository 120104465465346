import { all, call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreateSuccessMessage,
  fireUpdateSuccessMessage,
  getMessageFromError,
  fireResetPasswordSuccessMessage,
  fireCheckoutSuccessMessage,
  fireDeleteDoctorSuccessMessage,
  fireUploadSuccessMessage,
} from 'helpers/utils';
import * as doctorActions from './doctor.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';
import * as api from '../../api/doctor';

function* getDoctorRequested(action) {
  try {
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/provider', { params }),
    );
    yield put(doctorActions.getDoctorSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.getDoctorFailed(error));
  }
}

function* getSpecialistRequested(action) {
  try {
    const params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/concept', { params }),
    );
    yield put(doctorActions.getSpecialistSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.getSpecialistFailed(error));
  }
}
function* getHealthFacilityRequested(action) {
  try {
    const params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/concept', { params }),
    );
    yield put(doctorActions.getHealthFacilitySucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.getHealthFacilityFailed(error));
  }
}

function* handleUploadAvatarRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/user/${action.payload.uuid}/attribute`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );

    yield put(doctorActions.handleUploadAvatarSucceeded(res));
  } catch (error) {
    yield put(doctorActions.handleUploadAvatarFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleUpdateAvatarRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/user/${action.payload.uuid}/attribute`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );
    fireUploadSuccessMessage();
    yield put(doctorActions.handleUpdateAvatarSucceeded(res));
  } catch (error) {
    yield put(doctorActions.handleUpdateAvatarFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleUpdateCoverRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/user/${action.payload.uuid}/attribute`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );
    // fireUploadSuccessMessage();
    yield put(doctorActions.handleUpdateCoverSucceeded(res));
  } catch (error) {
    yield put(doctorActions.handleUpdateCoverFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleUploadCoverRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/user/${action.payload.uuid}/attribute`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );
    fireCreateSuccessMessage().then(
      () => {
        window.location.href = `/app/doctor/information`;
      },
      () => {},
    );
    yield put(doctorActions.handleUploadCoverSucceeded(res));
  } catch (error) {
    yield put(doctorActions.handleUploadCoverFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* getAvatarRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`v1/userAttribute/${action.payload}/value`),
    );
    yield put(doctorActions.getAvatarSucceeded(res));
  } catch (error) {
    yield put(doctorActions.getAvatarFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* getCoverRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`v1/userAttribute/${action.payload}/value`),
    );
    yield put(doctorActions.getCoverSucceeded(res));
  } catch (error) {
    yield put(doctorActions.getCoverFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* resetPassword(action: any) {
  try {
    yield call(
      api.resetPassword,
      action.payload.id,
      action.payload.newPassword,
    );

    fireResetPasswordSuccessMessage();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

export function* getDoctorDetailByIdRequested(action: any) {
  try {
    const params = { ...action.payload.params };
    const res = yield call(() =>
      authorizedRequest.get(`/v1/provider/${action.payload.id}`, { params }),
    );
    yield put(doctorActions.getDoctorDetailByIdSucceeded(res));
  } catch (error) {
    yield put(doctorActions.getDoctorDetailByIdFailed(error));
    message.error(getMessageFromError(error));
  }
}

export function* getDetailWalletDoctorRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/providerIncome/${action.payload.id.idChoose}`),
    );
    yield put(doctorActions.getDetailWalletDoctorSucceeded(res));
  } catch (error) {
    yield put(doctorActions.getDetailWalletDoctorFailed(error));
    message.error(getMessageFromError(error));
  }
}

export function* getDetailFeeHistoryRequested(action: any) {
  try {
    const params = { ...action.payload.params };
    const res = yield call(() =>
      authorizedRequest.get(
        `/v1/provider/${action.payload.id.idPro}/attribute/${action.payload.id.idA}`,
        { params },
      ),
    );
    yield put(doctorActions.getDetailFeeHistorySucceeded(res));
  } catch (error) {
    yield put(doctorActions.getDetailFeeHistoryFailed(error));
    message.error(getMessageFromError(error));
  }
}
function* checkoutFeeRequested(action: any) {
  try {
    const date = { ...action.payload.month };
    const res = yield call(api.checkoutFee, action.payload.id, date?.month);
    yield put(doctorActions.checkoutFeeSucceeded(res));
    fireCheckoutSuccessMessage().then(
      () => {
        window.location.reload();
      },
      () => {},
    );
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.checkoutFeeFailed(error));
  }
}

function* editFeeRequested(action: any) {
  try {
    const value = { ...action.payload.params };

    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/provider/${action.payload.id.idProvider}/attribute/${action.payload.id.idAttr}`,
        value,
      ),
    );
    yield put(doctorActions.editFeeSucceeded(res));
    fireUpdateSuccessMessage();
  } catch (error) {
    yield put(doctorActions.editFeeFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* addDoctorRequested(action: any) {
  try {
    const params = { ...action.payload };
    const res = yield call(() =>
      authorizedRequest.post(`/v1/provider`, {
        ...params,
      }),
    );
    fireCreateSuccessMessage().then(
      () => {
        window.location.href = `/app/doctor/information`;
      },
      () => {},
    );
    yield put(doctorActions.addDoctorSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.addDoctorFailed(error));
  }
}
function* editDoctorRequested(action: any) {
  try {
    const data = action.payload.payload;
    const res = yield call(() =>
      authorizedRequest.post(`/v1/provider/${action.payload.id}`, data),
    );
    yield put(doctorActions.editDoctorSucceeded(res));
    fireUpdateSuccessMessage().then(
      () => {
        window.location.href = `/app/doctor/information`;
      },
      () => {},
    );
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.editDoctorFailed(error));
  }
}
function* getDetailDoctorRequested(action) {
  try {
    const params = action.payload;
    const res = yield call(() =>
      authorizedRequest.get(`/v1/provider/${action.payload.id}`, params),
    );
    yield put(doctorActions.getDetailDoctorSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.getDetailDoctorFailed(error));
  }
}

function* handleDeleteDoctorRequested(action) {
  try {
    const res = yield call(() => {
      return authorizedRequest.delete(`/v1/provider/${action.payload}`);
    });
    fireDeleteDoctorSuccessMessage();
    yield put(doctorActions.handleDeleteDoctorSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(doctorActions.handleDeleteDoctorFailed(error));
  }
}
export default function* doctorSaga() {
  yield all([
    takeLatest(doctorActions.getDoctorRequested, getDoctorRequested),
    takeLatest(doctorActions.getSpecialistRequested, getSpecialistRequested),
    takeLatest(
      doctorActions.getHealthFacilityRequested,
      getHealthFacilityRequested,
    ),
    takeLatest(doctorActions.resetPassword, resetPassword),
    takeLatest(
      doctorActions.getDoctorDetailByIdRequested,
      getDoctorDetailByIdRequested,
    ),
    takeLatest(
      doctorActions.getDetailFeeHistoryRequested,
      getDetailFeeHistoryRequested,
    ),
    takeLatest(
      doctorActions.getDetailWalletDoctorRequested,
      getDetailWalletDoctorRequested,
    ),
    takeEvery(doctorActions.checkoutFeeRequested, checkoutFeeRequested),
    takeLatest(doctorActions.editFeeRequested, editFeeRequested),
    takeLatest(doctorActions.addDoctorRequested, addDoctorRequested),
    takeLatest(doctorActions.editDoctorRequested, editDoctorRequested),
    takeLatest(
      doctorActions.getDetailDoctorRequested,
      getDetailDoctorRequested,
    ),
    takeLatest(
      doctorActions.handleUploadAvatarRequested,
      handleUploadAvatarRequested,
    ),
    takeLatest(doctorActions.getAvatarRequested, getAvatarRequested),
    takeLatest(doctorActions.getCoverRequested, getCoverRequested),
    takeLatest(
      doctorActions.handleUploadCoverRequested,
      handleUploadCoverRequested,
    ),
    takeLatest(
      doctorActions.handleUpdateCoverRequested,
      handleUpdateCoverRequested,
    ),
    takeLatest(
      doctorActions.handleUpdateAvatarRequested,
      handleUpdateAvatarRequested,
    ),
    takeLatest(
      doctorActions.handleDeleteDoctorRequested,
      handleDeleteDoctorRequested,
    ),
  ]);
}
