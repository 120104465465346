import authorizedRequest from '../helpers/request/authorizedRequest';

export const resetPassword = (id: string, newPassword) =>
  authorizedRequest.post(`/v1/password/${id}`, {
    newPassword,
  });
export const checkoutFee = (id: string, month: any) => {
  authorizedRequest.post(`/v1/providerIncome/${id}`, {
    month,
  });
};

export const getFeeDetail = (providerId: string, attributeTypeId: string) => {
  authorizedRequest.get(
    `/v1/provider/${providerId}/attribute/${attributeTypeId}`,
  );
};

export const getDoctorById = (idDoctor: string, params) => {
  authorizedRequest.get(`/v1/provider/${idDoctor}`, {
    params,
  });
};

export const getFeeHistory = (idProvider: string, idType: string) => {
  authorizedRequest.get(`v1/provider/${idProvider}/attribute/${idType}`);
};
