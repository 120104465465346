import React, { FC, useEffect, useState, useRef, useCallback } from 'react';
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  Form,
  Modal,
  Spin,
  Image,
} from 'antd';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatDateDayVN } from 'helpers/utils';
import moment from 'moment';
import config from 'configs';
import ActionTable from '../../components/Share/Table/Action';
import ModalAddReferral from '../../components/Referral/ModalAddReferral';
import ModalEditReferral from '../../components/Referral/ModalEditReferral';
import ModalViewReferral from '../../components/Referral/ModalViewReferral';
import * as referralActions from '../../store/referral/referral.action';
import './Information.scss';

interface ParamsReferralModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  length?: any;
}

const initStartIndex: number = 1;

const Referral: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const [paramsReferral, setParamsReferral] = useState<ParamsReferralModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
    startDate: null,
    endDate: null,
  });

  const inputSearch = useRef<any>(null);

  const [showAddReferralModal, setShowAddReferralModal] = useState<boolean>(
    false,
  );
  const [showEditReferralModal, setShowEditReferralModal] = useState<boolean>(
    false,
  );

  const [showDetailReferral, setShowDetailReferral] = useState<boolean>(false);

  const [selectedData, setSelectedData] = useState<any>();
  const referralReducer = useSelector((state: any) => state.referralReducer);

  const onOpenModalAddReferral = () => {
    setShowAddReferralModal(true);
    delete paramsReferral?.q;
    delete paramsReferral?.startDate;
    delete paramsReferral?.endDate;
    form.setFieldsValue({ startDate: '', endDate: '', q: '' });
    // getReferral();
  };

  const onCloseModalAddReferral = () => {
    setShowAddReferralModal(false);
  };

  const onOpenEditAddReferral = item => {
    setSelectedData(item);
    setShowEditReferralModal(true);
  };

  const onCloseModalEditReferral = () => {
    setShowEditReferralModal(false);
  };

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsReferral({
      ...paramsReferral,
      ...body,
    });
  };

  const onFinish = (value: any) => {
    const { query } = value;
    const queryTrim = query.trim();

    setParamsReferral({
      ...paramsReferral,
      q: queryTrim,
      startIndex: initStartIndex,
    });
  };

  // eslint-disable-next-line no-shadow
  const handleLockReferral = record => {
    const status = _.get(record, 'status', 'LOCKED');

    const setStatus = () => {
      if (status === 'LOCKED') {
        const current = moment();
        const stDate = moment(record.startDate);
        const eDate = moment(record.endDate);

        if (
          current.isSameOrAfter(stDate, 'date') &&
          current.isSameOrBefore(eDate, 'date')
        ) {
          return 'HAPPENING';
        }
        if (current < stDate) {
          return 'WAITING';
        }
        if (current > eDate) {
          return 'ENDED';
        }
      }
      return 'LOCKED';
    };
    const onLock = async () => {
      const uuid = _.get(record, 'uuid', '');
      const newStatus = setStatus();
      const body = {
        uuid,
        params: { status: newStatus },
      };

      await dispatch(referralActions.handleLockedReferralRequested(body));
      await getReferral();
    };
    confirm({
      title:
        status !== 'LOCKED'
          ? 'Bạn có chắc chắn khoá mã giới thiệu này không ?'
          : 'Bạn có chắc chắn mở khóa mã giới thiệu này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onLock(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const getReferral = useCallback(() => {
    dispatch(referralActions.getReferralRequested(paramsReferral));
  }, [dispatch, paramsReferral]);

  useEffect(() => {
    getReferral();
  }, [paramsReferral]);

  const callImage = (value: any) => {
    if (value?.attributes[0]?.valueReference) {
      return `${config.BASE_API_URL}/v1/referralAttribute/${
        value?.attributes[0]?.uuid
      }/value?t=${Date.now()}`;
    }
    return `${config.BASE_API_URL}/v1/referralAttribute/${
      value?.attributes[0]?.uuid
    }/value?t=${Date.now()}`;
  };

  const columns = [
    {
      title: 'Ngày tạo',
      key: 'dateCreated',
      render: (value, row, index) => {
        return formatDateDayVN(row?.dateCreated);
      },
    },
    {
      title: 'Tên chương trình',
      key: 'name',
      width: '15%',
      render: (value, row, index) => {
        return value.name;
      },
    },
    {
      key: 'Code',
      title: 'Mã giới thiệu',
      width: '15%',
      render: (value, row, index) => {
        return (
          <div style={{ maxWidth: 200 }} className="">
            {value.referralCode}
          </div>
        );
      },
    },
    {
      key: 'logo',
      title: 'Logo',
      width: '5%',
      render: (value, row, index) => {
        return (
          <>
            {value?.attributes[0]?.uuid && (
              <Image
                style={{ width: 50, height: 40 }}
                src={callImage(value)}
                preview={false}
              />
            )}
          </>
        );
      },
    },
    {
      key: 'value',
      title: 'Số tiền',
      render: (value, row, index) => {
        return `${value.value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      },
    },
    {
      key: 'startDay',
      title: 'Ngày bắt đầu',
      render: (value, row, index) => {
        return formatDateDayVN(row?.startDate);
      },
    },
    {
      key: 'endDay',
      title: 'Ngày kết thúc',

      render: (value, row, index) => {
        return formatDateDayVN(row?.endDate);
      },
    },
    {
      key: 'status',
      title: 'Trạng thái',

      render: (value, row, index) => {
        switch (value.status) {
          case 'ENDED':
            return 'Đã kết thúc';
          case 'WAITING':
            return 'Chưa diễn ra';
          case 'HAPPENING':
            return 'Đang diễn ra';
          default:
            return 'Đã khóa';
        }
      },
    },
    {
      key: 'Action',
      title: 'Thao tác',
      render: (value: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            hasDelete={false}
            hasDetail={false}
            hasLock={true}
            isLock={value.status === 'LOCKED'}
            isEdit={value.status === 'LOCKED'}
            handleLock={() => handleLockReferral(value)}
            handleEdit={() => onOpenEditAddReferral(value)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="doctor-information">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý chương trình giới thiệu</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onOpenModalAddReferral}>
              Thêm mới
            </Button>
          </Col>
        </Row>

        <Form onFinish={onFinish} form={form}>
          <Row style={{ marginTop: 30 }} gutter={[20, 0]}>
            <Col xs={16} className="pr-5">
              <Form.Item name="query">
                <Input
                  ref={inputSearch}
                  placeholder="Tìm kiếm theo tên chương trình/mã giới thiệu..."
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: referralReducer?.isLoadingReferral,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={referralReducer?.dataReferral?.results}
            pagination={{
              current: paramsReferral.startIndex,
              pageSize: paramsReferral.limit,
              total: referralReducer?.dataReferral?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            rowClassName=" cursor-pointer"
            onRow={(record, rowIndex) => {
              return {
                onClick: event => {
                  setShowDetailReferral(true);
                  setSelectedData(record);
                },
              };
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
      {showAddReferralModal && (
        <ModalAddReferral
          show={showAddReferralModal}
          onHide={onCloseModalAddReferral}
        />
      )}
      {showEditReferralModal && (
        <ModalEditReferral
          show={showEditReferralModal}
          onHide={onCloseModalEditReferral}
          dataProps={selectedData}
        />
      )}
      {showDetailReferral && (
        <ModalViewReferral
          show={showDetailReferral}
          onHide={() => setShowDetailReferral(false)}
          dataProps={selectedData}
        />
      )}
    </div>
  );
};

export default Referral;
