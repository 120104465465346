/* eslint-disable import/no-cycle */
import { AxiosError, AxiosResponse } from 'axios';
import { removeCookie, removeAuthInfo } from 'helpers/utils';

export function mapData(res: AxiosResponse<any>) {
  return res.data;
}

export function mapError(err: AxiosError<any>) {
  if (err && err.response && err.response.status === 401) {
    removeCookie();
    removeAuthInfo();
    window.location.href = '/auth/login';
  }
  if (err && err.response && err.response.status === 403) {
    removeCookie();
    removeAuthInfo();
    if (window.location.pathname !== '/auth/login') {
      window.location.href = '/auth/login';
    }
  }

  throw err;
}
