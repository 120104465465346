import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { PromotionModel } from 'models/store/promotion.model';

export const {
  getPromotionRequested,
  getPromotionSucceeded,
  getPromotionFailed,
  getPromotionCodeRequested,
  getPromotionCodeSucceeded,
  getPromotionCodeFailed,
  getPromotionHistoryRequested,
  getPromotionHistorySucceeded,
  getPromotionHistoryFailed,
  handleCreatePromotionRequested,
  handleCreatePromotionSucceeded,
  handleCreatePromotionFailed,
  handleUpdatePromotionRequested,
  handleUpdatePromotionSucceeded,
  handleUpdatePromotionFailed,
  handleDisplayModalEdit,
  handleDisplayModalAdd,
  handleLockedPromotionRequested,
  handleLockedPromotionSucceeded,
  handleLockedPromotionFailed,
} = createActions(
  {
    GET_PROMOTION_REQUESTED: (payload: QueryString) => payload,
    GET_PROMOTION_SUCCEEDED: (payload: PromotionModel) => payload,
    GET_PROMOTION_FAILED: (error: any) => error,
    GET_PROMOTION_CODE_REQUESTED: (payload: QueryString) => payload,
    GET_PROMOTION_CODE_SUCCEEDED: (payload: PromotionModel) => payload,
    GET_PROMOTION_CODE_FAILED: (error: any) => error,
    GET_PROMOTION_HISTORY_REQUESTED: (payload: QueryString) => payload,
    GET_PROMOTION_HISTORY_SUCCEEDED: (payload: PromotionModel) => payload,
    GET_PROMOTION_HISTORY_FAILED: (error: any) => error,
    HANDLE_CREATE_PROMOTION_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_PROMOTION_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_PROMOTION_FAILED: (error: any) => error,
    HANDLE_UPDATE_PROMOTION_REQUESTED: (payload: QueryString) => payload,
    HANDLE_UPDATE_PROMOTION_SUCCEEDED: (payload: PromotionModel) => payload,
    HANDLE_UPDATE_PROMOTION_FAILED: (error: any) => error,
    HANDLE_DISPLAY_MODAL_EDIT: (error: any) => error,
    HANDLE_DISPLAY_MODAL_ADD: (error: any) => error,
    HANDLE_LOCKED_PROMOTION_REQUESTED: (payload: any) => payload,
    HANDLE_LOCKED_PROMOTION_SUCCEEDED: (payload: any) => payload,
    HANDLE_LOCKED_PROMOTION_FAILED: (error: any) => error,
  },
  {
    prefix: 'promotion',
  },
);
