import authorizedRequest from 'helpers/request/authorizedRequest';

export function getExportCodeFile(payload: any) {
  return authorizedRequest.get<any, Blob>(`v1/promotion/controller/code`, {
    params: payload,
    responseType: 'blob',
  });
}

export function getExportHistoryFile(payload: any) {
  return authorizedRequest.get<any, Blob>(`v1/promotion/controller/history`, {
    params: payload,
    responseType: 'blob',
  });
}

export function getReferralFile(payload: any) {
  return authorizedRequest.get<any, Blob>(`/v1/referral/controller/history`, {
    params: payload,
    responseType: 'blob',
  });
}

export function getEvaluateFile(payload: any) {
  // another api
  return authorizedRequest.get<any, Blob>(`/v1/providerEvaluate/export`, {
    params: payload,
    responseType: 'blob',
  });
}
