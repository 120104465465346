import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Select, Input, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { AppointmentSearchFormConst } from './appointment.const';
import * as doctorActions from '../../../store/doctor/doctor.action';

const { Option } = Select;
interface CProps {
  onFinish: (values) => void;
}

const SearchForm: FC<CProps> = ({ onFinish }) => {
  const status = AppointmentSearchFormConst.STATUS;
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [ParamsSpecialistModel] = useState<any>({
    v: 'full',
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
  });
  const doctorReducer = useSelector((state: any) => state.doctorReducer);

  const handleCancel = () => {
    form.setFieldsValue({
      // paymentType: null,
      status: '',
      providerAttributeValue: '',
      // paymentStatus: null,
      // queryString: null,
      fromDate: '',
      toDate: '',
      q: '',
    });
    form.submit();
  };

  const getSpecialist = (val: any) => {
    dispatch(
      doctorActions.getSpecialistRequested({
        ...ParamsSpecialistModel,
        ...val,
      }),
    );
  };

  const arrSpecialist = doctorReducer.dataSpecialist.results;

  const listOption = arrSpecialist?.map((item, index) => {
    return (
      <Option value={item.uuid} key={index}>
        {item.display}
      </Option>
    );
  });

  useEffect(() => {
    getSpecialist({ class: 'Specialist' });
  }, []);

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <div className="flex">
          <div className="w-1/4 mr-0 pr-2 flex flex-col">
            <span>Chuyên khoa</span>
            <Form.Item name="providerAttributeValue" initialValue="">
              <Select
                className="mt-2"
                placeholder="Chọn chuyên khoa"
                virtual={false}
              >
                <Option value="" key={100}>
                  Tất cả
                </Option>
                {listOption}
              </Select>
            </Form.Item>
          </div>
          <div className="w-1/4 mr-0 pr-2 flex flex-col">
            <span>Trạng thái tư vấn</span>
            <Form.Item name="status" initialValue="">
              <Select className="mt-2" placeholder="Chọn trạng thái">
                {status.map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="w-1/4 mr-0 pr-2 flex flex-col">
            <span>Ngày bắt đầu</span>
            <Form.Item name="fromDate">
              <DatePicker
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                locale={locale}
                className="mt-2"
                showToday={false}
              />
            </Form.Item>
          </div>
          <div className="w-1/4 mr-0 pr-2 flex flex-col">
            <span>Ngày kết thúc</span>
            <Form.Item name="toDate">
              <DatePicker
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                locale={locale}
                className="mt-2"
                showToday={false}
              />
            </Form.Item>
          </div>
          <div className="w-1/4 mr-0  flex flex-col">
            <span>Tìm kiếm</span>
            <Form.Item
              name="q"
              rules={[
                {
                  whitespace: false,
                },
              ]}
            >
              <Input className="mt-2" placeholder="Nhập để tìm kiếm" />
            </Form.Item>
          </div>
        </div>
        <div>
          <Button onClick={handleCancel}>Xóa</Button>
          <Button type="primary" className="ml-2" htmlType="submit">
            Tìm kiếm
          </Button>
        </div>
      </Form>
    </>
  );
};

export default SearchForm;
