import { combineReducers, ReducersMapObject } from 'redux';

import authenticateReducer from './authenticate/authenticate.reducer';
import { reducer as appointmentReducer } from './appointment';
import customerReducer from './customer/customer.reducer';
import doctorReducer from './doctor/doctor.reducer';
import servicePackageReducer from './servicePackage/servicePackage.reducer';
import promotionReducer from './promotion/promotion.reducer';
import referralReducer from './referral/referral.reducer';
import holidayReducer from './holiday/holiday.reducer';
import versionReducer from './version/version.reducer';
import marketingNoti from './marketingNoti/marketingNoti.reducer';
import customSetting from './customSetting/customSetting.reducer';
import rating from './rating/rating.reducer';
import service from './service/service.reducer';

const rootReducer: ReducersMapObject<any, any> = {
  authenticate: authenticateReducer,
  appointment: appointmentReducer,
  customerReducer,
  doctorReducer,
  servicePackageReducer,
  holidayReducer,
  versionReducer,
  promotionReducer,
  referralReducer,
  marketingNoti,
  customSetting,
  rating,
  service,
};

export default combineReducers(rootReducer);
