import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import {
  TransactionCustomerModel,
  DetailTransactionCustomerModel,
} from 'models/store/customer.model';

export const {
  getTransactionCustomerRequested,
  getTransactionCustomerSucceeded,
  getTransactionCustomerFailed,
  getTransactionCustomerDetailRequested,
  getTransactionCustomerDetailSucceeded,
  getTransactionCustomerDetailFailed,
  getTransactionCustomerSearchRequested,
  getTransactionCustomerSearchSucceeded,
  getTransactionCustomerSearchFailed,
  postAddTransactionCustomerRequested,
  postAddTransactionCustomerSucceeded,
  postAddTransactionCustomerFailed,
  postAddInformationCustomerRequested,
  postAddInformationCustomerSucceeded,
  postAddInformationCustomerFailed,
  postEditInformationCustomerRequested,
  postEditInformationCustomerSucceeded,
  postEditInformationCustomerFailed,
  postEditTransactionDetailRequested,
  postEditTransactionDetailSucceeded,
  postEditTransactionDetailFailed,
  getListTransactionRequested,
  getListTransactionSucceeded,
  getListTransactionFailed,
  getListAppointmentRequested,
  getListAppointmentSucceeded,
  getListAppointmentFailed,
  getPatientBookAppointmentRequested,
  getPatientBookAppointmentSucceeded,
  getPatientBookAppointmentFailed,
  postChangePasswordCustomerRequested,
  postChangePasswordCustomerSucceeded,
  postChangePasswordCustomerFailed,
  handleDeleteCustomerRequested,
  handleDeleteCustomerSucceeded,
  handleDeleteCustomerFailed,
  handleDisplayModalAdd,
  handleDisplayModalEditTransaction,
  handleCallTest,
  getListProvinceRequested,
  getListProvinceSucceeded,
  getListProvinceFailed,
  getListDistrictsRequested,
  getListDistrictsSucceeded,
  getListDistrictsFailed,

  getListJobRequested,
  getListJobSucceeded,
  getListJobFailed,

  getListInsuranceRequested,
  getListInsuranceSucceeded,
  getListInsuranceFailed,
} = createActions(
  {
    GET_TRANSACTION_CUSTOMER_REQUESTED: (payload: QueryString) => payload,
    GET_TRANSACTION_CUSTOMER_SUCCEEDED: (payload: TransactionCustomerModel) =>
      payload,
    GET_TRANSACTION_CUSTOMER_FAILED: (error: Error) => error,
    GET_TRANSACTION_CUSTOMER_DETAIL_REQUESTED: (payload: {
      id: string;
      params: QueryString;
    }) => payload,
    GET_TRANSACTION_CUSTOMER_DETAIL_SUCCEEDED: (
      payload: DetailTransactionCustomerModel,
    ) => payload,
    GET_TRANSACTION_CUSTOMER_DETAIL_FAILED: (error: Error) => error,
    GET_TRANSACTION_CUSTOMER_SEARCH_REQUESTED: (payload: QueryString) =>
      payload,
    GET_TRANSACTION_CUSTOMER_SEARCH_SUCCEEDED: (
      payload: TransactionCustomerModel,
    ) => payload,
    GET_TRANSACTION_CUSTOMER_SEARCH_FAILED: (error: Error) => error,
    POST_ADD_TRANSACTION_CUSTOMER_REQUESTED: (payload: any) => payload,
    POST_ADD_TRANSACTION_CUSTOMER_SUCCEEDED: (payload: any) => payload,
    POST_ADD_TRANSACTION_CUSTOMER_FAILED: (error: Error) => error,
    POST_ADD_INFORMATION_CUSTOMER_REQUESTED: (payload: any) => payload,
    POST_ADD_INFORMATION_CUSTOMER_SUCCEEDED: (payload: any) => payload,
    POST_ADD_INFORMATION_CUSTOMER_FAILED: (payload: any) => payload,
    POST_EDIT_INFORMATION_CUSTOMER_REQUESTED: (payload: any) => payload,
    POST_EDIT_INFORMATION_CUSTOMER_SUCCEEDED: (payload: any) => payload,
    POST_EDIT_INFORMATION_CUSTOMER_FAILED: (payload: any) => payload,
    POST_EDIT_TRANSACTION_DETAIL_REQUESTED: (payload: any) => payload,
    POST_EDIT_TRANSACTION_DETAIL_SUCCEEDED: (payload: any) => payload,
    POST_EDIT_TRANSACTION_DETAIL_FAILED: (payload: any) => payload,
    GET_LIST_TRANSACTION_REQUESTED: (payload: QueryString) => payload,
    GET_LIST_TRANSACTION_SUCCEEDED: (payload: TransactionCustomerModel) =>
      payload,
    GET_LIST_TRANSACTION_FAILED: (error: Error) => error,
    GET_LIST_APPOINTMENT_REQUESTED: (payload: QueryString) => payload,
    GET_LIST_APPOINTMENT_SUCCEEDED: (payload: TransactionCustomerModel) =>
      payload,
    GET_LIST_APPOINTMENT_FAILED: (error: Error) => error,
    GET_PATIENT_BOOK_APPOINTMENT_REQUESTED: (payload: QueryString) => payload,
    GET_PATIENT_BOOK_APPOINTMENT_SUCCEEDED: (
      payload: TransactionCustomerModel,
    ) => payload,
    GET_PATIENT_BOOK_APPOINTMENT_FAILED: (error: Error) => error,
    POST_CHANGE_PASSWORD_CUSTOMER_REQUESTED: (payload: {
      userUuid: string;
      body: any;
    }) => payload,

    POST_CHANGE_PASSWORD_CUSTOMER_SUCCEEDED: payload => payload,
    POST_CHANGE_PASSWORD_CUSTOMER_FAILED: (error: Error) => error,
    HANDLE_DELETE_CUSTOMER_REQUESTED: (payload: any) => payload,
    HANDLE_DELETE_CUSTOMER_SUCCEEDED: (payload: any) => payload,
    HANDLE_DELETE_CUSTOMER_FAILED: (error: any) => error,
    HANDLE_DISPLAY_MODAL_ADD: (error: any) => error,

    HANDLE_DISPLAY_MODAL_EDIT_TRANSACTION: (error: any) => error,

    // call test
    HANDLE_CALL_TEST: (payload: any) => payload,

    GET_LIST_PROVINCE_REQUESTED: payload => payload,
    GET_LIST_PROVINCE_SUCCEEDED: payload => payload,
    GET_LIST_PROVINCE_FAILED: (error: Error) => error,

    GET_LIST_DISTRICTS_REQUESTED: payload => payload,
    GET_LIST_DISTRICTS_SUCCEEDED: payload => payload,
    GET_LIST_DISTRICTS_FAILED: (error: Error) => error,

    GET_LIST_JOB_REQUESTED: payload => payload,
    GET_LIST_JOB_SUCCEEDED: payload => payload,
    GET_LIST_JOB_FAILED: (error: Error) => error,

    GET_LIST_INSURANCE_REQUESTED: payload => payload,
    GET_LIST_INSURANCE_SUCCEEDED: payload => payload,
    GET_LIST_INSURANCE_FAILED: (error: Error) => error,
  },
  {
    prefix: 'customer',
  },
);
