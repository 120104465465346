import { Button, Col, Form, Input, InputNumber, message, Row } from 'antd';
import { Interpretation, Status } from 'constants/Appointment';
import { regextTrimspace } from 'containers/Doctor/doctor.const';
// import * as _ from 'lodash';
import StoreState from 'models/store';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appointmentActions } from 'store/appointment';
import './Check.scss';
import CheckBefore from './CheckBefore/CheckBefore';

const Check: FC<any> = props => {
  const { initValues, dataProps, isEditAvailability } = props;

  const originTime = new Date();
  const dispatch = useDispatch();
  const [isEditPulse, setIsEditPulse] = useState<boolean>(false);
  const [isEditTemperature, setIsEditTemperature] = useState<boolean>(false);
  const [isEditBloodpressure, setIsEditBloodpressure] = useState<boolean>(
    false,
  );
  const [isEditBreathing, setIsEditBreathing] = useState<boolean>(false);
  const [isEditWeight, setIsEditWeight] = useState<boolean>(false);
  const [isEditHeight, setIsEditHeight] = useState<boolean>(false);

  // const initPulse = useMemo(() => {
  //   return { pulse: initValues?.pulse };
  // }, [initValues]);

  const appointmentUuid = useSelector(
    (store: StoreState) =>
      store.appointment.detailAppointment.selectedData?.dataCloned?.detail
        .uuid || '',
  );

  // const arrBloodPressureDiastolic = dataProps.detail?.encounters[0]?.obs?.filter(
  //   item => item?.display?.includes('DIASTOLIC BLOOD PRESSURE'),
  // );

  // const arrBloodPressureSystolic = dataProps.detail?.encounters[0]?.obs?.find(
  //   item => item?.display?.includes('SYSTOLIC BLOOD PRESSURE'),
  // );

  const arrPluse = dataProps?.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('PULSE'),
  );
  const arrTemperature = dataProps?.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('TEMPERATURE'),
  );

  const arrHeight = dataProps?.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('HEIGHT'),
  );

  const arrWeight = dataProps?.detail?.encounters[0]?.obs?.filter(item =>
    item?.display?.includes('WEIGHT'),
  );

  const arrBreathing = dataProps?.detail?.encounters[0]?.obs?.filter(item =>
    item?.display?.includes('Respiratory rate'),
  );

  const handleCancel1 = e => {
    setIsEditPulse(!isEditPulse);
  };
  // const handleSave1 = _.debounce(() => {
  //   setIsEditPulse(!isEditPulse);
  // }, 50);

  const handleCancel2 = e => {
    setIsEditTemperature(!isEditTemperature);
  };
  // const handleSave2 = _.debounce(() => {
  //   setIsEditTemperature(!isEditTemperature);
  // }, 50);
  const handleCancel3 = e => {
    setIsEditBloodpressure(!isEditBloodpressure);
  };
  // const handleSave3 = _.debounce(() => {
  //   setIsEditBloodpressure(!isEditBloodpressure);
  // }, 50);
  const handleCancel5 = e => {
    setIsEditBreathing(!isEditBreathing);
  };
  // const handleSave5 = _.debounce(() => {
  //   setIsEditBreathing(!isEditBreathing);
  // }, 50);
  const handleCancel6 = e => {
    setIsEditWeight(!isEditWeight);
  };
  // const handleSave6 = _.debounce(() => {
  //   setIsEditWeight(!isEditWeight);
  // }, 50);
  const handleCancel4 = e => {
    setIsEditHeight(!isEditHeight);
  };

  // const handleSave4 = _.debounce(() => {
  //   setIsEditHeight(!isEditHeight);
  // }, 50);

  const callbackPulseSuccess = () => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditPulse(false);
  };
  const callbackTemperatureSuccess = () => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditTemperature(false);
  };
  const callbackWeightSuccess = () => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditWeight(false);
  };
  const callbackBreathingSuccess = () => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditBreathing(false);
  };
  const callbackBloodSuccess = (messSucceeded: string) => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditBloodpressure(false);
    message.success(messSucceeded);
  };
  const callbackHeightSuccess = () => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
    setIsEditHeight(false);
  };

  const onEditPulse = values => {
    const { pulse } = values;
    const paramsEditPulse = {
      value: pulse,
      callbackSuccess: callbackPulseSuccess,
    };
    if (!initValues?.pulse) {
      const paramsCreatePulse = {
        concept: '5087AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        value: pulse,
        obsDatetime: originTime?.toISOString(),
        person: dataProps?.detail?.uuidPerson,
        encounter: dataProps?.detail?.encounters[0]?.uuid,
        callbackSuccess: callbackPulseSuccess,
      };
      dispatch(appointmentActions.handleCreatePulse(paramsCreatePulse));
    }
    if (initValues?.pulse) {
      const uuidPulse = arrPluse && arrPluse?.uuid;
      dispatch(
        appointmentActions.handleEditPulse({ uuidPulse, paramsEditPulse }),
      );
    }
  };

  const onEditTemperature = values => {
    const { temperature } = values;
    const paramsEditTemperature = {
      value: temperature,
      callbackSuccess: callbackTemperatureSuccess,
    };
    if (!initValues?.temperature) {
      const paramsCreateTemperature = {
        concept: '5088AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        value: temperature,
        obsDatetime: originTime?.toISOString(),
        person: dataProps?.detail?.uuidPerson,
        encounter: dataProps?.detail?.encounters[0]?.uuid,
        callbackSuccess: callbackTemperatureSuccess,
      };
      dispatch(
        appointmentActions.handleCreateTemperature(paramsCreateTemperature),
      );
    }
    if (initValues?.temperature) {
      const uuidTemperature = arrTemperature && arrTemperature?.uuid;
      dispatch(
        appointmentActions.handleEditTemperature({
          uuidTemperature,
          paramsEditTemperature,
        }),
      );
    }
  };
  const onEditWeight = values => {
    const { weight } = values;
    const paramsEditWeight = {
      value: weight,
      callbackSuccess: callbackWeightSuccess,
    };
    const uuidWeight = arrWeight && arrWeight[0]?.uuid;
    if (initValues?.weight) {
      dispatch(
        appointmentActions.handleEditWeight({
          uuidWeight,
          paramsEditWeight,
        }),
      );
    }
    if (!initValues?.weight) {
      const paramsCreateWeight = {
        concept: '5089AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        value: weight,
        obsDatetime: originTime?.toISOString(),
        person: dataProps?.detail?.uuidPerson,
        encounter: dataProps?.detail?.encounters[0]?.uuid,
        callbackSuccess: callbackWeightSuccess,
      };

      dispatch(appointmentActions.handleCreateWeight(paramsCreateWeight));
    }
  };

  const onEditBreathing = values => {
    const { breathing } = values;
    const paramsEditBreathing = {
      value: breathing,
      callbackSuccess: callbackBreathingSuccess,
    };
    const uuidBreathing = arrBreathing && arrBreathing[0]?.uuid;
    // console.log('uuidBreathing1231231231231', uuidBreathing);
    if (initValues?.breathing) {
      dispatch(
        appointmentActions.handleEditRespiratoryRate(
          uuidBreathing,
          paramsEditBreathing,
        ),
      );
    }
    if (!initValues?.breathing) {
      const paramsCreateRespiratoryRate = {
        concept: '5242AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
        value: breathing,
        obsDatetime: originTime?.toISOString(),
        person: dataProps?.detail?.uuidPerson,
        encounter: dataProps?.detail?.encounters[0]?.uuid,
        callbackSuccess: callbackBreathingSuccess,
      };
      dispatch(
        appointmentActions.handleCreateRespiratoryRate(
          paramsCreateRespiratoryRate,
        ),
      );
    }
  };

  const medicalExamination =
    dataProps?.detail?.dataCloned.detail.medicalExamination || {};

  const onEditBloodpressure = values => {
    const { bloodPressure } = values;

    const bloodPressureArr = bloodPressure.split('/');
    const paramsCreateBloodPressure = {
      concept: '5085AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: bloodPressure,
      obsDatetime: originTime?.toISOString(),
      person: dataProps?.detail?.uuidPerson,
      encounter: dataProps?.detail?.encounters[0]?.uuid,
      interpretation: Interpretation.NORMAL,
      status: Status.PRELIMINARY,
      callbackSuccess: () =>
        callbackBloodSuccess('Chỉnh sửa huyết áp thành công'),
      systolicBloodPressureValue: bloodPressureArr[0],
      diastolicBloodPressureValue: bloodPressureArr[1],
    };
    if (!initValues?.bloodPressure) {
      dispatch(
        appointmentActions.handleCreateBloodPressure(paramsCreateBloodPressure),
      );
    }
    if (initValues?.bloodPressure) {
      const paramsEditBloodPressure = {
        value: bloodPressure,
        systolicBloodPressureId: medicalExamination.systolicBloodPressure.uuid,
        diastolicBloodPressureId:
          medicalExamination.diastolicBloodPressure.uuid,
        callbackSuccess: () =>
          callbackBloodSuccess('Thêm mới huyết áp thành công'),
      };

      dispatch(
        appointmentActions.handleEditBloodPressure({
          paramsEditBloodPressure,
        }),
      );
    }
  };
  const onEditHeight = values => {
    const { height } = values;
    const uuidHeight = arrHeight && arrHeight?.uuid;

    const paramsEditHeight = {
      value: height,
      callbackSuccess: callbackHeightSuccess,
    };
    const paramsCreateHeight = {
      concept: '5090AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: height,
      obsDatetime: originTime?.toISOString(),
      person: dataProps?.detail?.uuidPerson,
      encounter: dataProps?.detail?.encounters[0]?.uuid,
      callbackSuccess: callbackHeightSuccess,
    };

    if (initValues?.height) {
      dispatch(
        appointmentActions.handleEditHeight({
          uuidHeight,
          paramsEditHeight,
        }),
      );
    }
    if (!initValues?.height) {
      dispatch(appointmentActions.handleCreateHeight(paramsCreateHeight));
    }
  };

  // const initValue = useMemo(() => {
  //   return {
  //     pulse: medicalExamination.pulse?.value || '',
  //     temperature: medicalExamination.temperature?.value || '',
  //     bloodPressure: medicalExamination.bloodPressure?.value || '',
  //     breathing: medicalExamination.breathing?.value || '',
  //     weight: medicalExamination.weight?.value || '',
  //     height: medicalExamination.height?.value || '',
  //   };
  // }, [medicalExamination]);
  const list = [
    {
      title: 'Mạch',
      inputName: 'pulse',
      inputType: 'text',
      unit: 'lần/phút',
      maxLength: 3,
      value: medicalExamination.pulse,
      isEdit: isEditPulse,
      onCancel: e => handleCancel1(e),
      onSave: onEditPulse,
      rules: [
        {
          validator: async (_r, names) => {
            if (!names) {
              return Promise.reject(new Error('Nội dung không được để trống'));
            }
            if (Number(names) < 1 || isNaN(Number(names))) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Chỉ số mạch không hợp lệ!');
            }
            if (Number(names) > 230) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(
                'Vui lòng nhập chỉ số mạch nhỏ hơn 230 lần/phút',
              );
            }
          },
        },
      ],
    },
    {
      title: 'Nhiệt độ',
      inputName: 'temperature',
      isEdit: isEditTemperature,
      unit: 'º C',
      value: medicalExamination.temperature,
      onCancel: e => handleCancel2(e),
      onSave: onEditTemperature,
      rules: [
        {
          pattern: regextTrimspace,
          message: 'Vui lòng điền thông tin bắt buộc!',
        },
        {
          validator: async (_r, names) => {
            // if (!names || names.trim().length < 1) {
            //   return Promise.reject(new Error('Nội dung không được để trống'));
            // }
            if (Number(names) < 1 || isNaN(Number(names))) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Chỉ số mạch không hợp lệ!');
            }
            if (names < 1) {
              return Promise.reject(new Error('Nhiệt độ không hợp lệ'));
            }
            if (Number(names) > 43 || Number(names) < 25) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Nhập nhiệt độ trong khoảng 25 - 43 ºC');
            }
          },
        },
      ],
    },
    {
      title: 'Huyết áp',
      inputName: 'bloodPressure',
      isEdit: isEditBloodpressure,
      unit: 'mmHg',
      value: medicalExamination.bloodPressure,
      maxLength: 7,
      onCancel: e => handleCancel3(e),
      onSave: onEditBloodpressure,
      rules: [
        {
          pattern: regextTrimspace,
          message: 'Vui lòng điền thông tin bắt buộc!',
        },
        {
          validator: async (_r, names) => {
            // if (!names || names.trim().length < 1) {
            //   return Promise.reject(new Error('Nội dung không được để trống'));
            // }
            const maxLength = 1000;

            if (Number(names) < 1) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Huyết áp không hợp lệ!');
            }

            const bloodPressure = names.split('/');

            if (
              !bloodPressure[0] ||
              !bloodPressure[1] ||
              isNaN(bloodPressure[0]) ||
              isNaN(bloodPressure[1]) ||
              Number(bloodPressure[0]) > 250 ||
              Number(bloodPressure[1]) > 150
            ) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Dữ liệu nhập vào không hợp lệ: ex 120/80');
            }
            if (Number(names) > maxLength) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(`Nhập trong khoảng ${maxLength} ký tự!`);
            }
          },
        },
      ],
    },
    {
      title: 'Nhịp thở',
      inputName: 'breathing',
      isEdit: isEditBreathing,
      value: medicalExamination.breathing,
      unit: 'lần/phút',
      onCancel: e => handleCancel5(e),
      onSave: onEditBreathing,
      rules: [
        {
          pattern: regextTrimspace,
          message: 'Vui lòng điền thông tin bắt buộc!',
        },
        {
          validator: async (_r, names) => {
            // if (!names || names.trim().length < 1) {
            //   return Promise.reject(new Error('Nội dung không được để trống'));
            // }
            if (
              Number(names) < 1 ||
              Number(names) > 300 ||
              isNaN(Number(names))
            ) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Nhịp thở không hợp lệ!');
            }
          },
        },
      ],
    },
    {
      title: 'Cân nặng',
      inputName: 'weight',
      unit: 'kg',
      value: medicalExamination.weight,
      isEdit: isEditWeight,
      onCancel: e => handleCancel6(e),
      onSave: onEditWeight,
      rules: [
        {
          pattern: regextTrimspace,
          message: 'Vui lòng điền thông tin bắt buộc!',
        },
        {
          validator: async (_r, names) => {
            // if (!names || names.trim().length < 1) {
            //   return Promise.reject(new Error('Nội dung không được để trống'));
            // }
            const numWeight = +names;
            if (Number(names) < 1 || isNaN(Number(names))) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Cân nặng không hợp lệ!');
            }
            if (numWeight > 250) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Nhập cân nặng nhỏ hơn 250kg');
            }
          },
        },
      ],
    },
    {
      title: 'Chiều cao',
      inputName: 'height',
      isEdit: isEditHeight,
      unit: 'cm',
      value: medicalExamination.height,
      onCancel: e => handleCancel4(e),
      onSave: onEditHeight,
      rules: [
        {
          pattern: regextTrimspace,
          message: 'Vui lòng điền thông tin bắt buộc!',
        },
        {
          validator: async (_r, names) => {
            // if (!names || names.trim().length < 1) {
            //   return Promise.reject(new Error('Nội dung không được để trống'));
            // }
            if (Number(names) < 1 || isNaN(Number(names))) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject('Chiều cao không hợp lệ!');
            }
            if (Number(names) > 272 || Number(names) < 10) {
              // eslint-disable-next-line prefer-promise-reject-errors
              return Promise.reject(`Nhập chiều cao trong khoảng 10 - 272cm!`);
            }
          },
        },
      ],
    },
  ];

  return (
    <div className="check-Container">
      <Row className="check-content__title">
        <span className="pl-6">Khám bệnh</span>
      </Row>
      <hr />
      <Row className="row-1 ">
        <div className="check-section">
          <div className="check-section__title">
            <span>Toàn thân</span>
          </div>
          <Row className="flex-wrap w-full info-check">
            {list.map(item => (
              <Col key={item.title} sm={24} lg={8}>
                <div className="fulse-container infor-container flex flex-col">
                  <div className="fulse-container__wrap">
                    <Form onFinish={item.onSave}>
                      <div className="div-1">
                        <div className="title">{item.title}</div>
                        <div className="btn-edit ">
                          {isEditAvailability &&
                            (!item.isEdit ? (
                              <Button
                                className="btn-update"
                                onClick={item.onCancel}
                              >
                                {item.value.value ? 'Sửa' : 'Thêm'}
                              </Button>
                            ) : (
                              <Row>
                                <Button
                                  className="btn-update"
                                  htmlType="submit"
                                >
                                  Lưu
                                </Button>
                                <Button
                                  className="btn-update btn-cancel"
                                  onClick={item.onCancel}
                                >
                                  Hủy
                                </Button>
                              </Row>
                            ))}
                        </div>
                      </div>

                      <div className="div-2 ">
                        {item.isEdit ? (
                          <>
                            <div className="div-2-form">
                              <Form.Item
                                style={{ width: '100%' }}
                                name={item.inputName}
                                className="m-0 p-0"
                                rules={item.rules}
                                initialValue={item?.value?.value}
                                preserve={false}
                              >
                                {item.inputType &&
                                item.inputType === 'number' ? (
                                  <InputNumber
                                    style={{ width: '100%' }}
                                    maxLength={item.maxLength}
                                    autoFocus={true}
                                    placeholder={item.unit}
                                    inputMode="numeric"
                                  />
                                ) : (
                                  <Input
                                    style={{ width: '100%' }}
                                    ref={input => input && input.focus()}
                                    placeholder={item.unit}
                                  />
                                )}
                              </Form.Item>
                            </div>
                            {/* <div className="div-2-span">
                              <span> {item.unit}</span>
                            </div> */}
                          </>
                        ) : (
                          <span className="text-index-body">
                            {item.value && item.value.value
                              ? item.value.value
                              : '-'}{' '}
                            <span className="text-unit">{item.unit}</span>
                          </span>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Row>
      <Row>
        <CheckBefore
          initValues={initValues}
          dataTranfer={dataProps}
          isEditAvailability={isEditAvailability}
        />
      </Row>
    </div>
  );
};

export default Check;
