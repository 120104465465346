import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Input, Form, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { useParams } from 'react-router';

import * as doctorInfoActions from '../../../store/doctor/doctor.action';
import ModalEditFee from '../../../components/Doctor/ModalEditFee';
import { formatMoney } from '../../../helpers/utils';
import * as STATUS from '../doctor.const';
import './FeeDetail.scss';

interface ParamsInformationDoctorModel {
  v?: string;
}
type initPassprops = {
  fee?: string;
};

const FeeDetail = () => {
  const [form] = Form.useForm();
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const [setSelectedFee] = useState<object | null>();
  const [showModalEditFee, setSowModalEditFee] = useState<boolean>(false);
  const [idProvider, setIdProvider] = useState<string>();
  const [idAttr, setIdAttr] = useState<any>();
  const [arr, setArr] = useState<any>();
  const [initPrice, setInitPrice] = useState<any>({ price: '' });

  const handleEditFee = record => {
    setSowModalEditFee(true);
  };

  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
    },
  );

  const getDoctorDetail = () => {
    dispatch(
      doctorInfoActions.getDoctorDetailByIdRequested(
        id,
        ParamsInformationDoctorModel,
      ),
    );
  };
  useEffect(() => {
    getDoctorDetail();
  }, [id]);

  const dataDetail = useSelector(
    (state: any) => state.doctorReducer.dataDoctorDetail,
  );
  const listData = (idPro, idA) => {
    dispatch(
      doctorInfoActions.getDetailFeeHistoryRequested(
        {
          idPro,
          idA,
        },
        ParamsInformationDoctorModel,
      ),
    );
  };

  useEffect(() => {
    if (dataDetail && dataDetail !== undefined) {
      setIdProvider(dataDetail?.uuid);
      setArr(
        dataDetail?.attributes?.filter(item =>
          item.display.includes('Examination fees'),
        ),
      );
    }
  }, [dataDetail]);

  useEffect(() => {
    if (arr && arr !== undefined) {
      setIdAttr(arr[0]?.uuid);
    }
  }, [arr]);

  useEffect(() => {
    if (idProvider && idProvider !== undefined && idProvider !== null) {
      if (idAttr && idAttr !== undefined && idAttr !== null) {
        listData(idProvider, idAttr);
      }
    }
  }, [idProvider, idAttr]);

  const dataFeeHistory = useSelector(
    (state: any) => state.doctorReducer.dataDetailHistoryFee,
  );

  useEffect(() => {
    setInitPrice({
      price: formatMoney(arr && arr !== undefined ? arr[0]?.value : ''),
    });
  }, [arr]);

  const initValues = {
    price: dataFeeHistory?.value || '',
    idProvider,
    idAttr,
  };

  useEffect(() => {
    form.setFieldsValue(initPrice);
  }, [form, initPrice]);

  const columns = [
    {
      title: 'STT',
      key: 'name',
      render: (value, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Ngày',
      key: 'time',
      render: (value, row, index) => {
        const date = value?.auditInfo?.dateCreated?.replace(
          STATUS.regexDate1,
          '',
        );
        const timeClock = value?.auditInfo?.dateCreated?.match(
          STATUS.regexTime,
        );
        const timeLatest = timeClock[0]?.match(STATUS.regexDate2);
        const time = timeLatest[0]
          ?.split('-')
          ?.reverse()
          ?.join(':');
        const presentHours = Number(time.match(STATUS.regexFilterTime)[0]);
        if (presentHours <= 12) {
          return time?.concat(' AM ') + date;
        }
        if (presentHours > 12) {
          return time?.concat(' PM ') + date;
        }
      },
    },
    {
      title: 'Người chỉnh sửa',
      key: 'user',
      render: (value, row, index) => {
        return value?.auditInfo?.creator?.display;
      },
    },
    {
      title: 'Thao tác',
      dataIndex: 'address',
      render: (value, row, index) => {
        if (row.action === STATUS.EDIT_STATUS) {
          return 'Chỉnh sửa';
        }
        if (row.action === STATUS.CREATE_STATUS) {
          return 'Tạo mới';
        }
      },
    },
    {
      title: 'Nội dung chỉnh sửa',
      key: 'content',
      render: (value, row, index) => {
        return formatMoney(value?.value)?.concat(' VND');
      },
    },
  ];
  return (
    <div className="fee-detail">
      <Card>
        <Row>
          <Col xs={24}>
            <h1>Chi tiết phí khám</h1>
          </Col>
        </Row>
        <Row className="mt-10">
          <Col xs={24}>
            <div className="fee-detail-value">
              <div>
                <Form
                  initialValues={initPrice}
                  form={form}
                  className="flex m-0"
                >
                  <Form.Item
                    name="price"
                    label="Phí Khám"
                    className="mr-10 font-thin"
                  >
                    <Input disabled />
                  </Form.Item>
                  <span className="mt-1 label-vnd">VND</span>
                </Form>
              </div>
              <div className="pt-1 ml-5">
                <Tooltip title="Chỉnh sửa">
                  <Link
                    className="no-underline"
                    to="#"
                    onClick={row => handleEditFee(row)}
                  >
                    Sửa
                  </Link>
                </Tooltip>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-13 ">
          <Col className="text-left" xs={24}>
            <span className="font-bold">Lịch sử chỉnh sửa</span>
          </Col>
        </Row>
      </Card>
      <Table
        className="mt-8"
        columns={columns}
        pagination={{ showSizeChanger: true }}
        dataSource={dataFeeHistory?.changeHistories}
      />
      {setSelectedFee || (
        <ModalEditFee
          visible={showModalEditFee}
          handleCancel={() => setSowModalEditFee(false)}
          data={initValues}
        />
      )}
    </div>
  );
};

export default FeeDetail;
