import React, { useState, FC } from 'react';
import {
  Modal,
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  message,
  InputNumber,
} from 'antd';
import * as _ from 'lodash';

import './ModalAddCustomer.scss';
import {
  regexTransactionCode,
  OPTIONS_TRANSACTION,
  TYPE_TRANSACTION_VALUE,
  OPTIONS_FORM_TRANSACTION,
  TYPE_TRANSACTION_REQUEST_VALUE,
  FORM_TRANSACTION_VALUE,
} from 'containers/Customer/customer.const';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTransactionCustomerSearchRequested,
  postAddTransactionCustomerRequested,
  getListAppointmentRequested,
} from 'store/customer/customer.action';
import StoreState from 'models/store';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { formatPhoneNumber } from 'helpers/utils';
import TextArea from 'antd/lib/input/TextArea';

interface CProps {
  show: boolean;
  isDetail?: boolean;
  dataDetail?: {
    fullName: string;
    phone: string;
    wallet: string;
    customerCode: string;
  };
  patient?: string | null;
  onHide: () => void;
}

interface InfoBasicModel {
  phone: string;
  fullName: string;
  wallet: string;
  amount?: number;
  transactionType: string;
  patient?: string | null;
  customerCode?: string;
}
const ModalAddCustomer: FC<CProps> = ({
  show,
  isDetail,
  dataDetail,
  patient,
  onHide,
}) => {
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [visible] = useState(show);

  const [infoBasic, setInfoBasic] = useState<InfoBasicModel>({
    phone: '',
    fullName: '',
    wallet: dataDetail?.wallet || '',
    transactionType: TYPE_TRANSACTION_VALUE.ADMIN_ADD,
    patient,
  });
  const customerReducer = useSelector(
    (state: StoreState) => state.customerReducer,
  );

  const onFinish = (values: any) => {
    let body = {
      ...values,
      amount: infoBasic.amount,
      type: TYPE_TRANSACTION_REQUEST_VALUE.BASE_TRANSACTION,
      wallet: infoBasic?.wallet,
      transactionType: infoBasic.transactionType,
    };

    delete body.customerCode;
    delete body.searchText;

    if (body.appointment) {
      body = {
        ...body,
        type: TYPE_TRANSACTION_REQUEST_VALUE.APPOINTMENT_TRANSACTION,
        transactionType: TYPE_TRANSACTION_VALUE.PATIENT_BOOK_APPOINTMENT,
      };
    }

    if (body.appointment === null) {
      delete body.appointment;
    }

    if (body.amount === null) {
      return message.error('Số tiền nhập chưa đúng.');
    }

    dispatch(postAddTransactionCustomerRequested(body));
  };

  const onSearchCustomerCode = (value: string | null) => {
    dispatch(
      getTransactionCustomerSearchRequested({
        q: value,
        totalCount: true,
        v: 'full',
        startIndex: 1,
        limit: 50,
        status: 'ACTIVE',
      }),
    );
  };

  const onChangeCustomerCode = (value: any) => {
    const { dataTransactionSearch } = customerReducer;
    const dataTransactionSearchFind = dataTransactionSearch.results.find(
      (item: any) => {
        return item.uuid === value;
      },
    );
    if (dataTransactionSearchFind) {
      setInfoBasic({
        ...infoBasic,
        phone: _.get(
          dataTransactionSearchFind,
          'person.attributes[0].value',
          '',
        ),
        fullName: dataTransactionSearchFind?.person?.display,
        wallet: dataTransactionSearchFind?.person?.wallet?.uuid || '',
        patient: dataTransactionSearchFind?.uuid,
      });
      form.setFieldsValue({
        appointment: null,
        customerCode: _.get(
          dataTransactionSearchFind,
          'identifiers[0].identifier',
          '',
        ),
      });
    }
  };

  const onChangeAmount = (amount: any) => {
    setInfoBasic({
      ...infoBasic,
      amount,
    });
  };

  const onChangeTypeTraction = event => {
    setInfoBasic({
      ...infoBasic,
      transactionType: event.target.value,
    });
  };

  const onSearchAppointment = (value: string | null) => {
    dispatch(
      getListAppointmentRequested({
        q: value,
        v: 'full',
        patient: infoBasic?.patient,
      }),
    );
  };

  const onChangeAppointment = (value: any) => {
    const { dataListAppointment } = customerReducer;
    const dataListAppointmentFind = dataListAppointment.results.find(
      (item: any) => {
        return item.uuid === value;
      },
    );

    const arrFee = dataListAppointmentFind?.timeSlot?.appointmentBlock?.provider?.attributes?.filter(
      item => item?.display?.includes('Examination fee'),
    );

    if (dataListAppointmentFind) {
      const customerCode = _.get(
        dataListAppointmentFind,
        'patient.identifiers[0].identifier',
        '',
      );

      setInfoBasic({
        ...infoBasic,
        phone: _.get(
          dataListAppointmentFind,
          'patient.person.attributes[0].value',
          '',
        ),
        fullName: dataListAppointmentFind?.patient?.person?.display,
        wallet: dataListAppointmentFind?.patient?.person?.wallet?.uuid || '',
        amount: _.get(arrFee, `[0].value`, null),
      });
      form.setFieldsValue({
        customerCode,
        amount: _.get(arrFee, `[0].value`, null),
      });
    }
  };

  return (
    <Modal
      title="Thêm mới giao dịch"
      centered
      visible={visible}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        className="modal-add-customer"
        requiredMark={false}
      >
        <Form.Item
          label="Loại giao dịch"
          name="transactionType"
          initialValue={infoBasic.transactionType}
        >
          <Radio.Group onChange={onChangeTypeTraction}>
            {OPTIONS_TRANSACTION.map((item: any) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
        {isDetail === true ? (
          <Row gutter={[10, 24]} className=" mb-6">
            <Col xs={24}>
              <Row>
                <Col xs={8}>Mã khách hàng</Col>
                <Col xs={16}>{dataDetail?.customerCode}</Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={8}>Số điện thoại</Col>
                <Col xs={16}>{dataDetail?.phone}</Col>
              </Row>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={8}>Họ và tên khách hàng</Col>
                <Col xs={16}>{dataDetail?.fullName}</Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <Form.Item
              label="Tìm kiếm khách hàng"
              rules={[
                {
                  required: true,
                  message: ' ',
                },
              ]}
              name="searchText"
            >
              <Select
                showSearch={true}
                suffixIcon={
                  customerReducer.isLoadingTransactionSearch ? (
                    <LoadingOutlined />
                  ) : (
                    <SearchOutlined />
                  )
                }
                notFoundContent={
                  customerReducer.isLoadingTransactionSearch ? null : (
                    <p>Không có dữ liệu</p>
                  )
                }
                filterOption={false}
                onSearch={onSearchCustomerCode}
                onFocus={() => onSearchCustomerCode(null)}
                onSelect={onChangeCustomerCode}
              >
                {customerReducer?.dataTransactionSearch?.results?.length
                  ? customerReducer.dataTransactionSearch.results.map(
                      (item: any) => {
                        return (
                          <Select.Option key={item?.uuid} value={item?.uuid}>
                            {item?.display} - {item?.user?.phoneNumber}
                          </Select.Option>
                        );
                      },
                    )
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              label="Mã khách hàng"
              name="customerCode"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập thông tin bắt buộc!',
                },
              ]}
            >
              <Input disabled value={infoBasic.customerCode} />
            </Form.Item>
            <Form.Item label="Số điện thoại">
              <Input disabled value={formatPhoneNumber(infoBasic.phone)} />
            </Form.Item>
            <Form.Item label="Họ tên khách hàng">
              <Input disabled value={infoBasic.fullName} />
            </Form.Item>
          </>
        )}

        {infoBasic.transactionType === TYPE_TRANSACTION_VALUE.ADMIN_ADD ? (
          <>
            <Form.Item
              label="Mã giao dịch"
              name="transactionCode"
              rules={[
                {
                  pattern: regexTransactionCode,
                  message: 'Mã giao dịch không đúng định dạng',
                },
                {
                  max: 50,
                  message: 'Vui lòng không nhập quá 50 ký tự',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Nội dung chuyển khoản"
              name="note"
              rules={[
                {
                  max: 300,
                  message: 'Vui lòng không nhập quá 300 ký tự',
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="Thanh toán cho cuộc hẹn" name="appointment">
              <Select
                showSearch={true}
                suffixIcon={
                  customerReducer.isLoadingAppointment ? (
                    <LoadingOutlined />
                  ) : (
                    <SearchOutlined />
                  )
                }
                notFoundContent={
                  customerReducer.isLoadingAppointment ? null : (
                    <p>Không có dữ liệu</p>
                  )
                }
                filterOption={false}
                onSearch={onSearchAppointment}
                onFocus={() => onSearchAppointment(null)}
                onSelect={onChangeAppointment}
              >
                {customerReducer?.dataListAppointment?.results?.length
                  ? customerReducer.dataListAppointment.results.map(
                      (item: any) => (
                        <Select.Option key={item?.uuid} value={item?.uuid}>
                          {item?.appointmentCode}
                        </Select.Option>
                      ),
                    )
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              label="Lý do"
              name="note"
              rules={[
                {
                  max: 300,
                  message: 'Vui lòng không nhập quá 300 ký tự',
                },
              ]}
            >
              <TextArea />
            </Form.Item>
          </>
        )}
        <div className="flex test">
          <Form.Item
            label="Số tiền"
            name="amount"
            className="form-items"
            rules={[
              { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
              ({ getFieldValue }) => ({
                validator() {
                  const amount = `${getFieldValue('amount')}`;
                  if (
                    (amount && amount.trim()?.length && Number(amount) < 1) ||
                    isNaN(Number(amount))
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Số tiền không hợp lệ!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              className="form-items-money"
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              style={{ width: '100%' }}
              maxLength={11}
              value={infoBasic?.amount}
              onChange={onChangeAmount}
            />
          </Form.Item>
          <span className="mt-2">VND</span>
        </div>
        <Form.Item
          label="Hình thức"
          name="form"
          initialValue={FORM_TRANSACTION_VALUE.TRANSFER}
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc.' },
          ]}
        >
          <Select>
            {OPTIONS_FORM_TRANSACTION.map((item: any) => {
              return (
                <Select.Option key={item.value} value={item.value}>
                  {item.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <div className="button-add-customer-modal-container">
          <Button type="default" onClick={onHide} className="btn-modal-add">
            Đóng
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={customerReducer.isLoadingAddTransaction}
            className="btn-modal-add"
          >
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddCustomer;
