import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Table,
  Spin,
  Form,
  Select,
  Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as _ from 'lodash';
import { formatPhoneNumber } from 'helpers/utils';
import './Information.scss';
import Action from 'components/Share/Table/Action';
import ModalAddInformation from 'components/Customer/ModalAddInformation';
import ModalViewInformation from 'components/Customer/ModalViewInformation';
import ModalEditInformation from 'components/Customer/ModalEditInformation';
import ModalResetPassword from 'components/Customer/ModalResetPassword';
import {
  getTransactionCustomerRequested,
  handleCallTest,
  handleDeleteCustomerRequested,
  // handleCallTest,
} from 'store/customer/customer.action';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from 'models/store';
import ModalConfirmCustomer from 'components/Customer/ModalConfirmCustomer';
import { TransactionModel } from 'models/store/customer.model';
import {
  OPTIONS_STATUS_CUSTOMER,
  OPTIONS_LIST_STATUS_CUSTOMER,
} from './customer.const';
import {
  regexConvertSearch1,
  regexConvertSearch2,
} from '../Doctor/doctor.const';

interface ParamsTransactionCustomerModel {
  v?: string;
  totalCount: boolean;
  startIndex: number;
  limit: number;
  q?: string;
  sortField?: string;
  sortDirection?: string;
}

const Information = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { Option } = Select;
  const inputEl = useRef<any>(null);
  const inputSearch = useRef<any>(null);
  const customerReducer = useSelector(
    (state: StoreState) => state.customerReducer,
  );
  const [isModalAddInformation, setModalAddInformation] = useState(false);
  const [isModalViewInformation, setModalViewInformation] = useState(false);
  const [isModalEditInformation, setModalEditInformation] = useState(false);
  const [isModalResetPassword, setModalResetPassword] = useState(false);
  const [paramsTransactionCustomer, setParamsTransactionCustomer] = useState<
    ParamsTransactionCustomerModel
  >({
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
  });
  const [isModalConfirmCustomer, setModalConfirmCustomer] = useState(false);
  const [itemInformation, setItemInformation] = useState<TransactionModel>(
    {} as TransactionModel,
  );

  const onOpenModalAddInformation = () => {
    setModalAddInformation(true);
  };
  const onCloseModalAddInformation = () => {
    setModalAddInformation(false);
  };
  const onOpenModalViewInformation = item => {
    setItemInformation(item);
    setModalViewInformation(true);
  };
  const onCloseModalViewInformation = () => {
    setModalViewInformation(false);
  };

  const onOpenModalEditInformation = item => {
    setItemInformation(item);
    setModalEditInformation(true);
  };
  const onCloseModalEditInformation = () => {
    setModalEditInformation(false);
  };
  const onOpenModalResetPassword = item => {
    setItemInformation(item);
    setModalResetPassword(true);
  };
  const onCloseModalResetPassword = () => {
    setModalResetPassword(false);
  };

  const onOpenModalStartCall = (record: any) => {
    const params: any = {
      patientUuid: _.get(record.person, 'uuid', ''),
      identifier: _.get(record.identifiers[0], 'identifier', ''),
    };

    confirm({
      title: 'Bạn có muốn thực hiện cuộc gọi hỗ trợ này?',
      icon: <ExclamationCircleOutlined />,
      width: '500px',
      okText: 'Có',
      cancelText: 'Không',
      onOk() {
        dispatch(handleCallTest(params));
      },
    });
  };

  const closeOpenConfirmCustomer = () => {
    setModalConfirmCustomer(false);
  };

  const getTransactionCustomer = useCallback(
    (values = {}) => {
      dispatch(
        getTransactionCustomerRequested({
          ...paramsTransactionCustomer,
          ...values,
        }),
      );
    },
    [dispatch, paramsTransactionCustomer, customerReducer.dataEditInformation],
  );

  const getListCustomer = () => {
    dispatch(getTransactionCustomerRequested());
  };

  const onChangePageTable = pagination => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsTransactionCustomer({ ...paramsTransactionCustomer, ...body });
  };

  const onFinish = values => {
    const searchInput1 = values?.q
      ? _.replace(values?.q, regexConvertSearch1, '')
      : '';
    const searchInput2 = _.replace(searchInput1, regexConvertSearch2, '');
    setParamsTransactionCustomer({
      ...paramsTransactionCustomer,
      ...values,
      q: searchInput2,
      startIndex: 1,
    });
  };

  const dataCustomer = useSelector(
    (state: any) => state.customerReducer.dataTransaction,
  );

  useEffect(() => {
    getTransactionCustomer();
  }, [
    getTransactionCustomer,
    paramsTransactionCustomer,
    dataCustomer?.totalCount,
  ]);
  useEffect(() => {
    if (customerReducer.dataAddInformation) {
      getTransactionCustomer();
    }
  }, [customerReducer.dataAddInformation, getTransactionCustomer]);

  useEffect(() => {
    if (customerReducer.isChangePasswordCustomer) {
      onCloseModalResetPassword();
    }
  }, [customerReducer.isChangePasswordCustomer]);

  const onBlurSelect = val => {
    if (inputSearch.current !== null && inputSearch.current !== undefined) {
      const searchInput1 = _.replace(
        inputSearch?.current?.props?.value,
        regexConvertSearch1,
        '',
      );
      const searchInput2 = _.replace(searchInput1, regexConvertSearch2, '');
      const values = { status: val, q: searchInput2 };
      setParamsTransactionCustomer({
        ...paramsTransactionCustomer,
        ...values,
        startIndex: 1,
      });
    }

    // eslint-disable-next-line no-unused-expressions
    const refs: any = inputEl?.current;
    refs.blur();
  };

  const optionForSelect = OPTIONS_LIST_STATUS_CUSTOMER.map(item => (
    <>
      <Option value={item.value} key={item.value}>
        {item.label}
      </Option>
    </>
  ));

  // eslint-disable-next-line no-shadow
  const handleDeleteCustomer = record => {
    const onDelete = () => {
      const uuid = _.get(record, 'uuid', '');
      dispatch(handleDeleteCustomerRequested(uuid));
      getListCustomer();
    };
    confirm({
      title: 'Bạn có chắc chắn xóa khách hàng này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };
  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value, row, index) => {
        return (
          (paramsTransactionCustomer.startIndex - 1) *
            paramsTransactionCustomer.limit +
          index +
          1
        );
      },
    },
    {
      title: 'Mã',
      width: '10%',
      render: value => {
        return _.get(value, 'identifiers[0].identifier', '');
      },
    },
    {
      title: 'Họ và tên',
      width: '25%',
      render: value => {
        return _.get(value, 'person.display', '');
      },
    },
    {
      title: 'Số điện thoại',
      width: '15%',
      render: value => {
        return formatPhoneNumber(_.get(value, 'user.display', null));
      },
    },
    {
      title: 'Email',
      width: '15%',
      render: value => {
        return _.get(value, 'user.email', null);
      },
    },
    {
      title: 'Trạng thái',
      width: 150,
      render: value => {
        return _.get(
          OPTIONS_STATUS_CUSTOMER,
          _.get(value, 'user.status', null),
          null,
        );
      },
    },
    {
      title: '',
      width: 150,
      render: value => {
        return (
          <Action
            isCancel={true}
            hasCallTest={true}
            isCallTest={value && value?.user?.status === 'IN_ACTIVE'}
            hasResetPassword={true}
            handleCallTest={() => onOpenModalStartCall(value)}
            handleDetail={() => onOpenModalViewInformation(value)}
            handleEdit={() => onOpenModalEditInformation(value)}
            handleDelete={() => handleDeleteCustomer(value)}
            handleResetPassword={() => onOpenModalResetPassword(value)}
          />
        );
      },
    },
  ];

  return (
    <div className="customer-information-transaction">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý thông tin khách hàng</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" style={{ marginRight: 16 }}>
              Import dữ liệu
            </Button>
            <Button type="primary" onClick={onOpenModalAddInformation}>
              Thêm mới
            </Button>
          </Col>
        </Row>

        <Form onFinish={onFinish}>
          <Row style={{ marginTop: 40 }} gutter={[20, 10]} align="bottom">
            <Col xs={6}>
              <Form.Item
                label="Trạng thái"
                labelCol={{ span: 24 }}
                name="status"
              >
                <Select
                  placeholder="Trạng thái"
                  ref={inputEl}
                  onSelect={e => onBlurSelect(e)}
                >
                  <Option value="" key={0}>
                    Tất cả
                  </Option>
                  {optionForSelect}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={8}>
              <Form.Item name="q">
                <Input
                  ref={inputSearch}
                  placeholder="Nhập họ và tên, mã, số điện thoại, email..."
                />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} />
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24}>
          <Table
            rowKey={record => record.uuid}
            pagination={{
              current: paramsTransactionCustomer?.startIndex,
              pageSize: paramsTransactionCustomer?.limit,
              total: customerReducer?.dataTransaction?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            dataSource={customerReducer?.dataTransaction?.results}
            columns={columns}
            onChange={onChangePageTable}
            loading={{
              spinning: customerReducer?.isLoadingTransaction,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
          />
        </Col>
      </Row>
      {isModalAddInformation && (
        <ModalAddInformation
          show={isModalAddInformation}
          onHide={onCloseModalAddInformation}
        />
      )}
      {isModalEditInformation && (
        <ModalEditInformation
          show={isModalEditInformation}
          onHide={onCloseModalEditInformation}
          title="Chỉnh sửa thông tin người dùng"
          itemInformation={itemInformation}
        />
      )}
      {isModalViewInformation && (
        <ModalViewInformation
          show={isModalViewInformation}
          onHide={onCloseModalViewInformation}
          itemInformation={itemInformation}
        />
      )}

      {isModalResetPassword && (
        <ModalResetPassword
          show={isModalResetPassword}
          itemInformation={itemInformation}
          onHide={onCloseModalResetPassword}
        />
      )}

      {isModalConfirmCustomer && (
        <ModalConfirmCustomer
          show={isModalConfirmCustomer}
          onHide={closeOpenConfirmCustomer}
        />
      )}
    </div>
  );
};

export default Information;
