/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo, useState, useCallback } from 'react';
import { Modal, Button, Tooltip, Spin, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { selectors, actions as appointmentActions } from 'store/appointment';
import {
  ExclamationCircleOutlined,
  PhoneOutlined,
  PhoneTwoTone,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { StartCall } from 'types/action/appointment/StartCall';
import * as _ from 'lodash';
import ActionTable from 'components/Share/Table/Action';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Appointment } from 'types/action/appointment/Appointment';
import Format from 'constants/Date';
import { pathRouteWithRole } from 'helpers/utils';
import ModalCancelAppointment from '../Modal/ModalCancelAppointment';
import ModalChangeStatusAppointment from '../Modal/ModalChangeStatusAppointment';
import './Table.scss';

interface RowDataModel {
  appointmentId: string;
  appointmentCode: string;
  patientDisplay: string;
  providerDisplay: string;
  appointmentType: string;
  timeSlot: string;
  status: string;
  paymentStatus: string;
  patientUUID: string;
  providerUUID: string;
  startDate: string;
  appointmentFollowing: Array<string>;
}

interface ParamSearchModel {
  v: string;
  totalCount: boolean;
  appointmentType?: string;
  limit: number;
  paymentStatus?: string;
  q?: string;
  startIndex: number;
  status?: string;
}
interface CProps {
  paramSearch: ParamSearchModel;
  onChangePageTable: (pagination, filters, sorters) => void;
  onSort: (type: string) => void;
}
const { confirm } = Modal;

const AppointmentTable: FC<CProps> = ({
  paramSearch,
  onChangePageTable,
  onSort,
}) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [visibleModal, setVisibleModal] = useState<any>(false);
  const [visibleModalStatus, setVisibleModalStatus] = useState<any>(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  const handleOpenModalCancelAppointment = item => {
    setSelectedItem(item);
    setVisibleModal(true);
  };

  const handleCloseModalCancelAppointment = () => {
    setVisibleModal(false);
  };

  const handleOpenModalStatusAppointment = item => {
    setSelectedItem(item);
    setVisibleModalStatus(true);
  };

  const handleCloseModalStatusAppointment = () => {
    setVisibleModalStatus(false);
  };
  const appointment = _.get(
    useSelector(state => selectors.getAppointment(state)),
    'appointment',
    [],
  );

  const dataCancel = useSelector((state: any) => state.appointment.dataCancel);
  const loadingStatus = _.get(
    useSelector(state => selectors.getAppointment(state)),
    'isLoading',
    [],
  );

  const listAppointmentLength = _.get(
    useSelector(state => selectors.getAppointment(state)),
    'appointment.total',
    [],
  );

  const handleViewDetail = (record: Appointment) => {
    history.push(
      `${pathRouteWithRole()}/appointments/detail/${_.get(
        record,
        'appointmentId',
      )}?patientId=${_.get(record, 'patientUUID')}`,
    );
  };

  const handleStartCall = (record: StartCall) => {
    confirm({
      title: 'Bạn có muốn thực hiện cuộc gọi này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      cancelText: 'Không',
      onOk() {
        dispatch(appointmentActions.startCall(record));
      },
    });
  };
  let appointmentStatus = '';
  const appointmentData: RowDataModel[] = [];

  const dataTable = useMemo(() => {
    if (appointment.data && appointment.data !== undefined) {
      appointment.data.forEach(item => {
        switch (item?.status?.code) {
          case 'SCHEDULED':
            appointmentStatus = 'Sắp diễn ra';
            break;
          case 'INCONSULTATION':
            appointmentStatus = 'Đang diễn ra';
            break;
          case 'WAITING_EXAMINATION':
            appointmentStatus = 'Chờ xét nghiệm';
            break;
          case 'COMPLETED':
            appointmentStatus = 'Hoàn thành';
            break;
          case 'CANCELLED':
            appointmentStatus = 'Đã hủy';
            break;
          case 'WAITING_PAYMENT':
            appointmentStatus = 'Chờ thanh toán';
            break;
          case 'MISSED':
            appointmentStatus = 'Trễ hẹn';
            break;
        }

        const appointmentTypeArr = _.get(
          item,
          'timeSlot.appointmentBlock.provider.attributes',
          '',
        );

        const appointmentFollows = _.get(item, 'appointmentFollows', []);
        const rowData: RowDataModel = {
          appointmentId: item?.uuid,
          appointmentCode: item?.appointmentCode,
          patientDisplay: item?.patient?.person?.display,
          providerDisplay: _.get(
            item,
            'timeSlot.appointmentBlock.provider.person.display',
            '',
          ),

          appointmentType:
            appointmentTypeArr?.length > 0
              ? appointmentTypeArr
                  ?.filter(vals => vals?.display?.includes('Specialist'))
                  .map(i => i?.value.display)
              : '',
          timeSlot: `${moment(item.timeSlot?.startDate).format(
            Format.DATE_TIME,
          )} (${moment(item.timeSlot?.startDate).format(
            Format.TIME,
          )} - ${moment(item.timeSlot?.endDate).format(Format.TIME)})`,
          status: appointmentStatus,
          paymentStatus: '----',
          patientUUID: item?.patient?.uuid,
          providerUUID: item.timeSlot?.appointmentBlock.provider.uuid,
          startDate: item.timeSlot?.startDate,
          appointmentFollowing:
            appointmentFollows?.length > 0
              ? appointmentFollows
                  ?.filter(
                    vals =>
                      moment(vals.followDate).diff(moment(), 'days') >= 0 &&
                      !vals.followResult,
                  )
                  .map(i => i.followDescription)
              : [],
        };
        appointmentData.push(rowData);
      });
    }
    return appointmentData;
  }, [appointment, _.isEmpty(dataCancel) === false]);

  const isDisable = useCallback(item => {
    return (
      (moment(item.startDate).diff(moment(), 'm') >= 5 &&
        item.status !== 'Đang diễn ra') ||
      item.status === 'Đã hủy' ||
      item.status === 'Chờ thanh toán' ||
      (item.status === 'Hoàn thành' && !item?.appointmentFollowing.length)
    );
  }, []);

  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value, row, index) => {
        return (paramSearch.startIndex - 1) * paramSearch.limit + index + 1;
      },
    },
    {
      title: 'Mã',
      dataIndex: 'appointmentCode',
      width: 120,
    },
    {
      title: 'Tên người khám',
      dataIndex: 'patientDisplay',
    },
    {
      title: 'Bác sĩ phụ trách',
      dataIndex: 'providerDisplay',
    },
    {
      title: 'Chuyên khoa',
      render: (row, value) => {
        if (row?.appointmentType) {
          return (
            <>
              {row?.appointmentType.map((item, index) => (
                <span>
                  {index !== row?.appointmentType.length - 1
                    ? `${item}, `
                    : `${item}`}
                </span>
              ))}
            </>
          );
        }
      },
    },
    {
      title: () => (
        <div className="header-time-slot">
          <div>Giờ tư vấn</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: '10px',
            }}
          >
            <CaretUpOutlined
              style={{ fontSize: '20px', padding: 0 }}
              onClick={() => onSort('DESC')}
            />
            <CaretDownOutlined
              style={{ fontSize: '20px' }}
              onClick={() => onSort('ASC')}
            />
          </div>
        </div>
      ),
      dataIndex: 'timeSlot',
    },
    {
      title: 'Trạng thái cuộc gọi tư vấn',
      dataIndex: 'status',
      width: 150,
    },
    {
      width: 150,
      render: (record: any) => {
        return (
          <div>
            <>
              <ActionTable
                hasDelete={false}
                hasEdit={false}
                hasChangeStatus={true}
                isChangeStatus={
                  !(
                    record.status === 'Đang diễn ra' ||
                    record.status === 'Sắp diễn ra' ||
                    record.status === 'Trễ hẹn' ||
                    record.status === 'Hoàn thành'
                  )
                }
                handleChangeStatus={() => {
                  handleOpenModalStatusAppointment(record);
                }}
                isCancel={
                  !(
                    record.status === 'Đang diễn ra' ||
                    record.status === 'Sắp diễn ra'
                  )
                }
                hasCancel={true}
                handleDetail={() => {
                  handleViewDetail(record);
                }}
                handleCancelAppointment={() =>
                  handleOpenModalCancelAppointment(record)
                }
              />
              <Tooltip title="Tạo cuộc gọi">
                <Button
                  className="lg:ml-2"
                  disabled={isDisable(record)}
                  onClick={() => handleStartCall(record)}
                >
                  {isDisable(record) ? <PhoneOutlined /> : <PhoneTwoTone />}
                </Button>
              </Tooltip>
            </>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        rowKey={record => {
          return record.appointmentCode;
        }}
        pagination={{
          current: paramSearch.startIndex,
          pageSize: paramSearch.limit,
          total: listAppointmentLength,
          showSizeChanger: true,
          locale: { items_per_page: '/Trang' },
        }}
        dataSource={dataTable}
        columns={columns}
        onChange={onChangePageTable}
        loading={{
          spinning: loadingStatus,
          indicator: <Spin />,
          tip: 'Đang tải...',
        }}
      />
      {visibleModal && (
        <ModalCancelAppointment
          data={selectedItem}
          visible={visibleModal}
          handleCancelModal={handleCloseModalCancelAppointment}
        />
      )}
      {visibleModalStatus && (
        <ModalChangeStatusAppointment
          data={selectedItem}
          visible={visibleModalStatus}
          handleCancelModal={handleCloseModalStatusAppointment}
        />
      )}
    </>
  );
};

export default AppointmentTable;
