import { CameraFilled } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Checkbox,
  Switch,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  SelectProps,
  DatePickerProps,
  Upload,
  DatePicker,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import jq from 'jquery';
import StoreState from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import authorizedRequest from 'helpers/request/authorizedRequest';
import useQuery from 'helpers/useQuery';
import { isEmpty } from 'lodash';
import * as doctorInfoActions from '../../../store/doctor/doctor.action';
import * as servicePackageActions from '../../../store/servicePackage/servicePackage.action';
import { regextTrimspace } from '../servicePackage.const';
import './add.scss';
import config from '../../../configs';

const { Option } = Select;

interface ParamsInformationDoctorModel {
  v?: string;
  class?: string;
}

interface ParamsServicePackageModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  isLock?: string | null;
  packageName: string | null;
  packageStatus: string | null;
}

const { RangePicker } = DatePicker;

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function filterPackageReferenceEdit(item: any, index: any, array: any) {
  if (
    item.lock != true &&
    moment().isSameOrAfter(moment(item.startDate)) &&
    moment().isSameOrBefore(item.endDate)
  ) {
    return item;
  }
}

function dataPackagePreference(idsPackageReference, packages) {
  if (idsPackageReference && packages) {
    const dataPackagePreferenceArr: any[] = [];
    idsPackageReference.split(',').forEach(element => {
      const obj = {
        label: packages.find(o => o.identifier === element)?.name,
        value: packages.find(o => o.identifier === element)?.identifier,
      };
      dataPackagePreferenceArr.push(obj);
    });
    return dataPackagePreferenceArr;
  }
}

function checkOutdatedPackages(items: any, packages: any) {
  if (items && packages) {
    const availablePackages = packages
      .filter(filterPackageReferenceEdit)
      .map(v => {
        return v.identifier;
      });
    const oudatedPackages: any[] = [];
    items.forEach(item => {
      if (!availablePackages.includes(item)) {
        const dataItem = packages.find(o => o.identifier === item);
        const objItem = { value: item, label: dataItem?.name };
        oudatedPackages.push(objItem);
      }
    });

    return oudatedPackages;
  }
}

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
    ['clean'],
  ],
};

const EditServicePackage: FC = type => {
  const { id } = useParams<any>();
  const query = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
  };

  const specialistReducer = useSelector(
    (state: StoreState) => state.doctorReducer.dataSpecialist.results,
  );
  const servicePackageReducer = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataServicePackage.datas,
  );
  const [isViewMode, setIsViewMode] = useState(query?.type === 'view');
  // const [dataHealth, setDataHealth] = useState<any>();

  const [form] = Form.useForm();
  const [urlAvatar, setUrlAvatar] = useState<any>('');
  const [urlCover, setUrlCover] = useState<any>('');
  const [contentEditor, setContentEditor] = useState<any>('');
  const [termOfUseEditor, setTermOfUseEditor] = useState<any>('');
  const [fileListAvatar, setFileListAvatar] = useState<any>();
  const [fileListCover, setFileListCover] = useState<any>();
  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
      class: 'Specialist',
    },
  );
  const [ParamsServicePackage, setParamsServicePackage] = useState<
    ParamsServicePackageModel
  >({
    isLock: '',
    packageName: '',
    packageStatus: '',
    // v: 'full',
    // startIndex: 1,
    // totalCount: true,
    // limit: 10,
    // sortField: 'DATE_CREATED',
    // sortDirection: 'DESC',
  });
  const dataDetail = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataServicePackageDetail,
  );
  const dataAllPackages = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataServicePackage,
  );
  const dataUploadAvatar = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataUploadAvatar,
  );
  const dataUploadCover = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataUploadCover,
  );
  const getSpecialist = () => {
    dispatch(
      doctorInfoActions.getSpecialistRequested({
        ...ParamsInformationDoctorModel,
      }),
    );
  };

  const getDataServicePackage = () => {
    dispatch(
      servicePackageActions.getServicePackageRequested({
        ...ParamsServicePackage,
      }),
    );
  };

  useEffect(() => {
    getSpecialist();
    getDataServicePackage();
  }, []);
  useEffect(() => {
    getDetailServicePackage();
  }, [id]);
  useEffect(() => {
    setFileListAvatar([
      {
        uid: '1',
        name: 'avatar',
        status: 'done',
        url: urlAvatar,
      },
    ]);
  }, [urlAvatar, dataUploadAvatar]);
  useEffect(() => {
    setFileListCover([
      {
        uid: '2',
        name: 'cover',
        status: 'done',
        url: urlCover,
      },
    ]);
  }, [urlCover, dataUploadCover]);

  useEffect(() => {
    if (dataDetail && !isEmpty(dataDetail)) {
      setContentEditor(dataDetail.content || '');
      setTermOfUseEditor(dataDetail.termsOfUse || '');
      if (dataDetail?.thumbnail) {
        setUrlAvatar(
          `${config.BASE_API_URL}/v2/servicePackage/${dataDetail?.uuid}/image/${dataDetail?.thumbnail}`,
        );
      }
      if (dataDetail?.background) {
        setUrlCover(
          `${config.BASE_API_URL}/v2/servicePackage/${dataDetail?.uuid}/image/${dataDetail?.background}`,
        );
      }
      form.setFieldsValue({
        identifier: dataDetail.identifier,
        packageFormatType: dataDetail.packageFormatType,
        nbMaxOfUse: dataDetail.nbMaxOfUse,
        name: dataDetail.name,
        content: dataDetail.content,
        idsPackageReference: dataDetail?.idsPackageReference
          ? dataPackagePreference(
              dataDetail?.idsPackageReference,
              servicePackageReducer,
            )
          : [],
        termsOfUse: dataDetail.termsOfUse,
        originalPrice: dataDetail.originalPrice,
        promotionPrice: dataDetail.promotionPrice,
        speciality: isJsonString(dataDetail?.speciality.replaceAll(`'`, `"`))
          ? JSON.parse(dataDetail?.speciality.replaceAll(`'`, `"`)).map(
              item => {
                return { value: item.uuid, label: item.name };
              },
            )
          : [],
        startEndDate: [
          moment(dataDetail.startDate),
          moment(dataDetail.endDate),
        ],
        numberOfDays: dataDetail.numberOfDays,
        lock: dataDetail.lock ? 'IN_ACTIVE' : 'ACTIVE',
        displayInApp: dataDetail.displayInApp,
      });
    }
  }, [dataDetail, form, servicePackageReducer]);
  const getDetailServicePackage = () => {
    dispatch(servicePackageActions.getDetailServicePackageRequested(id));
  };
  const dataEditServicePackage = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataEditServicePackage,
  );
  const onFinish = values => {
    const {
      lock,
      thumbnail,
      background,
      packageFormatType,
      name,
      idsPackageReference,
      content,
      termsOfUse,
      originalPrice,
      promotionPrice,
      startEndDate,
      numberOfDays,
      nbMaxOfUse,
      speciality,
      displayInApp,
    } = values;
    const payload = {
      name,
      content,
      termsOfUse,
      originalPrice,
      promotionPrice,
      packageFormatType,
      idsPackageReference: idsPackageReference
        .map(item => {
          return item.value;
        })
        .join(','),
      startDate: `${startEndDate[0].format('YYYY-MM-DD')}T00:00:00+07:00`,
      endDate: `${startEndDate[1].format('YYYY-MM-DD')}T23:59:59+07:00`,
      numberOfDays,
      nbMaxOfUse: packageFormatType == 'ON' ? nbMaxOfUse : 1,
      speciality: JSON.stringify(
        speciality?.map(item => {
          return { uuid: item.value, name: item.label };
        }),
      ),
      lock: lock !== 'ACTIVE',
      formThumbnail: null as any,
      formBackground: null as any,
      displayInApp,
    };
    if (numberOfDays <= 0) {
      message.error('Số ngày không hợp lệ');
      return;
    }
    // upload image
    if (dataEditServicePackage !== undefined) {
      if (thumbnail?.fileList[1]) {
        const file = thumbnail?.fileList[1];
        const data = {
          attributeType: '1962dfff-3cf3-420d-b804-f1e096dfb5fd',
        };

        const formData = new FormData();
        if (file !== undefined) {
          formData.append('json', JSON.stringify(data));
          formData.append('file', file?.originFileObj);
          payload.formThumbnail = formData;
        }
      }
      if (background?.fileList[1]) {
        const file1 = background?.fileList[1];
        const data1 = {
          attributeType: '1962dghj-3cf3-420d-b804-f1e096dgnaht',
        };

        const formData1 = new FormData();
        if (file1 !== undefined) {
          formData1.append('json', JSON.stringify(data1));
          formData1.append('file', file1?.originFileObj);
          payload.formBackground = formData1;
        }
      }
    }
    // end upload image
    dispatch(servicePackageActions.editServicePackageRequested(id, payload));
  };

  const handleUploadCover = (file: any) => {
    setFileListCover([
      {
        uid: '1',
        name: 'backgroud',
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);
    return false;
  };

  const handleRemoveCover = () => {
    setFileListCover('');
  };

  const handleUploadAvatar = (file: any) => {
    setFileListAvatar([
      {
        uid: '1',
        name: 'thumbnail',
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);
    return false;
  };

  const handleRemoveAvatar = () => {
    setFileListAvatar('');
  };

  useEffect(() => {
    jq(document).ready(() => {
      jq('.ant-btn').attr('title', '');
    });
  });

  return (
    <div className="edit-doctor">
      <Row>
        <Col xs={24}>
          <h1 className="mb-10">
            {isViewMode ? 'Thông tin gói dịch vụ' : 'Sửa gói khám dịch vụ'}
          </h1>
        </Col>
      </Row>

      <Form
        {...formItemLayout}
        labelAlign="left"
        labelWrap
        name="basic"
        onFinish={onFinish}
        className="modal-edit-doctor"
        requiredMark={false}
        form={form}
      >
        <div className="upload-images-box pb-5">
          <div className="box_image_1">
            <Form.Item
              name="thumbnail"
              label="Ảnh đại diện"
              className="form-item avatar"
              // labelCol={{ span: 6 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('thumbnail')?.fileList[1]?.size <
                        10485760 ||
                      getFieldValue('thumbnail')?.fileList?.length === 0 ||
                      getFieldValue('thumbnail') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Dung lượng ảnh không vượt quá 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                fileList={fileListAvatar}
                disabled={isViewMode}
                accept="image/*"
                beforeUpload={handleUploadAvatar}
                onRemove={handleRemoveAvatar}
                listType="picture-card"
                className="upload-image"
              >
                <CameraFilled
                  style={{
                    fontSize: '30px',
                    opacity: '45%',
                  }}
                />
              </Upload>
            </Form.Item>
          </div>
          <div className="box_image_2">
            <Form.Item
              name="background"
              label="Ảnh bìa"
              className="form-item cover"
              // labelCol={{ span: 6 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('background')?.fileList[1]?.size <
                        10485760 ||
                      getFieldValue('background')?.fileList?.length === 0 ||
                      getFieldValue('background') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Vui lòng nhập kích thước ảnh nhỏ hơn 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                fileList={fileListCover}
                disabled={isViewMode}
                accept="image/*"
                beforeUpload={handleUploadCover}
                onRemove={handleRemoveCover}
                listType="picture-card"
                className="upload-image"
              >
                <CameraFilled style={{ fontSize: '30px', opacity: '45%' }} />
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="ID gói dịch vụ"
          name="identifier"
          className="form-item"
        >
          <Input disabled={true} style={{ width: 'fit-content' }} />
        </Form.Item>
        <Form.Item
          label="Hình thức gói dịch vụ"
          name="packageFormatType"
          className="form-item"
        >
          <Select style={{ width: 'fit-content' }} disabled={isViewMode}>
            <Option value="ON">Online</Option>
            <Option value="OFF">Offline</Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.packageFormatType !== currentValues.packageFormatType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('packageFormatType') === 'ON' ? (
              <Form.Item
                label={'Số lần được sử dụng. Tối đa 1000'}
                name="nbMaxOfUse"
                className="form-item fee-test"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng điền thông tin bắt buộc!',
                  },
                ]}
              >
                <InputNumber
                  disabled={isViewMode}
                  style={{ width: 100 }}
                  className="input-type"
                  min="1"
                  max="1000"
                />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          label="Tên gói dịch vụ"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            { max: 255, message: 'Họ tên không vượt quá 255 ký tự!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
          className="form-item pt-3"
        >
          <Input disabled={isViewMode} style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item label="Trạng thái" name="lock" className="form-item">
          <Radio.Group disabled={isViewMode}>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Gói dịch vụ khuyến mãi kèm theo"
          name="idsPackageReference"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const outdatedPackages = checkOutdatedPackages(
                  value.map(item => {
                    return item.value;
                  }),
                  servicePackageReducer,
                );
                if (outdatedPackages && outdatedPackages.length) {
                  const labels = outdatedPackages.map(item => {
                    return item.label;
                  });
                  const print_labels = labels.join(',');
                  return Promise.reject(
                    new Error(
                      `Gói khám "${print_labels}" hết hạn hoặc bị khoá. Vui lòng gỡ khỏi gói tặng kèm`,
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            disabled={isViewMode}
            style={{ width: '50%' }}
            placeholder="Chọn gói khám tặng kèm"
            labelInValue={true}
            options={servicePackageReducer
              ?.filter(filterPackageReferenceEdit)
              ?.map(item => {
                if (id === item.uuid) {
                  return {
                    label: item.name,
                    value: item.identifier,
                    disabled: true,
                  };
                }
                return {
                  label: item.name,
                  value: item.identifier,
                };
              })}
          />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Chuyên khoa"
          name="speciality"
          rules={[
            {
              required: true,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: '50%' }}
            placeholder="Chọn chuyên khoa"
            disabled={isViewMode}
            labelInValue={true}
            options={specialistReducer?.map(item => {
              return {
                label: item.display,
                value: item.uuid,
              };
            })}
          />
        </Form.Item>
        <Form.Item label="Giá gốc" required>
          <Form.Item
            noStyle
            rules={[
              {
                required: true,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
              {
                pattern: /^[\d]{0,10}$/,
                message: 'Vui lòng nhập ít hơn 10 ký tự',
              },
            ]}
            name="originalPrice"
          >
            <InputNumber
              disabled={isViewMode}
              style={{ width: 300 }}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="input-type mr-2"
              placeholder="Nhập giá gốc"
              min={0}
              addonAfter={'đ'}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Giá ưu đãi" required>
          <Form.Item
            noStyle
            rules={[
              {
                required: true,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
              {
                pattern: /^[\d]{0,10}$/,
                message: 'Vui lòng nhập ít hơn 10 ký tự',
              },
            ]}
            name="promotionPrice"
          >
            <InputNumber
              disabled={isViewMode}
              style={{ width: 300 }}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="input-type mr-2"
              placeholder="Nhập giá ưu đãi"
              min={0}
              addonAfter={'đ'}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label={<p>Ngày bắt đầu và kết thúc</p>}
          name="startEndDate"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <RangePicker
            disabled={isViewMode}
            style={{ maxWidth: '800px' }}
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          label={'Hạn sử dụng (ngày). Tối đa 18000'}
          name="numberOfDays"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <InputNumber
            min="1"
            max="18000"
            controls={true}
            disabled={isViewMode}
            style={{ width: 300 }}
            className="input-type"
          />
        </Form.Item>
        <Form.Item
          label="Nội dung chi tiết"
          name="content"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
          ]}
        >
          <Col style={{ width: '90%' }}>
            <ReactQuill
              readOnly={isViewMode}
              value={contentEditor}
              onChange={(content, delta, source, editor) => {
                const data = content;
                form.setFieldsValue({
                  content: data,
                });
                setContentEditor(data);
              }}
              modules={modules}
            />
          </Col>
        </Form.Item>
        <Form.Item
          label="Điều khoản sử dụng"
          name="termsOfUse"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
          ]}
        >
          <Col style={{ width: '90%' }}>
            <ReactQuill
              readOnly={isViewMode}
              value={termOfUseEditor}
              onChange={(content, delta, source, editor) => {
                const data = content;
                console.log(data);
                form.setFieldsValue({
                  termsOfUse: data,
                });
                setTermOfUseEditor(data);
              }}
              modules={modules}
            />
          </Col>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.lock !== currentValues.lock
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('lock') === 'ACTIVE' ? (
              <Form.Item label="Hiển thị trên app" name="displayInApp">
                <Radio.Group disabled={isViewMode}>
                  <Radio value={true}>Có</Radio>
                  <Radio value={false}>Không</Radio>
                </Radio.Group>
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <div className="button-container">
          <Button
            type="default"
            onClick={() => history.push(`/app/service-package`)}
          >
            Quay lại
          </Button>
          {!isViewMode && (
            <Button className="ml-2" type="primary" htmlType="submit">
              Lưu
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default EditServicePackage;
