import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { DoctorModel } from 'models/store/doctor.model';

export interface DoctorReducerState {
  dataDoctor: DoctorModel;
  isLoadingDoctor: boolean;
  isLoading: boolean;
  isLoadingSpecialist: boolean;
  isLoadingHealthFacility: boolean;
  dataSpecialist: DoctorModel;
  dataHealthFacility: DoctorModel;
  dataDoctorDetail: DoctorModel;
  dataDetailHistoryFee: DoctorModel;
  dataDetailWalletDoctor: Array<any>;
  dataDetailDoctor: DoctorModel;
  isloadingCheckout: boolean;
  dataAfterCheckout: any;
  isLoadingUploadCover: boolean;
  isLoadingUploadAvatar: boolean;
  dataUploadAvatar: any;
  dataUploadCover: any;
  dataUpdateCover: any;
  dataUpdateAvatar: any;
  isLoadingUpdateAvatar: boolean;
  isLoadingUpdateCover: boolean;
  dataAddDoctor: DoctorModel;
  dataDeleteDoctor: any;
  isLoadingDelete: boolean;
}

const initialState: DoctorReducerState = {
  dataDoctor: {} as DoctorModel,
  isLoadingDoctor: false,
  isLoading: false,
  isLoadingSpecialist: false,
  dataSpecialist: {} as DoctorModel,
  dataHealthFacility: {} as DoctorModel,
  dataDoctorDetail: {} as DoctorModel,
  dataDetailHistoryFee: {} as DoctorModel,
  dataDetailWalletDoctor: [],
  isloadingCheckout: false,
  dataDetailDoctor: {} as DoctorModel,
  dataAfterCheckout: {},
  dataUploadCover: {} as any,
  dataUploadAvatar: {} as any,
  isLoadingUploadAvatar: false,
  isLoadingUploadCover: false,
  dataUpdateCover: {} as any,
  dataUpdateAvatar: {} as any,
  isLoadingUpdateAvatar: false,
  isLoadingUpdateCover: false,
  dataAddDoctor: {} as DoctorModel,
  dataDeleteDoctor: {} as any,
  isLoadingDelete: false,
  isLoadingHealthFacility: false,
};

const doctorReducer = handleActions<any, ActionModel>(
  {
    GET_DOCTOR_REQUESTED: state => {
      return {
        ...state,
        isLoadingDoctor: true,
      };
    },

    GET_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDoctor: action.payload,
        isLoadingDoctor: false,
      };
    },

    GET_DOCTOR_FAILED: state => {
      return {
        ...state,
        isLoadingDoctor: false,
      };
    },
    GET_DETAIL_DOCTOR_REQUESTED: state => {
      return {
        ...state,
        isLoading: true,
      };
    },

    GET_DETAIL_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDetailDoctor: action.payload,
        isLoading: false,
      };
    },

    GET_DETAIL_DOCTOR_FAILED: state => {
      return {
        ...state,
        isLoading: false,
      };
    },

    GET_SPECIALIST_REQUESTED: state => {
      return {
        ...state,
        isLoadingSpecialist: true,
      };
    },
    GET_SPECIALIST_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataSpecialist: action.payload,
        isLoadingSpecialist: false,
      };
    },
    GET_SPECIALIST_FAILED: state => {
      return {
        ...state,
        isLoadingSpecialist: false,
      };
    },
    GET_HEALTH_FACILITY_REQUESTED: state => {
      return {
        ...state,
        isLoadingHealthFacility: true,
      };
    },
    GET_HEALTH_FACILITY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataHealthFacility: action.payload,
        isLoadingHealthFacility: false,
      };
    },
    GET_HEALTH_FACILITY_FAILED: state => {
      return {
        ...state,
        isLoadingHealthFacility: false,
      };
    },
    GET_DOCTOR_DETAIL_BY_ID_REQUESTED: params => {
      return {
        ...params,
        isLoading: true,
      };
    },
    GET_DOCTOR_DETAIL_BY_ID_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDoctorDetail: action.payload,
        isLoading: false,
      };
    },
    GET_DOCTOR_DETAIL_BY_ID_FAILED: (error: string) => {
      return {
        error,
        isLoading: false,
      };
    },
    GET_DETAIL_WALLET_DOCTOR_REQUESTED: params => {
      return {
        ...params,
      };
    },
    GET_DETAIL_WALLET_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDetailWalletDoctor: action.payload,
      };
    },
    GET_DETAIL_WALLET_DOCTOR_FAILED: (error: string) => {
      return { error };
    },
    GET_DETAIL_FEE_HISTORY_REQUESTED: state => {
      return {
        ...state,
      };
    },
    GET_DETAIL_FEE_HISTORY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDetailHistoryFee: action.payload,
      };
    },
    GET_DETAIL_FEE_HISTORY_FAILED: state => {
      return {
        ...state,
      };
    },
    CHECKOUT_FEE_REQUESTED: state => {
      return { ...state, isloadingCheckout: true };
    },
    CHECKOUT_FEE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isloadingCheckout: false,
        dataAfterCheckout: action.payload,
      };
    },
    CHECKOUT_FEE_FAILED: state => {
      return { ...state, isloadingCheckout: false };
    },
    EDIT_FEE_REQUESTED: state => {
      return state;
    },
    EDIT_FEE_SUCCEEDED: (state, action) => {
      return { ...state };
    },
    EDIT_FEE_FAILED: error => {
      return error;
    },
    ADD_DOCTOR_REQUESTED: state => {
      return state;
    },
    ADD_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddDoctor: action.payload,
      };
    },
    ADD_DOCTOR_FAILED: error => {
      return error;
    },
    EDIT_DOCTOR_REQUESTED: state => {
      return state;
    },
    EDIT_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataDoctor: action.payload,
      };
    },
    EDIT_DOCTOR_FAILED: error => {
      return error;
    },
    HANDLE_UPLOAD_AVATAR_REQUESTED: state => {
      return state;
    },
    HANDLE_UPLOAD_AVATAR_SUCCEEDED: (state, action) => {
      return { ...state, dataUploadAvatar: action.payload };
    },
    HANDLE_UPLOAD_AVATAR_FAILED: error => {
      return error;
    },
    HANDLE_UPLOAD_COVER_REQUESTED: state => {
      return state;
    },
    HANDLE_UPLOAD_COVER_SUCCEEDED: (state, action) => {
      return { ...state, dataUploadCover: action.payload };
    },
    HANDLE_UPLOAD_COVER_FAILED: error => {
      return error;
    },
    HANDLE_UPDATE_AVATAR_REQUESTED: state => {
      return state;
    },
    HANDLE_UPDATE_AVATAR_SUCCEEDED: (state, action) => {
      return { ...state, dataUpdateAvatar: action.payload };
    },
    HANDLE_UPDATE_AVATAR_FAILED: error => {
      return error;
    },
    HANDLE_UPDATE_COVER_REQUESTED: state => {
      return state;
    },
    HANDLE_UPDATE_COVER_SUCCEEDED: (state, action) => {
      return { ...state, dataUpdateCover: action.payload };
    },
    HANDLE_UPDATE_COVER_FAILED: error => {
      return error;
    },
    GET_AVATAR_REQUESTED: state => {
      return state;
    },
    GET_AVATAR_SUCCEEDED: (state, action) => {
      return { ...state, avatar: action.payload };
    },
    GET_AVATAR_FAILED: error => {
      return error;
    },
    GET_COVER_REQUESTED: state => {
      return state;
    },
    GET_COVER_SUCCEEDED: (state, action) => {
      return { ...state, cover: action.payload };
    },
    GET_COVER_FAILED: error => {
      return error;
    },
    HANDLE_DELETE_DOCTOR_REQUESTED: state => {
      return { ...state, isLoadingDelete: true };
    },
    HANDLE_DELETE_DOCTOR_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
        dataDeleteDoctor: action.payload,
      };
    },
    HANDLE_DELETE_DOCTOR_FAILED: state => {
      return { ...state, isLoadingDelete: false };
    },
  },
  initialState,
  { prefix: 'doctor' },
);

export default doctorReducer;
