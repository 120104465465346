/* eslint-disable import/no-cycle */
import { COOKIES_NAME } from 'configs';
import Request from './request';

const authorizedRequest = new Request();
const cookies = localStorage.getItem(COOKIES_NAME);
if (cookies) {
  authorizedRequest.setCookies(cookies);
}

export default authorizedRequest;
