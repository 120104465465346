import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCreateMarketingNotiRequested } from 'store/marketingNoti/marketingNoti.action';
import './MarketingNoti.scss';

const tailLayout = {
  wrapperCol: { offset: 8, span: 12 },
};
const formItemLayout = {
  labelCol: {
    md: { span: 10, offset: 2 },
    lg: { span: 10, offset: 2 },
    xl: { span: 6, offset: 2 },
  },
  wrapperCol: {
    md: { span: 10 },
    lg: { span: 10 },
    xl: { span: 10 },
  },
};
const { TextArea } = Input;

const MarketingNoti: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state: any) => state.marketingNoti);

  const onFinish = (values: any) => {
    dispatch(handleCreateMarketingNotiRequested(values));
    if (data) {
      form.resetFields();
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  return (
    <div className="version-management">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Marketing Promotion Notification</h1>
          </Col>
        </Row>

        <div style={{ marginTop: 50 }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout={'horizontal'}
            // labelCol={{ span: 6, offset: 4 }}
            // wrapperCol={{ span: 8 }}
            {...formItemLayout}
            form={form}
            requiredMark={false}
            labelAlign={'left'}
          >
            <Form.Item
              label="Nội dung Promotion Notification"
              name="content"
              rules={[
                {
                  required: true,
                  message:
                    'Nội dung Promotion Notification không được để trống',
                },
                {
                  whitespace: true,
                  message:
                    'Nội dung Promotion Notification không được để trống',
                },
              ]}
              style={{ placeItems: 'center' }}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item
              label="Link bài viết"
              name="link"
              rules={[
                {
                  required: true,
                  message: 'Link bài viết không được để trống',
                },
                {
                  whitespace: true,
                  message: 'Link bài viết không được để trống',
                },
              ]}
              style={{ placeItems: 'center' }}
            >
              <Input />
            </Form.Item>

            <Form.Item {...tailLayout} style={{ marginLeft: 70 }}>
              <Space>
                <Button htmlType="button" onClick={onReset}>
                  Hủy
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Gửi
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default MarketingNoti;
