import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreatePromotionSuccessMessage,
  fireUpdatePromotionSuccessMessage,
  fireLockPromotionSuccessMessage,
  fireUnLockPromotionSuccessMessage,
  getMessageFromError,
  cleanKeyNull,
} from 'helpers/utils';
import moment from 'moment';

import * as promotionAction from './promotion.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

let paramsPromotion;

function* getPromotionRequested(action) {
  try {
    cleanKeyNull(action.payload);
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    if (params?.startDate) {
      params = {
        ...params,
        startDate: moment(params?.startDate).format('YYYY-MM-DD'),
      };
    }

    if (params?.endDate) {
      params = {
        ...params,
        endDate: moment(params?.endDate).format('YYYY-MM-DD'),
      };
    }

    if (`${params?.promotionName}`.trim().length === 0) {
      delete params.promotionName;
    }
    paramsPromotion = params;

    const res = yield call(() =>
      authorizedRequest.get('/v1/promotionCampaign?v=full', { params }),
    );
    yield put(promotionAction.getPromotionSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.getPromotionFailed(error));
  }
}

function* getPromotionCodeRequested(action) {
  try {
    let params = { ...action.payload };
    if (params.startIndex) {
      if (params.startIndex) {
        params = {
          ...params,
          startIndex: (params.startIndex - 1) * params.limit,
        };
      }
    }

    const res = yield call(() =>
      authorizedRequest.get(`/v1/promotionCode?v=full`, { params }),
    );
    yield put(promotionAction.getPromotionCodeSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.getPromotionCodeFailed(error));
  }
}

function* getPromotionHistoryRequested(action) {
  try {
    let params = { ...action.payload };
    if (params.startIndex) {
      if (params.startIndex) {
        params = {
          ...params,
          startIndex: (params.startIndex - 1) * params.limit,
        };
      }
    }

    const res = yield call(() =>
      authorizedRequest.get(`/v1/promotionHistory?v=full`, { params }),
    );
    yield put(promotionAction.getPromotionHistorySucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.getPromotionHistoryFailed(error));
  }
}

function* handleCreatePromotionRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post('/v1/promotionCampaign', action.payload),
    );
    fireCreatePromotionSuccessMessage();
    const params = {
      ...paramsPromotion,
      startIndex: paramsPromotion?.startIndex
        ? paramsPromotion?.startIndex / 10 + 1
        : 0,
    };
    yield put(promotionAction.handleCreatePromotionSucceeded(res));
    yield put(promotionAction.getPromotionRequested(params));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.handleCreatePromotionFailed(error));
  }
}

function* handleUpdatePromotionRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/promotionCampaign/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    fireUpdatePromotionSuccessMessage();
    const params = {
      ...paramsPromotion,
      startIndex: paramsPromotion?.startIndex
        ? paramsPromotion?.startIndex / 10 + 1
        : 0,
    };
    yield put(promotionAction.handleUpdatePromotionSucceeded(res));
    yield put(promotionAction.getPromotionRequested(params));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.handleUpdatePromotionFailed(error));
  }
}

function* handleLockedPromotionRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/promotionCampaign/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(promotionAction.handleLockedPromotionSucceeded(res));
    const params = {
      ...paramsPromotion,
      startIndex: paramsPromotion?.startIndex
        ? paramsPromotion?.startIndex / 10 + 1
        : 0,
    };

    yield put(promotionAction.getPromotionRequested(params));
    if (action.payload.params && action.payload.params.status === 'LOCKED') {
      fireLockPromotionSuccessMessage();
    } else {
      fireUnLockPromotionSuccessMessage();
    }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(promotionAction.handleLockedPromotionFailed(error));
  }
}

export default function* promotionSaga() {
  yield all([
    takeLatest(promotionAction.getPromotionRequested, getPromotionRequested),
    takeLatest(
      promotionAction.getPromotionCodeRequested,
      getPromotionCodeRequested,
    ),
    takeLatest(
      promotionAction.getPromotionHistoryRequested,
      getPromotionHistoryRequested,
    ),
    takeLatest(
      promotionAction.handleCreatePromotionRequested,
      handleCreatePromotionRequested,
    ),
    takeLatest(
      promotionAction.handleUpdatePromotionRequested,
      handleUpdatePromotionRequested,
    ),
    takeLatest(
      promotionAction.handleLockedPromotionRequested,
      handleLockedPromotionRequested,
    ),
  ]);
}
