import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { IServicePackage } from 'models/store/servicePackage.model';

export const {
  getServicePackageRequested,
  getServicePackageSucceeded,
  getServicePackageFailed,
  handleDeleteServicePackageRequested,
  handleDeleteServicePackageSucceeded,
  handleDeleteServicePackageFailed,
  getDetailServicePackageSucceeded,
  getDetailServicePackageRequested,
  getDetailServicePackageFailed,
  addServicePackageRequested,
  addServicePackageSucceeded,
  addServicePackageFailed,
  editServicePackageRequested,
  editServicePackageSucceeded,
  editServicePackageFailed,
  handleUploadAvatarServicePackageRequested,
  handleUploadAvatarServicePackageSucceeded,
  handleUploadAvatarServicePackageFailed,
  handleUploadCoverServicePackageRequested,
  handleUploadCoverServicePackageSucceeded,
  handleUploadCoverServicePackageFailed,
  handleLockServicePackageRequested,
  handleLockServicePackageSucceeded,
  handleLockServicePackageFailed,
  getAvatarRequested,
  getAvatarSucceeded,
  getAvatarFailed,
  getCoverRequested,
  getCoverSucceeded,
  getCoverFailed,
} = createActions(
  {
    GET_SERVICE_PACKAGE_REQUESTED: (payload: QueryString) => payload,
    GET_SERVICE_PACKAGE_SUCCEEDED: (payload: IServicePackage[]) => payload,
    GET_SERVICE_PACKAGE_FAILED: (error: Error) => error,
    HANDLE_DELETE_SERVICE_PACKAGE_REQUESTED: (payload: any) => payload,
    HANDLE_DELETE_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    HANDLE_DELETE_SERVICE_PACKAGE_FAILED: (error: any) => error,
    GET_DETAIL_SERVICE_PACKAGE_REQUESTED: (id: string, params: any) => ({
      id,
      params,
    }),
    GET_DETAIL_SERVICE_PACKAGE_SUCCEEDED: (payload: IServicePackage) => payload,
    GET_DETAIL_SERVICE_PACKAGE_FAILED: (error: Error) => error,
    ADD_SERVICE_PACKAGE_REQUESTED: (payload: any) => payload,
    ADD_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    ADD_SERVICE_PACKAGE_FAILED: (error: string) => error,
    EDIT_SERVICE_PACKAGE_REQUESTED: (id: string, payload: any) => ({
      id,
      payload,
    }),
    EDIT_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    EDIT_SERVICE_PACKAGE_FAILED: (error: string) => error,
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_REQUESTED: (payload: any) => payload,
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_FAILED: (error: any) => error,
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_REQUESTED: (payload: any) => payload,
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_FAILED: (error: any) => error,
    HANDLE_LOCK_SERVICE_PACKAGE_REQUESTED: (payload: any) => payload,
    HANDLE_LOCK_SERVICE_PACKAGE_SUCCEEDED: (payload: any) => payload,
    HANDLE_LOCK_SERVICE_PACKAGE_FAILED: (error: any) => error,
    GET_AVATAR_REQUESTED: (payload: any) => payload,
    GET_AVATAR_SUCCEEDED: (payload: any) => payload,
    GET_AVATAR_FAILED: (error: any) => error,
    GET_COVER_REQUESTED: (payload: any) => payload,
    GET_COVER_SUCCEEDED: (payload: any) => payload,
    GET_COVER_FAILED: (error: any) => error,
  },
  {
    prefix: 'servicePackage',
  },
);
