import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Radio, Button, Select, DatePicker } from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { TransactionModel } from 'models/store/customer.model';
import moment from 'moment';
import {
  regexCheckPhone,
  regexGetPhoneNumber,
  regexGetEmailLatest,
  regextTrimspace,
} from '../../containers/Doctor/doctor.const';
import {
  getListDistrictsRequested,
  getListInsuranceRequested,
  getListJobRequested,
  getListProvinceRequested,
  postEditInformationCustomerRequested,
} from '../../store/customer/customer.action';
import './ModalAddInformation.scss';

const { Option } = Select;
interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  itemInformation: TransactionModel;
}

interface initForm {
  status?: string;
  givenName?: string;
  phoneNumber?: number | string;
  email?: string;
  oganization?: string;
  gender: string | null | undefined;
  birthdate?: any;
  stateProvince?: string;
  cityVillage?: string;
  address1?: string;
  job?: string | null;
  infoFamily?: any;
  insurance?: string | null;
  codeContract?: string;
}
const ModalEditInformation: FC<CProps> = ({
  show,
  title,
  onHide,
  itemInformation,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const patientAttributesObj = {
    job: '',
    infoFamily: {
      name: '',
      telephone: '',
      relationship: '',
    },
    insurance: '',
    codeContract: '',
  };
  if (itemInformation.patientAttributes) {
    for (let i = 0; i < itemInformation.patientAttributes.length; i++) {
      if (
        itemInformation.patientAttributes[i].display === 'Family Contact Name'
      ) {
        patientAttributesObj.infoFamily.name =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (
        itemInformation.patientAttributes[i].display ===
        'Family Contact Telephone Number'
      ) {
        patientAttributesObj.infoFamily.telephone =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (
        itemInformation.patientAttributes[i].display ===
        'Family Contact Relationship'
      ) {
        patientAttributesObj.infoFamily.relationship =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (itemInformation.patientAttributes[i].display === 'Career') {
        patientAttributesObj.job =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (
        itemInformation.patientAttributes[i].display === 'Insurance company'
      ) {
        patientAttributesObj.insurance =
          itemInformation.patientAttributes[i]?.valueReference?.display;
      }

      if (itemInformation.patientAttributes[i].display == 'Patient Insurance') {
        patientAttributesObj.codeContract =
          itemInformation.patientAttributes[i].valueReference;
      }
    }
  }

  const initForm: initForm = {
    status: itemInformation?.user?.status,
    givenName: itemInformation?.person?.preferredName?.display,
    phoneNumber: itemInformation?.user?.display,
    email: itemInformation?.user?.email,
    oganization: '',
    gender: itemInformation?.person?.gender,
    birthdate: itemInformation?.person?.birthdate
      ? moment(itemInformation?.person?.birthdate)
      : null,
    cityVillage: itemInformation?.person?.preferredAddress?.cityVillage,
    stateProvince: itemInformation?.person?.preferredAddress?.stateProvince,
    address1:
      itemInformation?.person?.preferredAddress?.address1 == null
        ? ''
        : itemInformation?.person?.preferredAddress?.address1,
    job: patientAttributesObj.job === '' ? null : patientAttributesObj.job,
    infoFamily: [
      {
        name: patientAttributesObj.infoFamily.name,
        telephone: patientAttributesObj.infoFamily.telephone,
        relationship: patientAttributesObj.infoFamily.relationship,
      },
    ],

    insurance:
      patientAttributesObj.insurance === ''
        ? null
        : patientAttributesObj.insurance,
    codeContract: patientAttributesObj.codeContract,
  };
  const [selectedProvince, setSelectedProvince] = useState<any>('');
  const [selectedInsurance, setSelectedInsurance] = useState<any>('');
  const listProvince = useSelector(
    (state: any) => state.customerReducer.listProvince,
  );
  const listDistrict = useSelector(
    (state: any) => state.customerReducer.listDistrict,
  );
  const listJob = useSelector((state: any) => state.customerReducer.listJob);
  const listInsurance = useSelector(
    (state: any) => state.customerReducer.listInsurance,
  );

  const _filterProvince = (formValue: any) => {
    if (listProvince) {
      const province = listProvince.find(function(post, index) {
        if (post.name === formValue) {
          return post;
        }
      });
      setSelectedProvince(province?.provinceId);
      return province;
    }
  };

  useEffect(() => {
    dispatch(getListProvinceRequested());
    dispatch(getListJobRequested());
    dispatch(getListInsuranceRequested());
    if (itemInformation.patientAttributes) {
      for (let i = 0; i < itemInformation.patientAttributes.length; i++) {
        if (
          itemInformation.patientAttributes[i].display === 'Insurance company'
        ) {
          setSelectedInsurance(
            itemInformation.patientAttributes[i]?.valueReference?.uuid,
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    (function() {
      _filterProvince(itemInformation?.person?.preferredAddress?.cityVillage);
    })();
  }, [listProvince]);
  useEffect(() => {
    if (selectedProvince) {
      dispatch(
        getListDistrictsRequested({
          idProvince: selectedProvince,
        }),
      );
    }
  }, [selectedProvince]);
  const onFinish = values => {
    const {
      phoneNumber,
      status,
      email,
      givenName,
      address1,
      birthdate,
      cityVillage,
      gender,
      infoFamily,
      job,
      codeContract,
      stateProvince,
    } = values;
    const phoneLatest = phoneNumber.replace(regexGetPhoneNumber, '');
    const emailLatest = email ? email.replace(regexGetEmailLatest, '') : '';
    const phoneFamily = infoFamily[0].telephone.replace(
      regexGetPhoneNumber,
      '',
    );
    const body1 = {
      uuid: get(itemInformation, 'uuid', ''),
    };
    const data = {
      user: {
        username: phoneLatest,
        status,
        email: emailLatest,
        person: {
          uuid: get(itemInformation, 'person.uuid', ''),
          names: [
            {
              uuid: get(itemInformation, 'person.preferredName.uuid', null),
              givenName,
            },
          ],
          gender,
          addresses: [
            {
              uuid: get(itemInformation, 'person.preferredAddress.uuid', null),
              address1,
              cityVillage,
              stateProvince,
            },
          ],
          birthdate,
        },
      },
    };

    const source: any = {
      patientAttributes: [],
    };
    // if (
    //   get(itemInformation, 'patientAttributes[0].uuid', null) !== null &&
    //   get(itemInformation, 'patientAttributes[1].uuid', null) !== null
    // ) {
    if (get(itemInformation, 'patientAttributes[0].uuid', null) !== null) {
      if (infoFamily[0].name !== '') {
        source.patientAttributes.push({
          uuid: get(itemInformation, 'patientAttributes[0].uuid', null),
          attributeType: '1296b0dc-440a-11e6-a65c-00e04c220201',
          valueReference: infoFamily[0].name,
        });
      } else {
        source.patientAttributes.push({
          uuid: get(itemInformation, 'patientAttributes[0].uuid', null),
          attributeType: '1296b0dc-440a-11e6-a65c-00e04c220201',
          valueReference: null,
        });
      }
    }
    if (get(itemInformation, 'patientAttributes[1].uuid', null) !== null) {
      if (infoFamily[0].telephone !== '') {
        source.patientAttributes.push({
          uuid: get(itemInformation, 'patientAttributes[1].uuid', null),
          attributeType: '1296b0dc-440a-11e6-a65c-00e04c220202',
          valueReference: phoneFamily,
        });
      } else {
        source.patientAttributes.push({
          uuid: get(itemInformation, 'patientAttributes[1].uuid', null),
          attributeType: '1296b0dc-440a-11e6-a65c-00e04c220202',
          valueReference: null,
        });
      }
    }

    if (infoFamily[0].relationship !== '') {
      source.patientAttributes.push({
        uuid: get(itemInformation, 'patientAttributes[2].uuid', null),
        attributeType: '1296b0dc-440a-11e6-a65c-00e04c220203',
        valueReference: infoFamily[0].relationship,
      });
    }
    if (job !== null) {
      source.patientAttributes.push({
        uuid: get(itemInformation, 'patientAttributes[3].uuid', null),
        attributeType: '1296b0dc-440a-11e6-a65c-00e04c220204',
        valueReference: job,
      });
    }
    if (selectedInsurance !== '') {
      source.patientAttributes.push({
        uuid: get(itemInformation, 'patientAttributes[4].uuid', null),
        attributeType: '1296b0dc-440a-11e6-a65c-00e04c220205',
        valueReference: selectedInsurance,
      });
    }
    if (codeContract !== '') {
      source.patientAttributes.push({
        uuid: get(itemInformation, 'patientAttributes[5].uuid', null),
        attributeType: '1296b0dc-440a-11e6-a65c-00e04c220206',
        valueReference: codeContract,
      });
    }

    if (source.patientAttributes != null) {
      const mergeObjectData = Object.assign(data, source);
      const mergeObjectBody = Object.assign(body1, { data: mergeObjectData });
      dispatch(postEditInformationCustomerRequested(mergeObjectBody));
    } else {
      const mergeObjectBody = Object.assign(body1, { data });
      dispatch(postEditInformationCustomerRequested(mergeObjectBody));
    }

    onHide();
  };

  const handleChangeProvince = value => {
    _filterProvince(value);
    form.setFieldsValue({
      stateProvince: '',
    });
  };
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title={title}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={850}
      footer={null}
    >
      <Form
        form={form}
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initForm}
        requiredMark={false}
      >
        <Form.Item label="Trạng thái" name="status">
          <Radio.Group>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Họ và tên"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
            { max: 50, message: 'Họ tên không vượt quá 50 ký tự!' },
          ]}
          name="givenName"
        >
          <Input placeholder={'Nhập họ và tên'} />
        </Form.Item>

        <Form.Item
          label="Ngày sinh"
          name="birthdate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            placeholder={'Chọn ngày tháng'}
            format={'DD-MM-YYYY'}
            disabledDate={current => {
              const customDate = moment().format('YYYY-MM-DD');
              return current && current > moment(customDate, 'YYYY-MM-DD');
            }}
          />
        </Form.Item>

        <Form.Item label="Giới tính" name="gender">
          <Select placeholder={'Chọn giới tính'}>
            <Option value={'M'}>Nam</Option>
            <Option value={'F'}>Nữ</Option>
            <Option value={'NON'}>Khác</Option>
          </Select>
        </Form.Item>

        {/* ^([\s]*([\d\w]*[\s]*))* */}
        <Form.Item label="Email" name="email">
          <Input type={'email'} placeholder={'Nhập email'} />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc.' },
            {
              pattern: regexCheckPhone,
              message: 'Số điện thoại ít nhất 10 số và bắt đầu 03|05|07|08|09',
            },
          ]}
          name="phoneNumber"
        >
          <InputMask
            className="ant-input"
            mask="9999 999 999"
            maskChar=""
            placeholder={'Nhập số điện thoại'}
          />
        </Form.Item>

        <Form.Item label="Tỉnh/ Thành phố" name="cityVillage">
          <Select
            onChange={handleChangeProvince}
            placeholder={'Chọn tỉnh/ thành phố'}
          >
            {listProvince &&
              listProvince.map((key, value) => (
                <Option value={key.name} key={key.provinceId}>
                  {key.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Quận/ Huyện" name="stateProvince">
          <Select placeholder={'Chọn quận/ huyện'}>
            {listDistrict &&
              listDistrict.map((key, value) => (
                <Option value={key} key={key}>
                  {key}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Địa chỉ cụ thể" name="address1">
          <Input placeholder={'Nhập địa chỉ cụ thể'} />
        </Form.Item>

        <Form.Item label="Nghề nghiệp" name="job">
          <Select placeholder={'Chọn nghề  nghiệp'}>
            {listJob &&
              listJob
                .sort((a, b) => a.display.localeCompare(b.display))
                .map((key, value) => (
                  <Option value={key.display} key={key.uuid}>
                    {key.display}
                  </Option>
                ))}
          </Select>
        </Form.Item>

        <Form.Item label="Thông tin người thân">
          <Form.List name="infoFamily">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key} style={{ display: 'inline-flex' }}>
                      <Form.Item
                        name={[index, 'name']}
                        style={{ width: '30%' }}
                      >
                        <Input placeholder={'Nhập tên người thân'} />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'telephone']}
                        style={{ width: '30%', marginLeft: '3%' }}
                        rules={[
                          {
                            pattern: regexCheckPhone,
                            message:
                              'Số điện thoại ít nhất 10 số và bắt đầu 03|05|07|08|09',
                          },
                        ]}
                      >
                        <InputMask
                          placeholder={'Nhập số điện thoại người thân'}
                          className="ant-input"
                          mask="9999 999 999"
                          maskChar=""
                        />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'relationship']}
                        style={{ width: '30%', marginLeft: '3%' }}
                      >
                        <Input placeholder={'Nhập mối quan hệ'} />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form.Item>

        <Form.Item label="Công ty bảo hiểm" name="insurance">
          <Select
            placeholder={'Chọn công ty bảo hiểm'}
            onChange={value => {
              setSelectedInsurance(value);
            }}
          >
            {listInsurance &&
              listInsurance.map((key, value) => (
                <Option value={key.uuid} key={key.uuid}>
                  {key.display}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item label="Mã số hợp đồng/ thẻ" name="codeContract">
          <Input placeholder={'Nhập mã hợp đồng/ thẻ'} />
        </Form.Item>

        <Form.Item label="Tổ chức" name="oganization">
          <Input placeholder={'Nhập tên tổ chức'} />
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditInformation;
