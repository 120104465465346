import { GENDER, OBS_PREFIX, AttributePrefix } from 'constants/Appointment';
import { replace, startsWith, sortBy, flatten } from 'lodash';
import { DetailAppointmentResponse } from '../../models/api/Appointment.model';

export function mapAppointmentDetail(data: DetailAppointmentResponse) {
  const { person } = data.patient;
  if (data.visit && data.visit.encounters) {
    const medicalExamination = data.visit.encounters[0];
    const pulse = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.PULSE),
    );
    const temperature = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.TEMPERATURE),
    );
    const weight = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.WEIGHT),
    );
    const height = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.HEIGHT),
    );
    const diastolicBloodPressure = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE),
    );
    // console.log('diastolicBloodPressure', medicalExamination?.obs);
    const systolicBloodPressure = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE),
    );

    const respiratoryRate = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, OBS_PREFIX.RESPIRATORY_RATE),
    );

    const symptom = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.SYMPTOM))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.SYMPTOM, ''),
      }));
    const alcohol = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.ALCOHOL))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.ALCOHOL, ''),
      }));
    const cigarette = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.CIGARETTE))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.CIGARETTE, ''),
      }));
    const usedDrug = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.DRUGS_ARE_IN_USE))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.DRUGS_ARE_IN_USE, ''),
      }));
    const familyHistory = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.HISTORY_OF_FAMILY))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.HISTORY_OF_FAMILY, ''),
      }));
    const drugOrder = medicalExamination?.orders
      .filter((i: any) => i.type === 'drugorder')
      .map((item: any) => ({
        id: item.uuid,
        description: item.display,
      }));
    const images = medicalExamination?.obs
      .filter(i => startsWith(i.display, OBS_PREFIX.IMAGE))
      .map(item => ({
        id: item.uuid,
        name: replace(item.display, OBS_PREFIX.IMAGE, ''),
        orderId: item.order?.uuid || '',
      }));
    const oldExaminationImages = medicalExamination?.obs
      .filter(i => startsWith(i.display, OBS_PREFIX.OLD_EXAMINATION_IMAGE))
      .map(item => ({
        id: item.uuid,
        name: replace(item.display, OBS_PREFIX.OLD_EXAMINATION_IMAGE, ''),
        orderId: item.order?.uuid || '',
      }));

    const followUps = data.appointmentFollows.map(item => ({
      id: item.uuid,
      reason: item.followDescription,
      result: item.followResult,
      followDate: item.followDate,
      follower: item.follower.uuid,
      // reasonDetail: item.reasonDetail,
      // cancelReason: item.cancelReason,
      // startDate: item.timeSlot.startDate,
      // endDate: item.timeSlot.endDate,
    }));
    const clinicalExamination = medicalExamination?.obs
      .filter((i: any) =>
        startsWith(i.display, OBS_PREFIX.PHYSICAL_EXAMINATION),
      )
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.PHYSICAL_EXAMINATION, ''),
      }));
    const instructions = medicalExamination?.obs
      .filter((i: any) => startsWith(i.display, OBS_PREFIX.INSTRUCTION))
      .map((item: any) => ({
        id: item.uuid,
        description: replace(item.display, OBS_PREFIX.INSTRUCTION, ''),
      }));

    const requests = medicalExamination?.orders
      .filter((i: any) => i.type === 'testorder')
      .map((item: any) => ({
        id: item.uuid,
        description: item.display,
        date: item.dateActivated,
        instructions: item.instructions,
      }));
    const obs = data.visit?.encounters[0]?.obs || [];
    const questions = flatten(
      obs
        .filter(i => i.display.includes(OBS_PREFIX.QUESTION))
        .map(i => i.groupMembers),
    )
      .filter(i => i?.display.includes('Question: '))
      .map(i => ({ id: String(i?.uuid), question: String(i?.value) }));
    let diagnostic: Array<any> = [];

    data.visit.encounters.forEach((encounter: any) => {
      encounter.diagnoses.forEach((item: any) => {
        diagnostic.push({
          id: item.uuid,
          description: item.display,
          title:
            item.certainty === 'CONFIRMED'
              ? 'Chẩn đoán xác định'
              : 'Chẩn đoán sơ bộ',
          status: item.certainty,
          diagnosis: item.diagnosis,
        });
      });
    });
    diagnostic = sortBy(diagnostic, ['title']);
    const avatar = data.patient.user.attributes
      .reverse()
      .find(i => startsWith(i.display, AttributePrefix.AVATAR));

    return {
      patient: {
        avatar,
        name: person.display,
        age: person.age,
        gender:
          // eslint-disable-next-line no-nested-ternary
          person.gender === 'NON'
            ? GENDER.Other
            : person.gender === 'F'
            ? GENDER.F
            : GENDER.M,
        uuid: person.uuid,
        city: person.addresses[0] && person.addresses[0].cityVillage,
      },
      detail: {
        askDisease: {
          symptom,
          history: '',
          chronicDiseases: '',
          alcohol,
          cigarette,
          usedDrug,
          familyHistory,
          allergy: '',
        },
        medicalExamination: {
          pulse: {
            value: pulse
              ? Number(replace(pulse.display, OBS_PREFIX.PULSE, ''))
              : '',
            uuid: pulse ? pulse.uuid : '',
          },

          temperature: {
            value: temperature
              ? Number(replace(temperature.display, OBS_PREFIX.TEMPERATURE, ''))
              : '',
            uuid: temperature ? temperature.uuid : '',
          },

          bloodPressure: {
            uuid: systolicBloodPressure?.uuid,
            value:
              systolicBloodPressure && diastolicBloodPressure
                ? `${Number(
                    replace(
                      systolicBloodPressure.display,
                      OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE,
                      '',
                    ),
                  )}/${Number(
                    replace(
                      diastolicBloodPressure.display,
                      OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE,
                      '',
                    ),
                  )}`
                : '',
          },
          breathing: {
            value: respiratoryRate
              ? Number(
                  replace(
                    respiratoryRate.display,
                    OBS_PREFIX.RESPIRATORY_RATE,
                    '',
                  ),
                )
              : '',
            uuid: respiratoryRate ? respiratoryRate.uuid : '',
          },
          weight: {
            value: weight
              ? Number(replace(weight.display, OBS_PREFIX.WEIGHT, ''))
              : '',
            uuid: weight ? weight.uuid : '',
          },
          height: {
            value: height
              ? Number(replace(height.display, OBS_PREFIX.HEIGHT, ''))
              : '',
            uuid: height ? height.uuid : '',
          },
          diastolicBloodPressure: {
            value: diastolicBloodPressure
              ? Number(
                  replace(
                    diastolicBloodPressure.display,
                    OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE,
                    '',
                  ),
                )
              : null,
            uuid: diastolicBloodPressure ? diastolicBloodPressure.uuid : '',
          },
          systolicBloodPressure: {
            value: systolicBloodPressure
              ? Number(
                  replace(
                    systolicBloodPressure.display,
                    OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE,
                    '',
                  ),
                )
              : null,
            uuid: systolicBloodPressure ? systolicBloodPressure.uuid : '',
          },
          clinicalExamination: clinicalExamination || [],
          instructions: instructions || [],
        },
        diagnostic: diagnostic || [],
        drugOrder: drugOrder || [],
        questions,
        uuid: data.uuid,
        reason: data.reason,
        appointmentCode: data.appointmentCode,
        reasonDetail: data.reasonDetail,
        cancelReason: data.cancelReason || '',
        status: data.status.code,
        startDate: data.timeSlot.startDate,
        requests,
        images,
        oldExaminationImages,
        followUps,
      },
      encounters: data.visit.encounters,
    };
  }
  return {
    patient: {
      avatar: person.avatar,
      name: person.display,
      age: person.age,
      gender:
        // eslint-disable-next-line no-nested-ternary
        person.gender === 'NON' ? 'Khác' : person.gender === 'F' ? 'Nữ' : 'Nam',
      city: person.addresses[0] && person.addresses[0].cityVillage,
    },
    detail: {
      questions: [],
      askDisease: {
        symptom: [],
        history: [],
        familyHistory: [],
        chronicDiseases: [],
        alcohol: [],
        cigarette: [],
        allergy: [],
        usedDrug: [],
      },
      medicalExamination: {
        pulse: {
          value: '',
        },
        temperature: {
          value: '',
        },
        bloodPressure: {
          value: '',
        },
        breathing: {
          value: '',
        },
        weight: {
          value: '',
        },
        height: {
          value: '',
        },
        diastolicBloodPressure: {
          value: '',
        },
        systolicBloodPressure: {
          value: '',
        },
      },
      diagnostic: [],
      drugOrder: [],
      appointmentCode: data.appointmentCode,
      uuid: data.uuid,
      reason: data.reason,
      reasonDetail: data.reasonDetail,
      status: data.status.code,
      startDate: data.timeSlot.startDate,
      images: [],
      oldExaminationImages: [],
      followUps: [],
    },
  };
}
