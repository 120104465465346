import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import moment from 'moment';
import 'moment/locale/vi';
import {
  Form,
  Input,
  Card,
  Button,
  Row,
  Col,
  Modal,
  Spin,
  List,
  Tooltip,
} from 'antd';

import {
  MinusCircleOutlined,
  PlusCircleTwoTone,
  ExclamationCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import { initFormProps } from 'types/props/FormProps';
import { actions as appointmentActions } from 'store/appointment';
import StoreState from 'models/store';
import { StartCall } from 'types/action/appointment/StartCall';
import { AppointmentsStoreState } from 'models/store/appointmnet.model';
import { pathRouteWithRole } from 'helpers/utils';
import { CONST_ID } from 'containers/Appointment/appointment.const';

import {
  filterYear,
  getDayA,
  removeHyphen,
  removeYearHyphen,
} from '../Table/table.const';
import { getDateIos } from '../../../containers/Doctor/doctor.const';

import './index.scss';

// const { Option } = Select;
const { TextArea } = Input;
const { confirm } = Modal;

type FormProps = {
  handleSubmit?: (...args: any[]) => any;
  formId: string;
  initValue?: any;
  isEdit?: boolean;
  isEditable?: boolean;
  showSubmitButton?: boolean;
  className?: string;
};

type ParamList = {
  Detail: {
    detail: any;
    appointmentId: any;
    patientId: any;
    appointmentCode: string | number;
  };
};

const AppointmentForm: FC<FormProps> = (props: FormProps) => {
  const history = useHistory();

  const {
    formId,
    isEditable,
    initValue,
    // isEdit,
    showSubmitButton = true,
    className = null,
  } = {
    ...initFormProps,
    ...props,
  };

  const idUrl: any = useParams();

  const formItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
  };

  const appointment: any = useSelector((store: StoreState) => ({
    relationship: store.appointment.relationship,
    allergy: store.appointment.allergy,
    conditionHistory: store.appointment.conditionHistory,
    examinationHistory: store.appointment.examinationHistory,
    detail: initValue,
  }));

  useEffect(() => {
    form.setFieldsValue({
      allergy: appointment?.allergy?.allergy?.map(item => item?.name),
      chronic: appointment?.conditionHistory?.chronicDiseases?.map(
        item => item?.description,
      ),
      examinationHistory: appointment.examinationHistory?.map(item => item),
    });
  }, [
    appointment?.allergy?.allergy?.length,
    appointment?.conditionHistory?.chronicDiseases?.length,
    appointment.examinationHistory?.length,
  ]);

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [initLoading, setInitLoading] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [data, setData] = useState<any>();

  const appointmentId = initValue.detail.uuid;
  const patientId = initValue.patient.uuid;

  const onGetData = useCallback(() => {
    dispatch(
      appointmentActions.fetchExaminationHistory({
        patientId,
        appointmentId,
      }),
    );
    dispatch(appointmentActions.fetchRelationship(patientId));
    dispatch(appointmentActions.fetchAllergy(patientId));
    dispatch(
      appointmentActions.fetchConditionHistory({
        patientId,
      }),
    );
  }, [appointmentId, patientId, dispatch]);

  useEffect(() => {
    onGetData();
  }, [onGetData]);

  function processStatus(resStatus: string) {
    let formattedStatus: string = '';
    switch (resStatus) {
      case 'SCHEDULED':
        formattedStatus = 'Sắp diễn ra';
        break;
      case 'INCONSULTATION':
        formattedStatus = 'Đang diễn ra';
        break;
      case 'WAITING_EXAMINATION':
        formattedStatus = 'Chờ xét nghiệm';
        break;
      case 'COMPLETED':
        formattedStatus = 'Hoàn thành';
        break;
      case 'CANCELLED':
        formattedStatus = 'Đã hủy';
        break;
      case 'WAITING_PAYMENT':
        formattedStatus = 'Chờ thanh toán';
        break;
      case 'MISSED':
        formattedStatus = 'Trễ hẹn';
        break;
    }
    return formattedStatus;
  }

  const handleStartCall = (record: StartCall) => {
    confirm({
      title: 'Bạn có muốn thực hiện cuộc gọi này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      width: '500px',
      cancelText: 'Không',
      onOk() {
        dispatch(appointmentActions.startCall(record));
      },
    });
  };

  const diffDay = useMemo(() => {
    const date1 = +moment().toDate();

    const date2 = +moment(appointment.detail.detail.startDate).toDate();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }, [appointment.detail.detail.startDate]);

  const date = useMemo(() => {
    switch (diffDay) {
      case 0:
        return `Hôm nay - ${moment(appointment.detail.detail.startDate)
          .locale('vi')
          .format('dddd, DD/MM/YYYY')}`;
      case 1:
        return `Ngày mai - ${moment(appointment.detail.detail.startDate)
          .locale('vi')
          .format('dddd, DD/MM/YYYY')}`;
      default:
        return moment(appointment.detail.detail.startDate)
          .locale('vi')
          .format('dddd, DD/MM/YYYY');
    }
  }, [diffDay, appointment.detail.detail.startDate]);

  const time = useMemo(() => {
    return moment(appointment.detail.detail.startDate).format('HH:mm');
  }, [appointment.detail.detail.startDate]);

  const arrQuestionGroup = appointment?.detail?.encounters[0]?.obs?.filter(
    val => val?.display?.includes('Group A&Q: Number'),
  );

  const arrQuestionGroup1 =
    arrQuestionGroup !== undefined
      ? arrQuestionGroup?.map(item => item?.groupMembers)
      : [''];

  const res = arrQuestionGroup1?.map(item => {
    const res1 = item?.find(val => val?.display?.includes('Question'));
    return res1;
  });

  const finalQuestion = res?.map(value => value?.value);

  const arrPluse = appointment?.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('PULSE'),
  );
  const arrTemperature = appointment.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('TEMPERATURE'),
  );

  const arrHeight = appointment.detail?.encounters[0]?.obs?.find(item =>
    item?.display?.includes('HEIGHT'),
  );

  const arrWeight = appointment.detail?.encounters[0]?.obs?.filter(item =>
    item?.display?.includes('WEIGHT'),
  );
  const arrBloodPressureSystolic = appointment.detail?.encounters[0]?.obs?.filter(
    item => item?.display?.includes('SYSTOLIC BLOOD PRESSURE'),
  );

  const arrBloodPressureDiastolic = appointment.detail?.encounters[0]?.obs?.filter(
    item => item?.display?.includes('DIASTOLIC BLOOD PRESSURE'),
  );

  const arrDiagnosticConfirmed = appointment.detail.detail.diagnostic?.filter(
    item => item?.status?.includes('CONFIRMED'),
  );
  const arrDiagnosticProvisional = appointment.detail.detail.diagnostic?.filter(
    item => item?.status?.includes('PROVISIONAL'),
  );

  const arrInstructions = appointment.detail.encounters[0]?.obs?.filter(item =>
    item?.display?.includes('INSTRUCTION'),
  );

  const arrHistoryOfFamily = appointment.detail.encounters[0]?.obs?.filter(
    item => item?.display?.includes('HISTORY OF FAMILY'),
  );

  const arrCigarette = appointment.detail.encounters[0].obs?.filter(item =>
    item?.display?.includes('CIGARETTE'),
  );

  const arrAlcohol = appointment.detail.encounters[0].obs?.filter(item =>
    item?.display?.includes('ALCOHOL'),
  );
  const arrDrugsPresent = appointment.detail.encounters[0].obs?.filter(item =>
    item?.display?.includes('DRUGS ARE IN USE'),
  );

  const arrSystom = appointment.detail.encounters[0].obs?.filter(item =>
    item?.display?.includes('SYMPTOM'),
  );

  const arrRespiratoryRate = appointment.detail.encounters[0].obs?.filter(
    item => item?.display?.includes('Respiratory rate'),
  );

  const arrFollowUp = appointment.detail?.detail?.followups?.map(
    item => item?.reason,
  );

  const arrPhysicalExamination = appointment?.detail?.encounters[0]?.obs?.filter(
    item => item?.display?.includes('PHYSICAL EXAMINATION'),
  );

  const initAppointmentValue = useMemo(() => {
    return {
      ...initValue,
      physicalExamination:
        arrPhysicalExamination?.length > 0
          ? arrPhysicalExamination?.map(item => item?.value)
          : [''],
      followUps: arrFollowUp?.length > 0 ? arrFollowUp : [''],
      appointmentStatus: processStatus(appointment.detail.detail.status),
      paymentStatus: '------',
      appointmentCode: appointment.detail.detail.appointmentCode,
      reason: appointment.detail.detail.reason,
      reasonDetail: _.get(appointment, 'detail.detail.reasonDetail', ''),
      bloodPressureDiastolic:
        arrBloodPressureDiastolic?.length > 0
          ? arrBloodPressureDiastolic[0]?.value
          : '',
      symptom:
        arrSystom !== undefined ? arrSystom?.map(item => item?.value) : [''],
      anamnesis: _.has(appointment.conditionHistory, 'anamnesis')
        ? appointment.conditionHistory.anamnesis.map(item => item.description)
        : [],
      alcohol:
        arrAlcohol?.length > 0 ? arrAlcohol?.map(item => item?.value) : [''],
      cigarette:
        arrCigarette?.length > 0
          ? arrCigarette?.map(item => item?.value)
          : [''],
      familyHistory:
        arrHistoryOfFamily?.length > 0
          ? arrHistoryOfFamily?.map(item => item?.value)
          : [''],

      relationship: appointment.relationship.relationship,

      // eslint-disable-next-line no-nested-ternary
      diagnostic1:
        arrDiagnosticConfirmed?.length !== 0
          ? arrDiagnosticConfirmed?.map(item => item?.description)
          : [''],

      // eslint-disable-next-line no-nested-ternary
      diagnostic:
        arrDiagnosticProvisional?.length !== 0
          ? arrDiagnosticProvisional?.map(item => item?.description)
          : [''],

      // eslint-disable-next-line no-nested-ternary

      // eslint-disable-next-line no-nested-ternary
      requests:
        appointment?.detail?.detail?.requests?.length !== 0
          ? appointment.detail.detail.requests.map(item => item.description)
          : [''],
      drugsPresent:
        arrDrugsPresent?.length > 0
          ? arrDrugsPresent?.map(item => item?.value)
          : [''],
      // eslint-disable-next-line no-nested-ternary
      drugOrders: _.has(appointment.detail.detail, 'drugOrder')
        ? appointment.detail.detail.drugOrder?.length !== 0
          ? appointment.detail.detail.drugOrder.map(item => item.description)
          : ['']
        : [''],

      // eslint-disable-next-line no-nested-ternary
      instructions:
        arrInstructions !== undefined
          ? arrInstructions?.map(item => item.value)
          : [''],
      questionsByPatient: finalQuestion?.length > 0 ? finalQuestion : [''],
      temperature: arrTemperature?.value,
      breathing:
        arrRespiratoryRate !== undefined ? arrRespiratoryRate[0]?.value : '',
      bloodPressure:
        arrBloodPressureSystolic?.length > 0
          ? arrBloodPressureSystolic[0]?.value
          : [''],
      pulse: arrPluse?.value,
      weight: arrWeight[0]?.value,
      height: arrHeight?.value,
    };
  }, [appointment, appointment?.allergy?.allergy]);

  const [count] = useState<any>();
  const [list, setList] = useState<any>();

  useEffect(() => {
    setList(appointment?.examinationHistory?.map(item => item));
  }, [appointment?.examinationHistory?.length]);

  useEffect(() => {
    setInitLoading(false);
    setData(list);
    setList(list);
  }, []);

  const onLoadMore = () => {
    setLoading(true);
    setList(data?.concat([...new Array(count)].map(() => ({ loading: true }))));

    const itemAdded = list?.slice(list?.length - 4, list?.length - 1);

    const dataAdded = data?.concat(itemAdded);
    setList(dataAdded);
    setLoading(false);
    window.dispatchEvent(new Event('resize'));
  };

  const loadMore =
    !initLoading && !loading && list?.length > 3 ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore}>Xem thêm</Button>
      </div>
    ) : null;

  const phonePatient = useMemo(() => {
    return appointment?.detail?.phonePatient;
  }, [appointment?.detail?.phonePatient]);

  const handleFormSubmit = async values => {
    const {
      pulse,
      temperature,
      bloodPressure,
      height,
      weight,
      breathing,
      alcohol,
      cigarette,
      familyHistory,
      questionsByPatient,
      symptom,
      instructions,
      diagnostic,
      diagnostic1,
      drugsPresent,
      reason,
      reasonDetail,
      physicalExamination,
    } = values;
    const originTime = new Date();

    // for physical examination
    const pe1 = arrPhysicalExamination?.filter((item, key) => {
      return item?.value !== physicalExamination[key];
    });

    const pe2 = arrPhysicalExamination?.map(item => item?.value);
    const pe3 = physicalExamination?.filter((item, key) => {
      return item !== pe2[key];
    });

    if (pe3?.length === pe1?.length && pe3?.length > 0) {
      for (let j = 0; j < pe1?.length; j++) {
        const uuidPhysicalExamination = pe1[j]?.uuid;
        const paramsEditPhysicalExamination = {
          value: pe3[j],
        };

        dispatch(
          appointmentActions.handleEditPhysicalExamination({
            uuidPhysicalExamination,
            paramsEditPhysicalExamination,
          }),
        );
      }
    }
    // for reason
    const paramsReason = {
      reason,
      reasonDetail,
    };
    const idAppointment = initAppointmentValue?.uuidAppointment;
    await dispatch(
      appointmentActions.handleEditReasonGroup({ idAppointment, paramsReason }),
    );

    // for instructions
    const uuidInstructions =
      arrInstructions !== undefined ? arrInstructions[0]?.uuid : '';
    const paramsEditInstructions = {
      value: instructions,
    };
    const paramsCreateInstructions = {
      concept: '6ddca7a8-8d6a-45a8-ab1b-743fc5c4058a',
      value: instructions,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrInstructions?.length !== 0
      ? await dispatch(
          appointmentActions.handleEditInstructions({
            uuidInstructions,
            paramsEditInstructions,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateInstructions(paramsCreateInstructions),
        );

    // // for drugInuse

    const arrDrugs1 = arrDrugsPresent?.filter((item, key) => {
      return item?.value !== drugsPresent[key];
    });

    const arrDrugs2 = arrDrugsPresent?.map(item => item?.value);
    const arrDrugs3 = drugsPresent?.filter((item, key) => {
      return item !== arrDrugs2[key];
    });

    if (arrDrugs3?.length === arrDrugs1?.length && arrDrugs3?.length > 0) {
      for (let j = 0; j < arrDrugs1?.length; j++) {
        const uuidDrugsPresent = arrDrugs1[j]?.uuid;
        const paramsEditDrugsPresent = {
          value: arrDrugs3[j],
        };

        dispatch(
          appointmentActions.handleEditDrugsPresent({
            uuidDrugsPresent,
            paramsEditDrugsPresent,
          }),
        );
      }
    }

    // for question
    const questionData = res?.filter((item, key) => {
      return item?.value !== questionsByPatient[key];
    });

    const questionChange = questionsByPatient?.filter((item, key) => {
      return item !== finalQuestion[key];
    });

    if (
      questionData?.length === questionChange?.length &&
      questionData?.length > 0
    ) {
      for (let j = 0; j < questionData?.length; j++) {
        const uuidQuestionChange = questionData[j]?.uuid;
        const paramsQuestionByPatient = {
          value: questionChange[j],
        };
        dispatch(
          appointmentActions.handleEditQuestionByPatient({
            uuidQuestionChange,
            paramsQuestionByPatient,
          }),
        );
      }
    }

    // for provisisonal diagnostic

    const diagnosticData = arrDiagnosticProvisional?.filter((item, key) => {
      return item?.description !== diagnostic[key];
    });

    const diagnosticData1 = arrDiagnosticProvisional?.map(
      item => item?.description,
    );
    const dataMap = diagnostic?.filter((item, key) => {
      return item !== diagnosticData1[key];
    });

    if (dataMap?.length === diagnosticData?.length && dataMap?.length > 0) {
      for (let j = 0; j < diagnosticData?.length; j++) {
        const uuidDiagnostic = diagnosticData[j]?.id;
        const paramsEditDiagnostic = {
          diagnosis: { nonCoded: dataMap[j] },
          encounter: appointment?.detail?.encounters[0]?.uuid,
          certainty: 'PROVISIONAL',
          rank: 0,
        };

        dispatch(
          appointmentActions.handleEditDiagnosticProvisional({
            uuidDiagnostic,
            paramsEditDiagnostic,
          }),
        );
      }
    }

    const arraAdded = diagnostic?.slice(
      diagnosticData1?.length,
      diagnostic?.length + 1,
    );

    if (arraAdded?.length > 0) {
      const test = arraAdded?.map(item => {
        const paramsCreateDiagnosticProvisional = {
          diagnosis: { nonCoded: item },
          encounter: appointment?.detail?.encounters[0]?.uuid,
          certainty: 'PROVISIONAL',
          patient: appointment?.detail?.patient?.uuid,
          rank: 0,
        };
        dispatch(
          appointmentActions.handleCreateDiagnosticProvisional({
            paramsCreateDiagnosticProvisional,
          }),
        );
      });
      return test;
    }

    // for confirmed diagnostic
    const diagnostic1Data = arrDiagnosticConfirmed?.filter((item, key) => {
      return item?.description !== diagnostic1[key];
    });

    const diagnostic1Data1 = arrDiagnosticConfirmed?.map(
      item => item?.description,
    );
    const data1Map = diagnostic1?.filter((item, key) => {
      return item !== diagnostic1Data1[key];
    });

    if (data1Map?.length === diagnostic1Data?.length && data1Map?.length > 0) {
      for (let j = 0; j < diagnostic1Data?.length; j++) {
        const uuidDiagnostic1 = diagnostic1Data[j]?.id;
        const paramsEditDiagnostic1 = {
          diagnosis: { nonCoded: data1Map[j] },
          encounter: appointment?.detail?.encounters[0]?.uuid,
          certainty: 'CONFIRMED',
          rank: 0,
        };

        dispatch(
          appointmentActions.handleEditDiagnosticConfirmed({
            uuidDiagnostic1,
            paramsEditDiagnostic1,
          }),
        );
      }
    }

    const arraAdded1 = diagnostic1?.slice(
      diagnostic1Data1?.length,
      diagnostic1?.length + 1,
    );

    if (arraAdded1?.length > 0) {
      const test1 = arraAdded1?.map(item => {
        const paramsCreateDiagnosticConfirmed = {
          diagnosis: { nonCoded: item },
          encounter: appointment?.detail?.encounters[0]?.uuid,
          certainty: 'CONFIRMED',
          patient: appointment?.detail?.patient?.uuid,
          rank: 0,
        };
        dispatch(
          appointmentActions.handleCreateDiagnosticConfirmed({
            paramsCreateDiagnosticConfirmed,
          }),
        );
      });
      return test1;
    }

    // for pulse update
    const uuidPulse = arrPluse !== undefined ? arrPluse?.uuid : '';
    const paramsEditPulse = {
      value: pulse,
    };
    const paramsCreatePulse = {
      concept: '5087AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: pulse,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrPluse !== undefined
      ? await dispatch(
          appointmentActions.handleEditPulse({ uuidPulse, paramsEditPulse }),
        )
      : await dispatch(appointmentActions.handleCreatePulse(paramsCreatePulse));

    // for sys
    const diagnostic1DataSys = arrSystom?.filter((item, key) => {
      return item?.value !== symptom[key];
    });

    const diagnostic1Data1Sys = arrSystom?.map(item => item?.value);
    const data1MapSys = symptom?.filter((item, key) => {
      return item !== diagnostic1Data1Sys[key];
    });

    if (
      data1MapSys?.length === diagnostic1DataSys?.length &&
      data1MapSys?.length > 0
    ) {
      for (let j = 0; j < diagnostic1DataSys?.length; j++) {
        const uuidSystom = diagnostic1DataSys[j]?.uuid;
        const paramsEditSystom = {
          value: data1MapSys[j],
        };
        dispatch(
          appointmentActions.handleEditSystom({ uuidSystom, paramsEditSystom }),
        );
      }
    }

    const arraAddedSystom = symptom?.slice(
      diagnostic1Data1Sys?.length,
      symptom?.length + 1,
    );

    if (arraAddedSystom?.length > 0) {
      const test1 = arraAddedSystom?.map(item => {
        const paramsCreateSystom = {
          value: item,
          concept: CONST_ID.CONCEPT,
          obsDatetime: originTime?.toISOString(),
          person: appointment?.detail?.uuidPerson,
          encounter: appointment?.detail?.encounters[0]?.uuid,
        };
        dispatch(appointmentActions.handleCreateSystom(paramsCreateSystom));
      });
      return test1;
    }

    // for temperature update
    const uuidTemperature =
      arrTemperature !== undefined ? arrTemperature?.uuid : '';

    const paramsEditTemperature = {
      value: temperature,
    };
    const paramsCreateTemperature = {
      concept: '5088AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: temperature,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrTemperature !== undefined
      ? await dispatch(
          appointmentActions.handleEditTemperature({
            uuidTemperature,
            paramsEditTemperature,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateTemperature(paramsCreateTemperature),
        );

    // for update blood pressure

    const uuidBloodPressure =
      arrBloodPressureSystolic?.length !== 0
        ? arrBloodPressureSystolic[0]?.uuid
        : '';

    const paramsEditBloodPressure = {
      value: bloodPressure,
    };
    const paramsCreateBloodPressure = {
      concept: '5085AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: bloodPressure,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrBloodPressureSystolic?.length !== 0
      ? await dispatch(
          appointmentActions.handleEditBloodPressure({
            uuidBloodPressure,
            paramsEditBloodPressure,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateBloodPressure(
            paramsCreateBloodPressure,
          ),
        );

    // for height
    const uuidHeight = arrHeight !== undefined ? arrHeight?.uuid : '';

    const paramsEditHeight = {
      value: height,
    };
    const paramsCreateHeight = {
      concept: '5090AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: height,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrHeight !== undefined
      ? await dispatch(
          appointmentActions.handleEditHeight({
            uuidHeight,
            paramsEditHeight,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateHeight(paramsCreateHeight),
        );

    // for weight
    const uuidWeight = arrWeight !== undefined ? arrWeight[0]?.uuid : '';

    const paramsEditWeight = {
      value: weight,
    };
    const paramsCreateWeight = {
      concept: '5089AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: weight,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrWeight?.length !== 0
      ? await dispatch(
          appointmentActions.handleEditWeight({
            uuidWeight,
            paramsEditWeight,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateWeight(paramsCreateWeight),
        );

    // for breathing
    const uuidRespiratoryRate =
      arrRespiratoryRate !== undefined ? arrRespiratoryRate[0]?.uuid : '';

    const paramsEditRespiratoryRate = {
      value: breathing,
    };
    const paramsCreateRespiratoryRate = {
      concept: '5242AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
      value: breathing,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrRespiratoryRate?.length !== 0
      ? await dispatch(
          appointmentActions.handleEditRespiratoryRate({
            uuidRespiratoryRate,
            paramsEditRespiratoryRate,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateRespiratoryRate(
            paramsCreateRespiratoryRate,
          ),
        );

    // for alcohol

    const arrGetDiffAlcohol = arrAlcohol?.filter((item, key) => {
      return item?.value !== alcohol[key];
    });

    const arrFetchAlcohol = arrAlcohol?.map(item => item?.value);
    const arrGetAlcoholToCompare = alcohol?.filter((item, key) => {
      return item !== arrFetchAlcohol[key];
    });

    if (
      arrGetDiffAlcohol?.length === arrGetAlcoholToCompare?.length &&
      arrGetAlcoholToCompare?.length > 0
    ) {
      for (let j = 0; j < arrGetDiffAlcohol?.length; j++) {
        const uuidAlcohol = arrGetDiffAlcohol[j]?.uuid;
        const paramsEditAlcohol = {
          value: arrGetAlcoholToCompare[j],
        };
        dispatch(
          appointmentActions.handleEditAlcohol({
            uuidAlcohol,
            paramsEditAlcohol,
          }),
        );
      }
    }

    // for cigarette

    const arrGetCigarette = arrCigarette?.filter((item, key) => {
      return item?.value !== cigarette[key];
    });

    const arrFetchCigarette = arrCigarette?.map(item => item?.value);
    const arrGetCigratteToCompare = cigarette?.filter((item, key) => {
      return item !== arrFetchCigarette[key];
    });

    if (
      arrGetCigarette?.length === arrGetCigratteToCompare?.length &&
      arrGetCigratteToCompare?.length > 0
    ) {
      for (let j = 0; j < arrGetCigarette?.length; j++) {
        const uuidCigarette = arrGetCigarette[j]?.uuid;
        const paramsEditCigarette = {
          value: arrGetCigratteToCompare[j],
        };
        dispatch(
          appointmentActions.handleEditCigarette({
            uuidCigarette,
            paramsEditCigarette,
          }),
        );
      }
    }

    // for history family
    const uuidHistoryOfFamily =
      arrHistoryOfFamily !== undefined ? arrHistoryOfFamily[0]?.uuid : '';

    const paramsEditHistoryOfFamily = {
      value: familyHistory,
    };
    const paramsCreateHistoryOfFamily = {
      concept: 'ae6b4504-3f98-4387-a34d-29a7cfb43152',
      value: familyHistory,
      obsDatetime: originTime?.toISOString(),
      person: appointment?.detail?.uuidPerson,
      encounter: appointment?.detail?.encounters[0]?.uuid,
    };

    arrHistoryOfFamily?.length !== 0
      ? await dispatch(
          appointmentActions.handleEditHistoryOfFamily({
            uuidHistoryOfFamily,
            paramsEditHistoryOfFamily,
          }),
        )
      : await dispatch(
          appointmentActions.handleCreateHistoryOfFamily(
            paramsCreateHistoryOfFamily,
          ),
        );
  };

  const renderScreen = (appointmentParams: AppointmentsStoreState) => {
    if (
      // appointmentParams.detail.loading ||
      appointmentParams.conditionHistory.loading
    ) {
      return <Spin size="large" />;
    }
    return (
      <div className="appointment-container">
        <Form
          form={form}
          className={className || 'flex flex-col mt-4 justify-center  w-full '}
          labelAlign="left"
          layout="vertical"
          id={formId}
          {...formItemLayout}
          onFinish={handleFormSubmit}
          initialValues={initAppointmentValue}
        >
          <div className="flex flex-col m-0">
            <div className="flex justify-between">
              <div className="flex text-2xl ">
                <h2>Chi tiết cuộc tư vấn</h2>
              </div>
              <div className="container-button">
                <Button
                  type="primary"
                  disabled={
                    appointment.detail.detail.status === 'COMPLETED' ||
                    appointment.detail.detail.status === 'CANCELLED' ||
                    appointment.detail.detail.status ===
                      'WAITING_EXAMINATION' ||
                    appointment.detail.detail.status === 'WAITING_PAYMENT'
                  }
                  onClick={() => {
                    const params: StartCall = {
                      appointmentId: appointment.detail.detail.uuid,
                      appointmentCode:
                        appointment.detail.detail.appointmentCode,
                      receiveId: [
                        appointment.detail.patient.uuid,
                        appointment.detail.provider.uuid,
                      ],
                    };
                    handleStartCall(params);
                  }}
                >
                  Bắt đầu gọi
                </Button>
              </div>
            </div>

            <Form.Item
              name="appointmentStatus"
              className="w-full text-2xl pt-5 m-0"
              labelCol={{ span: showSubmitButton ? 4 : 6 }}
            >
              <Row className="p-0 m-0">
                <Col span={12} className="p-0 m-0">
                  <div className="info-code text-sm pb-5">
                    <span>
                      Mã cuộc hẹn: {appointment.detail.detail.appointmentCode}
                    </span>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="info-status text-sm">
                    <span>
                      {processStatus(appointment.detail.detail.status)}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="p-0 m-0">
                <Col span={24}>
                  <div className="info-time text-sm pb-5">
                    <span>{`${date}-${time}`}</span>
                  </div>
                  <div className="info-user text-sm pb-5 w-full">
                    <span>
                      Người khám:{' '}
                      {`${initValue?.patient?.name}${
                        initValue?.patient?.age === null
                          ? ''
                          : ` - ${initValue?.patient?.age} Tuổi`
                      }${
                        initValue?.patient?.carrer === undefined
                          ? ''
                          : ` - ${initValue?.patient?.carrer}`
                      }${
                        initValue?.patient?.city === '' ||
                        initValue?.patient?.city === null ||
                        initValue?.patient?.city === undefined
                          ? ''
                          : ` - ${initValue?.patient?.city}`
                      }${
                        phonePatient !== undefined ? ` - ${phonePatient}` : ''
                      }`}
                    </span>
                  </div>
                </Col>
              </Row>
            </Form.Item>
          </div>

          <div className="flex flex-col m-0">
            <div className="text-2xl pb-5">
              <h2>
                <span>Lý do khám bệnh</span>
              </h2>
            </div>
            <Form.Item
              name="reason"
              label="Lý do khám"
              className="w-full flex flex-row"
            >
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item
              name="reasonDetail"
              label="Mô tả chi tiết"
              className="w-full flex flex-row"
            >
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item
              className="form-item flex flex-row"
              label="Câu hỏi cho bác sỹ"
              name="questionsByPatient"
            >
              <Form.List name="questionsByPatient">
                {(fields = [{ name: 0, key: 0, fieldKey: 0 }]) => {
                  return (
                    <>
                      <div>
                        {fields.map((field, index) => (
                          <div key={field.key} className="flex m-0 p-0">
                            <Form.Item
                              label={`Câu hỏi thứ ${index + 1}`}
                              colon={index === 0}
                              labelCol={{ span: 2 }}
                              className="w-full m-0 p-0"
                            >
                              <Form.Item
                                {...field}
                                style={{ position: 'relative' }}
                              >
                                <Input disabled={!isEditable} />
                              </Form.Item>
                            </Form.Item>
                          </div>
                        ))}
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </Form.Item>
          </div>

          {appointment.detail.detail.status !== 'SCHEDULED' &&
          appointment.detail.detail.status !== 'WAITING_PAYMENT' ? (
            <>
              <div className="appointment-history flex flex-col">
                <div className="flex text-2xl p-0 m-0">
                  <h2>
                    <span>Lịch sử khám</span>
                  </h2>
                </div>
                <div className="content-history">
                  <List
                    className="demo-loadmore-list"
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item: any, key) => {
                      const filterTime =
                        item?.startDate !== undefined &&
                        item?.startDate?.match(getDateIos);

                      const reArrange =
                        filterTime?.length > 0 &&
                        filterTime[0]
                          ?.split('-')
                          .reverse()
                          .join('/');

                      const sortDateTimeSlot = a => {
                        if (a !== undefined) {
                          const yearA: any = a[0]?.match(filterYear);
                          const day: any = a[0]?.match(getDayA);
                          const dayA: any = _.replace(day[0], removeHyphen, '');

                          const filterMonth1 = _.replace(a[0], getDayA, '');
                          const monthA: any = _.replace(
                            filterMonth1,
                            removeYearHyphen,
                            '',
                          );

                          const timeCA: any = new Date(yearA[0], monthA, dayA);

                          return timeCA.getTime();
                        }
                      };

                      const timePresent: any = new Date();

                      const calculate = (a, b) => {
                        const elCompare = (a - b) / 86400000;
                        const lastEl = Math.round((elCompare - 30) / 30);

                        return elCompare > 0
                          ? `( khoảng ${Math.abs(lastEl) - 2} tháng trước)`
                          : `(khoảng ${Math.abs(lastEl) - 2} tháng sau)`;
                      };

                      return (
                        <List.Item>
                          <div className="appointment-history-detail">
                            <div className="appointment-history-detail-time">
                              {reArrange}
                              {calculate(
                                timePresent.getTime(),
                                sortDateTimeSlot(filterTime),
                              )}
                            </div>

                            <div className="appointment-history-detail-guess">
                              {list[key]?.diagnoses[0]?.content}
                            </div>
                            <div className="appointment-history-detail-time">
                              <Tooltip title="Xem chi tiết">
                                <Link
                                  to={`${pathRouteWithRole()}/appointments/edit/${
                                    idUrl?.id
                                  }`}
                                >
                                  Xem chi tiết
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                </div>
              </div>

              <div className="flex text-2xl">
                <h2>
                  <span>Hỏi bệnh</span>
                </h2>
              </div>

              <div className="flex pt-5">
                <div className="systom-container w-full">
                  <Form.Item
                    name="symptom"
                    label="Triệu chứng"
                    className="w-full flex flex-row"
                  >
                    <Form.List name="symptom">
                      {(
                        fields = [{ name: 0, key: 0, fieldKey: 0 }],
                        { add, remove },
                      ) => {
                        return (
                          <>
                            <div>
                              {fields.map((field, index) => (
                                <div key={field.key} className="flex flex-row ">
                                  <Form.Item
                                    colon={index === 0}
                                    labelCol={{ span: 2 }}
                                    className={
                                      isEditable
                                        ? 'w-10/12 m-0 p-0'
                                        : 'w-full m-0 p-0'
                                    }
                                  >
                                    <Form.Item
                                      {...field}
                                      style={{ position: 'relative' }}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Form.Item>
                                  {fields.length > 1 && (
                                    <Button
                                      className="ml-2"
                                      disabled={!isEditable}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    >
                                      <MinusCircleOutlined />
                                    </Button>
                                  )}
                                </div>
                              ))}
                            </div>
                            {(showSubmitButton &&
                              !initAppointmentValue.chronicDiseases) ||
                            isEditable ? (
                              <div className="flex justify-center items-center">
                                <Button
                                  disabled={!isEditable}
                                  type="dashed"
                                  className="ml-2 btn-add-field"
                                  onClick={() => {
                                    add();
                                  }}
                                >
                                  <PlusCircleTwoTone />
                                </Button>
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    </Form.List>
                  </Form.Item>
                </div>
              </div>

              <div className="illness-container">
                <div className="flex text-2xl pb-5">
                  <h2>
                    <span>Tiền sử bệnh</span>
                  </h2>
                </div>
                <Form.Item
                  className="form-item flex flex-row m-0"
                  label="Dị ứng"
                  name="allergy"
                >
                  <Form.List name="allergy">
                    {(
                      fields = [{ name: 0, key: 0, fieldKey: 0 }],
                      { add, remove },
                    ) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => (
                              <div key={field.key} className="flex">
                                <Form.Item
                                  colon={index === 0}
                                  labelCol={{ span: 2 }}
                                  className={
                                    isEditable
                                      ? 'w-10/12 m-0 p-0'
                                      : 'w-full m-0 p-0'
                                  }
                                >
                                  <Form.Item
                                    {...field}
                                    style={{ position: 'relative' }}
                                  >
                                    <TextArea disabled autoSize={true} />
                                  </Form.Item>
                                </Form.Item>
                                {fields.length > 1 && (
                                  <Button
                                    className="ml-2"
                                    disabled={!isEditable}
                                    type="dashed"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  >
                                    <MinusCircleOutlined />
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.chronicDiseases) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                type="dashed"
                                className="ml-2 btn-add-field"
                                onClick={() => {
                                  add();
                                }}
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
                <div className="chronic-container">
                  <Form.Item
                    className="form-item flex flex-row m-0"
                    label="Bệnh mãn tính"
                    name="chronic"
                  >
                    <Form.List name="chronic">
                      {(
                        fields = [{ name: 0, key: 0, fieldKey: 0 }],
                        { add, remove },
                      ) => {
                        return (
                          <>
                            <div>
                              {fields.map((field, index) => (
                                <div key={field.key} className="flex">
                                  <Form.Item
                                    colon={index === 0}
                                    labelCol={{ span: 2 }}
                                    className={
                                      isEditable
                                        ? 'w-10/12 m-0 p-0'
                                        : 'w-full m-0 p-0'
                                    }
                                  >
                                    <Form.Item
                                      {...field}
                                      style={{ position: 'relative' }}
                                    >
                                      <TextArea disabled autoSize={true} />
                                    </Form.Item>
                                  </Form.Item>
                                  {fields.length > 1 && (
                                    <Button
                                      className="ml-2"
                                      disabled={!isEditable}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    >
                                      <MinusCircleOutlined />
                                    </Button>
                                  )}
                                </div>
                              ))}
                            </div>
                            {(showSubmitButton &&
                              !initAppointmentValue.chronicDiseases) ||
                            isEditable ? (
                              <div className="flex justify-center items-center">
                                <Button
                                  disabled={!isEditable}
                                  type="dashed"
                                  className="ml-2 btn-add-field"
                                  onClick={() => {
                                    add();
                                  }}
                                >
                                  <PlusCircleTwoTone />
                                </Button>
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    </Form.List>
                  </Form.Item>
                </div>
                <div className="flex pt-5">
                  <div className="drugsPresent-container w-full ">
                    <Form.Item
                      name="drugsPresent"
                      label="Thuốc đang sử dụng"
                      className="w-full flex flex-row m-0 p-0 "
                    >
                      <Form.List name="drugsPresent">
                        {(
                          fields = [{ name: 0, key: 0, fieldKey: 0 }],
                          { add, remove },
                        ) => {
                          return (
                            <>
                              <div>
                                {fields.map((field, index) => (
                                  <div
                                    key={field.key}
                                    className="flex flex-row"
                                  >
                                    <Form.Item
                                      colon={index === 0}
                                      labelCol={{ span: 2 }}
                                      className={
                                        isEditable
                                          ? 'w-10/12  m-0 p-0'
                                          : 'w-full  m-0 p-0'
                                      }
                                    >
                                      <Form.Item
                                        {...field}
                                        style={{ position: 'relative' }}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Form.Item>
                                    {fields.length > 1 && (
                                      <Button
                                        className="ml-2"
                                        disabled={!isEditable}
                                        type="dashed"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {(showSubmitButton &&
                                !initAppointmentValue.chronicDiseases) ||
                              isEditable ? (
                                <div className="flex justify-center items-center">
                                  <Button
                                    disabled={!isEditable}
                                    type="dashed"
                                    className="ml-2 btn-add-field"
                                    onClick={() => {
                                      add();
                                    }}
                                  >
                                    <PlusCircleTwoTone />
                                  </Button>
                                </div>
                              ) : null}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form.Item>
                  </div>
                </div>
                <div className="flex pt-5">
                  <div className="alcohol-container w-full ">
                    <Form.Item
                      label="Rượu bia"
                      className="w-full flex flex-row m-0 p-0 "
                      name="alcohol"
                    >
                      <Form.List name="alcohol">
                        {(
                          fields = [{ name: 0, key: 0, fieldKey: 0 }],
                          { add, remove },
                        ) => (
                          <>
                            <div>
                              {fields.map((field, index) => (
                                <div key={field.key} className="flex flex-row">
                                  <Form.Item
                                    colon={index === 0}
                                    labelCol={{ span: 2 }}
                                    className={
                                      isEditable
                                        ? 'w-10/12  m-0 p-0'
                                        : 'w-full m-0 p-0'
                                    }
                                  >
                                    <Form.Item
                                      {...field}
                                      style={{ position: 'relative' }}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Form.Item>
                                  {fields.length > 1 && (
                                    <Button
                                      className="ml-2"
                                      disabled={!isEditable}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    >
                                      <MinusCircleOutlined />
                                    </Button>
                                  )}
                                </div>
                              ))}
                            </div>
                            {(showSubmitButton &&
                              !initAppointmentValue.chronicDiseases) ||
                            isEditable ? (
                              <div className="flex justify-center items-center">
                                <Button
                                  disabled={!isEditable}
                                  type="dashed"
                                  className="ml-2 btn-add-field"
                                  onClick={() => {
                                    add();
                                  }}
                                >
                                  <PlusCircleTwoTone />
                                </Button>
                              </div>
                            ) : null}
                          </>
                        )}
                      </Form.List>
                    </Form.Item>
                  </div>
                </div>
                <div className="flex pt-5">
                  <div className="familyHistory-container w-full ">
                    <Form.Item
                      name="cigarette"
                      label="Thuốc lá"
                      className="w-full flex flex-row m-0 p-0 "
                    >
                      <Form.List name="cigarette">
                        {(
                          fields = [{ name: 0, key: 0, fieldKey: 0 }],
                          { add, remove },
                        ) => {
                          return (
                            <>
                              <div>
                                {fields.map((field, index) => (
                                  <div
                                    key={field.key}
                                    className="flex flex-row"
                                  >
                                    <Form.Item
                                      colon={index === 0}
                                      labelCol={{ span: 2 }}
                                      className={
                                        isEditable
                                          ? 'w-10/12 m-0 p-0'
                                          : 'w-full  m-0 p-0'
                                      }
                                    >
                                      <Form.Item
                                        {...field}
                                        style={{ position: 'relative' }}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Form.Item>
                                    {fields.length > 1 && (
                                      <Button
                                        className="ml-2"
                                        disabled={!isEditable}
                                        type="dashed"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {(showSubmitButton &&
                                !initAppointmentValue.chronicDiseases) ||
                              isEditable ? (
                                <div className="flex justify-center items-center">
                                  <Button
                                    disabled={!isEditable}
                                    type="dashed"
                                    className="ml-2 btn-add-field"
                                    onClick={() => {
                                      add();
                                    }}
                                  >
                                    <PlusCircleTwoTone />
                                  </Button>
                                </div>
                              ) : null}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form.Item>
                  </div>
                </div>

                <div className="flex pt-5">
                  <div className="familyHistory-container w-full ">
                    <Form.Item
                      name="familyHistory"
                      label="Tiền sử gia đình"
                      className="w-full flex flex-row m-0 p-0 "
                    >
                      <Form.List name="familyHistory">
                        {(
                          fields = [{ name: 0, key: 0, fieldKey: 0 }],
                          { add, remove },
                        ) => {
                          return (
                            <>
                              <div>
                                {fields.map((field, index) => (
                                  <div
                                    key={field.key}
                                    className="flex flex-row"
                                  >
                                    <Form.Item
                                      colon={index === 0}
                                      labelCol={{ span: 2 }}
                                      className={
                                        isEditable
                                          ? 'w-10/12  m-0 p-0'
                                          : 'w-full  m-0 p-0'
                                      }
                                    >
                                      <Form.Item
                                        {...field}
                                        style={{ position: 'relative' }}
                                      >
                                        <Input />
                                      </Form.Item>
                                    </Form.Item>
                                    {fields.length > 1 && (
                                      <Button
                                        className="ml-2"
                                        disabled={!isEditable}
                                        type="dashed"
                                        onClick={() => {
                                          remove(field.name);
                                        }}
                                      >
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </div>
                                ))}
                              </div>
                              {(showSubmitButton &&
                                !initAppointmentValue.chronicDiseases) ||
                              isEditable ? (
                                <div className="flex justify-center items-center">
                                  <Button
                                    disabled={!isEditable}
                                    type="dashed"
                                    className="ml-2 btn-add-field"
                                    onClick={() => {
                                      add();
                                    }}
                                  >
                                    <PlusCircleTwoTone />
                                  </Button>
                                </div>
                              ) : null}
                            </>
                          );
                        }}
                      </Form.List>
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="flex text-2xl ">
                <h2>
                  <span>Khám bệnh</span>
                </h2>
              </div>
              <div className="flex pt-5 appointment-body-info">
                <Row gutter={24}>
                  <Col span={4}>
                    <div className="mt-50 text-l">
                      <span>Toàn thân</span>
                    </div>
                  </Col>

                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Mạch"
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item name="pulse" className="m-0 p-0 w-2/3">
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">/ph</span>
                      </div>
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Nhiệt độ"
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item name="temperature" className="m-0 p-0 w-2/3">
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">°C</span>
                      </div>
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Huyết áp"
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item
                          name="bloodPressure"
                          className="m-0 p-0 w-2/3"
                        >
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">
                          /{initAppointmentValue?.bloodPressureDiastolic}
                        </span>
                      </div>
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Nhịp thở "
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item name="breathing" className="m-0 p-0 w-2/3">
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">/ph</span>
                      </div>
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Cân nặng"
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item name="weight" className="m-0 p-0 w-2/3">
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">Kg</span>
                      </div>
                    </Card>
                  </Col>
                  <Col span={3}>
                    <Card
                      className="card-body-info"
                      size="small"
                      title="Chiều cao"
                      extra={
                        <Link to="#">
                          <EditOutlined className="card-body-info_icon_edit" />
                        </Link>
                      }
                    >
                      <div className="flex flex-row">
                        <Form.Item name="height" className="m-0 p-0 w-2/3">
                          <Input disabled={!isEditable} />
                        </Form.Item>
                        <span className="pt-1">CM</span>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="physical-examination w-full pt-5">
                <div className="physical-examination-conatainer">
                  <Form.Item
                    className="form-item flex flex-row m-0"
                    label="Khám lâm sàng"
                    name="physicalExamination"
                  >
                    <Form.List name="physicalExamination">
                      {(
                        fields = [{ name: 0, key: 0, fieldKey: 0 }],
                        { add, remove },
                      ) => {
                        return (
                          <>
                            <div>
                              {fields.map((field, index) => (
                                <div key={field.key} className="flex">
                                  <Form.Item
                                    colon={index === 0}
                                    labelCol={{ span: 4 }}
                                    className={
                                      isEditable
                                        ? 'w-10/12 m-0 p-0'
                                        : ' w-full m-0 p-0'
                                    }
                                  >
                                    <Form.Item {...field}>
                                      <Input disabled={!isEditable} />
                                    </Form.Item>
                                  </Form.Item>
                                  {fields.length > 1 && (
                                    <Button
                                      className="ml-2"
                                      disabled={!isEditable}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    >
                                      <MinusCircleOutlined />
                                    </Button>
                                  )}
                                </div>
                              ))}
                            </div>
                            {(showSubmitButton &&
                              !initAppointmentValue.diagnostic) ||
                            isEditable ? (
                              <div className="flex justify-center items-center">
                                <Button
                                  disabled={!isEditable}
                                  type="dashed"
                                  className="ml-2 btn-add-field"
                                  onClick={() => {
                                    add();
                                  }}
                                >
                                  <PlusCircleTwoTone />
                                </Button>
                              </div>
                            ) : null}
                          </>
                        );
                      }}
                    </Form.List>
                  </Form.Item>
                </div>
              </div>
              <div className="pb-5 text-2xl diagnostic-title">
                <h2>
                  <span>Chẩn đoán</span>
                </h2>
              </div>
              <div className="diagnostic-container">
                <Form.Item
                  className="form-item flex flex-row m-0"
                  label="Chẩn đoán sơ bộ"
                  name="diagnostic"
                >
                  <Form.List name="diagnostic">
                    {(
                      fields = [{ name: 0, key: 0, fieldKey: 0 }],
                      { add, remove },
                    ) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => (
                              <div key={field.key} className="flex">
                                <Form.Item
                                  colon={index === 0}
                                  labelCol={{ span: 4 }}
                                  className={
                                    isEditable
                                      ? 'w-10/12 m-0 p-0'
                                      : ' w-full m-0 p-0'
                                  }
                                >
                                  <Form.Item {...field}>
                                    <Input disabled={!isEditable} />
                                  </Form.Item>
                                </Form.Item>
                                {fields.length > 1 && (
                                  <Button
                                    className="ml-2"
                                    disabled={!isEditable}
                                    type="dashed"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  >
                                    <MinusCircleOutlined />
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.diagnostic) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                type="dashed"
                                className="ml-2 btn-add-field"
                                onClick={() => {
                                  add();
                                }}
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
                <Form.Item
                  className="form-item flex flex-row m-0"
                  label="Chẩn đoán xác định"
                  name="diagnostic1"
                >
                  <Form.List name="diagnostic1">
                    {(
                      fields = [{ name: 0, key: 0, fieldKey: 0 }],
                      { add, remove },
                    ) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => (
                              <div key={field.key} className="flex">
                                <Form.Item
                                  colon={index === 0}
                                  labelCol={{ span: 4 }}
                                  className={
                                    isEditable
                                      ? 'w-10/12 m-0 p-0'
                                      : ' w-full m-0 p-0'
                                  }
                                >
                                  <Form.Item {...field}>
                                    <Input disabled={!isEditable} />
                                  </Form.Item>
                                </Form.Item>
                                {fields.length > 1 && (
                                  <Button
                                    className="ml-2"
                                    disabled={!isEditable}
                                    type="dashed"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  >
                                    <MinusCircleOutlined />
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.diagnostic) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                type="dashed"
                                className="ml-2 btn-add-field"
                                onClick={() => {
                                  add();
                                }}
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
                <Form.Item
                  className="form-item flex flex-row m-0"
                  label="Chỉ định Cận lâm sàng"
                  name="requests"
                >
                  <Form.List name="requests">
                    {(
                      fields = [{ name: 0, key: 0, fieldKey: 0 }],
                      { add, remove },
                    ) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => (
                              <div key={field.key} className="flex">
                                <Form.Item
                                  colon={index === 0}
                                  labelCol={{ span: 4 }}
                                  className={
                                    isEditable
                                      ? 'w-10/12  m-0 p-0'
                                      : ' w-full m-0 p-0'
                                  }
                                >
                                  <Form.Item {...field}>
                                    <Input disabled />
                                  </Form.Item>
                                </Form.Item>
                                {fields.length > 1 && (
                                  <Button
                                    className="ml-2"
                                    disabled={!isEditable}
                                    type="dashed"
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  >
                                    <MinusCircleOutlined />
                                  </Button>
                                )}
                              </div>
                            ))}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.requests) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                                className="ml-2 btn-add-field"
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
                <Form.Item
                  className="form-item flex flex-row m-0"
                  label="Đơn thuốc"
                  name="drugOrders"
                >
                  <Form.List name="drugOrders">
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => {
                              return (
                                <div key={field.key} className="flex">
                                  <Form.Item
                                    colon={index === 0}
                                    labelCol={{ span: 4 }}
                                    className={
                                      isEditable
                                        ? 'w-10/12 m-0 p-0'
                                        : ' w-full m-0 p-0'
                                    }
                                  >
                                    <Form.Item {...field}>
                                      <TextArea disabled autoSize={true} />
                                    </Form.Item>
                                  </Form.Item>

                                  {fields.length > 1 && (
                                    <Button
                                      className="ml-2"
                                      disabled={!isEditable}
                                      type="dashed"
                                      onClick={() => {
                                        remove(field.name);
                                      }}
                                    >
                                      <MinusCircleOutlined />
                                    </Button>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.drugOrders) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                className="ml-2 btn-add-field"
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
                <Form.Item
                  className="form-item flex flex-row "
                  label="Lời dặn bác sỹ"
                  name="instructions"
                >
                  <Form.List name="instructions">
                    {(fields = [{ name: 0, key: 0, fieldKey: 0 }], { add }) => {
                      return (
                        <>
                          <div>
                            {fields.map((field, index) => (
                              <div key={field.key} className="flex">
                                <Form.Item
                                  colon={index === 0}
                                  labelCol={{ span: 4 }}
                                  className={
                                    isEditable
                                      ? 'w-10/12 m-0 p-0'
                                      : ' w-full m-0 p-0'
                                  }
                                >
                                  <Form.Item {...field}>
                                    <TextArea
                                      disabled={!isEditable}
                                      autoSize={true}
                                    />
                                  </Form.Item>
                                </Form.Item>
                              </div>
                            ))}
                          </div>
                          {(showSubmitButton &&
                            !initAppointmentValue.instructions) ||
                          isEditable ? (
                            <div className="flex justify-center items-center">
                              <Button
                                disabled={!isEditable}
                                className="ml-2 btn-add-field"
                                type="dashed"
                                onClick={() => {
                                  add();
                                }}
                              >
                                <PlusCircleTwoTone />
                              </Button>
                            </div>
                          ) : null}
                        </>
                      );
                    }}
                  </Form.List>
                </Form.Item>
              </div>
              <div className="folow-patient">
                <div className="text-2xl pb-5">
                  <h2>
                    <span>Theo dõi</span>
                  </h2>
                </div>
                <div className="followUps-container">
                  <Form.Item
                    name="followUps"
                    label="theo dõi"
                    className="w-full flex flex-row m-0"
                  >
                    <Form.List name="followUps">
                      {(
                        fields = [{ name: 0, key: 0, fieldKey: 0 }],
                        { add, remove },
                      ) => {
                        return (
                          <>
                            <div>
                              <div style={{ position: 'relative' }}>
                                {fields.map((field, index) => {
                                  return (
                                    <div key={field.key} className="flex">
                                      <Form.Item
                                        colon={index === 0}
                                        labelCol={{ span: 4 }}
                                        className={
                                          isEditable
                                            ? 'w-10/12 m-0 p-0'
                                            : ' w-full m-0 p-0'
                                        }
                                      >
                                        <Form.Item {...field}>
                                          <TextArea
                                            disabled={!isEditable}
                                            autoSize={true}
                                          />
                                        </Form.Item>
                                        <div className="result-container flex flex-row relative mb-5">
                                          <div className="result-container__title absolute ">
                                            Kết quả theo dõi
                                          </div>
                                          <div className="result-container__input w-full">
                                            <Input />
                                          </div>
                                        </div>
                                      </Form.Item>
                                      {fields.length > 1 && (
                                        <Button
                                          className="ml-2"
                                          disabled={!isEditable}
                                          type="dashed"
                                          onClick={() => {
                                            remove(field.name);
                                          }}
                                        >
                                          <MinusCircleOutlined />
                                        </Button>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              {(showSubmitButton &&
                                !initAppointmentValue.instructions) ||
                              isEditable ? (
                                <div className="flex justify-center items-center">
                                  <Button
                                    style={{
                                      position: 'absolute',
                                      right: '0',
                                      bottom: '20px',
                                    }}
                                    disabled={!isEditable}
                                    type="dashed"
                                    className="ml-2"
                                    onClick={() => {
                                      add();
                                    }}
                                  >
                                    <PlusCircleTwoTone />
                                  </Button>
                                </div>
                              ) : null}
                            </div>
                          </>
                        );
                      }}
                    </Form.List>
                  </Form.Item>
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="flex justify-center mt-4">
            <>
              {appointment.detail.detail.requests?.length !== 0 ? (
                <Button className="lg:ml-2">
                  <span className="btn-start-call">Chờ xét nghiệm</span>
                </Button>
              ) : (
                <Button disabled>
                  <span className="btn-start-call">Chờ xét nghiệm</span>
                </Button>
              )}

              {isEditable ? (
                <>
                  <Button
                    className="ml-4"
                    form={formId}
                    key="submit"
                    type="primary"
                    htmlType="submit"
                  >
                    {isEditable ? 'Lưu' : 'Thêm'}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="lg:ml-2"
                    type="primary"
                    onClick={() =>
                      history.push(
                        `${pathRouteWithRole()}/appointments/edit/${idUrl?.id}`,
                      )
                    }
                  >
                    <span className="btn-start-call">Sửa</span>
                  </Button>
                </>
              )}
            </>
          </div>
          {/* ) : null} */}
        </Form>
      </div>
    );
  };

  return renderScreen(appointment as AppointmentsStoreState);
};

export default AppointmentForm;
