import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
// import { PromotionModel } from 'models/store/promotion.model';

export const {
  handleCreateMarketingNotiRequested,
  handleCreateMarketingNotiSucceeded,
  handleCreateMarketingNotiFailed,
} = createActions(
  {
    HANDLE_CREATE_MARKETING_NOTI_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_MARKETING_NOTI_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_MARKETING_NOTI_FAILED: (error: any) => error,
  },
  {
    prefix: 'marketingNoti',
  },
);
