import { Card, Col, Row, Table, Spin, Button, Modal } from 'antd';
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Specialist.scss';
import StoreState from 'models/store';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ActionTable from './Table/Action';
import ModalEditSpecialist from './Table/Modal/ModalEditSpecialist';
import {
  handleDeleteSpecialistRequested,
  getSpecialistCustomsRequested,
} from '../../store/customSetting/customSetting.action';
import { ParamsInformationDoctorModel, ParamsPostSpecialist } from './modal';

interface IPagination {
  current?: number;
  pageSize?: number;
}
const SpeciaList: FC = () => {
  const dataSpecial = useSelector((state: StoreState) => state.customSetting);

  const [params, setParams] = useState<ParamsPostSpecialist>();

  const dispatch = useDispatch();
  const [ParamsSpecialistModel, setParamsSpecialistModel] = useState<
    ParamsInformationDoctorModel
  >({
    v: 'full',
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
    totalCount: true,
    class: 'Specialist',
    startIndex: 1,
    limit: 10,
  });
  const [isShowPopUpAdd, setIsShowPopUpAdd] = useState(false);
  const [title, setTitle] = useState('');
  const getSpecialist = useCallback(
    (valSpe = {}) => {
      dispatch(
        getSpecialistCustomsRequested({ ...ParamsSpecialistModel, ...valSpe }),
      );
    },
    [ParamsSpecialistModel],
  );

  useEffect(() => {
    getSpecialist();
  }, [ParamsSpecialistModel]);

  const onChangeTable = (pagination: IPagination) => {
    const { current, pageSize } = pagination;

    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsSpecialistModel({
      ...ParamsSpecialistModel,
      ...body,
    });
  };
  const handleDelete = (record: any) => {
    const onDelete = () => {
      dispatch(
        handleDeleteSpecialistRequested({
          id: record.uuid,
          handleSuccess: () => {
            // const Index =
            //   dataSpecial.dataSpecialist.results?.length === 1
            //     ? (ParamsSpecialistModel?.startIndex || 1) - 1
            //     : ParamsSpecialistModel?.startIndex;
            const Index = 1;

            getSpecialist({
              startIndex: Index,
            });
            setParamsSpecialistModel({
              ...ParamsSpecialistModel,
              startIndex: Index,
            });
          },
        }),
      );
    };
    Modal.confirm({
      title: 'Bạn có chắc chắn xóa chuyên khoa này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };
  const onOpenPopUp = (Title: string, record) => {
    const param = {
      uuidConcept: Object.values(record).length !== 0 ? record?.uuid : '',
      uuidName: Object.values(record).length !== 0 ? record?.name.uuid : '',
      name: Object.values(record).length !== 0 ? record?.display : '',
    };
    setParams(param);
    setTitle(Title);
    setIsShowPopUpAdd(true);
  };

  const onClosePopUpAdd = () => {
    setIsShowPopUpAdd(false);
  };
  const onRefresh = () => {
    getSpecialist();
  };
  const columns = [
    {
      title: 'STT',
      width: '5%',
      key: '1',
      render: (value, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Chuyên khoa',
      key: '2',
      width: '35%',
      render: (value, row, index) => {
        return value.name.display;
      },
    },
    {
      key: '3',
      title: 'Ngày tạo',
      width: '30%',
      render: (value, row, index) => {
        return moment(value?.auditInfo.dateCreated).format('DD/MM/YYYY');
      },
    },
    {
      key: 'Action',
      title: 'Thao tác',
      width: '30%',
      render: (row: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            handleDelete={() => handleDelete(row)}
            handleEdit={() => onOpenPopUp('Chỉnh sửa chuyên khoa', row)}
          />
        </div>
      ),
    },
  ];
  return (
    <div className="version-management">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Danh sách chuyên khoa</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              onClick={() => onOpenPopUp('Thêm mới chuyên khoa', {})}
            >
              Thêm mới
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col xs={24}>
            <Table
              columns={columns}
              rowKey={row => row.uuid}
              loading={{
                spinning: dataSpecial.isLoadingSpecialist,
                indicator: <Spin />,
                tip: 'Đang tải...',
              }}
              dataSource={dataSpecial.dataSpecialist.results}
              pagination={{
                current: ParamsSpecialistModel.startIndex,
                pageSize: ParamsSpecialistModel.limit,
                total: dataSpecial.dataSpecialist.totalCount,
                showSizeChanger: true,
                locale: { items_per_page: '/Trang' },
              }}
              onChange={onChangeTable}
            />
          </Col>
        </Row>
      </Card>
      {/* {isShowPopUpAdd && ( */}
      <ModalEditSpecialist
        show={isShowPopUpAdd}
        onHide={onClosePopUpAdd}
        title={title}
        params={params}
        onRefresh={onRefresh}
      />
      {/* )} */}
    </div>
  );
};

export default SpeciaList;
