import { message } from 'antd';
import authorizedRequest from 'helpers/request/authorizedRequest';
import { omit } from 'lodash';
import { getMessageFromError } from 'helpers/utils';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import * as versionActions from './version.action';

function* getVersionRequested(action) {
  try {
    const params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/version/all', {
        params: { ...params, ha: '' },
      }),
    );
    yield put(versionActions.getVersionSuccessed(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.getVersionFailed(error));
  }
}

function* getHightestVersionDoctorRequested(action) {
  try {
    const params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/version', { params }),
    );
    yield put(versionActions.getHightestVersionDoctorSuccess(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.getHightestVersionDoctorFailed(error));
  }
}

function* getHightestVersionPatientRequested(action) {
  try {
    const params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/version', { params }),
    );
    yield put(versionActions.getHightestVersionPatientSuccess(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.getHightestVersionPatientFailed(error));
  }
}

function* getVersionSettingsRequested(action) {
  try {
    const bodyParams = omit(action.payload, 'isSuccess');
    const response = yield call(() =>
      authorizedRequest.get('/v1/version/setting', bodyParams),
    );
    yield put(versionActions.getVersionSettingsSuccessed(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.getVersionSettingsFailed(error));
  }
}

function* postUpdateVersionSettingsRequested(action) {
  try {
    const bodyParams = omit(action.payload);
    const res = yield call(() =>
      authorizedRequest.post('/v1/version/setting', bodyParams),
    );
    yield put(versionActions.postUpdateVersionSettingsSuccessed(res));
    // action.payload.isSuccess();
    message.success('Cập nhật thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.postUpdateVersionSettingsFailed(error));
  }
}

function* postAddVersionRequested(action) {
  try {
    const bodyParams = omit(action.payload, 'isSuccess');
    const res = yield call(() =>
      authorizedRequest.post('/v1/version', bodyParams),
    );
    yield put(versionActions.postAddVersionSuccessed(res));
    action.payload.isSuccess();
    message.success('Thêm mới thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(versionActions.postAddVersionFailed(error));
  }
}

function* handleDeleteVersionRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.delete(`/v1/version`, {
        params: action.payload,
      }),
    );
    yield put(versionActions.handleDeleteVersionSuccessed(res));
  } catch (error) {
    yield put(versionActions.handleDeleteVersionFailed(error));
    message.error(getMessageFromError(error));
  }
}

export default function* versionSaga() {
  yield all([
    takeLatest(
      versionActions.getVersionSettingsRequested,
      getVersionSettingsRequested,
    ),

    takeLatest(
      versionActions.getHightestVersionPatientRequested,
      getHightestVersionPatientRequested,
    ),

    takeLatest(
      versionActions.getHightestVersionDoctorRequested,
      getHightestVersionDoctorRequested,
    ),

    takeLatest(
      versionActions.postUpdateVersionSettingsRequested,
      postUpdateVersionSettingsRequested,
    ),

    takeLatest(versionActions.getVersionRequested, getVersionRequested),

    takeLatest(versionActions.postAddVersionRequested, postAddVersionRequested),

    takeLatest(
      versionActions.handleDeleteVersionRequested,
      handleDeleteVersionRequested,
    ),
  ]);
}
