import React, { FC, useEffect, useState } from 'react';
import { Card, Form, Row, Col, Input, Button, Table, Spin } from 'antd';
import './Transaction.scss';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { formatMoney, formatPhoneNumber } from '../../helpers/utils';
import * as doctorInfoActions from '../../store/doctor/doctor.action';
import ActionTable from '../../components/Share/Table/Action';
import {
  regexConvertSearch1,
  regexConvertSearch2,
  regexConvertTrim,
  regexCheckPhone,
} from './doctor.const';

interface ParamsTransactionDoctorModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
}

const TransactionOfDoctor: FC = () => {
  const dispatch = useDispatch();

  const [paramsTransactionDoctor, setParamsTransactionDoctor] = useState<
    ParamsTransactionDoctorModel
  >({
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
  });

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsTransactionDoctor({
      ...paramsTransactionDoctor,
      ...body,
    });
  };

  const doctorReducer = useSelector((state: any) => state.doctorReducer);

  const isPhoneNumber = val => {
    return regexCheckPhone.test(val);
  };

  const getDoctorTransactionSearch = (val: any) => {
    const trimVal1 = _.replace(val?.q, regexConvertSearch1, '');
    const trimVal2 = _.replace(trimVal1, regexConvertSearch2, '');
    const trimVal3 = _.replace(trimVal2, regexConvertTrim, '');
    const seaching = isPhoneNumber(trimVal2) ? trimVal3 : trimVal2;
    dispatch(
      doctorInfoActions.getDoctorRequested({
        ...paramsTransactionDoctor,
        q: seaching,
        startIndex: 1,
      }),
    );
  };

  const getDoctorTransaction = () => {
    dispatch(
      doctorInfoActions.getDoctorRequested({
        ...paramsTransactionDoctor,
      }),
    );
  };

  useEffect(() => {
    getDoctorTransaction();
  }, [paramsTransactionDoctor]);

  const history = useHistory();
  const columns = [
    {
      title: 'STT',
      key: '1',
      render: (value, row, index) => {
        if (
          paramsTransactionDoctor?.startIndex !== undefined &&
          paramsTransactionDoctor?.limit !== undefined &&
          paramsTransactionDoctor
        ) {
          return (
            (paramsTransactionDoctor?.startIndex - 1) *
              paramsTransactionDoctor?.limit +
            index +
            1
          );
        }
      },
    },
    {
      title: 'Mã',
      key: '2',
      render: (value, row, index) => {
        return value?.identifier;
      },
    },
    {
      title: 'Họ và tên',
      key: '3',
      render: (value, row, index) => {
        const data = row?.person?.display;
        return data;
      },
    },
    {
      title: 'Username',
      key: '4',
      render: (value, row, index) => {
        const data = row?.person?.wallet?.user?.display;
        return data;
      },
    },
    {
      title: 'Số điện thoại',
      key: '5',
      render: (value, row, index) => {
        return formatPhoneNumber(row?.user?.phoneNumber);
      },
    },
    {
      title: 'Số dư(VND)',
      key: '6',
      render: (value, row, index) => {
        const data = row?.person?.wallet?.balance;
        if (data === 0) {
          return 0;
        }

        return formatMoney(data);
      },
    },
    {
      key: '7',
      render: (value, row, index) => {
        return (
          <ActionTable
            hasDelete={false}
            hasEdit={false}
            handleDetail={() => {
              history.push(
                `/app/doctor/transaction/detail-transaction/${_.get(
                  row,
                  'uuid',
                )}`,
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="doctor-transaction">
      <Card>
        <Row className="pb-30">
          <Col xs={24}>
            <h1>Quản lý giao dịch và số dư tài khoản</h1>
          </Col>
        </Row>

        <Form
          name="basic"
          onFinish={value => getDoctorTransactionSearch(value)}
        >
          <Row className="row-search ">
            <Col xs={16} className="pr-5">
              <Form.Item name="q">
                <Input placeholder="Nhập mã, username, họ tên, số điện thoại..." />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={doctorReducer?.dataDoctor?.results}
            pagination={{
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
              current: paramsTransactionDoctor.startIndex,
              pageSize: paramsTransactionDoctor.limit,
              total: doctorReducer?.dataDoctor?.totalCount || 10,
            }}
            onChange={onChangeTable}
            loading={{
              spinning: doctorReducer?.isLoadingDoctor,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default TransactionOfDoctor;
