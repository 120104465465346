import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Button } from 'antd';
import * as _ from 'lodash';
import './ModalResetPassword.scss';
import { formatPhoneNumber } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { postChangePasswordCustomerRequested } from 'store/customer/customer.action';

interface CProps {
  show: boolean;
  onHide: () => void;
  itemInformation: any;
}

const ModalResetPassword: FC<CProps> = ({ show, itemInformation, onHide }) => {
  const dispatch = useDispatch();
  const onFinish = values => {
    const bodySubmit = {
      userUuid: _.get(
        itemInformation,
        'person.user.uuid',
        itemInformation?.user?.uuid,
      ),
      body: {
        newPassword: values?.newPassword,
      },
    };

    dispatch(postChangePasswordCustomerRequested(bodySubmit));
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title="Thay đổi mật khẩu"
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        requiredMark={false}
        className="modal-reset-information"
      >
        <Form.Item
          label="Số điện thoại"
          initialValue={formatPhoneNumber(
            _.get(itemInformation, 'person.attributes[0].value', null),
          )}
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc.' },
          ]}
          name="phoneNumber"
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Mật khẩu mới"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc.' },
            { min: 6, message: 'Mật khẩu phải có ít nhất 6 kí tự' },
          ]}
          name="newPassword"
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Xác nhận mật khẩu mới"
          name="confirm"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thông tin bắt buộc.',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(
                  'Mật khẩu mới và Mật khẩu xác nhận không giống nhau.',
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div className="text-center">
          Lưu ý: Mật khẩu mới có chứa ít nhất 6 ký tự
        </div>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalResetPassword;
