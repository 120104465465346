import React, { FC, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Button } from 'antd';
// import InputMask from 'react-input-mask';
import { useDispatch } from 'react-redux';
// import { get } from 'lodash';
// import { TransactionModel } from 'models/store/customer.model';
// import { regextTrimspace } from '../../containers/Doctor/doctor.const';
import './ModalEditSpecialist.scss';
import { regextTrimspace } from 'containers/Doctor/doctor.const';
import {
  handleAddSpecialistRequested,
  handleEditSpecialistRequested,
} from '../../../../store/customSetting/customSetting.action';
import { ParamsPostSpecialist } from '../../modal';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  params?: ParamsPostSpecialist;
  onRefresh: () => void;
}

const ModalEditSpecialist: FC<CProps> = ({
  show,
  title,
  onHide,
  params,
  onRefresh,
}) => {
  // const dispatch = useDispatch();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const onFinish = val => {
    const dataPush = {
      names: [
        {
          name: val.specialistName,
          locale: 'vi',
        },
      ],
      datatype: '8d4a4c94-c2cc-11de-8d13-0010c6dffd0f',
      conceptClass: 'df8bfce1-e51b-4e53-ae8f-81d4681a30a6',
    };
    if (title === 'Thêm mới chuyên khoa') {
      dispatch(
        handleAddSpecialistRequested({
          data: dataPush,
          handleSuccess: () => onRefresh(),
        }),
      );
      onHide();
    } else {
      const paramsPost = {
        url: params,
        data: { name: val.specialistName },
        handleSuccess: () => onRefresh(),
      };
      dispatch(handleEditSpecialistRequested(paramsPost));
      onHide();
    }
  };
  useEffect(() => {
    form.setFieldsValue({ specialistName: params?.name ?? '' });
  }, [params]);

  return (
    <Modal
      title={title}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={850}
      footer={null}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        requiredMark={false}
        initialValues={{
          specialistName: params?.name || '',
        }}
        form={form}
      >
        <Form.Item
          label="Tên chuyên khoa"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
            {
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
            { max: 100, message: 'Cho phép nhập tối đa 100 kí tự!' },
          ]}
          name="specialistName"
        >
          <Input placeholder={'Nhập tên chuyên khoa'} />
        </Form.Item>

        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditSpecialist;
