import { HightestVersion, VersionModel } from 'models/store/version.model';
import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';

export const {
  getVersionRequested,
  getVersionSuccessed,
  getVersionFailed,

  getVersionSettingsRequested,
  getVersionSettingsSuccessed,
  getVersionSettingsFailed,

  getHightestVersionDoctorRequested,
  getHightestVersionDoctorSuccess,
  getHightestVersionDoctorFailed,

  getHightestVersionPatientRequested,
  getHightestVersionPatientSuccess,
  getHightestVersionPatientFailed,

  postUpdateVersionSettingsRequested,
  postUpdateVersionSettingsSuccessed,
  postUpdateVersionSettingsFailed,

  postAddVersionRequested,
  postAddVersionSuccessed,
  postAddVersionFailed,

  handleDeleteVersionRequested,
  handleDeleteVersionSuccessed,
  handleDeleteVersionFailed,
} = createActions(
  {
    GET_HIGHTEST_VERSION_DOCTOR_REQUESTED: (payload: HightestVersion) =>
      payload,
    GET_HIGHTEST_VERSION_DOCTOR_SUCCESS: (payload: HightestVersion) => payload,
    GET_HIGHTEST_VERSION_DOCTOR_FAILED: (error: Error) => error,

    GET_HIGHTEST_VERSION_PATIENT_REQUESTED: (payload: HightestVersion) =>
      payload,
    GET_HIGHTEST_VERSION_PATIENT_SUCCESS: (payload: HightestVersion) => payload,
    GET_HIGHTEST_VERSION_PATIENT_FAILED: (error: Error) => error,

    GET_VERSION_REQUESTED: (payload: QueryString) => payload,
    GET_VERSION_SUCCESSED: (payload: VersionModel) => payload,
    GET_VERSION_FAILED: (error: Error) => error,

    GET_VERSION_SETTINGS_REQUESTED: (payload: any) => payload,
    GET_VERSION_SETTINGS_SUCCESSED: (payload: any) => payload,
    GET_VERSION_SETTINGS_FAILED: (error: Error) => error,

    POST_UPDATE_VERSION_SETTINGS_REQUESTED: (payload: any) => payload,
    POST_UPDATE_VERSION_SETTINGS_SUCCESSED: (payload: any) => payload,
    POST_UPDATE_VERSION_SETTINGS_FAILED: (error: Error) => error,

    POST_ADD_VERSION_REQUESTED: (payload: any, isSuccess: () => void) =>
      payload,
    POST_ADD_VERSION_SUCCESSED: (payload: any) => payload,
    POST_ADD_VERSION_FAILED: (error: any) => error,

    HANDLE_DELETE_VERSION_REQUESTED: (payload: any) => payload,
    HANDLE_DELETE_VERSION_SUCCESSED: (payload: any) => payload,
    HANDLE_DELETE_VERSION_FAILED: (error: any) => error,
  },
  {
    prefix: 'version',
  },
);
