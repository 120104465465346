import React, { useState, FC, useEffect } from 'react';
import {
  Modal,
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  InputNumber,
} from 'antd';
import { TransactionModel } from 'models/store/customer.model';
import * as _ from 'lodash';
import {
  OPTIONS_TRANSACTION,
  OPTIONS_FORM_TRANSACTION,
  TYPE_TRANSACTION_VALUE,
} from 'containers/Customer/customer.const';
import './ModalEditCustomer.scss';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleDisplayModalEditTransaction,
  postEditTransactionDetailRequested,
} from '../../store/customer/customer.action';
import {
  regextTrimspace,
  regexConvertSearch1,
} from '../../containers/Doctor/doctor.const';

interface CProps {
  show: boolean;
  onHide: () => void;
  itemTransaction: TransactionModel;
  customerCode: string;
  phone: string;
  dataProps: any;
}

const ModalEditCustomer: FC<CProps> = ({
  show,
  onHide,
  itemTransaction,
  customerCode,
  phone,
  dataProps,
}) => {
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const dispatch = useDispatch();
  const [visible] = useState(show);
  const [initForm, setInitForm] = useState<any>({
    transactionType:
      itemTransaction?.transactionType ===
      TYPE_TRANSACTION_VALUE.PATIENT_BOOK_APPOINTMENT
        ? TYPE_TRANSACTION_VALUE.ADMIN_DEDUCT
        : itemTransaction?.transactionType,
    amount: itemTransaction?.amount,
    transactionCode: itemTransaction?.transactionCode,
    note: itemTransaction?.note,
    appointment: _.get(itemTransaction, 'appointment.appointmentCode', ''),
    form1: itemTransaction?.form,
  });

  const ChangeTransactionType = e => {
    setInitForm({
      ...initForm,
      transactionType: e.target.value,
    });
  };

  const errorEdittransaction = useSelector(
    (state: any) => state?.customerReducer?.errorTransaction,
  );

  useEffect(() => {
    form.setFieldsValue(initForm);
  }, [form]);

  const onFinish = val => {
    const { amount, form1, note, changeReason, transactionCode } = val;
    const body = {
      uuid: itemTransaction?.uuid,
      data: {
        amount,
        form: form1,
        transactionCode: transactionCode || '',
        note: note || '',
        changeReason:
          changeReason !== undefined
            ? changeReason?.replace(regexConvertSearch1, '')
            : '',
      },
    };
    if (!body?.data?.transactionCode) {
      delete body?.data?.transactionCode;
    }
    dispatch(postEditTransactionDetailRequested(body));
  };

  if (errorEdittransaction === false) {
    onHide();
    dispatch(handleDisplayModalEditTransaction());
  }

  return (
    <Modal
      title="Chỉnh sửa giao dịch"
      centered
      visible={visible}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="basic"
        onFinish={onFinish}
        initialValues={initForm}
        className="modal-detail-customer"
        requiredMark={false}
      >
        <Form.Item label="Loại giao dịch" name="transactionType">
          <Radio.Group disabled onChange={ChangeTransactionType}>
            {OPTIONS_TRANSACTION.map((item: any) => {
              return (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>
        <Row gutter={[10, 24]} className=" mb-6">
          <Col xs={24}>
            <Row>
              <Col xs={8}>Mã khách hàng</Col>
              <Col xs={12}>{customerCode}</Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={8}>Số điện thoại</Col>
              <Col xs={12}>{phone}</Col>
            </Row>
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={8}>Họ và tên KH:</Col>
              <Col xs={12}>{dataProps?.userName}</Col>
            </Row>
          </Col>
        </Row>

        {initForm?.transactionType === TYPE_TRANSACTION_VALUE.ADMIN_ADD ? (
          <>
            <Form.Item label="Mã giao dịch" name="transactionCode">
              <Input />
            </Form.Item>
            <Form.Item label="Nội dung chuyển khoản" name="note">
              <TextArea />
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item label="Lý do" name="note">
              <TextArea maxLength={300} value={itemTransaction?.note} />
            </Form.Item>
          </>
        )}
        <div className="flex fee">
          <Form.Item
            labelAlign="left"
            label="Số tiền"
            name="amount"
            className="form-item fee-test"
            rules={[
              { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
              ({ getFieldValue }) => ({
                validator() {
                  const amount = `${getFieldValue('amount')}`;
                  if (
                    (amount && amount.trim()?.length && Number(amount) < 1) ||
                    isNaN(Number(amount))
                  ) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Số tiền không hợp lệ!');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="input-type"
              maxLength={11}
            />
          </Form.Item>
          <span className="title-money">VND</span>
        </div>
        {itemTransaction?.previousVersion !== null ? (
          <Form.Item label="Hình thức" name="form1">
            <Select disabled>
              {OPTIONS_FORM_TRANSACTION.map((item: any) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        ) : (
          <Form.Item label="Hình thức" name="form1">
            <Select>
              {OPTIONS_FORM_TRANSACTION.map((item: any) => {
                return (
                  <Select.Option key={item.value} value={item.value}>
                    {item.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          label="Lý do chỉnh sửa"
          name="changeReason"
          rules={[
            {
              pattern: regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Input />
        </Form.Item>

        <div className="button-container">
          <>
            <Button className="mr-2" type="default" onClick={onHide}>
              Đóng
            </Button>
            <Button className="ml-2" type="primary" htmlType="submit">
              Lưu
            </Button>
          </>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditCustomer;
