import React, { FC } from 'react';
import { Row } from 'antd';

import Allergy from './Allergy';
import DrugInUse from './DrugInUse';
import Chronic from './Chronic';
import Beer from './Beer';
import Cigratte from './Cigratte';
import HistoryOfFamily from './HistoryOfFamily';
import './Anamnesis.scss';

const Anamnesis: FC<any> = props => {
  const { dataStore, data, onGetAppointmentDetail, isEditAvailability } = props;

  return (
    <div className="anamnesis-Contaier">
      <div className="anamnesis-content">
        <Row className="anamnesis-content__title">
          <span className="pl-6">Tiền sử bệnh</span>
        </Row>
        <hr />
        <div>
          <Allergy
            initValues={dataStore?.allergy}
            dataProps={data}
            isEditAvailability={isEditAvailability}
          />
          <hr />
          <Chronic dataProps={data} isEditAvailability={isEditAvailability} />
          <hr />
          <Beer
            dataProps={data}
            initValues={dataStore?.alcohol}
            onGetAppointmentDetail={onGetAppointmentDetail}
            isEditAvailability={isEditAvailability}
          />
          <hr />
          <Cigratte
            dataProps={data}
            initValues={dataStore?.cigarette}
            onGetAppointmentDetail={onGetAppointmentDetail}
            isEditAvailability={isEditAvailability}
          />
          <hr />
          <HistoryOfFamily
            dataProps={data}
            initValues={dataStore?.familyHistory}
            isEditAvailability={isEditAvailability}
          />
          <DrugInUse
            initValues={dataStore?.drugsPresent}
            dataProps={data}
            isEditAvailability={isEditAvailability}
          />
        </div>
      </div>
    </div>
  );
};

export default Anamnesis;
