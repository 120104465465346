import React, { FC, useMemo } from 'react';
import { Modal, Select, Form, Button, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postUpdateStatusAppointmentRequested } from 'store/appointment/appointment.actions';
import './ModalChangeStatusAppointment.scss';

const ModalChangeStatusAppointment: FC<any> = ({
  visible,
  handleCancelModal,
  data,
}) => {
  const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
  };
  const [form] = Form.useForm();
  const { Option } = Select;
  const dispatch = useDispatch();
  const appointmentReducer = useSelector((state: any) => state.appointment);

  const statusChangeList = useMemo(() => {
    switch (data?.status) {
      case 'Sắp diễn ra':
        return [
          { display: 'Đang diễn ra', value: 'INCONSULTATION' },
          { display: 'Hủy cuộc hẹn  ', value: 'CANCELLED' },
        ];
      case 'Trễ hẹn':
        return [
          { display: 'Đang diễn ra', value: 'INCONSULTATION' },
          { display: 'Hủy cuộc hẹn  ', value: 'CANCELLED' },
        ];
      case 'Đang diễn ra':
        return [{ display: 'Hủy cuộc hẹn', value: 'CANCELLED' }];
      case 'Hoàn thành':
        return [{ display: 'Đang diễn ra', value: 'INCONSULTATION' }];
    }
  }, [data]);

  const onFinish = values => {
    const payload = {
      uuid: data !== undefined && data?.appointmentId,
      body: {
        status: values.status,
        reasonChangeStatus: values.reasonChangeStatus.trim(),
      },
    };

    dispatch(postUpdateStatusAppointmentRequested(payload));
    if (!appointmentReducer.isLoadingUpdateStatusAppointment) {
      handleCancelModal();
    }
  };

  return (
    <Modal
      title={data?.appointmentCode}
      centered
      visible={visible}
      onOk={handleCancelModal}
      onCancel={handleCancelModal}
      width={650}
      footer={null}
    >
      <div className=" font-semibold text-sm h-8 flex items-center mb-2">
        <span className="pr-1"> Tên người khám: </span>
        <span className=" font-medium">{data?.patientDisplay}</span>
      </div>
      <div className=" font-semibold text-sm h-8 flex items-center mb-2">
        <span className="pr-1"> Bác sĩ phụ trách: </span>
        <span className=" font-medium">{data?.providerDisplay}</span>
      </div>
      <div className=" font-semibold text-sm h-8 flex items-center mb-2">
        <span className="pr-1"> Giờ tư vấn: </span>
        <span className=" font-medium">{data?.timeSlot}</span>
      </div>
      <div className=" font-semibold text-sm h-8 flex items-center mb-2">
        <span className="pr-1"> Trạng thái hiện tại: </span>
        <span className=" font-medium">{data?.status}</span>
      </div>
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        className="modal-change-status"
        form={form}
        requiredMark={false}
      >
        <div className="flex flex-col">
          <Form.Item
            label="Trạng thái cuộc hẹn mới: "
            name="status"
            className=""
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thông tin bắt buộc!',
              },
            ]}
          >
            <Select className=" cancel-reason" placeholder="Chọn trạng thái">
              {statusChangeList?.map(item => (
                <Option key={String(item.value)} value={item.value}>
                  {item.display}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Lý do: "
            name="reasonChangeStatus"
            className=""
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thông tin bắt buộc!',
              },
              {
                max: 1024,
                message: 'Vui lòng không nhập quá 1024 ký tự',
              },
              {
                whitespace: true,
                message: 'Vui lòng nhập thông tin bắt buộc!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className=" flex justify-around mt-8">
            <Button
              type="default"
              className=" w-24"
              onClick={handleCancelModal}
            >
              Huỷ
            </Button>
            <Button
              type="primary"
              className=" w-24"
              htmlType="submit"
              loading={appointmentReducer?.isLoadingUpdateStatusAppointment}
            >
              Lưu
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalChangeStatusAppointment;
