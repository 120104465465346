import React, { FC, useState, useEffect } from 'react';
import { Modal, Table, Spin } from 'antd';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import StoreState from 'models/store';
import { formatMoney, formatDateVN } from 'helpers/utils';
import { TYPE_TRANSACTION_VALUE } from 'containers/Customer/customer.const';
import { getPatientBookAppointmentRequested } from 'store/customer/customer.action';
import './ModalDetailHistory.scss';

interface CProps {
  show: boolean;
  onHide: () => void;
  user: string;
}

const ModalDetailHistory: FC<CProps> = ({ show, onHide, user }) => {
  const dispatch = useDispatch();
  const [visible] = useState(show);
  const [paramsPatientBook, setParamsPatientBook] = useState({
    user: '',
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
    types: [TYPE_TRANSACTION_VALUE.PATIENT_BOOK_APPOINTMENT],
  });

  const customerReducer = useSelector(
    (state: StoreState) => state.customerReducer,
  );

  useEffect(() => {
    const body = {
      ...paramsPatientBook,
      user,
      forms: ['TRANSFER', 'WALLET_D4U'],
    };

    dispatch(getPatientBookAppointmentRequested(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsPatientBook]);

  const onChangePageTable = (pagination: any) => {
    const { current, pageSize } = pagination;

    setParamsPatientBook({
      ...paramsPatientBook,
      startIndex: current,
      limit: pageSize,
    });
  };

  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value, row, index) => {
        return (paramsPatientBook.startIndex - 1) * 10 + index + 1;
      },
    },
    {
      title: 'Thời gian',
      render: value => {
        return formatDateVN(_.get(value, 'auditInfo.dateCreated', false));
      },
    },
    {
      title: 'Mã cuộc tư vấn',
      render: value => {
        return _.get(value, 'appointment.appointmentCode', '');
      },
    },
    {
      title: 'Bác sĩ tư vấn',
      render: value => {
        return _.get(
          value,
          'appointment.timeSlot.appointmentBlock.provider.display',
          '',
        );
      },
    },
    {
      title: 'Họ tên người khám',
      render: value => {
        return _.get(value, 'appointment.patient.person.display', '');
      },
    },
    {
      title: 'Số tiền(VND)',
      render: value => {
        return formatMoney(_.get(value, 'realPayment', 0));
      },
    },
    {
      title: 'Người thanh toán',
      render: value => {
        return _.get(value, 'auditInfo.creator.person.display', '');
      },
    },
  ];
  return (
    <Modal
      title="Lịch sử thanh toán"
      centered
      visible={visible}
      onOk={onHide}
      onCancel={onHide}
      width="100%"
      style={{ maxWidth: '1100px' }}
      footer={null}
    >
      <Table
        style={{ marginBottom: 20 }}
        rowKey={record => Number(record.transactionCode)}
        pagination={{
          current: paramsPatientBook.startIndex,
          pageSize: paramsPatientBook.limit,
          total: customerReducer?.dataPatientBookAppointment?.totalCount || 10,
          showSizeChanger: true,
          locale: { items_per_page: '/Trang' },
        }}
        loading={{
          spinning: customerReducer?.isLoadingPatientBookAppointment,
          indicator: <Spin />,
          tip: 'Đang tải...',
        }}
        dataSource={customerReducer?.dataPatientBookAppointment?.results}
        columns={columns}
        onChange={onChangePageTable}
      />
    </Modal>
  );
};

export default ModalDetailHistory;
