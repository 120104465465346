export interface ApiListModel {
  data: Array<any>;
  isLoading: boolean;
  selectedData: {} | null;
}

export const initModel: ApiListModel = {
  data: [],
  isLoading: false,
  selectedData: null,
};
