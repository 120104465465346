import { Row } from 'antd';
import React, { FC } from 'react';
import classes from './CancelReason.module.scss';

interface Props {
  reason: string;
}

const CancelReason: FC<Props> = ({ reason }) => {
  return (
    <div>
      <div className={classes.titleContainer}>
        <Row>
          <span className="pl-6">Lý do hủy</span>
        </Row>
      </div>
      <hr />
      <br />
      <div className={classes.contentContainer}>
        <div className={classes.reason}>{reason}</div>
      </div>
    </div>
  );
};

export default CancelReason;
