import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Radio, Button, Select, DatePicker } from 'antd';
import InputMask from 'react-input-mask';
// import * as _ from 'lodash';
import { TransactionModel } from 'models/store/customer.model';
import './ModalViewInformation.scss';
// import { OPTIONS_LIST_STATUS_CUSTOMER } from 'containers/Customer/customer.const';
// import { formatPhoneNumber } from 'helpers/utils';
import moment from 'moment';

interface CProps {
  show: boolean;
  onHide: () => void;
  itemInformation: TransactionModel;
}
interface initForm {
  status?: string;
  givenName?: string;
  phoneNumber?: number | string;
  email?: string;
  oganization?: string;
  gender: string | undefined;
  birthdate?: any;
  province?: string;
  district?: string;
  addresses?: string;
  job?: string;
  infoFamily?: any;
  insurance?: string;
  codeContract?: string;
}
const { Option } = Select;
const ModalViewInformation: FC<CProps> = ({
  show,
  itemInformation,
  onHide,
}) => {
  const onFinish = event => {
    return event;
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const patientAttributesObj = {
    job: '',
    infoFamily: {
      name: '',
      telephone: '',
      relationship: '',
    },
    insurance: '',
    codeContract: '',
  };
  if (itemInformation.patientAttributes) {
    for (let i = 0; i < itemInformation.patientAttributes.length; i++) {
      if (
        itemInformation.patientAttributes[i].display == 'Family Contact Name'
      ) {
        patientAttributesObj.infoFamily.name =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (
        itemInformation.patientAttributes[i].display ==
        'Family Contact Telephone Number'
      ) {
        patientAttributesObj.infoFamily.telephone =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (
        itemInformation.patientAttributes[i].display ==
        'Family Contact Relationship'
      ) {
        patientAttributesObj.infoFamily.relationship =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (itemInformation.patientAttributes[i].display == 'Career') {
        patientAttributesObj.job =
          itemInformation.patientAttributes[i].valueReference;
      }

      if (itemInformation.patientAttributes[i].display == 'Insurance company') {
        patientAttributesObj.insurance =
          itemInformation.patientAttributes[i]?.valueReference?.display;
      }

      if (itemInformation.patientAttributes[i].display == 'Patient Insurance') {
        patientAttributesObj.codeContract =
          itemInformation.patientAttributes[i].valueReference;
      }
    }
  }

  const initForm: initForm = {
    status: itemInformation?.user?.status,
    givenName: itemInformation?.person?.preferredName?.display,
    phoneNumber: itemInformation?.user?.display,
    email: itemInformation?.user?.email,
    oganization: '',
    gender:
      itemInformation?.person?.gender === 'NON'
        ? ''
        : itemInformation?.person?.gender,
    birthdate: itemInformation?.person?.birthdate
      ? moment(itemInformation?.person?.birthdate)
      : null,
    province: itemInformation?.person?.preferredAddress?.cityVillage,
    district: itemInformation?.person?.preferredAddress?.stateProvince,
    addresses: itemInformation?.person?.preferredAddress?.address1,
    job: patientAttributesObj.job,
    infoFamily: [
      {
        name: patientAttributesObj.infoFamily.name,
        telephone: patientAttributesObj.infoFamily.telephone,
        relationship: patientAttributesObj.infoFamily.relationship,
      },
    ],

    insurance: patientAttributesObj.insurance,
    codeContract: patientAttributesObj.codeContract,
  };
  return (
    <Modal
      title="Xem thông tin khách hàng"
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initForm}
        requiredMark={false}
      >
        <Form.Item label="Trạng thái" name="status">
          <Radio.Group>
            <Radio value="ACTIVE" disabled={true}>
              Hoạt động
            </Radio>
            <Radio value="IN_ACTIVE" disabled={true}>
              Khóa
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Họ và tên" name="givenName">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Ngày sinh" name="birthdate">
          <DatePicker placeholder={''} format={'DD-MM-YYYY'} disabled />
        </Form.Item>

        <Form.Item label="Giới tính" name="gender">
          <Select disabled>
            <Option value={'M'}>Nam</Option>
            <Option value={'F'}>Nữ</Option>
            <Option value={'O'}>Khác</Option>
          </Select>
        </Form.Item>

        {/* ^([\s]*([\d\w]*[\s]*))* */}
        <Form.Item label="Email" name="email">
          <Input type={'email'} disabled />
        </Form.Item>
        <Form.Item label="Số điện thoại" name="phoneNumber">
          <InputMask
            className="ant-input"
            mask="9999 999 999"
            maskChar=""
            disabled
          />
        </Form.Item>

        <Form.Item label="Tỉnh/ Thành phố" name="province">
          <Select disabled>
            <Option value={'tinh'}>tinh</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Quận/ Huyện" name="district">
          <Select disabled>
            <Option value={'ThanhPho'}>ThanhPho</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Địa chỉ cụ thể" name="addresses">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Nghề nghiệp" name="job">
          <Select disabled>
            <Option value={'nghenghiep'}>nghenghiep</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Thông tin người thân">
          <Form.List name="infoFamily">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <div key={field.key} style={{ display: 'inline-flex' }}>
                      <Form.Item
                        name={[index, 'name']}
                        style={{ width: '30%' }}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'telephone']}
                        style={{ width: '30%', marginLeft: '3%' }}
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        name={[index, 'relationship']}
                        style={{ width: '30%', marginLeft: '3%' }}
                      >
                        <Input disabled />
                      </Form.Item>
                    </div>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form.Item>

        <Form.Item label="Công ty bảo hiểm" name="insurance">
          <Select disabled>
            <Option value={'baohiem'}>baohiem</Option>
          </Select>
        </Form.Item>

        <Form.Item label="Mã số hợp đồng/ thẻ" name="codeContract">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Tổ chức" name="oganization">
          <Input disabled />
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalViewInformation;
