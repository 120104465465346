import React, { ComponentType, FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import StoreState from 'models/store';

const withAuthenticate = <P extends any>(
  Component: ComponentType<P>,
): FC<P> => ({ props }: any) => {
  const isAuthenticated = useSelector(
    (state: StoreState) => !!state.authenticate.cookies,
  );
  if (isAuthenticated) {
    return <Component {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/auth/login',
      }}
    />
  );
};

export default withAuthenticate;
