enum Message {
  CREATE_SUCCESS = 'Thêm mới bác sỹ thành công!',
  UPDATE_SUCCESS = 'Chỉnh sửa thông tin bác sỹ thành công!',
  DELETE_SUCCESS = 'Xóa thông tin bác sỹ thành công !',
  RESET_SUCCESS = 'Thay đổi mật khẩu thành công!',
  CHECKOUT_SUCCESS = 'Thanh toán thành công!',
  DELETE_DOCTOR_SUCCESS = 'Xóa thông tin bác sỹ thành công !',
  CREATE_SERVICE_PACKAGE_SUCCESS = 'Thêm mới gói khám dịch vụ thành công!',
  UPDATE_SERVICE_PACKAGE_SUCCESS = 'Chỉnh sửa thông tin gói khám dịch vụ thành công!',
  DELETE_SERVICE_PACKAGE_SUCCESS = 'Xóa thông tin gói khám dịch vụ thành công !',
  CREATE_HOLIDAY_SUCCESS = 'Thêm mới kỳ nghỉ thành công!',
  UPDATE_HOLIDAY_SUCCESS = 'Chỉnh sửa kỳ nghỉ thành công!',
  DELETE_HOLIDAY_SUCCESS = 'Xóa kỳ nghỉ thành công!',
  UPLOAD_SUCCESS = 'Upload ảnh thành công!',
  CANCEL_APPOINTMENT_SUCCESS = 'Hủy cuộc hẹn thành công',
  DELETE_CUSTOMER_SUCCESS = 'Xóa khách hàng thành công!',
  DELETE_SPECIALIST_SUCCESS = 'Xóa chuyên khoa thành công!',
  CREATE_PROMOTION_SUCCESS = 'Thêm mới khuyến mại thành công!',
  UPDATE_PROMOTION_SUCCESS = 'Chỉnh sửa khuyến mại thành công!',
  LOCKED_PROMOTION_SUCCESS = 'Khóa khuyến mại thành công!',
  UNLOCKED_PROMOTION_SUCCESS = 'Mở khóa khuyến mại thành công!',
  CREATE_REFERRAL_SUCCESS = 'Thêm mới chương trình giới thiệu thành công!',
  UPDATE_REFERRAL_SUCCESS = 'Chỉnh sửa chương trình giới thiệu thành công!',
  LOCKED_REFERRAL_SUCCESS = 'Khóa chương trình giới thiệu thành công!',
  UNLOCKED_REFERRAL_SUCCESS = 'Mở khóa mã giới thiệu thành công!',
  PROMOTION_MARKETING_SUCCESS = 'Thêm thông báo khuyến mại thành công!',
  CONFIG_TIME_APPOINTMENT_SUCCESS = 'Sửa thời gian thành công!',
  CONFIG_TIME_APPOINTMENT_FAIL = 'Thời gian được phép đặt trước cuộc hẹn đang được áp dụng',
  LOCKED_SERVICE_PACKAGE_SUCCESS = 'Khóa gói khám dịch vụ thành công!',
  UNLOCKED_SERVICE_PACKAGE_SUCCESS = 'Mở khóa gói khám dịch vụ thành công!',
}

export default Message;
