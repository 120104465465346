import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreatePromotionMarketingSuccessMessage,
  getMessageFromError,
} from 'helpers/utils';

import * as marketingNoti from './marketingNoti.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

function* handleCreateMarketingNoti(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post('/v1/marketingNotification', action.payload),
    );
    fireCreatePromotionMarketingSuccessMessage();
    yield put(marketingNoti.handleCreateMarketingNotiSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(marketingNoti.handleCreateMarketingNotiFailed(error));
  }
}

export default function* marketingNotiSaga() {
  yield all([
    takeLatest(
      marketingNoti.handleCreateMarketingNotiRequested,
      handleCreateMarketingNoti,
    ),
  ]);
}
