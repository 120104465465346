import { replace, startsWith, has, uniq } from 'lodash';
import * as appointmentConst from 'constants/Appointment';
import { mapAppointmentDetail } from './mapData/mapAppointmentDetail';

export default function mapDetail(data: any) {
  const { person } = data.patient;
  const { provider } = data.timeSlot.appointmentBlock;
  if (data.visit && data.visit.encounters) {
    const medicalExamination = data.visit.encounters[0];
    const pulse = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.PULSE),
    );
    const temperature = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.TEMPERATURE),
    );

    const weight = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.WEIGHT),
    );
    const height = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.HEIGHT),
    );
    const diastolicBloodPressure = medicalExamination?.obs.find((i: any) =>
      startsWith(
        i.display,
        appointmentConst.OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE,
      ),
    );
    const systolicBloodPressure = medicalExamination?.obs.find((i: any) =>
      startsWith(
        i.display,
        appointmentConst.OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE,
      ),
    );
    const respiratoryRate = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.RESPIRATORY_RATE),
    );
    const symptom = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.SYMPTOM),
    );
    const alcohol = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.ALCOHOL),
    );
    const cigarette = medicalExamination?.obs.find((i: any) =>
      startsWith(i.display, appointmentConst.OBS_PREFIX.CIGARETTE),
    );
    const drugOrder = medicalExamination?.orders
      .filter((i: any) => i.type === 'drugorder')
      .map((item: any) => ({
        id: item.uuid,
        description: item.display,
      }));
    const clinicalExamination = medicalExamination?.obs
      .filter((i: any) =>
        startsWith(i.display, appointmentConst.OBS_PREFIX.PHYSICAL_EXAMINATION),
      )
      .map((item: any) => ({
        id: item.uuid,
        description: replace(
          item.display,
          appointmentConst.OBS_PREFIX.PHYSICAL_EXAMINATION,
          '',
        ),
      }));
    const instructions = medicalExamination?.obs
      .filter((i: any) =>
        startsWith(i.display, appointmentConst.OBS_PREFIX.INSTRUCTION),
      )
      .map((item: any) => ({
        id: item.uuid,
        description: replace(
          item.display,
          appointmentConst.OBS_PREFIX.INSTRUCTION,
          '',
        ),
      }));
    const requests = medicalExamination?.orders
      .filter((i: any) => i.type === 'testorder')
      .map((item: any) => ({
        id: item.uuid,
        description: item.display,
        instructions: item.instructions,
        date: item.dateActivated,
      }));
    const diagnostic: Array<any> = [];
    data.visit.encounters.forEach((encounter: any) => {
      encounter.diagnoses.forEach((item: any) => {
        diagnostic.push({
          id: item.uuid,
          description: item.display,
          title:
            item.certainty === 'CONFIRMED'
              ? 'Chẩn đoán xác định'
              : 'Chẩn đoán sơ bộ',
          status: item.certainty,
          diagnosis: item.diagnosis,
        });
      });
    });

    const carrerData = data?.patient?.patientAttributes?.filter(item =>
      item?.display?.includes('Career'),
    );

    const carrerDisplay =
      carrerData !== undefined && carrerData[0]?.valueReference;

    const arrPhone =
      person?.attributes?.length > 0
        ? person?.attributes?.filter(item =>
            item?.display?.includes('Telephone Number'),
          )
        : [''];

    return {
      patient: {
        avatar: person.avatar,
        name: person.display,
        age: person.age,
        gender:
          // eslint-disable-next-line no-nested-ternary
          person?.gender === 'M'
            ? 'Name'
            : person?.gender === 'F'
            ? 'Nữ'
            : null,
        uuid: person.uuid,
        city: person.addresses[0] && person.addresses[0].display,
        carrer: carrerDisplay,
        weight: {
          value: weight
            ? Number(
                replace(weight.display, appointmentConst.OBS_PREFIX.WEIGHT, ''),
              )
            : '',
          uuid: weight ? weight.uuid : '',
        },
        height: height
          ? Number(
              replace(height.display, appointmentConst.OBS_PREFIX.HEIGHT, ''),
            )
          : '',
      },
      dataCloned: mapAppointmentDetail(data),
      provider: {
        avatar: provider.avatar,
        name: provider.person.display,
        age: provider.person.age,
        gender:
          person.gender !== 'Nam' || person.gender !== 'Nữ'
            ? 'Khác'
            : person.gender,
        uuid: provider.uuid,
        attributes: has(provider, 'attributes')
          ? uniq(provider.attributes.map(item => item.value.display))
          : [],
        allProvider: provider,
      },
      uuidPerson: person?.uuid,
      uuidAppointment: data?.uuid,
      arrPetient: data?.patient,
      phonePatient: arrPhone?.length > 0 ? arrPhone[0]?.value : '',
      detail: {
        askDisease: {
          symptom: symptom
            ? replace(symptom.display, appointmentConst.OBS_PREFIX.SYMPTOM, '')
            : '',
          history: '',
          familyHistory: '',
          chronicDiseases: '',
          alcohol: alcohol
            ? replace(alcohol.display, appointmentConst.OBS_PREFIX.ALCOHOL, '')
            : '',
          cigarette: cigarette
            ? replace(
                cigarette.display,
                appointmentConst.OBS_PREFIX.CIGARETTE,
                '',
              )
            : '',
          allergy: '',
        },
        medicalExamination: {
          pulse: {
            value: pulse
              ? Number(
                  replace(pulse.display, appointmentConst.OBS_PREFIX.PULSE, ''),
                )
              : '',
            uuid: pulse ? pulse.uuid : '',
          },
          temperature: {
            value: temperature
              ? Number(
                  replace(
                    temperature.display,
                    appointmentConst.OBS_PREFIX.TEMPERATURE,
                    '',
                  ),
                )
              : '',
            uuid: temperature ? temperature.uuid : '',
          },
          bloodPressure: `${
            systolicBloodPressure
              ? Number(
                  replace(
                    systolicBloodPressure.display,
                    appointmentConst.OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE,
                    '',
                  ),
                )
              : ''
          }/${
            diastolicBloodPressure
              ? {
                  value: Number(
                    replace(
                      diastolicBloodPressure.display,
                      appointmentConst.OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE,
                      '',
                    ),
                  ),
                  uuid: diastolicBloodPressure.uuid,
                }
              : ''
          }`,
          breathing: {
            value: respiratoryRate
              ? Number(
                  replace(
                    respiratoryRate.display,
                    appointmentConst.OBS_PREFIX.RESPIRATORY_RATE,
                    '',
                  ),
                )
              : '',
            uuid: respiratoryRate ? respiratoryRate.uuid : '',
          },
          weight: {
            value: weight
              ? Number(
                  replace(
                    weight.display,
                    appointmentConst.OBS_PREFIX.WEIGHT,
                    '',
                  ),
                )
              : '',
            uuid: weight ? weight.uuid : '',
          },
          height: height
            ? Number(
                replace(height.display, appointmentConst.OBS_PREFIX.HEIGHT, ''),
              )
            : '',
          diastolicBloodPressure: {
            value: diastolicBloodPressure
              ? Number(
                  replace(
                    diastolicBloodPressure.display,
                    appointmentConst.OBS_PREFIX.DIASTOLIC_BLOOD_PRESSURE,
                    '',
                  ),
                )
              : null,
            uuid: diastolicBloodPressure ? diastolicBloodPressure.uuid : '',
          },
          systolicBloodPressure: {
            value: systolicBloodPressure
              ? Number(
                  replace(
                    systolicBloodPressure.display,
                    appointmentConst.OBS_PREFIX.SYSTOLIC_BLOOD_PRESSURE,
                    '',
                  ),
                )
              : null,
            uuid: systolicBloodPressure ? systolicBloodPressure.uuid : '',
          },
          clinicalExamination: clinicalExamination || [],
          instructions: instructions || [],
        },
        diagnostic: diagnostic || [],
        drugOrder: drugOrder || [],
        uuid: data.uuid,
        followups: data.followups || [],
        appointmentCode: data.appointmentCode,
        reason: data.reason,
        reasonDetail: data.reasonDetail,
        status: data.status.code,
        startDate: data.timeSlot.startDate,
        requests,
      },
      encounters: data.visit.encounters,
    };
  }

  return {
    patient: {
      avatar: person.avatar,
      name: person.display,
      age: person.age,
      gender: person.gender === 'F' ? 'Nữ' : 'Nam',
      city: person.addresses[0] && person.addresses[0].cityVillage,
      weight: {
        value: '',
      },
      height: {
        value: '',
      },
    },
    provider: {
      avatar: provider.avatar,
      name: provider.person.display,
      age: provider.person.age,
      gender: provider.person.gender === 'F' ? 'Nữ' : 'Nam',
      uuid: provider.uuid,
      attributes: has(provider, 'attributes')
        ? uniq(provider.attributes.map(item => item.value.display))
        : [],
    },
    detail: {
      askDisease: {
        symptom: '',
        history: '',
        familyHistory: '',
        chronicDiseases: '',
        alcohol: '',
        cigarette: '',
        allergy: '',
      },
      medicalExamination: {
        pulse: {
          value: '',
        },
        temperature: {
          value: '',
        },
        bloodPressure: {
          value: '',
        },
        breathing: {
          value: '',
        },
        weight: {
          value: '',
        },
        height: {
          value: '',
        },
        diastolicBloodPressure: {
          value: '',
        },
        systolicBloodPressure: {
          value: '',
        },
      },
      diagnostic: [],
      drugOrder: [],
      appointmentCode: data.appointmentCode,
      uuid: data.uuid,
      reason: data.reason,
      status: data.status.code,
      startDate: data.timeSlot.startDate,
    },
  };
}
