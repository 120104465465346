import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
// import { PromotionModel } from 'models/store/promotion.model';
import { DoctorModel } from 'models/store/doctor.model';
import { ResponseSpecialist } from 'models/store/specialist.model';

interface ParamsDeleteSpecialist {
  id: string;
  handleSuccess?: () => void;
}
interface ParamsAddSpecialist {
  data: string;
  handleSuccess?: () => void;
}
interface ParamsEditSpecialist {
  data: string;
  handleSuccess?: () => void;
  url: string;
}

export const {
  handleCustomSettingRequested,
  handleCustomSettingSucceeded,
  handleCustomSettingFailed,
  handleGetTimeCustomsRequested,
  handleGetTimeCustomsSucceeded,
  handleGetTimeCustomsFailed,
  getSpecialistCustomsRequested,
  getSpecialistCustomsSucceeded,
  getSpecialistCustomsFailed,
  handleDeleteSpecialistRequested,
  handleDeleteSpecialistSucceeded,
  handleDeleteSpecialistFailed,
  handleAddSpecialistRequested,
  handleAddSpecialistSucceeded,
  handleAddSpecialistFailed,
  handleEditSpecialistRequested,
  handleEditSpecialistSucceeded,
  handleEditSpecialistFailed,
} = createActions(
  {
    HANDLE_CUSTOM_SETTING_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CUSTOM_SETTING_SUCCEEDED: (payload: any) => payload,
    HANDLE_CUSTOM_SETTING_FAILED: (error: any) => error,

    HANDLE_GET_TIME_CUSTOMS_REQUESTED: (payload: QueryString) => payload,
    HANDLE_GET_TIME_CUSTOMS_SUCCEEDED: (payload: any) => payload,
    HANDLE_GET_TIME_CUSTOMS_FAILED: (error: any) => error,
    GET_SPECIALIST_CUSTOMS_REQUESTED: (payload: QueryString) => payload,
    GET_SPECIALIST_CUSTOMS_SUCCEEDED: (payload: DoctorModel) => payload,
    GET_SPECIALIST_CUSTOMS_FAILED: (error: Error) => error,
    HANDLE_DELETE_SPECIALIST_REQUESTED: (payload: ParamsDeleteSpecialist) =>
      payload,
    HANDLE_DELETE_SPECIALIST_SUCCEEDED: (payload: any) => payload,
    HANDLE_DELETE_SPECIALIST_FAILED: (error: Error) => error,
    HANDLE_ADD_SPECIALIST_REQUESTED: (payload: ParamsAddSpecialist) => payload,
    HANDLE_ADD_SPECIALIST_SUCCEEDED: (payload: ResponseSpecialist) => payload,
    HANDLE_ADD_SPECIALIST_FAILED: (payload: Error) => payload,
    HANDLE_EDIT_SPECIALIST_REQUESTED: (payload: ParamsEditSpecialist) =>
      payload,
    HANDLE_EDIT_SPECIALIST_SUCCEEDED: (payload: ResponseSpecialist) => payload,
    HANDLE_EDIT_SPECIALIST_FAILED: (payload: Error) => payload,
  },
  {
    prefix: 'customSetting',
  },
);
