import React, { FC, useState } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import StoreState from 'models/store';
import { postAllergyRequested } from 'store/appointment/appointment.actions';
import { formRules } from 'helpers/formUtils';
import { APPOINTMENT_CODE } from '../../../../../constants/Appointment';
import AppointmentCard from '../../AppointmentCard/AppointmentCard';

const Allergy: FC<any> = props => {
  const { dataProps, isEditAvailability } = props;
  // const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  // const [uuidSelected, setUuidSelected] = useState<string>('');
  // const [description, setDescription] = useState<string>('');
  const dispatch = useDispatch();
  const allergies = _.get(dataProps, 'allergy.allergy', []);
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  // const handleEdit = _.debounce((i, v) => {
  //   setDescription(v);
  //   setUuidSelected(i);
  //   setIsEdit(true);
  // }, 50);

  // const handleCancel = _.debounce(key => {
  //   setIsEdit(false);
  // }, 50);

  // const onSave = _.debounce(key => {
  //   setIsEdit(false);
  // }, 50);

  const onFinish = values => {
    const { allergyValue } = values;
    const bodyAllergy = {
      id: dataProps?.detail?.uuidPerson,
      body: {
        allergen: {
          allergenType: 'OTHER',
          codedAllergen: APPOINTMENT_CODE.ALLERGEN,
          nonCodedAllergen: allergyValue.trim(),
        },
      },
    };
    dispatch(postAllergyRequested(bodyAllergy));
    setIsAdd(false);
  };

  // useEffect(() => {
  //   if (isSubmit === true && appointmentReducer?.isAddAllergy === true) {
  //     setIsAdd(false);
  //   }
  // }, [appointmentReducer?.isAddAllergy]);

  return (
    <div className="allery-container">
      {allergies.length ? (
        <AppointmentCard title="Dị ứng">
          <Form>
            {allergies.map((item, key) => {
              return (
                <Row key={item.id} className="row-1">
                  <Col xs={24}>
                    <div className="anamnesis-section">
                      <div className="anamnesis-section__content">
                        <div className="anamnesis-section__content_inside">
                          <span className="text-have-color-#555555">
                            {item?.name}
                          </span>
                          {/* )} */}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Form>
        </AppointmentCard>
      ) : (
        <Row className="row-1">
          <Col xs={24}>
            <div className="anamnesis-section">
              <div className="anamnesis-section__title">
                <div>
                  <span>Dị ứng</span>
                </div>
              </div>
              <div className="no-content">
                <span className="no-content__text">Chưa có thông tin</span>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {isEditAvailability && (
        <div className="anamnesis-add-container">
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="anamnesis-input-add">
                <Form.Item name="allergyValue" rules={formRules}>
                  <Input
                    size="large"
                    placeholder="Thêm thông tin dị ứng"
                    autoFocus
                  />
                </Form.Item>
              </div>
              <div className="anamnesis-action-add">
                <div className="btn-ok">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={appointmentReducer?.isLoadingAddAllergy}
                  >
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(!isAdd)}
                  >
                    <span>Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => setIsAdd(!isAdd)}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Allergy;
