import React, { FC, useMemo, useState } from 'react';
import { Row, Col, Button, Spin } from 'antd';
// import * as _ from 'lodash';
import moment from 'moment';
import Calender from 'assets/images/common/calender.svg';
import { CERTAINTY } from 'constants/Appointment';
import {
  filterYear,
  getDayA,
  removeHyphen,
  // removeYearHyphen,
  removeMonth,
} from '../../Table/table.const';
import { getDateIos } from '../../../../containers/Doctor/doctor.const';
import './HistoryCheck.scss';

interface CProps {
  initValues: any;
  loading?: boolean;
}

const limit = 4;

const HistoryCheck: FC<CProps> = props => {
  const { initValues, loading } = props;
  const [index, setIndex] = useState<any>(1);
  const examinationHistory = useMemo(() => {
    if (initValues) {
      return initValues.slice(0, index);
    }
    return [];
  }, [initValues, index]);

  const handleViewMore = () => {
    setIndex(limit);
  };

  return (
    <div className="historyCheck-Contaier">
      <div className="historyCheck-content">
        <Row className="historyCheck-content__title">
          <span className="pl-6">Lịch sử khám</span>
        </Row>
        <hr />
        <br />
        {loading && (
          <div className="loading" style={{ textAlign: 'center' }}>
            <Spin size="large" tip="Loading..." />
          </div>
        )}
        {examinationHistory &&
          examinationHistory?.map((item, indexKey) => {
            const filterTime =
              item?.startDate !== undefined &&
              item?.startDate?.match(getDateIos);

            const getMonth1 =
              filterTime?.length > 0 && filterTime[0].replace(filterYear, '');

            const getMonth2 = getMonth1 && getMonth1.replace(getDayA, '');

            const getFinalMonth =
              getMonth2 && getMonth2.replace(removeHyphen, '');

            const getYear = filterTime[0]?.match(filterYear);

            const getFinalDay = getMonth1 && getMonth1.replace(removeMonth, '');

            return (
              <Row className="row-1" key={indexKey}>
                <Col xs={24}>
                  <div className="historyCheck-section">
                    <div className="historyCheck-section__title">
                      <span>
                        <img src={Calender} alt="" />
                      </span>
                      <span className="pt-1 pl-2">
                        {getFinalDay} tháng {getFinalMonth}, {getYear[0]} (
                        {moment(item.startDate).fromNow()})
                      </span>
                    </div>
                    <div className="historyCheck-section__content">
                      <div className="historyCheck-section__content_inside">
                        <div className="diagnostic-section__title">
                          <span>
                            {item.diagnoses[0].certainty ===
                            CERTAINTY.PROVISIONAL
                              ? 'Chẩn đoán sơ bộ'
                              : 'Chẩn đoán xác định'}
                          </span>
                        </div>
                        {item.diagnoses[0]?.content}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
      </div>
      {index < limit && initValues?.length > 1 ? (
        <div className="historyCheck-viewmore">
          <Button
            className="historyCheck-viewmore__btn"
            onClick={handleViewMore}
          >
            Xem thêm ({initValues?.length > limit ? limit : initValues?.length}/
            {initValues?.length})
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default HistoryCheck;
