import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreateHolidaySuccessMessage,
  fireUpdateHolidaySuccessMessage,
  fireDeleteHolidaySuccessMessage,
  getMessageFromError,
  cleanKeyNull,
} from 'helpers/utils';
import moment from 'moment';

import * as holidayActions from './holiday.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

let paramsHoliday;
function* getHolidayRequested(action) {
  try {
    cleanKeyNull(action.payload);
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    if (params?.startDate) {
      params = {
        ...params,
        startDate: moment(params?.startDate).format('YYYY-MM-DD'),
      };
    }

    if (params?.endDate) {
      params = {
        ...params,
        endDate: moment(params?.endDate).format('YYYY-MM-DD'),
      };
    }

    if (`${params?.holidayName}`.trim().length === 0) {
      delete params.holidayName;
    }
    paramsHoliday = params;
    const res = yield call(() =>
      authorizedRequest.get('/v1/holiday', { params }),
    );
    yield put(holidayActions.getHolidaySucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(holidayActions.getHolidayFailed(error));
  }
}

function* handleCreateHolidayRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post('/v1/holiday', action.payload),
    );
    fireCreateHolidaySuccessMessage();
    yield put(holidayActions.handleCreateHolidaySucceeded(res));
    yield put(holidayActions.getHolidayRequested(paramsHoliday));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(holidayActions.handleCreateHolidayFailed(error));
  }
}

function* handleUpdateHolidayRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/holiday/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    fireUpdateHolidaySuccessMessage();
    yield put(holidayActions.handleUpdateHolidaySucceeded(res));
    yield put(holidayActions.getHolidayRequested(paramsHoliday));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(holidayActions.handleUpdateHolidayFailed(error));
  }
}

function* handleDeleteHolidayRequested(action) {
  try {
    const res = yield call(() => {
      return authorizedRequest.delete(`/v1/holiday/${action.payload}`);
    });
    yield put(holidayActions.handleDeleteHolidaySucceeded(res));
    fireDeleteHolidaySuccessMessage();
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(holidayActions.handleDeleteHolidayFailed(error));
  }
}

export default function* holidaySaga() {
  yield all([
    takeLatest(holidayActions.getHolidayRequested, getHolidayRequested),
    takeLatest(
      holidayActions.handleCreateHolidayRequested,
      handleCreateHolidayRequested,
    ),
    takeLatest(
      holidayActions.handleUpdateHolidayRequested,
      handleUpdateHolidayRequested,
    ),
    takeLatest(
      holidayActions.handleDeleteHolidayRequested,
      handleDeleteHolidayRequested,
    ),
  ]);
}
