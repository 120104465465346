import React, { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appointmentActions, selectors } from 'store/appointment';
import { Card, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import AppointmentForm from 'components/Appointment/Form';

const AppointmentEdit: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const appointmentId = get(params, 'id');

  useEffect(() => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentId));
  }, [dispatch, appointmentId, params]);

  const appointment = useSelector(state =>
    selectors.getSelectedAppointment(state, appointmentId),
  );

  return (
    <>
      <Card className="p-4">
        <div className="flex justify-center">
          {appointment ? (
            <AppointmentForm
              formId="edit_appointment"
              isEditable={true}
              initValue={appointment}
            />
          ) : (
            <Spin />
          )}
        </div>
      </Card>
    </>
  );
};

export default AppointmentEdit;
