import { CameraFilled } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Button,
  Checkbox,
  Switch,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Upload,
  SelectProps,
  DatePickerProps,
  DatePicker,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import moment from 'moment';
import jq from 'jquery';
import StoreState from 'models/store';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { forEach } from 'lodash';

// import authorizedRequest from 'helpers/request/authorizedRequest';
import {
  ATTRIBUTE_TYPE_PROCESS_PLACE,
  ATTRIBUTE_TYPE_SPECIALIST,
  regextTrimspace,
} from '../servicePackage.const';

import './add.scss';

import * as doctorInfoActions from '../../../store/doctor/doctor.action';
import * as servicePackageActions from '../../../store/servicePackage/servicePackage.action';

const { Option } = Select;

interface ParamsInformationDoctorModel {
  v?: string;
  class?: string;
}

interface ParamsServicePackageModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  isLock?: string | null;
  packageName: string | null;
  packageStatus: string | null;
}

const initialValues = {
  lock: 'ACTIVE',
  specialist: [],
  idsPackageReference: [],
  packageFormatType: 'ON',
  displayInApp: false,
};

const AddServicePackage: FC = type => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
  };

  const specialistReducer = useSelector(
    (state: StoreState) => state.doctorReducer.dataSpecialist.results,
  );

  const [coverData, setCover] = useState(null);
  const [avatarData, setAvatar] = useState(null);
  const [fileAvatar, setFileAvatar] = useState<any>();
  const [fileCover, setFileCover] = useState<any>();
  const [form] = Form.useForm();

  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
      class: 'Specialist',
    },
  );

  const [ParamsServicePackage, setParamsServicePackage] = useState<
    ParamsServicePackageModel
  >({
    isLock: '',
    packageName: '',
    packageStatus: '',
    // v: 'full',
    // startIndex: 1,
    // totalCount: true,
    // limit: 10,
    // sortField: 'DATE_CREATED',
    // sortDirection: 'DESC',
  });

  const getSpecialist = () => {
    dispatch(
      doctorInfoActions.getSpecialistRequested({
        ...ParamsInformationDoctorModel,
      }),
    );
  };

  const getDataServicePackage = () => {
    dispatch(
      servicePackageActions.getServicePackageRequested({
        ...ParamsServicePackage,
      }),
    );
  };

  useEffect(() => {
    getSpecialist();
    getDataServicePackage();
  }, []);

  // const dataAddServicePackage = useSelector(
  //   (state: StoreState) => state.servicePackageReducer.dataAddServicePackage,
  // );

  const servicePackageReducer = useSelector(
    (state: StoreState) => state.servicePackageReducer.dataServicePackage.datas,
  );

  function filterPackageReference(item: any, index: any, array: any) {
    if (
      item.lock != true &&
      moment().isSameOrAfter(moment(item.startDate)) &&
      moment().isSameOrBefore(item.endDate)
    ) {
      return item;
    }
  }

  const { RangePicker } = DatePicker;
  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      [{ align: [] }, { color: [] }, { background: [] }], // dropdown with defaults from theme
      ['clean'],
    ],
  };

  const onFinish = values => {
    const {
      lock,
      thumbnail,
      background,
      packageFormatType,
      name,
      idsPackageReference,
      content,
      termsOfUse,
      originalPrice,
      promotionPrice,
      startEndDate,
      numberOfDays,
      nbMaxOfUse,
      speciality,
      displayInApp,
    } = values;
    const payload = {
      name,
      content,
      termsOfUse,
      originalPrice,
      promotionPrice,
      packageFormatType,
      idsPackageReference: idsPackageReference.join(),
      startDate: `${startEndDate[0].format('YYYY-MM-DD')}T00:00:00+07:00`,
      endDate: `${startEndDate[1].format('YYYY-MM-DD')}T23:59:59+07:00`,
      numberOfDays,
      nbMaxOfUse: packageFormatType == 'ON' ? nbMaxOfUse : 1,
      speciality: JSON.stringify(
        speciality?.map(item => {
          return { uuid: item.value, name: item.label };
        }),
      ),
      lock: lock !== 'ACTIVE',
      formThumbnail: null as any,
      formBackground: null as any,
      displayInApp,
    };

    if (numberOfDays <= 0) {
      message.error('Số ngày không hợp lệ');
      return;
    }
    setFileAvatar(thumbnail);
    setFileCover(background);
    if (thumbnail !== undefined) {
      const file = thumbnail?.fileList[0];
      const data = {
        attributeType: '1962dfff-3cf3-420d-b804-f1e096dfb5fd',
      };

      const formData = new FormData();
      if (file !== undefined) {
        formData.append('json', JSON.stringify(data));
        formData.append('file', file?.originFileObj);
        payload.formThumbnail = formData;
      }
    }
    if (background !== undefined) {
      const file1 = background.fileList[0];
      const data1 = {
        attributeType: '1962dghj-3cf3-420d-b804-f1e096dgnaht',
      };

      const formData1 = new FormData();
      if (file1 !== undefined) {
        formData1.append('json', JSON.stringify(data1));
        formData1.append('file', file1?.originFileObj);
        payload.formBackground = formData1;
      }
    }

    dispatch(servicePackageActions.addServicePackageRequested(payload));
  };

  const handleUploadCover = (file: any) => {
    setCover(file);
    return false;
  };
  const handleRemoveCover = () => {
    setCover(null);
  };

  const handleUploadAvatar = (file: any) => {
    setAvatar(file);
    return false;
  };

  const handleRemoveAvatar = () => {
    setAvatar(null);
  };

  useEffect(() => {
    jq(document).ready(() => {
      jq('.ant-btn').attr('title', '');
    });
  });

  return (
    <div className="add-doctor">
      <Row>
        <Col xs={24}>
          <h1 className="mb-10">Thêm mới gói khám dịch vụ</h1>
        </Col>
      </Row>

      <Form
        {...formItemLayout}
        name="basic"
        labelWrap
        initialValues={initialValues}
        onFinish={onFinish}
        className="modal-add-customer"
        requiredMark={false}
        form={form}
      >
        <div className="upload-images-box box-image">
          <div className="box-image_1">
            <Form.Item
              labelAlign="left"
              name="thumbnail"
              label="Ảnh đại diện"
              className="form-item avatar"
              // labelCol={{ span: 12 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('avatar')?.fileList[0]?.size < 10485760 ||
                      getFieldValue('avatar')?.fileList?.length === 0 ||
                      getFieldValue('avatar') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Dung lượng ảnh không vượt quá 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                disabled={false}
                accept="image/*"
                name="image"
                beforeUpload={handleUploadAvatar}
                onRemove={handleRemoveAvatar}
                listType="picture-card"
                className="upload-image"
              >
                {avatarData === null && true ? (
                  <div>
                    <CameraFilled
                      className="camera-icon"
                      style={{
                        fontSize: '30px',
                        opacity: '45%',
                        position: 'relative',
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </Upload>
            </Form.Item>
          </div>
          <div className="">
            <div className="box-image_2">
              <Form.Item
                labelAlign="left"
                name="background"
                label="Ảnh bìa"
                className="form-item cover"
                // labelCol={{ span: 12 }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue('cover')?.fileList[0]?.size < 10485760 ||
                        getFieldValue('cover')?.fileList?.length === 0 ||
                        getFieldValue('cover') === undefined
                      ) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        'Vui lòng nhập kích thước ảnh nhỏ hơn 10MB!',
                      );
                    },
                  }),
                ]}
              >
                <Upload
                  disabled={false}
                  accept="image/*"
                  name="cover"
                  beforeUpload={handleUploadCover}
                  onRemove={handleRemoveCover}
                  listType="picture-card"
                  className="upload-image"
                >
                  {coverData === null && true ? (
                    <div>
                      <CameraFilled
                        style={{ fontSize: '30px', opacity: '45%' }}
                      />
                    </div>
                  ) : null}
                </Upload>
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item
          label="Hình thức gói dịch vụ"
          name="packageFormatType"
          className="form-item"
        >
          <Select style={{ width: 'fit-content' }}>
            <Option value="ON">Online</Option>
            <Option value="OFF">Offline</Option>
          </Select>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.packageFormatType !== currentValues.packageFormatType
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('packageFormatType') === 'ON' ? (
              <Form.Item
                labelAlign="left"
                label={<p>Số lần sử dụng. Tối đa 1000</p>}
                name="nbMaxOfUse"
                className="form-item fee-test nb_max_of_use"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng điền thông tin bắt buộc!',
                  },
                ]}
              >
                <Row gutter={[10, 0]} align="middle">
                  <Col xs={6}>
                    <InputNumber min={1} max={1000} />
                  </Col>
                </Row>
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <Form.Item
          label="Tên gói dịch vụ"
          name="name"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            { max: 200, message: 'Họ tên không vượt quá 200 ký tự!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
          className="form-item  pt-3"
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Gói dịch vụ khuyến mãi kèm theo"
          name="idsPackageReference"
        >
          <Select
            mode="multiple"
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: '50%' }}
            placeholder="Chọn gói khám tặng kèm"
            options={servicePackageReducer
              ?.filter(filterPackageReference)
              .map(item => {
                return {
                  label: item.name,
                  value: item.identifier,
                };
              })}
          />
        </Form.Item>
        <Form.Item label="Trạng thái" name="lock" className="form-item">
          <Radio.Group>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Chuyên khoa"
          name="speciality"
          rules={[
            {
              required: true,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: '50%' }}
            placeholder="Chọn chuyên khoa"
            labelInValue={true}
            options={specialistReducer?.map(item => {
              return {
                label: item.display,
                value: item.uuid,
              };
            })}
          />
        </Form.Item>
        <Form.Item label="Giá gốc" required>
          <Form.Item
            noStyle
            rules={[
              {
                required: true,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
              {
                pattern: /^[\d]{0,10}$/,
                message: 'Vui lòng nhập ít hơn 10 ký tự',
              },
            ]}
            name="originalPrice"
          >
            <InputNumber
              style={{ width: 300 }}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="input-type mr-2"
              placeholder="Nhập giá gốc"
              min={0}
              addonAfter={'đ'}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item label="Giá ưu đãi" required>
          <Form.Item
            noStyle
            rules={[
              {
                required: true,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
              {
                pattern: /^[\d]{0,10}$/,
                message: 'Vui lòng nhập ít hơn 10 ký tự',
              },
            ]}
            name="promotionPrice"
          >
            <InputNumber
              style={{ width: 300 }}
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              className="input-type mr-2"
              placeholder="Nhập giá ưu đãi"
              min={0}
              addonAfter={'đ'}
            />
          </Form.Item>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label={<p>Ngày bắt đầu và kết thúc</p>}
          name="startEndDate"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <RangePicker
            style={{ maxWidth: '800px' }}
            disabledDate={disabledDate}
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label={<p>Hạn sử dụng (ngày). Tối đa 18000</p>}
          name="numberOfDays"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <InputNumber
                type="number"
                className="input-type"
                min="1"
                max="18000"
              />
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          label="Nội dung chi tiết"
          name="content"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
          ]}
        >
          <Col style={{ width: '90%' }}>
            <ReactQuill
              onChange={(content, delta, source, editor) => {
                const data = content;
                form.setFieldsValue({
                  content: data,
                });
              }}
              modules={modules}
            />
          </Col>
        </Form.Item>
        <Form.Item
          label="Điều khoản sử dụng"
          name="termsOfUse"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập thông tin bắt buộc!',
            },
          ]}
        >
          <Col style={{ width: '90%' }}>
            <ReactQuill
              onChange={(content, delta, source, editor) => {
                const data = content;
                form.setFieldsValue({
                  termsOfUse: data,
                });
              }}
              modules={modules}
            />
          </Col>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.lock !== currentValues.lock
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('lock') === 'ACTIVE' ? (
              <Form.Item label="Hiển thị trên app" name="displayInApp">
                <Switch />
              </Form.Item>
            ) : null
          }
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={() => history.goBack()}>
            Quay lại
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddServicePackage;
