import unauthorizedRequest from 'helpers/request/unauthorizedRequest';
import { UserInfoModel } from '../models/store/authenticate.model';

export interface LoginRequest {
  userName: string;
  password: string;
}
export interface LoginResponse {
  user: UserInfoModel;
  token: string;
}

export function loginApi(base64: string) {
  const datalogin = unauthorizedRequest.get('/v1/session', {
    headers: {
      Authorization: `Basic ${base64}`,
    },
  });
  return datalogin;
}
