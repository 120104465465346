import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import { indexOf, get } from 'lodash';
import * as api from 'api/authentication';
import {
  getMessageFromError,
  setCookie,
  setAuthInfo,
  removeAuthInfo,
  removeCookie,
  isRoleDoctor,
} from 'helpers/utils';
import { USERNAME } from 'configs';

import * as authenticateActions from './authenticate.action';
import { router } from '../../helpers/service/history';
import authorizedRequest from '../../helpers/request/authorizedRequest';

const base64 = require('base-64');
const utf8 = require('utf8');

export const ROLES_SYSTEM = {
  PROVIDER: 'Provider',
  SYSTEM_DEVELOPER: 'System Developer',
  PRIVILEGE_LEVEL: 'Privilege Level: Full',
};

function* loginSaga(
  action: ReturnType<typeof authenticateActions.login.request>,
) {
  try {
    const { userName, password } = action.payload;
    const bytes = utf8.encode(`${userName.toLocaleLowerCase()}:${password}`);
    const generateBase64 = base64.encode(bytes);
    const response = yield call(api.loginApi, generateBase64);

    if (response.authenticated === false) {
      yield put(authenticateActions.login.failure(response));
      return message.error(
        getMessageFromError('Tên đăng nhập hoặc mật khẩu không đúng'),
      );
    }

    const listRoles: string[] = [];
    if (response?.user?.roles) {
      response.user.roles.forEach((item: any) => {
        listRoles.push(item.name);
      });
    }

    if (
      indexOf(listRoles, ROLES_SYSTEM.SYSTEM_DEVELOPER) === -1 &&
      indexOf(listRoles, ROLES_SYSTEM.PRIVILEGE_LEVEL) === -1 &&
      indexOf(listRoles, ROLES_SYSTEM.PROVIDER) === -1
    ) {
      message.error(
        getMessageFromError('Bạn không có quyền truy cập trang web này'),
      );
      yield put(authenticateActions.login.failure(response));
      return router.push('/auth/login');
    }

    const data: authenticateActions.LoginSuccessPayload = {
      sessionId: response?.sessionId,
      uuid: response?.user?.uuid,
      userInfo: {
        id: response?.user?.uuid,
        displayName: response?.user?.display,
        userName: response?.user?.userName,
        roles: listRoles,
      },
      provider: {
        uuid: get(response, 'provider.uuid', null),
      },
    };
    setCookie(data?.sessionId);
    setAuthInfo(data);
    localStorage.setItem(USERNAME, data?.userInfo?.displayName);
    yield put(authenticateActions.login.success(data));

    authorizedRequest.setCookies(data?.sessionId);
    if (data?.sessionId !== undefined || data?.sessionId !== null) {
      yield call(() => authorizedRequest.post(`/v1/session`, { locale: 'vi' }));
    }

    if (isRoleDoctor()) {
      return router.push('/doctor');
    }

    router.push('/app/');
  } catch (err) {
    yield put(
      authenticateActions.login.failure('Lỗi không xác định từ máy chủ'),
    );
    message.error(getMessageFromError(err));
  }
}

function* handleLogout() {
  try {
    yield call(() => {
      return authorizedRequest.delete(`/v1/session`);
    });
    yield put(authenticateActions.logoutSuccess());
    removeAuthInfo();
    removeCookie();
    router.push('/auth/login');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

export default function* authenticateSaga() {
  yield all([
    takeLatest(authenticateActions.login.request, loginSaga),
    takeLatest(authenticateActions.logout, handleLogout),
  ]);
}
