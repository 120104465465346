import React, { FC } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { actions as appointmentActions } from 'store/appointment';
import './ModalCancelAppointment.scss';

const ModalCancelAppointment: FC<any> = ({
  visible,
  handleCancelModal,
  data,
}) => {
  const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const dispatch = useDispatch();

  const errorCancel = useSelector(
    (state: any) => state.appointment.errorCancel,
  );
  const appointmentReducer = useSelector((state: any) => state.appointment);

  const onFinish = values => {
    const cancelReason = `${values?.cancelReason}`.trim();
    if (cancelReason.length === 0) {
      return form.setFieldsValue({
        cancelReason: '',
      });
    }
    const params = {
      ...values,
      cancelReason,
      status: 'CANCELLED',
    };
    const body = {
      uuid: data !== undefined && data?.appointmentId,
      params,
    };
    dispatch(appointmentActions.handleCancelAppointmentRequested(body));
  };

  if (errorCancel === false) {
    handleCancelModal();
    dispatch(appointmentActions.handleDisplayModal());
  }

  const onSuggestion = (text: string) => {
    const cancelReason = form.getFieldValue('cancelReason');

    form.setFieldsValue({
      cancelReason: cancelReason.concat(`${text} `),
    });
  };

  return (
    <Modal
      title="Bạn có chắc chắn hủy lịch khám này?"
      centered
      visible={visible}
      onOk={handleCancelModal}
      onCancel={handleCancelModal}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        className="modal-add-customer"
        form={form}
      >
        <div className="flex test">
          <Form.Item
            initialValue=""
            name="cancelReason"
            className="form-items"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thông tin bắt buộc!',
              },
            ]}
          >
            <TextArea
              className="form-items cancel-reason"
              placeholder="Vui lòng nhập lý do hủy"
            />
          </Form.Item>
        </div>
        <div className="suggestion flex-wrap pb-5">
          <Button
            className="btn-suggestion"
            onClick={() => onSuggestion('Không liên lạc được')}
          >
            Không liên lạc được
          </Button>
          <Button
            className="btn-suggestion"
            onClick={() => onSuggestion('Khách hàng nhầm chuyên khoa')}
          >
            Khách hàng nhầm chuyên khoa
          </Button>
          <Button
            className="btn-suggestion"
            onClick={() => onSuggestion('Lịch khám quá giờ hẹn')}
          >
            Lịch khám quá giờ hẹn
          </Button>
        </div>
        <div className="button-cancel-appointment-container">
          <Button type="default" className="mr-2" onClick={handleCancelModal}>
            Đóng
          </Button>
          <Button
            type="primary"
            className="ml-2"
            htmlType="submit"
            loading={appointmentReducer?.isLoadingCancelPhone}
          >
            Hủy
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalCancelAppointment;
