import React, { FC, useState, useEffect } from 'react';
import { Modal, InputNumber, Button, Form } from 'antd';
import { useParams } from 'react-router';
import './ModalEditFee.scss';
import { useDispatch, useSelector } from 'react-redux';
import * as doctorActions from '../../store/doctor/doctor.action';

interface ParamsInformationDoctorModel {
  v?: string;
}

type Props = {
  visible: boolean;
  handleCancel: (...args: any[]) => any;
  data: any;
};

type TypeModel = {
  price: string;
};

const ModalEditFee: FC<Props> = props => {
  const { visible, handleCancel, data } = props;
  const { id } = useParams<any>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [idProvider, setIdProvider] = useState<any>();
  const [idAttr, setIdAttr] = useState<any>();
  const [initialFee, setInitialFee] = useState<any>('');
  const [checked, setChecked] = useState<boolean>(false);
  const dataAfterChangeFee = useSelector(
    (state: any) => state.doctorReducer.dataDetailHistoryFee,
  );
  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
    },
  );

  const onClickHandleCancel = () => {
    handleCancel();
    form.resetFields();
  };
  useEffect(() => {
    setInitialFee('');
    form.setFieldsValue({
      fee: '',
    });
    setChecked(false);
  }, [visible]);
  console.log('data: ', data);

  const getDoctorDetail = () => {
    dispatch(
      doctorActions.getDoctorDetailByIdRequested(
        id,
        ParamsInformationDoctorModel,
      ),
    );
  };
  useEffect(() => {
    getDoctorDetail();
  }, [dataAfterChangeFee]);

  useEffect(() => {
    if (data && data !== undefined) {
      setIdAttr(data?.idAttr);
      setIdProvider(data?.idProvider);
    }
  }, [data]);

  const handleSubmit = val => {
    const params = { ...val, value: String(val.fee) };
    delete params?.fee;
    dispatch(doctorActions.editFeeRequested({ idProvider, idAttr }, params));
    if (params?.fee !== val.fee) {
      dispatch(
        doctorActions.getDetailFeeHistoryRequested(
          {
            idPro: idProvider,
            idA: idAttr,
          },
          ParamsInformationDoctorModel,
        ),
      );
    }
    form.resetFields();
    handleCancel();
  };

  const keyCodeArr = [8, 35, 36, 37, 38, 39, 40];

  return (
    <div className="form-edit-fee">
      <Modal
        title="Sửa phí khám"
        visible={visible}
        onCancel={onClickHandleCancel}
        footer={null}
        className="text-center"
      >
        <Form
          form={form}
          layout="horizontal"
          id="edit-fee"
          onFinish={handleSubmit}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
          initialValues={{ fee: '' }}
        >
          <Form.Item
            labelAlign="left"
            label="Phí khám"
            name="fee"
            className="form-contain"
            rules={[
              { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
              ({ getFieldValue, setFieldsValue }) => ({
                validator(rule, value) {
                  const reg = new RegExp('^[a-zA-Z]+$');
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (!checked) {
                    return Promise.resolve();
                  }
                  if (reg.test(value)) {
                    setInitialFee('');
                    setFieldsValue({ fee: '' });
                  }
                  // const validateNumber = value.replace(/[,]/g, '');
                  // const isNumberInteger = (item: number) =>
                  //   Number.isInteger(+item);
                  if (
                    // !value ||
                    Number(value) >= 0 &&
                    Number(value) <= 20000000
                  ) {
                    return Promise.resolve();
                  }

                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Phí khám không hợp lệ!');
                },
              }),
            ]}
          >
            <InputNumber
              className="form-contain-input"
              name="fee"
              formatter={(value: any) =>
                `${value.toString()}`
                  .replace(/[\D\s\._\-]+/g, '')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              style={{ width: '100%' }}
              maxLength={11}
              onKeyDown={e => {
                const keys = /[0-9]/g;
                if (
                  !String(e.key).match(keys) &&
                  !keyCodeArr.includes(e.keyCode) &&
                  !(e.keyCode === 65 && e.ctrlKey === true)
                ) {
                  e.preventDefault();
                }
              }}
              type="text"
              keyboard={false}
              onChange={(e: any) => {
                setInitialFee(e);
                setChecked(true);
              }}
              value={initialFee}
            />
          </Form.Item>
        </Form>
        <div className="flex justify-center mt-2">
          <Button onClick={() => onClickHandleCancel()}>Đóng</Button>
          <Button
            className="ml-4"
            form="edit-fee"
            key="submit"
            htmlType="submit"
            type="primary"
          >
            Lưu
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ModalEditFee;
