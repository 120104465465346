import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Form,
  Input,
  AutoComplete,
  Checkbox,
  Radio,
  InputNumber,
  // Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appointmentActions } from 'store/appointment';
import StoreState from 'models/store';

import { formRules } from 'helpers/formUtils';
import { usageTimeList, eatingStatus } from '../drugOrder.constant';
import './DrugForm.scss';

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const CheckLayout = {
  wrapperCol: { offset: 6 },
};

const { TextArea } = Input;

const DrugForm: FC<any> = ({
  onFinish,
  listOptionDrugActiveIngredient = [],
  onChooseDrugActiveIngredient,
  listOptionDrug = [],
  onChooseConceptDrug,
  unit,
  loading,
  onClose,
  initData,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isDrugActive, setIsDrugActive] = useState<boolean>(false);
  const [isDrugActiveError, setIsDrugActiveError] = useState<boolean>(false);
  const dataDrugUnit = useSelector(
    (state: StoreState) => state.appointment.drugUnit,
  );

  const optionUnit = useMemo(() => {
    return dataDrugUnit?.map(unitItem => ({
      value: unitItem?.display,
    }));
  }, [dataDrugUnit]);

  const initValues = useMemo(() => {
    const isDrugNonCode = initData?.drug?.display === 'Other';
    const ConceptDrug = isDrugNonCode
      ? initData?.drugNonCode
      : initData?.drug?.display;
    const DrugActiveIngredient = isDrugNonCode
      ? ''
      : initData?.concept?.display;
    return {
      ConceptDrug,
      DrugActiveIngredient,
      amount: initData?.quantity || null,
      eatingStatus: initData?.eatingStatus,
      timeUses: initData?.timeUses,
      usage: initData?.dosingInstructions,
      unit: initData?.drugUnits || unit,
    };
  }, [initData]);

  const conceptDrug = useMemo(() => {
    const value = form.getFieldValue('ConceptDrug')?.trim();
    const filterData = listOptionDrug?.filter(option => {
      return option!.value.toUpperCase()?.indexOf(value?.toUpperCase()) !== -1;
    });
    return {
      value,
      filterData,
    };
  }, [form.getFieldValue('ConceptDrug'), listOptionDrug]);

  const drugActiveIngredient = useMemo(() => {
    const value = form.getFieldValue('DrugActiveIngredient')?.trim();
    const filterData = listOptionDrugActiveIngredient?.filter(option => {
      return option!.value?.toUpperCase().indexOf(value?.toUpperCase()) !== -1;
    });
    return {
      value,
      filterData,
    };
  }, [
    form.getFieldValue('DrugActiveIngredient'),
    listOptionDrugActiveIngredient,
  ]);

  const finishForm = values => {
    onFinish(values);
    form.resetFields();
  };

  const onBlurConceptDrug = () => {
    const { filterData, value } = conceptDrug;
    if (filterData[0]?.value === value) {
      onChooseConceptDrug(value, filterData[0]);
    } else {
      onChooseConceptDrug(value, {});
      form.setFieldsValue({ unit: '' });
    }
  };
  function checkValid() {
    const { filterData, value } = drugActiveIngredient;
    if (filterData[0]?.value === value) {
      onChooseDrugActiveIngredient(value, filterData[0]);
      setIsDrugActive(true);
      setIsDrugActiveError(false);
    } else {
      onChooseDrugActiveIngredient(drugActiveIngredient.value, {});
      setIsDrugActive(false);
      setIsDrugActiveError(true);
    }
  }
  const onBlurDrugActiveIngredient = () => {
    const { filterData, value } = drugActiveIngredient;
    const checkDrug = setTimeout(checkValid, 5000);
    if (filterData[0]?.value !== value) {
      clearTimeout(checkDrug);
      setIsDrugActiveError(true);
    }
  };

  const onFocusDrug = event => {
    const { value } = conceptDrug;
    if (!value && !isDrugActive) {
      onChooseConceptDrug('', {});
    }
  };

  useEffect(() => {
    dispatch(appointmentActions.getDrugUnit());
  }, []);

  useEffect(() => {
    if (!form.getFieldValue('ConceptDrug')) {
      form.setFieldsValue({ unit: '' });
    }
  }, [form.getFieldValue('ConceptDrug')]);

  useEffect(() => {
    if (unit) {
      form.setFieldsValue({ unit });
    }
  }, [unit]);

  useEffect(() => {
    if (isDrugActiveError && !!drugActiveIngredient.value) {
      form.setFields([
        {
          name: 'DrugActiveIngredient',
          errors: ['Không có hoạt chất này'],
        },
      ]);
    } else {
      form.setFields([
        {
          name: 'DrugActiveIngredient',
          errors: [],
        },
      ]);
    }
  }, [isDrugActiveError, drugActiveIngredient]);

  return (
    <Form
      onFinish={finishForm}
      {...formItemLayout}
      labelAlign="left"
      requiredMark={false}
      initialValues={initValues}
      form={form}
    >
      <div className="drugOrder-input-add">
        <Form.Item name="DrugActiveIngredient" label="Hoạt chất">
          <AutoComplete
            virtual={false}
            placeholder="Nhập hoạt chất"
            style={{ width: '100%' }}
            options={listOptionDrugActiveIngredient}
            onChange={(value, option) => {
              form.setFieldsValue({ ConceptDrug: '' });
              setIsDrugActiveError(false);
              onChooseDrugActiveIngredient(value, option);
            }}
            filterOption={(inputValue, option) =>
              (option!.value as any)
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            onBlur={onBlurDrugActiveIngredient}
          />
        </Form.Item>
        <Form.Item
          name="ConceptDrug"
          label="Tên thuốc biệt dược"
          rules={formRules}
        >
          <AutoComplete
            placeholder="Nhập thuốc biệt dược"
            style={{ width: '100%' }}
            virtual={false}
            options={listOptionDrug}
            onChange={(value, option) => {
              onChooseConceptDrug(value, option);
            }}
            filterOption={(inputValue, option) =>
              (option!.value as any)
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            onFocus={onFocusDrug}
            onBlur={onBlurConceptDrug}
          />
        </Form.Item>
        <Form.Item label="Số lượng" required className="mb-0">
          <div className=" flex justify-between">
            <Form.Item
              className=" w-1/2"
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 9999,
                  message: 'Vui lòng nhập số lớn hơn 0 và không quá 4 ký tự',
                },
                {
                  required: true,
                  message: 'Nội dung không được để trống',
                },
              ]}
              name="amount"
            >
              <InputNumber
                className=" w-4/5"
                placeholder="Nhập số lượng thuốc"
              />
            </Form.Item>

            <div className=" w-1/2">
              <Form.Item
                label="Đơn vị"
                rules={[
                  {
                    required: true,
                    message: 'Nội dung không được để trống',
                  },
                ]}
                name="unit"
              >
                <AutoComplete
                  placeholder="Nhập đơn vị"
                  options={optionUnit}
                  virtual={false}
                  filterOption={(inputValue, option) =>
                    (option!.value as any)
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </div>
          </div>
        </Form.Item>

        <Form.Item
          className=""
          label="Cách dùng"
          name="usage"
          rules={[
            {
              required: true,
              message: 'Nội dung không được để trống',
            },
            {
              max: 500,
              message: 'Vui lòng không nhập quá 500 ký tự',
            },
            { whitespace: true, message: 'Nội dung không được để trống' },
          ]}
        >
          <TextArea placeholder="Nhập cách dùng" />
        </Form.Item>
        <Form.Item name="timeUses" {...CheckLayout} label="">
          <Checkbox.Group>
            {usageTimeList.map((time, index) => (
              <Checkbox key={String(index)} value={time.value}>
                {time.display}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>
        <Form.Item {...CheckLayout} name="eatingStatus">
          <Radio.Group>
            {eatingStatus.map((time, index) => (
              <Radio key={String(index)} value={time.value}>
                {time.display}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      <div className="flex justify-between drugOrder-action-add">
        <div className="btn-ok ">
          <Button htmlType="submit" type="primary" loading={loading}>
            Lưu
          </Button>
        </div>
        <div className="btn-cancel">
          <a className="no-underline" href="#" onClick={onClose}>
            <span className="btn-add-form-input">Hủy</span>
          </a>
        </div>
      </div>
    </Form>
  );
};

export default DrugForm;
