import { message } from 'antd';
import { cleanKeyNull, getMessageFromError } from 'helpers/utils';
import moment from 'moment';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import authorizedRequest from '../../helpers/request/authorizedRequest';
import * as rating from './rating.action';

// let paramsRate;
function* getRateRequested(action) {
  try {
    cleanKeyNull(action.payload);
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    if (params?.startDate) {
      params = {
        ...params,
        startDate: moment(params?.startDate).format('YYYY-MM-DD'),
      };
    }

    if (params?.endDate) {
      params = {
        ...params,
        endDate: moment(params?.endDate).format('YYYY-MM-DD'),
      };
    }

    if (`${params?.holidayName}`.trim().length === 0) {
      delete params.holidayName;
    }
    // let  paramsRate = params;
    const res = yield call(() =>
      authorizedRequest.get('/v1/providerEvaluate', { params }),
    );
    yield put(rating.getRatingSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(rating.getRatingFailed(error));
  }
}

export default function* ratingSaga() {
  yield all([takeLatest(rating.getRatingRequested, getRateRequested)]);
}
