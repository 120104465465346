import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Space,
  message,
} from 'antd';
import './ModalEditPromotion.scss';
import * as promotionAction from '../../store/promotion/promotion.action';
import * as STATUS from '../../containers/Doctor/doctor.const';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps: any;
}

const { Option } = Select;

const ModalEditPromotion: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [type, setType] = useState<string>('');
  const [sDate, setSDate] = useState<any>();
  const [eDate, setEDate] = useState<any>();
  const promotionReducer = useSelector((state: any) => state.promotionReducer);
  const [discountType, setDiscountType] = useState<string>(
    dataProps?.discountType,
  );
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const getValStartDate = current => {
    if (eDate && eDate < current) {
      form.setFieldsValue({
        endDate: '',
      });
      setEDate(null);
    }
    setSDate(current);
  };
  const getValEndDate = current => {
    setEDate(current);
  };
  const disabledDate = current => {
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const disabledEndDate = current => {
    return current && current < sDate;
  };

  useEffect(() => {
    setSDate(moment(dataProps?.startDate));
  }, [dataProps]);

  const onChangeDiscountType = value => {
    setDiscountType(value);
    form.setFieldsValue({
      value: '',
    });
  };

  const onFinish = values => {
    const {
      // startDate,
      endDate,
      value,
      numberOfUses,
      numberOfPromotionCodes,
    } = values;
    if (numberOfUses < dataProps.numberOfUses) {
      return message.error(
        'Số lượt sử dụng mã khuyến mại mới không thể nhỏ hơn số lượt sử dụng mã khuyến mại cũ!',
      );
    }

    // const newStartDate = startDate?.format('YYYY-MM-DD hh:mm:ss');
    const newEndDate = endDate?.format('YYYY-MM-DD hh:mm:ss');
    const numberUses = Number(numberOfUses);
    const numberPromotionCodes = Number(numberOfPromotionCodes);

    const params =
      type === 'ALL'
        ? {
            value,
            discountType,
            // startDate: newStartDate,
            endDate: newEndDate,
            numberOfUses: numberUses,
          }
        : {
            value,
            discountType,
            // startDate: newStartDate,
            endDate: newEndDate,
            numberOfUses: numberUses,
            numberOfPromotionCodes: numberPromotionCodes,
          };

    const body = {
      uuid: dataProps.uuid,
      params,
    };

    dispatch(promotionAction.handleUpdatePromotionRequested(body));
  };

  useEffect(() => {
    if (promotionReducer?.errorEdit === false) {
      onHide();
      dispatch(promotionAction.handleDisplayModalEdit());
    }
  }, [promotionReducer]);

  useEffect(() => {
    form.setFieldsValue({
      name: dataProps?.name || '',
      type: dataProps?.type,
      discountType: dataProps?.discountType,
      startDate: moment(dataProps?.startDate),
      endDate: moment(dataProps?.endDate),
      numberOfUses: dataProps?.numberOfUses,
      promotionCampaignCode: dataProps.promotionCampaignCode,
      numberOfPromotionCodes: dataProps?.numberOfPromotionCodes,
      value: dataProps?.value,
      promotionTemplateCode: dataProps?.promotionTemplateCode || '',
    });
    setType(dataProps?.type);
    setEDate(moment(dataProps?.startDate));
    setSDate(moment(dataProps?.endDate));
  }, [dataProps]);

  return (
    <Modal
      title={title || 'Sửa khuyến mại'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={800}
      footer={null}
      className="z-9999"
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        form={form}
        requiredMark={false}
      >
        <Form.Item
          label="Tên sự kiện/ Đợt khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
          name="name"
        >
          <Input
            disabled={true}
            placeholder="Nhập tên khuyến mại"
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Form.Item
          label="Mã sự kiện/ Đợt khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            {
              pattern: STATUS.justNumAndChar,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="promotionCampaignCode"
        >
          <Input
            disabled={true}
            placeholder="Nhập mã sự kiện/ Đợt khuyến mại"
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Form.Item
          label="Loại khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
          name="type"
        >
          <Select disabled={true} style={{ width: '80%' }}>
            <Option value="ALL">Dùng chung</Option>
            <Option value="SEPARATE">Dùng riêng</Option>
          </Select>
        </Form.Item>
        <div className="wrapper-item">
          {type === 'ALL'
            ? type && (
                <Form.Item
                  label="Mã khuyến mại"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thông tin bắt buộc!',
                    },
                    {
                      pattern: STATUS.justNumAndChar,
                      message: 'Vui lòng nhập đúng thông tin!',
                    },
                  ]}
                  name="promotionTemplateCode"
                >
                  <Input
                    disabled={true}
                    placeholder="Nhập mã khuyến mại"
                    style={{ width: '80%' }}
                  />
                </Form.Item>
              )
            : type && (
                <Form.Item
                  label="Số lượng"
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập thông tin bắt buộc!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          (getFieldValue('numberOfPromotionCodes') > 0 &&
                            Number.isInteger(
                              getFieldValue('numberOfPromotionCodes'),
                            )) ||
                          getFieldValue('numberOfPromotionCodes') === ''
                        ) {
                          return Promise.resolve();
                        }

                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Giá trị không hợp lệ!');
                      },
                    }),
                  ]}
                  name="numberOfPromotionCodes"
                >
                  <InputNumber
                    placeholder="Nhập số lượng "
                    style={{ width: '80%' }}
                  />
                </Form.Item>
              )}
        </div>
        <Form.Item
          label="Số lượt sử dụng"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  (getFieldValue('numberOfUses') > 0 &&
                    Number.isInteger(getFieldValue('numberOfUses'))) ||
                  getFieldValue('numberOfUses') === ''
                ) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Giá trị không hợp lệ!');
              },
            }),
          ]}
          name="numberOfUses"
        >
          <InputNumber
            placeholder="Nhập số lượt sử dụng"
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Form.Item
          label="Hình thức khuyến mại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
          name="discountType"
        >
          <Select
            value={discountType}
            style={{ width: '80%' }}
            onChange={onChangeDiscountType}
          >
            <Option value="REAL_NUMBER">Tiền mặt</Option>
            <Option value="PERCENT">Chiết khấu(%)</Option>
          </Select>
        </Form.Item>
        <Form.Item label="Giá trị khuyến mại" required>
          <Space>
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền thông tin bắt buộc!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      (getFieldValue('value') >= 0 &&
                        Number.isInteger(getFieldValue('value'))) ||
                      getFieldValue('value') === ''
                    ) {
                      if (
                        (discountType === 'PERCENT' &&
                          getFieldValue('value') > 100) ||
                        getFieldValue('value') > 999999999
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise.reject('Giá trị không hợp lệ!');
                      }
                      return Promise.resolve();
                    }

                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject('Giá trị không hợp lệ!');
                  },
                }),
              ]}
              name="value"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value: any) =>
                  discountType === 'PERCENT'
                    ? `${value}`
                    : `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                className="input-type mr-2"
              />
            </Form.Item>
            <div className="">{discountType === 'PERCENT' ? '%' : 'VND'}</div>
          </Space>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate}
            disabled={dataProps.status === 'HAPPENING'}
            locale={locale}
            onChange={getValStartDate}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            locale={locale}
            format="DD/MM/YYYY"
            onChange={getValEndDate}
            placeholder="Chọn ngày kết thúc"
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditPromotion;
