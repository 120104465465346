import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreateReferralSuccessMessage,
  fireUpdateReferralSuccessMessage,
  fireLockReferralSuccessMessage,
  fireUnLockReferralSuccessMessage,
  getMessageFromError,
  cleanKeyNull,
} from 'helpers/utils';
import moment from 'moment';

import * as referralAction from './referral.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

let paramsReferral;

function* getReferralRequested(action) {
  try {
    cleanKeyNull(action.payload);
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    if (params?.startDate) {
      params = {
        ...params,
        startDate: moment(params?.startDate).format('YYYY-MM-DD'),
      };
    }

    if (params?.endDate) {
      params = {
        ...params,
        endDate: moment(params?.endDate).format('YYYY-MM-DD'),
      };
    }

    if (`${params?.ReferralName}`.trim().length === 0) {
      delete params.ReferralName;
    }
    paramsReferral = params;

    const res = yield call(() =>
      authorizedRequest.get('/v1/referralCampaign?v=full', { params }),
    );
    yield put(referralAction.getReferralSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(referralAction.getReferralFailed(error));
  }
}

function* handleCreateReferralRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post('/v1/referralCampaign', action.payload),
    );
    if (res.error) {
      message.error(
        getMessageFromError(res.error?.fieldErrors?.referralCode[0]),
      );
      yield put(referralAction.handleCreateReferralFailed(res.error));
      return;
    }

    const params = {
      ...paramsReferral,
      startIndex: paramsReferral?.startIndex
        ? paramsReferral?.startIndex / 10 + 1
        : 0,
    };
    fireCreateReferralSuccessMessage();
    yield put(referralAction.handleCreateReferralSucceeded(res));
    yield put(referralAction.getReferralRequested(params));
    // }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(referralAction.handleCreateReferralFailed(error));
  }
}

function* handleUpdateReferralRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/referralCampaign`, action.payload),
    );
    fireUpdateReferralSuccessMessage();
    const params = {
      ...paramsReferral,
      startIndex: paramsReferral?.startIndex
        ? paramsReferral?.startIndex / 10 + 1
        : 0,
    };
    yield put(referralAction.handleUpdateReferralSucceeded(res));
    yield put(referralAction.getReferralRequested(params));
  } catch (error) {
    // message.error(getMessageFromError(error));
    yield put(referralAction.handleUpdateReferralFailed(error));
  }
}

function* handleLockedReferralRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/referralCampaign/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(referralAction.handleLockedReferralSucceeded(res));
    const params = {
      ...paramsReferral,
      startIndex: paramsReferral?.startIndex
        ? paramsReferral?.startIndex / 10 + 1
        : 0,
    };

    yield put(referralAction.getReferralRequested(params));
    if (action.payload.params && action.payload.params.status === 'LOCKED') {
      fireLockReferralSuccessMessage();
    } else {
      fireUnLockReferralSuccessMessage();
    }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(referralAction.handleLockedReferralFailed(error));
  }
}

function* getDetailReferralRequested(action) {
  try {
    let params = {
      v: action.payload.v,
      startIndex: action.payload.startIndex,
      totalCount: action.payload.totalCount,
      limit: action.payload.limit,
      referralCode: action.payload.referralCode,
    };
    if (params.startIndex) {
      if (params.startIndex) {
        params = {
          ...params,
          startIndex: (params.startIndex - 1) * params.limit,
        };
      }
    }
    const res = yield call(() =>
      authorizedRequest.get('/v1/referralHistory', { params }),
    );
    yield put(referralAction.getDetailReferralSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(referralAction.getDetailReferralFailed(error));
  }
}

function* handleCreateLogoReferralRequested(action) {
  try {
    console.log(action);
  } catch (error) {
    console.log(error);
  }
}
export default function* ReferralSaga() {
  yield all([
    takeLatest(referralAction.getReferralRequested, getReferralRequested),
    takeLatest(
      referralAction.handleCreateReferralRequested,
      handleCreateReferralRequested,
    ),
    takeLatest(
      referralAction.handleUpdateReferralRequested,
      handleUpdateReferralRequested,
    ),
    takeLatest(
      referralAction.handleLockedReferralRequested,
      handleLockedReferralRequested,
    ),
    takeLatest(
      referralAction.getDetailReferralRequested,
      getDetailReferralRequested,
    ),
    takeLatest(
      referralAction.handleCreateLogoReferralRequested,
      handleCreateLogoReferralRequested,
    ),
  ]);
}
