import { takeLatest, call, put, all } from 'redux-saga/effects';
import * as api from 'api/appointment';
import { ActionModel } from 'types/ActionModel';
import { message } from 'antd';
import {
  getMessageFromError,
  fireCancelAppointmentSuccessMessage,
} from 'helpers/utils';
// import mapQuery from 'helpers/mapQuery';
import { flattenDeep, omit } from 'lodash';
import mapDetail from 'helpers/mapDetailAppointment';
import authorizedRequest from 'helpers/request/authorizedRequest';
import { AppointmentFiled, CERTAINTY } from 'constants/Appointment';
import { getAppointmentConceptData } from 'helpers/getAppointmentConceptData';

import appointmentActions from './actions';
import * as appointmentActionsSecond from './appointment.actions';

function* handleFetchAppointmentList(action: ActionModel) {
  try {
    let params = { ...action.payload.query };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params?.status === '') {
      delete params.status;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    const data = yield call(() =>
      authorizedRequest.get('/v1/appointmentscheduling/appointment', {
        params,
      }),
    );

    yield put(appointmentActions.fetchAppointmentSuccess(data));
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleStartCall(action: ActionModel) {
  try {
    window.open(
      `https://meet.doctor4u.vn/${action.payload.appointmentCode}`,
      '_blank',
      'rel="noreferrer"',
    );

    const params = {
      appointmentId: action.payload.appointmentId,
    };
    yield call(api.startCall, params);
    yield put(
      appointmentActions.fetchDetailAppointment(action?.payload?.appointmentId),
    );
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCancelAppointmentRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/appointmentscheduling/appointment/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(appointmentActions.handleCancelAppointmentSucceeded(res));
    fireCancelAppointmentSuccessMessage();
  } catch (error) {
    getMessageFromError(error);
  }
}

function* handleFetchAppointmentDetail(action: ActionModel) {
  try {
    const data = yield call(api.getAppointmentById, action.payload);
    if (data?.encounters?.length) {
      // const encounter = data?.encounters[0];
    }
    const result = yield mapDetail(data);

    yield put(appointmentActions.fetchDetailAppointmentSuccess(result));
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* listExaminationHistory(action: any) {
  try {
    const params = {
      patient: action.payload.patientId,
      provider: action.payload.provider,
    };
    const response = yield call(
      () =>
        authorizedRequest.get(
          `/v1/appointmentscheduling/appointment?v=full&totalCount=true`,
          { params },
        ),

      // mapQuery(action.payload.query),
    );
    const filterResponse = response.results.filter(
      (item: any) =>
        item.uuid !== action.payload.appointmentId &&
        (item.status.code === 'COMPLETED' ||
          item.status.code === 'WAITING_EXAMINATION'),
    );
    const mapData = filterResponse.map((item: any) => ({
      id: item.uuid,
      startDate: item.timeSlot.startDate,
      diagnoses:
        item.visit && item.visit.encounters[0].diagnoses
          ? item.visit.encounters[0].diagnoses.map((i: any) => ({
              id: i.uuid,
              content: i.diagnosis.nonCoded || i.display,
              certainty: i.certainty,
            }))
          : [],
    }));
    const fillData = mapData.filter((item: any) => item.diagnoses.length !== 0);

    yield put(appointmentActions.fetchExaminationHistorySuccess(fillData));
  } catch (err) {
    yield put(appointmentActions.fetchExaminationHistoryFailed());
    // message.error('Tải lịch sử khám không thành công');
  }
}

function* fetchRelationship(action: ActionModel) {
  try {
    const response = yield call(api.fetchRelationship, action.payload);
    const data = response.results.map((item: any) => ({
      id: item.uuid,
      name: item.display,
    }));
    yield put(appointmentActions.fetchRelationSuccess(data));
  } catch (err) {
    // safeAlert(err);
    // message.error('Tải tiền sử gia đình không thành công');
  }
}

function* fetchAllergy(action: any) {
  try {
    const response = yield call(api.fetchAllergyApi, action.payload);
    const mapResponse = response.results.map((item: any) => ({
      id: item.uuid,
      name: item.display,
    }));
    yield put(appointmentActions.fetchAllergySuccess(mapResponse));
  } catch (err) {
    // message.error('Tải thông tin dị ứng không thành công');
  }
}

function* fetchConditionHistory(action: any) {
  try {
    const response = yield call(
      api.fetchConditionHistory,
      action.payload.patientId,
    );
    const mapData = response.map((item: any) => {
      return item.conditions;
    });
    const flatten = flattenDeep(mapData);
    if (flatten.length > 0) {
      const mapKey = flatten.map((item: any) => ({
        id: item.uuid,
        status: item.status,
        description: item.conditionNonCoded || item.concept.name,
      }));
      const anamnesis = mapKey.filter((item: any) => item.status !== 'CHRONIC');
      const chronicDiseases = mapKey.filter(
        (item: any) => item.status === 'CHRONIC',
      );
      yield put(
        appointmentActions.fetchConditionHistorySuccess({
          anamnesis,
          chronicDiseases,
        }),
      );
    } else {
      yield put(
        appointmentActions.fetchConditionHistorySuccess({
          anamnesis: [],
          chronicDiseases: [],
        }),
      );
    }
  } catch (err) {
    // message.error('Tải thông tin bệnh mãn tính không thành công');
  }
}

function* handleEditPulse(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/obs/${action.payload.uuidPulse}`,
        omit(
          {
            ...action.payload.paramsEditPulse,
          },
          ['callbackSuccess'],
        ),
      ),
    );
    yield put(appointmentActions.handleEditPulseSuccess(res));
    yield action.payload.paramsEditPulse.callbackSuccess();
    message.success('Chỉnh sửa mạch thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreatePulse(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/obs`,
        omit(
          {
            ...action.payload,
          },
          ['callbackSuccess'],
        ),
      ),
    );
    yield put(appointmentActions.handleCreatePulseSuccess(res));
    message.success('Thêm mới mạch thành công!');
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditTemperature(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidTemperature}`, {
        ...action.payload.paramsEditTemperature,
      }),
    );
    yield put(appointmentActions.handleEditTemperatureSuccess(res));
    message.success('Chỉnh sửa nhiệt độ thành công!');
    yield action.payload.paramsEditTemperature.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateTemperature(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateTemperatureSuccess(res));
    message.success('Thêm mới nhiệt độ thành công!');
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}
// for height
function* handleEditHeight(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidHeight}`, {
        ...action.payload.paramsEditHeight,
      }),
    );
    yield put(appointmentActions.handleEditHeightSuccess(res));
    message.success('Chỉnh sửa chiều cao thành công!');
    yield action.payload.paramsEditHeight.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateHeight(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateHeightSuccess(res));
    message.success('Thêm mới chiều cao thành công!');
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}
// for weight
function* handleEditWeight(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidWeight}`, {
        ...action.payload.paramsEditWeight,
      }),
    );
    yield put(appointmentActions.handleEditWeightSuccess(res));
    message.success('Chỉnh sửa cân nặng thành công!');
    yield action.payload.paramsEditWeight.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateWeight(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateWeightSuccess(res));
    message.success('Thêm mới cân nặng thành công!');
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

// for respiratory  rate
function* handleEditRespiratoryRate(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/obs/${action.payload.uuidBreathing}`,
        action.payload.paramsEditBreathing,
      ),
    );
    // console.log(res);
    yield put(appointmentActions.handleEditRespiratoryRateSuccess(res));
    message.success('Chỉnh sửa nhịp thở thành công!');
    yield action.payload.paramsEditBreathing.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateRespiratoryRate(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateRespiratoryRateSuccess(res));
    message.success('Thêm mới nhịp thở thành công!');
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}
// for blood pressure
function* handleEditBloodPressure(action) {
  try {
    const systolicBloodPressureData = omit(
      {
        ...action.payload.paramsEditBloodPressure,
        value:
          action.payload.paramsEditBloodPressure.systolicBloodPressureValue,
        concept: getAppointmentConceptData(
          AppointmentFiled.systolicBloodPressure,
        ),
      },
      [
        'systolicBloodPressureValue',
        'systolicBloodPressureId',
        'diastolicBloodPressureId',
        'diastolicBloodPressureValue',
      ],
    );

    const diastolicBloodPressureData = omit(
      {
        ...action.payload.paramsEditBloodPressure,
        value:
          action.payload.paramsEditBloodPressure.diastolicBloodPressureValue,
        concept: getAppointmentConceptData(
          AppointmentFiled.diastolicBloodPressure,
        ),
      },
      [
        'systolicBloodPressureValue',
        'systolicBloodPressureId',
        'diastolicBloodPressureId',
        'diastolicBloodPressureValue',
      ],
    );
    yield call(
      api.updateObsApi,
      action.payload.form.systolicBloodPressureId,
      systolicBloodPressureData,
    );
    yield call(
      api.updateObsApi,
      action.payload.diastolicBloodPressureId,
      diastolicBloodPressureData,
    );
    // const res = yield call(() =>
    //   authorizedRequest.post(`/v1/obs/${action.payload.uuidBloodPressure}`, {
    //     ...action.payload.paramsEditBloodPressure,
    //   }),
    // );
    yield put(appointmentActions.handleEditBloodPressureSuccess());
    yield action.payload.paramsEditBloodPressure.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateBloodPressure(action) {
  try {
    const systolicBloodPressureData = omit(
      {
        ...action.payload,
        value: action.payload.systolicBloodPressureValue,
        concept: getAppointmentConceptData(
          AppointmentFiled.systolicBloodPressure,
        ),
      },
      ['diastolicBloodPressureValue', 'systolicBloodPressureValue'],
    );
    const diastolicBloodPressureData = omit(
      {
        ...action.payload,
        value: action.payload.diastolicBloodPressureValue,
        concept: getAppointmentConceptData(
          AppointmentFiled.diastolicBloodPressure,
        ),
      },
      ['diastolicBloodPressureValue', 'systolicBloodPressureValue'],
    );
    yield call(api.createObsApi, systolicBloodPressureData);
    yield call(api.createObsApi, diastolicBloodPressureData);
    // const res = yield call(() =>
    //   authorizedRequest.post(`/v1/obs`, {
    //     ...action.payload,
    //   }),
    // );
    yield put(appointmentActions.handleCreateBloodPressureSuccess());
    yield action.payload.callbackSuccess();
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

// for alcohol
function* handleEditAlcohol(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidAlcohol}`, {
        ...action.payload.paramsEditAlcohol,
      }),
    );
    yield call(action.payload.onSucceeded);
    yield put(appointmentActions.handleEditAlcoholSuccess(res));
    message.success('Chỉnh sửa rượu bia thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateAlcohol(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateAlcoholSuccess(res));
    message.success('Thêm mới rượu, bia thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

// for alcohol
function* handleEditCigarette(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidCigarette}`, {
        ...action.payload.paramsEditCigarette,
      }),
    );
    yield put(appointmentActions.handleEditCigaretteSuccess(res));
    message.success('Chỉnh sửa thuốc lá thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateCigarette(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload.paramsCreateCigratte,
      }),
    );
    yield call(action.payload.onSucceeded);
    yield put(appointmentActions.handleCreateCigaretteSuccess(res));
    message.success('Thêm mới thuốc lá thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

// for HistoryOfFamily
function* handleEditHistoryOfFamily(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidHistoryOfFamily}`, {
        ...action.payload.paramsEditHistoryOfFamily,
      }),
    );
    yield put(appointmentActions.handleEditHistoryOfFamilySuccess(res));
    message.success('Chỉnh sửa tiền sử thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateHistoryOfFamily(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateHistoryOfFamilySuccess(res));
    message.success('Thêm mới tiền sử gia đình thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditQuestionByPatient(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidQuestionChange}`, {
        ...action.payload.paramsQuestionByPatient,
      }),
    );
    yield put(appointmentActions.handleEditQuestionByPatientSuccess(res));
    message.success('Chỉnh sửa câu hỏi thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateSystom(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateSystomSuccess(res));
    message.success('Thêm mới triệu chứng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditSystom(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidSystom}`, {
        ...action.payload.paramsEditSystom,
      }),
    );
    yield put(appointmentActions.handleEditSystomSuccess(res));
    message.success('Chỉnh sửa triệu chứng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateDrugsPresent(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateDrugsPresentSuccess(res));
    message.success('Thêm mới thuốc đang sử dụng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditDrugsPresent(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidDrugsPresent}`, {
        ...action.payload.paramsEditDrugsPresent,
      }),
    );
    yield put(appointmentActions.handleEditDrugsPresentSuccess(res));
    message.success('Chỉnh sửa thuốc đang sử dụng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateInstructions(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, {
        ...action.payload,
      }),
    );
    yield put(appointmentActions.handleCreateInstructionsSuccess(res));
    message.success('Thêm mới hướng dẫn thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditInstructions(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs/${action.payload.uuidInstructions}`, {
        ...action.payload.paramsEditInstructions,
      }),
    );
    yield put(appointmentActions.handleEditInstructionsSuccess(res));
    message.success('Chỉnh sửa hướng dẫn thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditDiagnosticProvisional(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/patientdiagnoses/${action.payload.uuidDiagnostic}`,
        {
          ...action.payload.paramsEditDiagnostic,
        },
      ),
    );
    yield put(appointmentActions.handleEditDiagnosticProvisionalSuccess(res));
    message.success('Chỉnh sửa chuẩn đoán sơ bộ thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateDiagnosticProvisional(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/patientdiagnoses`, {
        ...action.payload.paramsCreateDiagnosticProvisional,
      }),
    );
    yield put(appointmentActions.handleCreateDiagnosticProvisionalSuccess(res));
    message.success('Thêm mới chuẩn đoán sơ bộ thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditDiagnostic(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/patientdiagnoses/${action.payload.id}`, {
        ...action.payload.formData,
      }),
    );
    if (action.payload.callbackSuccess) {
      action.payload.callbackSuccess();
    }
    yield put(appointmentActions.handleEditDiagnosticSucceeded(res));
    if (action.payload.formData.certainty === CERTAINTY.PROVISIONAL) {
      message.success(`Chỉnh sửa chẩn đoán sơ bộ thành công!`);
    } else {
      message.success(`Chỉnh sửa chẩn đoán xác định thành công!`);
    }
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateDiagnostic(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/patientdiagnoses`, {
        ...action.payload.formData,
      }),
    );
    if (action.payload.callbackSuccess) {
      action.payload.callbackSuccess();
    }
    yield put(appointmentActions.handleCreateDiagnosticSucceeded(res));
    if (action.payload.formData.certainty === CERTAINTY.PROVISIONAL) {
      message.success(`Thêm mới chẩn đoán sơ bộ thành công!`);
    } else {
      message.success(`Thêm mới chẩn đoán xác định thành công!`);
    }
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* fetchDiagnosisSaga(action: any) {
  try {
    const response = yield call(api.getConceptDiagnosisApi, action.payload);

    yield put(
      appointmentActionsSecond.fetchDiagnosisSucceeded(response.results),
    );
  } catch (err) {
    message.error(getMessageFromError(err));
  }
}

function* searchDiagnosisSaga(action: any) {
  try {
    const response = yield call(api.getConceptDiagnosisApi, action.payload);
    yield put(
      appointmentActionsSecond.fetchDiagnosisSucceeded(response.results),
    );
  } catch (err) {
    message.error(getMessageFromError(err));
  }
}

function* handleEditReasonGroup(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/appointmentscheduling/appointment/${action.payload.idAppointment}`,
        {
          ...action.payload.paramsReason,
        },
      ),
    );
    yield put(appointmentActions.handleEditReasonGroupSuccess(res));
    message.success('Chỉnh sửa huyết áp thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditAllergy(action: any) {
  try {
    const res = yield call(() => authorizedRequest.post(`/v1`, {}));
    yield put(appointmentActions.handleEditAllergySuccess(res));
    message.success('Chỉnh sửa huyết áp thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleEditPhysicalExamination(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/obs/${action.payload.uuidPhysicalExamination}`,
        { ...action.payload.paramsEditPhysicalExamination },
      ),
    );
    yield put(appointmentActions.handleEditPhysicalExaminationSuccess(res));
    message.success('Chỉnh sửa khám lâm sàng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreatePhysicalExamination(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/obs`, action.payload),
    );
    yield put(appointmentActions.handleCreatePhysicalExaminationSuccess(res));
    message.success('Thêm khám lâm sàng thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateChronic(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/condition`, action.payload),
    );
    yield put(appointmentActions.handleCreateChronicSucceeded(res));
    message.success('Thêm bệnh mãn tính thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleCreateClsOrder(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/order`, action.payload),
    );
    yield put(appointmentActions.handleCreateClsOrderSucceeded(res));
    message.success('Thêm chỉ định khám lâm sàng thành công!');
  } catch (error) {
    yield put(appointmentActions.handleCreateClsOrderFailed());
    message.error(getMessageFromError(error));
  }
}

function* handleCreateOrderDrug(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/order`, action.payload),
    );
    yield put(appointmentActions.handleCreateOrderDrugSucceeded(res));
    message.success('Thêm đơn thuốc thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(appointmentActions.handleCreateOrderDrugFailed());
  }
}

function* handleEditDrug(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/order/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(appointmentActions.handleEditDrugSuccess(res));
    message.success('Sửa đơn thuốc thành công!');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(appointmentActions.handleEditDrugFail());
  }
}

function* handleEditCls(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/order/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(appointmentActions.handleEditClsSuccess(res));
    message.success('Sửa chỉ định cận lâm sàng thành công!');
  } catch (error) {
    yield put(appointmentActions.handleEditClsFailed());
    message.error(getMessageFromError(error));
  }
}

function* getClsConceptOrder() {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/concept?v=full&class=ClsSet`),
    );

    yield put(appointmentActions.getClsConceptOrderSucceeded(res?.results));
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* getConceptDrugActiveIngredient(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(
        `/v1/concept?class=DrugActiveIngredient&q=${action.payload}`,
      ),
    );
    yield put(
      appointmentActions.getConceptDrugActiveIngredientSucceeded(res?.results),
    );
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* getSelectDrugConcept(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(
        `/v1/drug?conceptUuid=${action.payload.uuid}&v=full`,
      ),
    );
    yield put(
      appointmentActions.getSelectDrugConceptSucceeded({
        payload: res?.results,
        meta: action.payload,
      }),
    );
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* getSearchDrugConcept(action: any) {
  try {
    const url = action.payload
      ? `/v1/drug?q=${action.payload}&v=full`
      : `/v1/drug?v=full`;
    const res = yield call(() => authorizedRequest.get(url));
    yield put(
      appointmentActions.getSearchDrugConceptSucceeded({
        payload: res?.results,
        meta: action.payload,
      }),
    );
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* getDrugUnit() {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/concept/?class=DrugUnit`),
    );

    yield put(appointmentActions.getDrugUnitSucceeded(res?.results));
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* postAllergyRequested(actions) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `v1/patient/${actions?.payload?.id}/allergy`,
        actions?.payload?.body,
      ),
    );

    yield put(appointmentActionsSecond.postAllergySucceeded(response));
    message.success('Thêm mới dị ứng thành công!');
    yield put(appointmentActions.fetchAllergy(actions?.payload?.id));
  } catch (error) {
    yield put(appointmentActionsSecond.postAllergyFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* postConditionRequested(actions) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(`v1/condition`, actions?.payload),
    );

    yield put(appointmentActionsSecond.postConditionSucceeded(response));
    message.success('Thêm mới bệnh mãn tính thành công!');
    yield put(
      appointmentActions.fetchConditionHistory({
        patientId: actions?.payload?.patient,
      }),
    );
  } catch (error) {
    yield put(appointmentActionsSecond.postConditionFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* postFeedbackRequested(actions) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(`v1/feedback`, actions?.payload),
    );

    yield put(appointmentActionsSecond.postFeedbackSucceeded(response));
    message.success('Gửi tin nhắn thành công!');
  } catch (error) {
    yield put(appointmentActionsSecond.postFeedbackFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* postUpdateStatusAppointmentRequested(actions) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `v1/appointmentscheduling/appointment_phase9/${actions?.payload?.uuid}`,
        actions?.payload?.body,
      ),
    );

    yield put(
      appointmentActionsSecond.postUpdateStatusAppointmentSucceeded(response),
    );
    yield put(
      appointmentActions.fetchDetailAppointment(actions?.payload?.uuid),
    );
    message.success('Cập nhật trạng thái thành công!');
  } catch (error) {
    yield put(
      appointmentActionsSecond.postUpdateStatusAppointmentFailed(error),
    );
    message.error(getMessageFromError(error));
  }
}

function* createFollowing(action: any) {
  try {
    yield call(api.createFollowingApi, action.payload.form);

    yield message.success('Thêm mới theo dõi thành công!');
    yield call(action.payload.callbackSuccess);
  } catch (err) {
    message.error(getMessageFromError(err));
    yield put(appointmentActionsSecond.createFollowingFailed());
    if (action.payload.callbackFailed) {
      yield call(action.payload.callbackFailed);
    }
  }
}

function* updateFollowing(action: any) {
  try {
    yield call(
      api.updateFollowingApi,
      action.payload.followUuid,
      action.payload.form,
    );
    if (action.payload.form.followResult) {
      yield message.success('Cập nhật kết quả theo dõi thành công!');
    } else {
      yield message.success('Chỉnh sửa theo dõi thành công!');
    }
    yield call(action.payload.callbackSuccess);
  } catch (err) {
    if (action.payload.callbackFailed) {
      yield call(action.payload.callbackFailed);
    }
  }
}

export default function* sagas() {
  yield all([
    takeLatest(appointmentActions.fetchAppointment, handleFetchAppointmentList),
    takeLatest(appointmentActions.startCall, handleStartCall),
    takeLatest(
      appointmentActions.fetchDetailAppointment,
      handleFetchAppointmentDetail,
    ),
    takeLatest(
      appointmentActions.fetchExaminationHistory,
      listExaminationHistory,
    ),
    takeLatest(appointmentActions.fetchRelationship, fetchRelationship),
    takeLatest(appointmentActions.fetchAllergy, fetchAllergy),
    takeLatest(appointmentActions.fetchConditionHistory, fetchConditionHistory),
    takeLatest(appointmentActions.handleEditPulse, handleEditPulse),
    takeLatest(appointmentActions.handleCreatePulse, handleCreatePulse),
    takeLatest(appointmentActions.handleEditTemperature, handleEditTemperature),
    takeLatest(
      appointmentActions.handleCreateTemperature,
      handleCreateTemperature,
    ),
    takeLatest(
      appointmentActions.handleEditBloodPressure,
      handleEditBloodPressure,
    ),
    takeLatest(
      appointmentActions.handleCreateBloodPressure,
      handleCreateBloodPressure,
    ),
    takeLatest(appointmentActions.handleCreateHeight, handleCreateHeight),
    takeLatest(appointmentActions.handleEditHeight, handleEditHeight),
    takeLatest(appointmentActions.handleCreateWeight, handleCreateWeight),
    takeLatest(appointmentActions.handleEditWeight, handleEditWeight),
    takeLatest(
      appointmentActions.handleCreateRespiratoryRate,
      handleCreateRespiratoryRate,
    ),
    takeLatest(
      appointmentActions.handleEditRespiratoryRate,
      handleEditRespiratoryRate,
    ),
    takeLatest(appointmentActions.handleCreateAlcohol, handleCreateAlcohol),
    takeLatest(appointmentActions.handleEditAlcohol, handleEditAlcohol),
    takeLatest(appointmentActions.handleCreateCigarette, handleCreateCigarette),
    takeLatest(appointmentActions.handleEditCigarette, handleEditCigarette),
    takeLatest(
      appointmentActions.handleCreateHistoryOfFamily,
      handleCreateHistoryOfFamily,
    ),
    takeLatest(
      appointmentActions.handleEditHistoryOfFamily,
      handleEditHistoryOfFamily,
    ),
    takeLatest(
      appointmentActions.handleEditQuestionByPatient,
      handleEditQuestionByPatient,
    ),
    takeLatest(appointmentActions.handleCreateSystom, handleCreateSystom),
    takeLatest(appointmentActions.handleEditSystom, handleEditSystom),
    takeLatest(
      appointmentActions.handleCreateInstructions,
      handleCreateInstructions,
    ),
    takeLatest(
      appointmentActions.handleEditInstructions,
      handleEditInstructions,
    ),
    takeLatest(
      appointmentActions.handleEditDiagnosticProvisional,
      handleEditDiagnosticProvisional,
    ),
    takeLatest(appointmentActions.handleEditDiagnostic, handleEditDiagnostic),
    takeLatest(
      appointmentActions.handleCreateDiagnostic,
      handleCreateDiagnostic,
    ),
    takeLatest(
      appointmentActions.handleCreateDiagnosticProvisional,
      handleCreateDiagnosticProvisional,
    ),
    takeLatest(
      appointmentActions.handleCreateDrugsPresent,
      handleCreateDrugsPresent,
    ),
    takeLatest(
      appointmentActions.handleEditDrugsPresent,
      handleEditDrugsPresent,
    ),
    takeLatest(appointmentActions.handleEditReasonGroup, handleEditReasonGroup),
    takeLatest(appointmentActions.handleEditAllergy, handleEditAllergy),
    takeLatest(
      appointmentActions.handleEditPhysicalExamination,
      handleEditPhysicalExamination,
    ),
    takeLatest(
      appointmentActions.handleCancelAppointmentRequested,
      handleCancelAppointmentRequested,
    ),
    takeLatest(
      appointmentActions.handleCreatePhysicalExamination,
      handleCreatePhysicalExamination,
    ),
    takeLatest(appointmentActions.handleCreateChronic, handleCreateChronic),
    takeLatest(appointmentActions.handleCreateClsOrder, handleCreateClsOrder),
    takeLatest(appointmentActions.handleCreateOrderDrug, handleCreateOrderDrug),
    takeLatest(appointmentActions.getClsConceptOrder, getClsConceptOrder),
    // takeLatest(appointmentActions.getCLSOrder, getCLSOrder),
    takeLatest(
      appointmentActions.getConceptDrugActiveIngredient,
      getConceptDrugActiveIngredient,
    ),
    takeLatest(appointmentActions.handleEditDrug, handleEditDrug),
    takeLatest(appointmentActions.handleEditCls, handleEditCls),
    takeLatest(appointmentActions.getSelectDrugConcept, getSelectDrugConcept),
    takeLatest(appointmentActions.getSearchDrugConcept, getSearchDrugConcept),
    takeLatest(appointmentActions.getDrugUnit, getDrugUnit),
    takeLatest(appointmentActionsSecond.fetchDiagnosis, fetchDiagnosisSaga),
    takeLatest(appointmentActionsSecond.searchDiagnosis, searchDiagnosisSaga),
    takeLatest(appointmentActionsSecond.createFollowing, createFollowing),
    takeLatest(appointmentActionsSecond.updateFollowing, updateFollowing),
    takeLatest(
      appointmentActionsSecond.postAllergyRequested,
      postAllergyRequested,
    ),
    takeLatest(
      appointmentActionsSecond.postConditionRequested,
      postConditionRequested,
    ),
    takeLatest(
      appointmentActionsSecond.postFeedbackRequested,
      postFeedbackRequested,
    ),
    takeLatest(
      appointmentActionsSecond.postUpdateStatusAppointmentRequested,
      postUpdateStatusAppointmentRequested,
    ),
  ]);
}
