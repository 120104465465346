import { AppointmentReducerState } from 'types/reducer/appointment/AppointmentReducerState';
import { handleActions } from 'redux-actions';
import { initModel } from 'types/ApiListModel';
import { initDetailModel } from 'types/ApiDetailModel';
import { ActionModel } from 'types/ActionModel';
import StoreState from 'types/StoreModel';

// #region SECTION
type Section = {
  getAppointment: Function;
  getSelectedAppointment: Function;
};

const getAppointment = (state: StoreState) => state.appointment;
const getSelectedAppointment = (state: StoreState, id: string) => {
  return state.appointment.detailAppointment.selectedData;
};

export const selectors: Section = {
  getAppointment,
  getSelectedAppointment,
};
// #endregion

// #region REDUCER
const InitialState: AppointmentReducerState = {
  appointment: initModel,
  detailAppointment: initDetailModel,
  examinationHistory: [],
  relationship: initModel,
  conceptDrug: initModel,
  ConceptDrugActiveIngredient: initDetailModel,
  allergy: initModel,
  conditionHistory: initModel,
  dataCancel: {},
  errorCancel: '',
  dataAddAlcohol: {},
  dataAddCigarette: {},
  dataAddHeight: {},
  dataAddHistoryOfFamily: {},
  dataAddInstructions: {},
  dataAddPulse: {},
  dataAddRespiratoryRate: {},
  dataAddSystom: {},
  dataAddTemperature: {},
  dataAddWeight: {},
  dataAddPhysicalExamination: {},
  dataAddDrugsPresent: {},
  dataEditAlcohol: {},
  dataEditCigarette: {},
  dataEditHeight: {},
  dataEditHistoryOfFamily: {},
  dataEditInstructions: {},
  dataEditPulse: {},
  dataEditRespiratoryRate: {},
  dataEditSystom: {},
  dataEditTemperature: {},
  dataEditWeight: {},
  dataEditPhysicalExamination: {},
  dataEditDrugsPresent: {},
  dataAddChronic: {},
  dataAddOrder: {},
  dataAddOrderDrug: {},
  dataCLSConcept: [],
  diagnosis: [],
  drugUnit: [],
  dataConceptDrugActiveIngredient: [],
  isExaminationHistoryLoading: false,
  isLoadingCancelPhone: false,
  isCreateSystem: false,
  isLoadingCreateSystem: false,
  isAddAllergy: false,
  isLoadingAddAllergy: false,
  isAddChronic: false,
  isLoadingAddChronic: false,
  isLoadingCreateDrugsPresent: false,
  isAddCreateDrugsPresent: false,
  isAddAlcohol: false,
  isLoadingCLSOrder: false,
  isLoadingAddAlcohol: false,
  isAddCreateCigarette: false,
  isLoadingCreateCigarette: false,
  isAddCreateHistoryOfFamily: false,
  isLoadingCreateHistoryOfFamily: false,
  isLoadingFeedback: false,
  isCreateFeedback: false,
  isUpdateStatusAppointment: false,
  isLoadingUpdateStatusAppointment: false,
  isAddPhysicalExamination: false,
  isLoadingAddPhysicalExamination: false,
  dataBloodPressure: null,
  isLoadingOrderDrug: false,
  isAddOrderDrug: false,
  isEditCLSOrder: false,
  isLoadingOrder: false,
  isAddOrderCls: false,
  isLoadingCreateInstructions: false,
  isAddCreateInstructions: false,
  isFetchDiagnosis: false,
  isSearchingDiagnosis: false,
};

const appointmentReducer = handleActions<AppointmentReducerState, ActionModel>(
  {
    HANDLE_UPDATE_BODY_INFO: state => {
      return {
        ...state,
        isLoading: true,
      };
    },
    HANDLE_UPDATE_BODY_INFO_SUCCESS: (state, action) => {
      return {
        ...state,
        dataUpdateBodyInfo: action.payload,
        isLoading: false,
      };
    },
    FETCH_APPOINTMENT: (state, action) => {
      return {
        ...state,
        appointment: { ...state.appointment },
        isLoading: true,
      };
    },

    FETCH_APPOINTMENT_SUCCESS: (state: any, action: any) => {
      return {
        ...state,
        appointment: {
          ...state.appointment,
          data: action.payload.results,
          total: action.payload.totalCount,
        },

        isLoading: false,
      };
    },

    FETCH_DETAIL_APPOINTMENT: (state, action) => {
      return {
        ...state,
        detailAppointment: {
          isLoading: true,
          selectedData: null,
        },
      };
    },

    FETCH_DETAIL_APPOINTMENT_SUCCESS: (state, action: any) => {
      return {
        ...state,
        detailAppointment: {
          isLoading: false,
          selectedData: action.payload,
        },
      };
    },

    FETCH_EXAMINATION_HISTORY: (state, action) => {
      return {
        ...state,
        isExaminationHistoryLoading: true,
        examinationHistory: null,
      };
    },

    FETCH_EXAMINATION_HISTORY_SUCCESS: (state, action) => {
      return {
        ...state,
        examinationHistory: action.payload,
        isExaminationHistoryLoading: false,
      };
    },

    FETCH_EXAMINATION_HISTORY_FAILED: (state, action) => {
      return {
        ...state,
        isExaminationHistoryLoading: false,
      };
    },

    FETCH_RELATIONSHIP: (state, action) => {
      return {
        ...state,
        relationship: { ...state.relationship },
        isLoading: true,
      };
    },

    FETCH_RELATIONSHIP_SUCCESS: (state, action) => {
      return {
        ...state,
        relationship: {
          ...state.relationship,
          ...action.payload,
        },
        isLoading: false,
      };
    },

    FETCH_ALLERGY: (state, action) => {
      return {
        ...state,
        allergy: {
          allergy: [],
          loading: true,
        },
      };
    },

    FETCH_ALLERGY_SUCCESS: (state, action) => {
      return {
        ...state,
        allergy: {
          allergy: action.payload,
          loading: false,
        },
      };
    },

    // concept drug

    FETCH_CONCEPT_DRUG: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: [],
          loading: true,
        },
      };
    },

    FETCH_CONCEPT_DRUG_SUCCESS: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: action.payload,
          loading: false,
        },
      };
    },

    FETCH_CONDITION_HISTORY: (state, action) => {
      return {
        ...state,
        conditionHistory: { ...state.conditionHistory },
        isLoading: true,
      };
    },

    FETCH_CONDITION_HISTORY_SUCCESS: (state, action) => {
      return {
        ...state,
        conditionHistory: {
          ...state.conditionHistory,
          ...action.payload,
        },
        isLoading: false,
      };
    },
    HANDLE_EDIT_PULSE: state => {
      return {
        ...state,
        isLoadingUpdatePulse: true,
      };
    },
    HANDLE_EDIT_PULSE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataPulse: action.payload,
        isLoadingUpdatePulse: false,
      };
    },
    HANDLE_CREATE_PULSE: state => {
      return {
        ...state,
        dataAddPulse: null,
        isLoadingCreatePulse: true,
      };
    },
    HANDLE_CREATE_PULSE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddPulse: action.payload,
        isLoadingCreatePulse: false,
      };
    },
    // for temperature
    HANDLE_EDIT_TEMPERATURE: state => {
      return {
        ...state,
        dataEditTemperature: null,
        isLoadingEditTemperature: true,
      };
    },
    HANDLE_EDIT_TEMPERATURE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditTemperature: action.payload,
        isLoadingEditTemperature: false,
      };
    },
    HANDLE_CREATE_TEMPERATURE: state => {
      return {
        ...state,
        dataAddTemperature: null,
        isLoadingCreateTemperature: true,
      };
    },
    HANDLE_CREATE_TEMPERATURE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddTemperature: action.payload,
        isLoadingCreateTemperature: false,
      };
    },
    // chronic

    HANDLE_CREATE_CHRONIC: state => {
      return {
        ...state,
        dataAddChronic: null,
        isLoadingDataAddChronic: true,
      };
    },
    HANDLE_CREATE_CHRONIC_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddChronic: action.payload,
        isLoadingDataAddChronic: false,
      };
    },
    // for blood pressure
    HANDLE_EDIT_BLOOD_PRESSURE: state => {
      return {
        ...state,
        isLoadingEditBloodPressure: true,
      };
    },
    HANDLE_EDIT_BLOOD_PRESSURE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataBloodPressure: action.payload,
        isLoadingEditBloodPressure: false,
      };
    },
    HANDLE_CREATE_BLOOD_PRESSURE: state => {
      return {
        ...state,
        isLoadingCreateBloodPressure: true,
      };
    },
    HANDLE_CREATE_BLOOD_PRESSURE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataBloodPressure: action.payload,
        isLoadingCreateBloodPressure: false,
      };
    },
    // for height
    HANDLE_EDIT_HEIGHT: state => {
      return {
        ...state,
        dataEditHeight: null,
        isLoadingEditHeight: true,
      };
    },
    HANDLE_EDIT_HEIGHT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditHeight: action.payload,
        isLoadingEditHeight: false,
      };
    },
    HANDLE_CREATE_HEIGHT: state => {
      return {
        ...state,
        dataAddHeight: null,
        isLoadingCreateHeight: true,
      };
    },
    HANDLE_CREATE_HEIGHT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddHeight: action.payload,
        isLoadingCreateHeight: false,
      };
    },

    // for weight
    HANDLE_EDIT_WEIGHT: state => {
      return {
        ...state,
        dataEditWeight: null,
        isLoadingEditWeight: true,
      };
    },
    HANDLE_EDIT_WEIGHT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditWeight: action.payload,
        isLoadingEditWeight: false,
      };
    },
    HANDLE_CREATE_WEIGHT: state => {
      return {
        ...state,
        dataAddWeight: null,
        isLoadingCreateWeight: true,
      };
    },
    HANDLE_CREATE_WEIGHT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddWeight: action.payload,
        isLoadingCreateWeight: false,
      };
    },

    // for respiratory rate
    HANDLE_EDIT_RESPIRATORY_RATE: state => {
      return {
        ...state,
        dataEditRespiratoryRate: null,
        isLoadingEditRespiratoryRate: true,
      };
    },
    HANDLE_EDIT_RESPIRATORY_RATE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditRespiratoryRate: action.payload,
        isLoadingEditRespiratoryRate: false,
      };
    },
    HANDLE_CREATE_RESPIRATORY_RATE: state => {
      return {
        ...state,
        dataAddRespiratoryRate: null,
        isLoadingCreateRespiratoryRate: true,
      };
    },
    HANDLE_CREATE_RESPIRATORY_RATE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddRespiratoryRate: action.payload,
        isLoadingCreateRespiratoryRate: false,
      };
    },

    // for alcohol
    HANDLE_EDIT_AlCOHOL: state => {
      return {
        ...state,
        dataEditAlcohol: null,
        isLoadingEditAlcohol: true,
      };
    },
    HANDLE_EDIT_AlCOHOL_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditAlcohol: action.payload,
        isLoadingEditAlcohol: false,
      };
    },
    HANDLE_CREATE_AlCOHOL: state => {
      return {
        ...state,
        dataAddAlcohol: null,
        isLoadingCreateAlcohol: true,
      };
    },
    HANDLE_CREATE_AlCOHOL_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddAlcohol: action.payload,
        isLoadingCreateAlcohol: false,
      };
    },

    // for alcohol
    HANDLE_EDIT_CIGARETTE: state => {
      return {
        ...state,
        dataEditCigarette: null,
        isLoadingEditCigarette: true,
      };
    },
    HANDLE_EDIT_CIGARETTE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditCigarette: action.payload,
        isLoadingEditCigarette: false,
      };
    },
    HANDLE_CREATE_CIGARETTE: state => {
      return {
        ...state,
        isAddCreateCigarette: false,
        isLoadingCreateCigarette: true,
      };
    },
    HANDLE_CREATE_CIGARETTE_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddCigarette: action.payload,
        isAddCreateCigarette: true,
        isLoadingCreateCigarette: false,
      };
    },
    HANDLE_CREATE_CIGARETTE_FAILED: (state, action) => {
      return {
        ...state,
        isAddCreateCigarette: false,
        isLoadingCreateCigarette: false,
      };
    },
    // for history of family
    HANDLE_EDIT_HISTORY_OF_FAMILY: state => {
      return {
        ...state,
        dataEditHistoryOfFamily: null,
        isLoadingEditHistoryOfFamily: true,
      };
    },
    HANDLE_EDIT_HISTORY_OF_FAMILY_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditHistoryOfFamily: action.payload,
        isLoadingEditHistoryOfFamily: false,
      };
    },
    HANDLE_CREATE_HISTORY_OF_FAMILY: state => {
      return {
        ...state,
        isAddCreateHistoryOfFamily: false,
        isLoadingCreateHistoryOfFamily: true,
      };
    },
    HANDLE_CREATE_HISTORY_OF_FAMILY_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddHistoryOfFamily: action.payload,
        isAddCreateHistoryOfFamily: true,
        isLoadingCreateHistoryOfFamily: false,
      };
    },
    HANDLE_CREATE_HISTORY_OF_FAMILY_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingCreateHistoryOfFamily: false,
        isAddCreateHistoryOfFamily: false,
      };
    },
    // for question

    HANDLE_EDIT_QUESTION_BY_PATIENT: state => {
      return {
        ...state,
        dataEditQuestionByPatient: null,
        isLoadingEditQuestionByPatient: true,
      };
    },
    HANDLE_EDIT_QUESTION_BY_PATIENT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditQuestionByPatient: action.payload,
        isLoadingEditQuestionByPatient: false,
      };
    },

    // for systom
    HANDLE_EDIT_SYSTOM: state => {
      return {
        ...state,
        dataEditSystom: null,
        isLoadingEditSystom: true,
      };
    },
    HANDLE_EDIT_SYSTOM_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditSystom: action.payload,
        isLoadingEditSystom: false,
      };
    },
    HANDLE_CREATE_SYSTOM: state => {
      return {
        ...state,
        isCreateSystem: false,
        isLoadingCreateSystem: true,
      };
    },
    HANDLE_CREATE_SYSTOM_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddSystom: action.payload,
        isCreateSystem: true,
        isLoadingCreateSystem: false,
      };
    },
    HANDLE_CREATE_SYSTOM_FAILED: (state, action) => {
      return {
        ...state,
        isCreateSystem: false,
        isLoadingCreateSystem: false,
      };
    },

    // for instructions
    HANDLE_EDIT_INSTRUCTIONS: state => {
      return {
        ...state,
        dataEditInstructions: null,
        isLoadingEditInstructions: true,
      };
    },
    HANDLE_EDIT_INSTRUCTIONS_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditInstructions: action.payload,
        isLoadingEditInstructions: false,
      };
    },
    HANDLE_CREATE_INSTRUCTIONS: state => {
      return {
        ...state,
        isLoadingCreateInstructions: true,
        isAddCreateInstructions: false,
      };
    },
    HANDLE_CREATE_INSTRUCTIONS_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddInstructions: action.payload,
        isLoadingCreateInstructions: false,
        isAddCreateInstructions: true,
      };
    },
    HANDLE_CREATE_INSTRUCTIONS_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingCreateInstructions: false,
        isAddCreateInstructions: false,
      };
    },

    HANDLE_EDIT_DIAGNOSTIC_PROVISIONAL: state => {
      return {
        ...state,
        dataEditDiagnorsticProvisional: null,
        isLoadingEditDiagnorsticProvisional: true,
      };
    },
    HANDLE_EDIT_DIAGNOSTIC_PROVISIONAL_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditDiagnorsticProvisional: action.payload,
        isLoadingEditDiagnorsticProvisional: false,
      };
    },

    HANDLE_CREATE_DIAGNOSTIC_PROVISIONAL: state => {
      return {
        ...state,
        dataCreateDiagnorsticProvisional: null,
        isLoadingCreateDiagnorsticProvisional: true,
      };
    },
    HANDLE_CREATE_DIAGNOSTIC_PROVISIONAL_SUCCESS: (state, action) => {
      return {
        ...state,
        dataCreateDiagnorsticProvisional: action.payload,
        isLoadingCreateDiagnorsticProvisional: false,
      };
    },

    HANDLE_EDIT_DIAGNOSTIC_CONFIRMED: state => {
      return {
        ...state,
        dataEditDiagnorsticConfirmed: null,
        isLoadingEditDiagnorsticConfirmed: true,
      };
    },
    HANDLE_EDIT_DIAGNOSTIC_CONFIRMED_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditDiagnorsticConfirmed: action.payload,
        isLoadingEditDiagnorsticConfirmed: false,
      };
    },
    HANDLE_CREATE_DIAGNOSTIC_CONFIRMED: state => {
      return {
        ...state,
        dataCreateDiagnorsticConfirmed: null,
        isLoadingCreateDiagnorsticConfirmed: true,
      };
    },
    HANDLE_CREATE_DIAGNOSTIC_CONFIRMED_SUCCESS: (state, action) => {
      return {
        ...state,
        dataCreateDiagnorsticConfirmed: action.payload,
        isLoadingCreateDiagnorsticConfirmed: false,
      };
    },

    HANDLE_EDIT_DRUGS_PRESENT: state => {
      return {
        ...state,
        dataEditDrugsPresent: null,
        isLoadingEditDrugsPresent: true,
      };
    },
    HANDLE_EDIT_DRUGS_PRESENT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditDrugsPresent: action.payload,
        isLoadingEditDrugsPresent: false,
      };
    },
    HANDLE_CREATE_DRUGS_PRESENT: state => {
      return {
        ...state,
        isLoadingCreateDrugsPresent: true,
        isAddCreateDrugsPresent: false,
      };
    },
    HANDLE_CREATE_DRUGS_PRESENT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddDrugsPresent: action?.payload,
        isLoadingCreateDrugsPresent: false,
        isAddCreateDrugsPresent: true,
      };
    },
    HANDLE_CREATE_DRUGS_PRESENT_FAILED: state => {
      return {
        ...state,
        isLoadingCreateDrugsPresent: false,
        isAddCreateDrugsPresent: false,
      };
    },

    HANDLE_EDIT_REASON_GROUP: state => {
      return {
        ...state,
        dataEditReasonGroup: null,
        isLoadingEditReasonGroup: true,
      };
    },
    HANDLE_EDIT_REASON_GROUP_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditReasonGroup: action.payload,
        isLoadingEditReasonGroup: false,
      };
    },
    HANDLE_EDIT_ALLERGY: state => {
      return {
        ...state,
        dataEditAllergy: null,
        isLoadingEditAllergy: true,
      };
    },
    HANDLE_EDIT_ALLERGY_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditAllergy: action.payload,
        isLoadingEditAllergy: false,
      };
    },

    HANDLE_EDIT_DRUG: state => {
      return {
        ...state,
        isLoadingOrderDrug: true,
        isAddOrderDrug: true,
      };
    },
    HANDLE_EDIT_DRUG_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddOrderDrug: action.payload,
        isLoadingOrderDrug: false,
        isAddOrderDrug: true,
      };
    },

    HANDLE_EDIT_DRUG_FAIL: (state, action) => {
      return {
        ...state,
        isLoadingOrderDrug: false,
        isAddOrderDrug: false,
      };
    },

    HANDLE_EDIT_CLS: state => {
      return {
        ...state,
        isLoadingCLSOrder: true,
        isEditCLSOrder: false,
      };
    },
    HANDLE_EDIT_CLS_SUCCESS: (state, action) => {
      return {
        ...state,
        isLoadingCLSOrder: false,
        isEditCLSOrder: true,
      };
    },
    HANDLE_EDIT_CLS_FAILED: state => {
      return {
        ...state,
        isLoadingCLSOrder: false,
        isEditCLSOrder: false,
      };
    },

    HANDLE_EDIT_PHYSICAL_EXAMINATION: state => {
      return {
        ...state,
        dataEditPhysicalExamination: null,
        isLoadingEditPhysicalExamination: true,
      };
    },
    HANDLE_EDIT_PHYSICAL_EXAMINATION_SUCCESS: (state, action) => {
      return {
        ...state,
        dataEditPhysicalExamination: action.payload,
        isLoadingEditPhysicalExamination: false,
      };
    },

    HANDLE_CREATE_PHYSICAL_EXAMINATION: state => {
      return {
        ...state,
        isAddPhysicalExamination: false,
        isLoadingAddPhysicalExamination: true,
      };
    },
    HANDLE_CREATE_PHYSICAL_EXAMINATION_SUCCESS: (state, action) => {
      return {
        ...state,
        dataAddPhysicalExamination: action.payload,
        isLoadingAddPhysicalExamination: false,
        isAddPhysicalExamination: true,
      };
    },
    HANDLE_CREATE_PHYSICAL_EXAMINATION_FAILED: state => {
      return {
        ...state,
        isLoadingAddPhysicalExamination: false,
        isAddPhysicalExamination: false,
      };
    },

    HANDLE_DISPLAY_MODAL: state => {
      return {
        ...state,
        errorCancel: '',
      };
    },

    HANDLE_CANCEL_APPOINTMENT_REQUESTED: (state, action) => {
      return {
        ...state,
        dataCancel: null,
        errorCancel: '',
        isLoadingCancelPhone: true,
      };
    },
    HANDLE_CANCEL_APPOINTMENT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataCancel: action.payload,
        errorCancel: false,
        isLoadingCancelPhone: false,
      };
    },
    HANDLE_CANCEL_APPOINTMENT_FAILED: state => {
      return {
        ...state,
        errorCancel: true,
        isLoadingCancelPhone: false,
      };
    },
    HANDLE_CREATE_CLS_ORDER: state => {
      return {
        ...state,
        isLoadingOrder: true,
        isAddOrderCls: false,
      };
    },
    HANDLE_CREATE_CLS_ORDER_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddOrder: action.payload,
        isLoadingOrder: false,
        isAddOrderCls: true,
      };
    },
    HANDLE_CREATE_CLS_ORDER_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingOrder: false,
        isAddOrderCls: false,
      };
    },

    HANDLE_CREATE_ORDER_DRUG: state => {
      return {
        ...state,
        isLoadingOrderDrug: true,
        isAddOrderDrug: false,
      };
    },
    HANDLE_CREATE_ORDER_DRUG_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddOrderDrug: action.payload,
        isLoadingOrderDrug: false,
        isAddOrderDrug: true,
      };
    },

    HANDLE_CREATE_ORDER_DRUG_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingOrderDrug: false,
        isAddOrderDrug: false,
      };
    },

    GET_CLS_CONCEPT_ORDER: state => {
      return {
        ...state,
        dataCLSConcept: [],
      };
    },
    GET_CLS_CONCEPT_ORDER_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataCLSConcept: action.payload,
      };
    },
    GET_CONCEPT_DRUG_ACTIVE_INGREDIENT: state => {
      return {
        ...state,
        dataConceptDrugActiveIngredient: [],
      };
    },

    GET_CONCEPT_DRUG_ACTIVE_INGREDIENT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataConceptDrugActiveIngredient: action.payload,
      };
    },

    GET_SELECT_DRUG_CONCEPT: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: [],
          isLoading: false,
          selectedData: null,
        },
      };
    },

    GET_SELECT_DRUG_CONCEPT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: action.payload.payload,
          isLoading: false,
          selectedData: action.payload.meta,
        },
      };
    },

    GET_SEARCH_DRUG_CONCEPT: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: [],
          isLoading: false,
          selectedData: null,
        },
      };
    },

    GET_SEARCH_DRUG_CONCEPT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        conceptDrug: {
          data: action.payload.payload,
          isLoading: false,
          selectedData: action.payload.meta,
        },
      };
    },

    GET_DRUG_UNIT: (state, action) => {
      return {
        ...state,
        drugUnit: [],
      };
    },

    GET_DRUG_UNIT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        drugUnit: action.payload,
      };
    },

    POST_ALLERGY_REQUESTED: state => {
      return {
        ...state,
        isAddAllergy: false,
        isLoadingAddAllergy: true,
      };
    },
    POST_ALLERGY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isAddAllergy: true,
        isLoadingAddAllergy: false,
      };
    },
    POST_ALLERGY_FAILED: state => {
      return {
        ...state,
        isAddAllergy: false,
        isLoadingAddAllergy: false,
      };
    },

    POST_CONDITION_REQUESTED: state => {
      return {
        ...state,
        isAddChronic: false,
        isLoadingAddChronic: true,
      };
    },
    POST_CONDITION_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isAddChronic: true,
        isLoadingAddChronic: false,
      };
    },
    POST_CONDITION_FAILED: state => {
      return {
        ...state,
        isAddChronic: false,
        isLoadingAddChronic: false,
      };
    },
    HANDLE_CREATE_ALCOHOL: state => {
      return {
        ...state,
        isAddAlcohol: false,
        isLoadingAddAlcohol: true,
      };
    },
    HANDLE_CREATE_ALCOHOL_SUCCESS: state => {
      return {
        ...state,
        isAddAlcohol: true,
        isLoadingAddAlcohol: false,
      };
    },
    HANDLE_CREATE_ALCOHOL_FAILED: state => {
      return {
        ...state,
        isAddAlcohol: false,
        isLoadingAddAlcohol: false,
      };
    },
    POST_FEEDBACK_REQUESTED: state => {
      return {
        ...state,
        isCreateFeedback: false,
        isLoadingFeedback: true,
      };
    },
    POST_FEEDBACK_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isCreateFeedback: true,
        isLoadingFeedback: false,
      };
    },
    POST_FEEDBACK_FAILED: state => {
      return {
        ...state,
        isCreateFeedback: false,
        isLoadingFeedback: false,
      };
    },
    POST_UPDATE_STATUS_APPOINTMENT_REQUESTED: state => {
      return {
        ...state,
        isUpdateStatusAppointment: false,
        isLoadingUpdateStatusAppointment: true,
      };
    },
    POST_UPDATE_STATUS_APPOINTMENT_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isUpdateStatusAppointment: true,
        isLoadingUpdateStatusAppointment: false,
      };
    },
    POST_UPDATE_STATUS_APPOINTMENT_FAILED: state => {
      return {
        ...state,
        isUpdateStatusAppointment: false,
        isLoadingUpdateStatusAppointment: false,
      };
    },
    FETCH_DIAGNOSIS: (state, action) => {
      return {
        ...state,
        isFetchDiagnosis: true,
      };
    },
    FETCH_DIAGNOSIS_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isFetchDiagnosis: true,
        diagnosis: action.payload,
      };
    },
    SEARCHING_DIAGNOSIS: (state, action) => {
      return {
        ...state,
        isSearchingDiagnosis: true,
      };
    },
    SEARCHING_DIAGNOSIS_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isSearching: true,
        diagnosis: action.payload,
        isSearchingDiagnosis: false,
      };
    },
  },
  InitialState,
  { prefix: 'APPOINTMENT' },
);

export default appointmentReducer;
// #endregion
