export const usageTimeList = [
  {
    display: 'Sáng',
    value: 'MORNING',
  },
  {
    display: 'Trưa',
    value: 'NOON',
  },
  {
    display: 'Chiều',
    value: 'AFTERNOON',
  },
  {
    display: 'Tối',
    value: 'EVENING',
  },
];

export const eatingStatus = [
  {
    display: 'Trước ăn',
    value: 'BEFORE',
  },
  {
    display: 'Sau ăn',
    value: 'AFTER',
  },
];
