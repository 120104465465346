import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { AppointmentListModel } from '../../types/reducer/appointment/AppointmentModel';
import { StartCallModel } from '../../types/reducer/appointment/StartCallModel';

const appointmentActions = createActions(
  {
    // #region APPOINTMENT
    FETCH_APPOINTMENT: (query: QueryString) => ({ query }),
    FETCH_APPOINTMENT_SUCCESS: appointmentPayload => appointmentPayload,

    FETCH_DETAIL_APPOINTMENT: (id: string) => id,
    FETCH_DETAIL_APPOINTMENT_SUCCESS: appointmentPayload => appointmentPayload,

    FETCH_EXAMINATION_HISTORY: (id: string) => id,
    FETCH_EXAMINATION_HISTORY_SUCCESS: examinationHistoryPayload =>
      examinationHistoryPayload,
    FETCH_EXAMINATION_HISTORY_FAILED: () => {},

    // FETCH_DIAGNOSIS: (id: string) => id,
    // FETCH_DIAGNOSIS_SUCCESS: diagnosisPayload => diagnosisPayload,

    FETCH_CONDITION_HISTORY: (id: string) => id,
    FETCH_CONDITION_HISTORY_SUCCESS: conditionHistoryPayload =>
      conditionHistoryPayload,

    FETCH_ALLERGY: (id: string) => id,
    FETCH_ALLERGY_SUCCESS: allergyPayload => allergyPayload,

    FETCH_RELATIONSHIP: (id: string) => id,
    FETCH_RELATIONSHIP_SUCCESS: relationshipPayload => relationshipPayload,

    DELETE_APPOINTMENT: (id: string) => id,
    DELETE_APPOINTMENT_SUCCESS: (appointmentPayload: AppointmentListModel) =>
      appointmentPayload,

    HANDLE_EDIT_PULSE: (id: string) => id,
    HANDLE_EDIT_PULSE_SUCCESS: bodyPulse => bodyPulse,

    HANDLE_CREATE_PULSE: payload => payload,
    HANDLE_CREATE_PULSE_SUCCESS: bodyPulse => bodyPulse,

    HANDLE_EDIT_TEMPERATURE: (id: string) => id,
    HANDLE_EDIT_TEMPERATURE_SUCCESS: bodyTemperature => bodyTemperature,

    HANDLE_CREATE_TEMPERATURE: payload => payload,
    HANDLE_CREATE_TEMPERATURE_SUCCESS: bodyTemperature => bodyTemperature,

    HANDLE_EDIT_BLOOD_PRESSURE: (id: string) => id,
    HANDLE_EDIT_BLOOD_PRESSURE_SUCCESS: bodyBloodPressure => bodyBloodPressure,

    HANDLE_CREATE_BLOOD_PRESSURE: payload => payload,
    HANDLE_CREATE_BLOOD_PRESSURE_SUCCESS: bodyBloodPressure =>
      bodyBloodPressure,

    HANDLE_EDIT_HEIGHT: (id: string) => id,
    HANDLE_EDIT_HEIGHT_SUCCESS: bodyEditHeight => bodyEditHeight,

    HANDLE_CREATE_HEIGHT: payload => payload,
    HANDLE_CREATE_HEIGHT_SUCCESS: bodyAddHeight => bodyAddHeight,

    HANDLE_EDIT_WEIGHT: (id: string) => id,
    HANDLE_EDIT_WEIGHT_SUCCESS: bodyEditWeight => bodyEditWeight,

    HANDLE_CREATE_WEIGHT: payload => payload,
    HANDLE_CREATE_WEIGHT_SUCCESS: bodyAddWeight => bodyAddWeight,

    HANDLE_EDIT_RESPIRATORY_RATE: (
      uuidBreathing: string,
      paramsEditBreathing: any,
    ) => ({
      uuidBreathing,
      paramsEditBreathing,
    }),
    HANDLE_EDIT_RESPIRATORY_RATE_SUCCESS: bodyEditRespiratoryRate =>
      bodyEditRespiratoryRate,

    HANDLE_CREATE_RESPIRATORY_RATE: payload => payload,
    HANDLE_CREATE_RESPIRATORY_RATE_SUCCESS: bodyAddRespiratoryRate =>
      bodyAddRespiratoryRate,

    HANDLE_EDIT_ALCOHOL: (id: string) => id,
    HANDLE_EDIT_ALCOHOL_SUCCESS: bodyEditAlcohol => bodyEditAlcohol,

    HANDLE_CREATE_ALCOHOL: payload => payload,
    HANDLE_CREATE_ALCOHOL_SUCCESS: bodyAddAlcohol => bodyAddAlcohol,

    HANDLE_EDIT_CIGARETTE: (id: string) => id,
    HANDLE_EDIT_CIGARETTE_SUCCESS: bodyEditCigarette => bodyEditCigarette,

    HANDLE_CREATE_CIGARETTE: payload => payload,
    HANDLE_CREATE_CIGARETTE_SUCCESS: payload => payload,

    HANDLE_EDIT_HISTORY_OF_FAMILY: (id: string) => id,
    HANDLE_EDIT_HISTORY_OF_FAMILY_SUCCESS: bodyEditHistoryOfFamily =>
      bodyEditHistoryOfFamily,

    HANDLE_CREATE_HISTORY_OF_FAMILY: payload => payload,
    HANDLE_CREATE_HISTORY_OF_FAMILY_SUCCESS: payload => payload,

    HANDLE_EDIT_SYSTOM: (uuidSystom: string, paramsEditSystom) => ({
      uuidSystom,
      paramsEditSystom,
    }),
    HANDLE_EDIT_SYSTOM_SUCCESS: bodyEditSystom => bodyEditSystom,

    HANDLE_CREATE_SYSTOM: payload => payload,
    HANDLE_CREATE_SYSTOM_SUCCESS: bodyAddSystom => bodyAddSystom,

    HANDLE_EDIT_INSTRUCTIONS: (id: string) => id,
    HANDLE_EDIT_INSTRUCTIONS_SUCCESS: bodyEditIntructions =>
      bodyEditIntructions,

    HANDLE_CREATE_INSTRUCTIONS: payload => payload,
    HANDLE_CREATE_INSTRUCTIONS_SUCCESS: bodyAddIntructions =>
      bodyAddIntructions,

    HANDLE_EDIT_QUESTION_BY_PATIENT: (id: string) => id,
    HANDLE_EDIT_QUESTION_BY_PATIENT_SUCCESS: bodyEditQuestionByPatient =>
      bodyEditQuestionByPatient,

    HANDLE_EDIT_DIAGNOSTIC_PROVISIONAL: (id: string) => id,
    HANDLE_EDIT_DIAGNOSTIC_PROVISIONAL_SUCCESS: bodyEditDiagnorsticProvisional =>
      bodyEditDiagnorsticProvisional,

    HANDLE_CREATE_DIAGNOSTIC_PROVISIONAL: (id: string) => id,
    HANDLE_CREATE_DIAGNOSTIC_PROVISIONAL_SUCCESS: bodyCreateDiagnorsticProvisional =>
      bodyCreateDiagnorsticProvisional,

    HANDLE_EDIT_DIAGNOSTIC: (id: string, formData, callbackSuccess) => ({
      id,
      formData,
      callbackSuccess,
    }),
    HANDLE_EDIT_DIAGNOSTIC_SUCCEEDED: bodyEditDiagnorstic =>
      bodyEditDiagnorstic,

    HANDLE_CREATE_DIAGNOSTIC: (formData: any, callbackSuccess) => ({
      formData,
      callbackSuccess,
    }),
    HANDLE_CREATE_DIAGNOSTIC_SUCCEEDED: bodyCreateDiagnorstic =>
      bodyCreateDiagnorstic,

    HANDLE_EDIT_DRUGS_PRESENT: (id: string) => id,
    HANDLE_EDIT_DRUGS_PRESENT_SUCCESS: bodyEditDrugsPresent =>
      bodyEditDrugsPresent,

    HANDLE_CREATE_DRUGS_PRESENT: (id: string) => id,
    HANDLE_CREATE_DRUGS_PRESENT_SUCCESS: bodyCreateDrugsPresent =>
      bodyCreateDrugsPresent,

    HANDLE_EDIT_REASON_GROUP: (id: string) => id,
    HANDLE_EDIT_REASON_GROUP_SUCCESS: bodyReason => bodyReason,

    HANDLE_EDIT_ALLERGY: (id: string) => id,
    HANDLE_EDIT_ALLERGY_SUCCESS: bodyAllergy => bodyAllergy,

    HANDLE_EDIT_PHYSICAL_EXAMINATION: (id: string) => id,
    HANDLE_EDIT_PHYSICAL_EXAMINATION_SUCCESS: (bodyPe: string) => bodyPe,

    HANDLE_CREATE_PHYSICAL_EXAMINATION: (id: string) => id,
    HANDLE_CREATE_PHYSICAL_EXAMINATION_SUCCESS: (bodyPe: string) => bodyPe,

    HANDLE_CREATE_CHRONIC: payload => payload,
    HANDLE_CREATE_CHRONIC_SUCCEEDED: payload => payload,

    HANDLE_CANCEL_APPOINTMENT_REQUESTED: (id: string) => id,
    HANDLE_CANCEL_APPOINTMENT_SUCCEEDED: payload => payload,
    HANDLE_CANCEL_APPOINTMENT_FAILED: error => error,
    HANDLE_DISPLAY_MODAL: payload => payload,

    HANDLE_CREATE_ORDER_DRUG: payload => payload,
    HANDLE_CREATE_ORDER_DRUG_SUCCEEDED: payload => payload,
    HANDLE_CREATE_ORDER_DRUG_FAILED: payload => payload,

    HANDLE_EDIT_DRUG: payload => payload,
    HANDLE_EDIT_DRUG_SUCCESS: payload => payload,
    HANDLE_EDIT_DRUG_FAIL: payload => payload,

    HANDLE_CREATE_CLS_ORDER: payload => payload,
    HANDLE_CREATE_CLS_ORDER_SUCCEEDED: payload => payload,
    HANDLE_CREATE_CLS_ORDER_FAILED: payload => payload,

    HANDLE_EDIT_CLS: payload => payload,
    HANDLE_EDIT_CLS_SUCCESS: payload => payload,
    HANDLE_EDIT_CLS_FAILED: payload => payload,

    GET_CONCEPT_DRUG_ACTIVE_INGREDIENT: payload => payload,
    GET_CONCEPT_DRUG_ACTIVE_INGREDIENT_SUCCEEDED: payload => payload,

    GET_SELECT_DRUG_CONCEPT: payload => payload,
    GET_SELECT_DRUG_CONCEPT_SUCCEEDED: payload => payload,

    GET_SEARCH_DRUG_CONCEPT: payload => payload,
    GET_SEARCH_DRUG_CONCEPT_SUCCEEDED: payload => payload,

    GET_CLS_CONCEPT_ORDER: payload => payload,
    GET_CLS_CONCEPT_ORDER_SUCCEEDED: payload => payload,

    GET_DRUG_UNIT: payload => payload,
    GET_DRUG_UNIT_SUCCEEDED: payload => payload,
    // #endregion

    // #region CALL
    START_CALL: (startCallPayload: StartCallModel) => startCallPayload,
    // #endregion
  },
  {
    prefix: 'APPOINTMENT',
  },
);

export default appointmentActions;
