import React, { useState, useEffect, FC, useRef } from 'react';

import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  Form,
  Select,
  Modal,
  Spin,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from 'models/store';
import * as _ from 'lodash';
import ResetPasswordDoctor from 'components/Doctor/ModalResetPasswordDoctor';
import ActionTable from '../../components/Share/Table/Action';
import * as STATUS from './doctor.const';
import * as doctorInfoActions from '../../store/doctor/doctor.action';
import { formatPhoneNumber } from '../../helpers/utils';
import './Information.scss';

interface ParamsInformationDoctorModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
  sortField?: string;
  sortDirection?: string;
}

const InformationOfDoctor: FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const selectStatus = useRef<any>(null);
  const selectSpecialist = useRef<any>(null);
  const inputSearch = useRef<any>(null);
  const [selectedUser, setSelectedUser] = useState<null | any>(null);
  const [showResetModal, setShowResetModal] = useState<boolean>(false);

  const [paramsDoctor, setParamsDoctor] = useState<
    ParamsInformationDoctorModel
  >({
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
  });
  const { Option } = Select;
  const { confirm } = Modal;

  const initFormSearch = {
    attributeType: '',
    q: '',
    value: '',
    status: '',
  };
  const healthFacilityData = useSelector(
    (state: StoreState) => state.doctorReducer.dataHealthFacility.results,
  );
  const doctorReducer = useSelector((state: StoreState) => state.doctorReducer);
  const handleResetPassword = user => {
    setShowResetModal(true);
    setSelectedUser(user);
  };
  const getHealthFacility = () => {
    dispatch(
      doctorInfoActions.getHealthFacilityRequested({
        v: 'full',
        class: 'Health facilities',
      }),
    );
  };
  useEffect(() => {
    getHealthFacility();
  }, []);
  // const [ParamsSpecialistModel] = useState<ParamsInformationDoctorModel>({
  //   v: 'full',
  //   sortField: 'DATE_CREATED',
  //   sortDirection: 'DESC',
  // });

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;

    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsDoctor({
      ...paramsDoctor,
      ...body,
    });
  };

  const getDoctorInfo = (val = {}) => {
    dispatch(
      doctorInfoActions.getDoctorRequested({
        ...paramsDoctor,
        ...val,
      }),
    );
  };
  // const getSpecialist = (valSpe = {}) => {
  //   dispatch(
  //     doctorInfoActions.getSpecialistRequested({
  //       ...ParamsSpecialistModel,
  //       ...valSpe,
  //     }),
  //   );
  // };

  useEffect(() => {
    getDoctorInfo();
    // getSpecialist({ class: 'Specialist' });
  }, [paramsDoctor]);

  // const arrSpecialist = doctorReducer?.dataSpecialist?.results;

  const listOption = healthFacilityData?.map((item, index) => {
    return (
      <>
        <Option value={item.uuid} key={index}>
          {item.display}
        </Option>
      </>
    );
  });

  // eslint-disable-next-line no-shadow
  const handleDeleteDoctor = record => {
    const onDelete = () => {
      const uuid = _.get(record, 'uuid', '');
      dispatch(doctorInfoActions.handleDeleteDoctorRequested(uuid));
      getDoctorInfo();
    };
    confirm({
      title: 'Bạn có chắc chắn xóa bác sĩ này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const onSearchDoctor = valueSearch => {
    if (valueSearch !== undefined && valueSearch !== null) {
      const searchInput = valueSearch?.q;
      const searchInput1 = _.replace(
        searchInput,
        STATUS.regexConvertSearch1,
        '',
      );
      const searchInput2 = _.replace(
        searchInput1,
        STATUS.regexConvertSearch2,
        '',
      );
      const paramsSearch = {
        ...valueSearch,
        q: searchInput2,
        attributeType: STATUS.ATTRIBUTE_TYPE_MAJORS,
      };
      getDoctorInfo(paramsSearch);
      setParamsDoctor({
        ...paramsDoctor,
        ...paramsSearch,
        startIndex: 1,
      });
    }
  };

  const onBlurSelect = val => {
    if (inputSearch.current !== null && inputSearch.current !== undefined) {
      const searchInput1 = _.replace(
        inputSearch?.current?.props?.value,
        STATUS.regexConvertSearch1,
        '',
      );
      const searchInput2 = _.replace(
        searchInput1,
        STATUS.regexConvertSearch2,
        '',
      );
      const paramsSearch = {
        q: searchInput2,
        value: val,
        status: selectStatus?.current?.props?.value,
      };
      const dataFromSearchForm = {
        ...paramsSearch,
        attributeType: STATUS.ATTRIBUTE_TYPE_MAJORS,
      };
      setParamsDoctor({
        ...paramsDoctor,
        ...dataFromSearchForm,
        startIndex: 1,
      });
    }
    // eslint-disable-next-line no-unused-expressions
    const refs: any = selectSpecialist?.current;
    refs.blur();
  };

  const onBlurSelect1 = val => {
    if (inputSearch.current !== null && inputSearch.current !== undefined) {
      const searchInput1 = _.replace(
        inputSearch?.current?.props?.value,
        STATUS.regexConvertSearch1,
        '',
      );
      const searchInput2 = _.replace(
        searchInput1,
        STATUS.regexConvertSearch2,
        '',
      );
      const paramsSearch = {
        q: searchInput2,
        value: selectSpecialist?.current?.props?.value,
        status: val,
      };
      const dataFromSearchForm = {
        ...paramsSearch,
        attributeType: STATUS.ATTRIBUTE_TYPE_MAJORS,
      };
      setParamsDoctor({
        ...paramsDoctor,
        ...dataFromSearchForm,
        startIndex: 1,
      });
    }

    // eslint-disable-next-line no-unused-expressions
    const refs: any = selectStatus?.current;
    refs.blur();
  };

  const columns = [
    {
      title: 'STT',
      width: '5%',
      key: '1',
      render: (value, row, index) => {
        if (
          paramsDoctor?.startIndex !== undefined &&
          paramsDoctor?.limit !== undefined &&
          paramsDoctor
        ) {
          return (
            (paramsDoctor?.startIndex - 1) * paramsDoctor?.limit + index + 1
          );
        }
      },
    },
    {
      title: 'Mã',
      key: '2',
      render: (value, row, index) => {
        return value.identifier;
      },
    },
    {
      key: '3',
      title: 'Họ và tên',
      render: (value, row, index) => {
        if (value && value !== null) {
          return value?.person?.preferredName?.display;
        }
      },
    },
    {
      key: '4',
      title: 'Username',
      render: (value, row, index) => {
        if (value && value !== null) {
          return value?.user?.display;
        }
      },
    },
    {
      key: '5',
      title: 'Số điện thoại',
      render: (value, index, row) => {
        if (value && value !== null) {
          return formatPhoneNumber(value?.user?.phoneNumber);
        }
      },
    },
    {
      key: '6',
      title: 'Email',
      render: (value, row, index) => {
        return value?.user?.email;
      },
    },
    {
      key: '7',
      title: 'Chuyên khoa',
      width: 350,
      render: (value, row, index) => {
        const dataMap = value?.attributes.filter(item =>
          item?.display?.includes('Specialist:'),
        );
        return (
          <div>
            {dataMap?.map((item, key) => (
              <span key={item.uuid} style={{ paddingRight: '4px' }}>
                {`${item.value.display}${
                  key !== dataMap.length - 1 ? ',' : ''
                }`}
              </span>
            ))}
          </div>
        );
      },
    },
    {
      key: '8',
      title: 'Trạng thái',
      width: 150,
      render: (value, row, index) => {
        if (value?.user?.status?.toUpperCase() === STATUS.INACTIVE_STATUS) {
          return 'Khóa';
        }
        if (value?.user?.status?.toUpperCase() === STATUS.ACTIVE_STATUS) {
          return 'Hoạt động';
        }
      },
    },
    {
      key: 'Action',
      width: 110,
      render: (row: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            hasResetPassword
            handleDelete={() => handleDeleteDoctor(row)}
            handleResetPassword={() => handleResetPassword(row)}
            handleDetail={() => {
              history.push(
                `/app/doctor/information/detail-doctor/${_.get(row, 'uuid')}`,
                { ...row },
              );
            }}
            handleEdit={() => {
              history.push(
                `/app/doctor/information/edit-doctor/${_.get(row, 'uuid')}`,
              );
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="doctor-information">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý thông tin bác sĩ</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Link type="button" to="/app/doctor/information/add-doctor">
              <Button type="primary">Thêm mới</Button>
            </Link>
          </Col>
        </Row>

        <Form onFinish={onSearchDoctor} initialValues={initFormSearch}>
          <Row style={{ marginTop: 20 }} gutter={[20, 0]}>
            <Col xs={6}>
              <span>Trạng thái</span>
              <Form.Item name="status">
                <Select
                  className="mt-2"
                  ref={selectStatus}
                  onSelect={e => onBlurSelect1(e)}
                >
                  <Option value="" key="98">
                    Tất cả
                  </Option>
                  <Option value="ACTIVE" key="99">
                    Hoạt động
                  </Option>
                  <Option value="IN_ACTIVE" key="100">
                    Khóa
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={6}>
              <span>Cơ sở y tế</span>
              <Form.Item name="value">
                <Select
                  className="mt-2"
                  ref={selectSpecialist}
                  onSelect={e => onBlurSelect(e)}
                >
                  <Option value="" key="101">
                    Tất cả
                  </Option>
                  {listOption}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7} style={{ marginTop: 30, paddingRight: 0 }}>
              <Form.Item name="q">
                <Input
                  ref={inputSearch}
                  placeholder="Nhập họ và tên, mã, số điện thoại, email..."
                />
              </Form.Item>
            </Col>
            <Col xs={4} style={{ marginTop: 30 }}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: doctorReducer?.isLoadingDoctor,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={doctorReducer?.dataDoctor?.results}
            pagination={{
              current: paramsDoctor.startIndex,
              pageSize: paramsDoctor.limit,
              total: doctorReducer?.dataDoctor?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
      {!setSelectedUser || (
        <ResetPasswordDoctor
          user={selectedUser}
          visible={showResetModal}
          handleCancel={() => {
            setShowResetModal(false);
          }}
        />
      )}
    </div>
  );
};

export default InformationOfDoctor;
