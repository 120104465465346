import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, Button, Table, Spin } from 'antd';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import Action from 'components/Share/Table/Action';
import ModalAddCustomer from 'components/Customer/ModalAddCustomer';
import ModalDetailCustomer from 'components/Customer/ModalDetailCustomer';
import ModalEditCustomer from 'components/Customer/ModalEditCustomer';
import ModalDetailHistory from 'components/Customer/ModalDetailHistory';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTransactionCustomerDetailRequested,
  getListTransactionRequested,
} from 'store/customer/customer.action';
import { useParams } from 'react-router';
import StoreState from 'models/store';
import { formatMoneyVND, formatMoney, formatPhoneNumber } from 'helpers/utils';
import ModalConfirmCustomer from 'components/Customer/ModalConfirmCustomer';
import { TransactionModel } from 'models/store/customer.model';
import {
  FORM_TRANSACTION,
  TYPE_TRANSACTION,
  TYPE_TRANSACTION_VALUE,
} from './customer.const';
import './TransactionDetail.scss';

interface ParamsTransactionCustomerModel {
  v?: string;
  totalCount: boolean;
  startIndex: number;
  limit: number;
  q?: string;
  type?: string[];
}

const TransactionDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const [isModalAddCustomer, setModalAddCustomer] = useState(false);
  const [isModalDetailCustomer, setModalDetailCustomer] = useState(false);
  const [isModalDetailHistory, setModalDetailHistory] = useState(false);
  const [isModalEditCustomer, setModalEditCustomer] = useState(false);
  const [isModalConfirmCustomer, setModalConfirmCustomer] = useState(false);
  const [paramsTransactionCustomer] = useState<ParamsTransactionCustomerModel>({
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
  });
  const [paramsListTransaction, setParamsListTransaction] = useState({
    user: '',
    v: 'full',
    startIndex: 1,
    totalCount: true,
    limit: 10,
    types: [
      TYPE_TRANSACTION_VALUE.ADMIN_ADD,
      TYPE_TRANSACTION_VALUE.ADMIN_DEDUCT,
      TYPE_TRANSACTION_VALUE.REFUND_PATIENT,
    ],
  });

  const [itemTransaction, setItemTransaction] = useState<TransactionModel>(
    {} as TransactionModel,
  );
  const {
    dataDetailTransaction,
    dataAddTransaction,
    dataListTransaction,
    isLoadingListTransaction,
    dataTransactionDetailCustomer,
  } = useSelector((state: StoreState) => state.customerReducer);

  const onOpenAddCustomer = () => {
    setModalAddCustomer(true);
  };
  const closeOpenAddCustomer = () => {
    setModalAddCustomer(false);
  };

  const onOpenDetailCustomer = (value: TransactionModel) => {
    setModalDetailCustomer(true);
    setItemTransaction(value);
  };
  const closeOpenDetailCustomer = () => {
    setModalDetailCustomer(false);
  };

  const onOpenEditCustomer = (value: TransactionModel) => {
    setModalEditCustomer(true);
    setItemTransaction(value);
  };
  const closeOpenEditCustomer = () => {
    setModalEditCustomer(false);
  };

  const onOpenDetailHistory = (event: any) => {
    event.preventDefault();
    setModalDetailHistory(true);
  };
  const closeOpenDetailHistory = () => {
    setModalDetailHistory(false);
  };

  const closeOpenConfirmCustomer = () => {
    setModalConfirmCustomer(false);
  };

  const getTransactionCustomerDetail = useCallback(() => {
    dispatch(
      getTransactionCustomerDetailRequested({
        id,
        params: paramsTransactionCustomer,
      }),
    );
  }, [dispatch, id, paramsTransactionCustomer]);

  useEffect(() => {
    getTransactionCustomerDetail();
  }, [dataTransactionDetailCustomer, paramsTransactionCustomer]);

  useEffect(() => {
    if (dataAddTransaction) {
      setModalAddCustomer(false);
      getTransactionCustomerDetail();
    }
  }, [dataAddTransaction, getTransactionCustomerDetail]);
  useEffect(() => {
    if (dataDetailTransaction?.uuid) {
      const params = {
        ...paramsListTransaction,
        user: _.get(
          dataDetailTransaction,
          'person.wallet.user.uuid',
          dataDetailTransaction?.person?.wallet?.uuid,
        ),
      };
      setParamsListTransaction(params);
      dispatch(getListTransactionRequested(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailTransaction]);

  const onChangePageTable = (pagination: any) => {
    const { current, pageSize } = pagination;

    setParamsListTransaction({
      ...paramsListTransaction,
      startIndex: current,
      limit: pageSize,
    });

    dispatch(
      getListTransactionRequested({
        ...paramsListTransaction,
        startIndex: current,
        limit: pageSize,
      }),
    );
  };

  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value: any, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Mã giao dịch',
      dataIndex: 'transactionCode',
      width: 150,
    },
    {
      title: 'Số tiền(VND)',
      render: (value: any) => {
        return formatMoney(_.get(value, 'amount', 0));
      },
    },
    {
      title: 'Hình thức',
      render: (value: any) => {
        return _.get(FORM_TRANSACTION, value.form, '');
      },
    },
    {
      title: 'Loại giao dịch',
      render: (value: any) => {
        return _.get(TYPE_TRANSACTION, value.transactionType, '');
      },
    },
    {
      title: '',
      width: 150,
      render: value => {
        if (value?.previousVersion !== null) {
          return (
            <Action
              hasDelete={false}
              isEdit={true}
              handleDetail={() => onOpenDetailCustomer(value)}
              handleEdit={() => onOpenEditCustomer(value)}
            />
          );
        }
        if (value?.previousVersion === null) {
          return (
            <Action
              hasDelete={false}
              isEdit={false}
              handleDetail={() => onOpenDetailCustomer(value)}
              handleEdit={() => onOpenEditCustomer(value)}
            />
          );
        }
      },
    },
  ];

  return (
    <div className="customer-transaction">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Xem chi tiết</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              disabled={
                dataDetailTransaction &&
                dataDetailTransaction?.user?.status === 'IN_ACTIVE'
              }
              loading={dataDetailTransaction?.person?.wallet?.uuid == undefined}
              onClick={onOpenAddCustomer}
            >
              <span style={{ color: 'black' }}>Thêm mới giao dịch</span>
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: 40 }} gutter={[0, 25]}>
          <Col xs={24}>
            Tên khách hàng: {dataDetailTransaction?.person?.display}
          </Col>
          <Col xs={24}>
            Mã KH:{' '}
            {_.get(dataDetailTransaction, 'identifiers[0].identifier', '')}
          </Col>
          <Col xs={24}>
            Số điện thoại:{' '}
            {formatPhoneNumber(
              _.get(dataDetailTransaction, 'person.attributes[0].value', null),
            )}
          </Col>
          <Col xs={24}>
            <Row>
              <Col xs={9}>
                Số dư:{' '}
                {formatMoneyVND(
                  _.get(dataDetailTransaction, 'person.wallet.balance', 0),
                )}
              </Col>
              <Col xs={15}>
                <span style={{ paddingRight: 30 }}>
                  Đã sử dụng:{' '}
                  {formatMoneyVND(
                    _.get(dataDetailTransaction, 'usedAmount', 0),
                  )}{' '}
                </span>{' '}
                <Link to="#" onClick={onOpenDetailHistory}>
                  Chi tiết
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: 15 }}>
          <Col xs={24}>
            <Table
              rowKey={record => Number(record.transactionCode)}
              pagination={{
                current: paramsListTransaction.startIndex,
                pageSize: paramsListTransaction.limit,
                total: dataListTransaction?.totalCount || 10,
                showSizeChanger: true,
                locale: { items_per_page: '/Trang' },
              }}
              onChange={onChangePageTable}
              loading={{
                spinning: isLoadingListTransaction,
                indicator: <Spin />,
                tip: 'Đang tải...',
              }}
              dataSource={dataListTransaction?.results || []}
              columns={columns}
            />
          </Col>
        </Row>
      </Card>
      {isModalAddCustomer && (
        <ModalAddCustomer
          show={isModalAddCustomer}
          isDetail={true}
          dataDetail={{
            phone: formatPhoneNumber(
              _.get(dataDetailTransaction, 'person.attributes[0].value', null),
            ),
            fullName: dataDetailTransaction?.person?.display,
            customerCode: _.get(
              dataDetailTransaction,
              'identifiers[0].identifier',
              '',
            ),
            wallet: _.get(dataDetailTransaction, 'person.wallet.uuid', ''),
          }}
          patient={_.get(dataDetailTransaction, 'person.uuid', '')}
          onHide={closeOpenAddCustomer}
        />
      )}

      {isModalEditCustomer && (
        <ModalEditCustomer
          show={isModalEditCustomer}
          onHide={closeOpenEditCustomer}
          itemTransaction={itemTransaction}
          customerCode={_.get(
            dataDetailTransaction,
            'identifiers[0].identifier',
            '',
          )}
          phone={formatPhoneNumber(
            _.get(dataDetailTransaction, 'person.attributes[0].value', null),
          )}
          dataProps={{
            ...itemTransaction,
            userName: dataDetailTransaction?.person?.display,
          }}
        />
      )}
      {isModalDetailCustomer && (
        <ModalDetailCustomer
          show={isModalDetailCustomer}
          onHide={closeOpenDetailCustomer}
          itemTransaction={{
            ...itemTransaction,
            userName: dataDetailTransaction?.person?.display,
          }}
          customerCode={_.get(
            dataDetailTransaction,
            'identifiers[0].identifier',
            '',
          )}
          phone={formatPhoneNumber(
            _.get(dataDetailTransaction, 'person.attributes[0].value', null),
          )}
        />
      )}
      {isModalDetailHistory && (
        <ModalDetailHistory
          show={isModalDetailHistory}
          onHide={closeOpenDetailHistory}
          user={paramsListTransaction.user}
        />
      )}

      {isModalConfirmCustomer && (
        <ModalConfirmCustomer
          show={isModalConfirmCustomer}
          onHide={closeOpenConfirmCustomer}
        />
      )}
    </div>
  );
};

export default TransactionDetail;
