import { createReducer } from 'typesafe-actions';

import { AuthenticateStoreState } from 'models/store/authenticate.model';
import { getCookie } from 'helpers/utils';
import { login, logoutSuccess } from './authenticate.action';

const initialState: AuthenticateStoreState = {
  cookies: getCookie(),
  userInfo: undefined,
  loading: false,
  providerUuid: '',
  error: '',
};

const authenticateReducer = createReducer<AuthenticateStoreState>(initialState)
  .handleAction(login.request, state => ({
    ...state,
    loading: true,
    error: '',
  }))

  .handleAction(login.success, (state, action) => ({
    ...state,
    cookies: action.payload.sessionId,
    userInfo: action.payload.userInfo,
    loading: false,
    providerUuid: action.payload.provider.uuid,
  }))
  .handleAction(login.failure, (state, action) => ({
    ...state,
    loading: false,
    error: action.payload,
  }))
  .handleAction(logoutSuccess, state => ({
    ...state,
    cookies: '',
    userInfo: undefined,
  }));

export default authenticateReducer;
