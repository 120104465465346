export class AppointmentSearchFormConst {
  static readonly APPOINTMENT_TYPE: string[] = [
    'Chuyên khoa Nhi',
    'Chuyên khoa Nội',
  ];

  static readonly STATUS = [
    {
      label: 'Tất cả',
      value: '',
    },
    {
      label: 'Sắp diễn ra',
      value: 'SCHEDULED',
    },
    {
      label: 'Đang diễn ra',
      value: 'INCONSULTATION',
    },
    {
      label: 'Chờ xét nghiệm',
      value: 'WAITING_EXAMINATION',
    },
    {
      label: 'Hoàn thành',
      value: 'COMPLETED',
    },
    {
      label: 'Trễ hẹn',
      value: 'MISSED',
    },
    {
      label: 'Đã hủy',
      value: 'CANCELLED',
    },
  ];

  static readonly PAYMENT_STATUS: string[] = [
    'Tất cả',
    'Đã thanh toán',
    'Chưa thanh toán',
  ];
}
