import React, { FC } from 'react';
import { USERNAME } from 'configs';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from 'models/store';
import * as authenticateAction from '../../../store/authenticate/authenticate.action';

interface FormValues {
  remember: boolean;
  userName: string;
  password: string;
}

const initValues = () => {
  if (localStorage.getItem(USERNAME) !== undefined) {
    return {
      remember: true,
      userName: localStorage.getItem(USERNAME),
      password: '',
    };
  }
  return {
    remember: true,
    userName: '',
    password: '',
  };
};

const LoginForm: FC = () => {
  const dispatch = useDispatch();
  const authenticateReducer = useSelector(
    (state: StoreState) => state.authenticate,
  );

  async function callLogin(values) {
    await dispatch(
      authenticateAction.login.request({
        userName: values.userName,
        password: values.password,
      }),
    );
  }

  const handleSubmit = values => {
    callLogin(values);
  };

  return (
    <Card className="w-3/5 lg:w-1/3 p-4 shadow-xl mt-4">
      <Form initialValues={initValues()} onFinish={handleSubmit}>
        <Form.Item
          name="userName"
          rules={[
            {
              required: true,
              message: 'Tên đăng nhập không được để trống',
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="text-base mb-2 mr-1" />}
            placeholder="Tên đăng nhập"
            type="string"
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Mật khẩu không được để trống',
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="text-base mb-2 mr-1" />}
            type="password"
            placeholder="Mật khẩu"
            autoComplete={'off'}
          />
        </Form.Item>
        <Form.Item className="flex text-center m-0">
          <Button
            type="primary"
            htmlType="submit"
            className="shadow-xl"
            loading={authenticateReducer?.loading}
          >
            Đăng nhập
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default LoginForm;
