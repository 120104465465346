import React, { useState, useEffect, FC, useCallback } from 'react';
import { Card, Row, Col, Button, Table, Input, Form, Spin } from 'antd';
import ModalAddCustomer from 'components/Customer/ModalAddCustomer';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from 'models/store';
import _ from 'lodash';
import { formatMoney, formatPhoneNumber } from 'helpers/utils';
import * as customerActions from '../../store/customer/customer.action';
import {
  regexConvertSearch1,
  regexConvertSearch2,
} from '../Doctor/doctor.const';
import './Transaction.scss';

interface ParamsTransactionCustomerModel {
  v?: string;
  totalCount: boolean;
  startIndex: number;
  limit: number;
  q?: string;
}

const Transaction: FC = () => {
  const dispatch = useDispatch();
  const [isModalAddCustomer, setModalAddCustomer] = useState(false);

  const [paramsTransactionCustomer, setParamsTransactionCustomer] = useState<
    ParamsTransactionCustomerModel
  >({ v: 'full', startIndex: 1, totalCount: true, limit: 10 });

  const customerReducer = useSelector(
    (state: StoreState) => state.customerReducer,
  );

  const onOpenAddCustomer = () => {
    setModalAddCustomer(true);
  };
  const closeOpenAddCustomer = () => {
    setModalAddCustomer(false);
  };

  const getTransactionCustomer = useCallback(
    (values = {}) => {
      dispatch(
        customerActions.getTransactionCustomerRequested({
          ...paramsTransactionCustomer,
          ...values,
        }),
      );
    },
    [dispatch, paramsTransactionCustomer],
  );

  const onFinish = (values: any) => {
    const searchInput1 = values?.q
      ? _.replace(values?.q, regexConvertSearch1, '')
      : '';
    const searchInput2 = _.replace(searchInput1, regexConvertSearch2, '');
    setParamsTransactionCustomer({
      ...paramsTransactionCustomer,
      ...values,
      q: searchInput2,
      startIndex: 1,
    });
  };

  const onChangePageTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsTransactionCustomer({ ...paramsTransactionCustomer, ...body });
  };

  useEffect(() => {
    getTransactionCustomer();
  }, [getTransactionCustomer, paramsTransactionCustomer]);

  useEffect(() => {
    getTransactionCustomer();
  }, []);

  useEffect(() => {
    if (customerReducer?.dataAddTransaction) {
      setModalAddCustomer(false);
      getTransactionCustomer();
    }
  }, [customerReducer?.dataAddTransaction, getTransactionCustomer]);

  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value, row, index) => {
        return (
          (paramsTransactionCustomer.startIndex - 1) *
            paramsTransactionCustomer.limit +
          index +
          1
        );
      },
    },
    {
      title: 'Mã',
      width: 120,
      render: (value: any) => {
        return _.get(value, 'identifiers[0].identifier', '');
      },
    },
    {
      title: 'Họ và tên',
      render: (value: any) => {
        return _.get(value, 'person.display', '');
      },
    },
    {
      title: 'Số điện thoại',
      render: (value: any) => {
        return formatPhoneNumber(
          _.get(value, 'person.attributes[0].value', null),
        );
      },
    },
    {
      title: 'Số dư(VND)',
      render: (value: any) => {
        if (formatMoney(_.get(value, 'person.wallet.balance', 0)) === null) {
          return 0;
        }
        return formatMoney(_.get(value, 'person.wallet.balance', 0));
      },
    },
    {
      title: '',
      width: 150,
      render: (value: any) => {
        return (
          <Link to={`/app/customer/transaction/${value?.uuid}`}>
            Xem chi tiết
          </Link>
        );
      },
    },
  ];

  return (
    <div className="customer-transaction">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý giao dịch và số dư tài khoản</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onOpenAddCustomer}>
              Thêm mới giao dịch
            </Button>
          </Col>
        </Row>

        <Form onFinish={onFinish}>
          <Row style={{ marginTop: 40 }} gutter={[20, 10]}>
            <Col xs={6}>
              <Form.Item name="q">
                <Input placeholder="Nhập mã, username, họ tên, số điện thoại..." />
              </Form.Item>
            </Col>
            <Col xs={6}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Col>
            <Col xs={12} />
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24}>
          <Table
            rowKey={record => record.uuid}
            pagination={{
              current: paramsTransactionCustomer.startIndex,
              pageSize: paramsTransactionCustomer.limit,
              total: customerReducer?.dataTransaction?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            dataSource={customerReducer?.dataTransaction?.results}
            columns={columns}
            onChange={onChangePageTable}
            loading={{
              spinning: customerReducer?.isLoadingTransaction,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
          />
        </Col>
      </Row>
      {isModalAddCustomer && (
        <ModalAddCustomer
          show={isModalAddCustomer}
          onHide={closeOpenAddCustomer}
        />
      )}
    </div>
  );
};

export default Transaction;
