import React, { FC, useState, useEffect } from 'react';
import { Card } from 'antd';
import * as _ from 'lodash';
import AppointmentTable from 'components/Appointment/Table';
import SearchForm from 'components/Appointment/SearchForm';
import { useDispatch, useSelector } from 'react-redux';
import appointmentActions from 'store/appointment/actions';
import * as reGex from '../Doctor/doctor.const';
import './Appointment.scss';

// interface ParamSearchModel {
//   v: string;
//   totalCount: boolean;
//   limit: number;
//   startIndex: number;
//   sortField: string;
//   sortDirection: string;
// }

const Appointment: FC = () => {
  const dispatch = useDispatch();

  const [paramSearch, setParamsSearch] = useState<any>({
    v: 'full',
    totalCount: true,
    limit: 10,
    startIndex: 1,
    sortDirection: 'DESC',
    sortField: 'DATE_STARTED',
  });

  const isUpdateStatusAppointment = useSelector(
    (state: any) => state.appointment.isUpdateStatusAppointment,
  );
  const dataCancel = useSelector((state: any) => state.appointment.dataCancel);
  const onFinish = values => {
    const { q, fromDate, toDate } = values;
    const removeStartSpace = _.replace(q, reGex.regexConvertSearch1, '');
    const removeEndSpace = _.replace(
      removeStartSpace,
      reGex.regexConvertSearch2,
      '',
    );
    setParamsSearch({
      ...paramSearch,
      ...values,
      q: removeEndSpace,
      fromDate:
        fromDate !== undefined && fromDate !== null && fromDate !== ''
          ? fromDate.format(`yyyy-MM-DDTHH:mm:ss`)
          : '',
      toDate:
        toDate !== undefined && toDate !== null && toDate !== ''
          ? toDate.format(`yyyy-MM-DDTHH:mm:ss`)
          : '',
      startIndex: 1,
      providerAttributeType: reGex.ATTRIBUTE_TYPE_SPECIALIST,
    });
  };

  const onChangePageTable = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsSearch({ ...paramSearch, ...body });
  };
  const handleSort = (type: string) => {
    setParamsSearch(prev => ({
      ...prev,
      sortDirection: type,
    }));
  };

  useEffect(() => {
    const condition =
      paramSearch?.status === undefined || paramSearch?.status === '';
    condition &&
      setParamsSearch({
        ...paramSearch,
        status: [
          'SCHEDULED',
          'INCONSULTATION',
          'WAITING_EXAMINATION',
          'COMPLETED',
          'CANCELLED',
          'MISSED',
        ],
      });

    paramSearch?.fromDate === '' && delete paramSearch.fromDate;
    paramSearch?.toDate === '' && delete paramSearch.toDate;
    dispatch(appointmentActions.fetchAppointment(paramSearch));
  }, [paramSearch, _.isEmpty(dataCancel) === false, isUpdateStatusAppointment]);

  return (
    <div className="appointment">
      <Card>
        <h1>Quản lý cuộc tư vấn</h1>
        <div className="w-4/5">
          <SearchForm onFinish={onFinish} />
        </div>
      </Card>
      <div className="table-appointment">
        <AppointmentTable
          paramSearch={paramSearch}
          onChangePageTable={onChangePageTable}
          onSort={handleSort}
        />
      </div>
    </div>
  );
};

export default Appointment;
