import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import {
  TransactionCustomerModel,
  DetailTransactionCustomerModel,
  ListTransactionModel,
} from 'models/store/customer.model';

export interface CustomerReducerState {
  dataTransaction: TransactionCustomerModel;
  isLoadingTransaction: boolean;
  dataDetailTransaction: DetailTransactionCustomerModel;
  isLoadingDetailTransaction: boolean;
  dataTransactionSearch: TransactionCustomerModel;
  isLoadingTransactionSearch: boolean;
  isLoadingAddTransaction: boolean;
  dataAddTransaction: any;
  isLoadingAddInformation: boolean;
  dataAddInformation: any;
  isLoadingEditInformation: boolean;
  dataEditInformation: any;
  dataListTransaction: ListTransactionModel;
  isLoadingListTransaction: boolean;
  dataListAppointment: any;
  isLoadingAppointment: boolean;
  dataPatientBookAppointment: ListTransactionModel;
  isLoadingPatientBookAppointment: boolean;
  isChangePasswordCustomer: boolean;
  isLoadingTransactionDetailCustomer: boolean;
  dataTransactionDetailCustomer: any;
  dataDeleteCustomer: any;
  isLoadingDelete: boolean;
  errorAdd: any;
  errorTransaction: any;
  listProvince: any;
  listDistrict: any;
  listJob: any;
  listInsurance: any;
}

const initialState: CustomerReducerState = {
  dataTransaction: {} as TransactionCustomerModel,
  isLoadingTransaction: false,
  dataDetailTransaction: {} as DetailTransactionCustomerModel,
  isLoadingDetailTransaction: false,
  dataTransactionSearch: {} as TransactionCustomerModel,
  isLoadingTransactionSearch: false,
  isLoadingAddTransaction: false,
  dataAddTransaction: null,
  dataListTransaction: {} as ListTransactionModel,
  isLoadingListTransaction: false,
  dataListAppointment: {},
  isLoadingAppointment: false,
  dataPatientBookAppointment: {} as ListTransactionModel,
  isLoadingPatientBookAppointment: false,
  isChangePasswordCustomer: false,
  isLoadingAddInformation: false,
  dataAddInformation: {} as ListTransactionModel,
  isLoadingEditInformation: false,
  dataEditInformation: {} as ListTransactionModel,
  dataTransactionDetailCustomer: {},
  isLoadingTransactionDetailCustomer: false,
  dataDeleteCustomer: {} as any,
  isLoadingDelete: false,
  errorAdd: '',
  errorTransaction: '',
  listProvince: [],
  listDistrict: [],
  listJob: [],
  listInsurance: [],
};

const customerReducer = handleActions<any, ActionModel>(
  {
    GET_TRANSACTION_CUSTOMER_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingTransaction: true,
      };
    },

    GET_TRANSACTION_CUSTOMER_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataTransaction: action.payload,
        isLoadingTransaction: false,
      };
    },

    GET_TRANSACTION_CUSTOMER_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingTransaction: false,
      };
    },
    GET_TRANSACTION_CUSTOMER_DETAIL_REQUESTED: (
      state: CustomerReducerState,
    ) => {
      return {
        ...state,
        dataDetailTransaction: {},
        isLoadingDetailTransaction: true,
      };
    },

    GET_TRANSACTION_CUSTOMER_DETAIL_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataDetailTransaction: action.payload,
        isLoadingDetailTransaction: false,
      };
    },

    GET_TRANSACTION_CUSTOMER_DETAIL_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingDetailTransaction: false,
      };
    },
    GET_TRANSACTION_CUSTOMER_SEARCH_REQUESTED: (
      state: CustomerReducerState,
    ) => {
      return {
        ...state,
        isLoadingTransactionSearch: true,
      };
    },

    GET_TRANSACTION_CUSTOMER_SEARCH_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataTransactionSearch: action.payload,
        isLoadingTransactionSearch: false,
      };
    },

    GET_TRANSACTION_CUSTOMER_SEARCH_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingTransactionSearch: false,
      };
    },
    POST_ADD_TRANSACTION_CUSTOMER_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAddTransaction: true,
        dataAddTransaction: null,
      };
    },

    POST_ADD_TRANSACTION_CUSTOMER_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataAddTransaction: action.payload,
        isLoadingAddTransaction: false,
      };
    },

    POST_ADD_TRANSACTION_CUSTOMER_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAddTransaction: false,
      };
    },

    POST_ADD_INFORMATION_CUSTOMER_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAddInformation: true,
        errorAdd: '',
      };
    },
    HANDLE_DISPLAY_MODAL_ADD: () => {
      return {
        errorAdd: '',
      };
    },

    HANDLE_DISPLAY_MODAL_EDIT_TRANSACTION: () => {
      return {
        errorTransaction: '',
      };
    },

    POST_ADD_INFORMATION_CUSTOMER_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataAddInformation: action.payload,
        isLoadingAddInformation: false,
        errorAdd: false,
      };
    },

    POST_ADD_INFORMATION_CUSTOMER_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAddInformation: false,
        errorAdd: true,
      };
    },

    POST_EDIT_INFORMATION_CUSTOMER_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingEditInformation: true,
        dataEditInformation: null,
      };
    },

    POST_EDIT_INFORMATION_CUSTOMER_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataEditInformation: action.payload,
        isLoadingEditInformation: false,
      };
    },

    POST_EDIT_INFORMATION_CUSTOMER_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingEditInformation: false,
      };
    },

    POST_EDIT_TRANSACTION_DETAIL_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingTransactionDetailCustomer: true,
        dataTransactionDetailCustomer: null,
        errorTransaction: '',
      };
    },

    POST_EDIT_TRANSACTION_DETAIL_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        isLoadingTransactionDetailCustomer: false,
        dataTransactionDetailCustomer: action.payload,
        errorTransaction: false,
      };
    },

    POST_EDIT_TRANSACTION_DETAIL_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingTransactionDetailCustomer: false,
        errorTransaction: true,
      };
    },

    GET_LIST_TRANSACTION_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingListTransaction: true,
        dataListTransaction: {},
      };
    },

    GET_LIST_TRANSACTION_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        dataListTransaction: action.payload,
        isLoadingListTransaction: false,
      };
    },

    GET_LIST_TRANSACTION_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingListTransaction: false,
      };
    },
    GET_LIST_APPOINTMENT_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAppointment: true,
        dataListAppointment: {},
      };
    },

    GET_LIST_APPOINTMENT_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        dataListAppointment: action.payload,
        isLoadingAppointment: false,
      };
    },

    GET_LIST_APPOINTMENT_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingAppointment: false,
      };
    },
    GET_PATIENT_BOOK_APPOINTMENT_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        dataPatientBookAppointment: {},
        isLoadingPatientBookAppointment: true,
      };
    },

    GET_PATIENT_BOOK_APPOINTMENT_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataPatientBookAppointment: action.payload,
        isLoadingPatientBookAppointment: false,
      };
    },

    GET_PATIENT_BOOK_APPOINTMENT_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isLoadingPatientBookAppointment: false,
      };
    },
    POST_CHANGE_PASSWORD_CUSTOMER_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
        dataChangePasswordCustomer: {},
        isChangePasswordCustomer: false,
      };
    },

    POST_CHANGE_PASSWORD_CUSTOMER_SUCCEEDED: (
      state: CustomerReducerState,
      action,
    ) => {
      return {
        ...state,
        dataChangePasswordCustomer: action.payload,
        isChangePasswordCustomer: true,
      };
    },

    POST_CHANGE_PASSWORD_CUSTOMER_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
        isChangePasswordCustomer: false,
      };
    },
    HANDLE_DELETE_CUSTOMER_REQUESTED: state => {
      return {
        ...state,
        isLoadingDelete: true,
      };
    },
    HANDLE_DELETE_CUSTOMER_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
        dataDeleteCustomer: action.payload,
      };
    },
    HANDLE_DELETE_CUSTOMER_FAILED: state => {
      return {
        ...state,
        isLoadingDelete: false,
      };
    },
    GET_LIST_PROVINCE_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },
    GET_LIST_PROVINCE_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        listProvince: action.payload,
      };
    },
    GET_LIST_PROVINCE_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },

    GET_LIST_DISTRICTS_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },
    GET_LIST_DISTRICTS_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        listDistrict: action.payload,
      };
    },
    GET_LIST_DISTRICTS_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },

    GET_LIST_JOB_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },
    GET_LIST_JOB_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        listJob: action.payload,
      };
    },
    GET_LIST_JOB_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },

    GET_LIST_INSURANCE_REQUESTED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },
    GET_LIST_INSURANCE_SUCCEEDED: (state: CustomerReducerState, action) => {
      return {
        ...state,
        listInsurance: action.payload,
      };
    },
    GET_LIST_INSURANCE_FAILED: (state: CustomerReducerState) => {
      return {
        ...state,
      };
    },
  },
  initialState,
  { prefix: 'customer' },
);

export default customerReducer;
