import { Layout, Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useLocation, matchPath, useHistory } from 'react-router';
import React, { FC, ReactElement, useState, useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import path from 'path';
import Logo from 'assets/images/common/icon_sider.png';
import { useDispatch } from 'react-redux';
import * as authenticateAction from 'store/authenticate/authenticate.action';
import { isRoleDoctor } from 'helpers/utils';

import appRoutes from '../routes/app.route';
import './app.layout.scss';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const layoutLinkPrefix = '/app';

function show(isHide: boolean | undefined, e: ReactElement) {
  return !isHide ? e : null;
}
const AppLayout: FC = () => {
  const [collapse, setCollapse] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const getCurrentRoute = () => {
    const pathnameReplace = location.pathname.replace(layoutLinkPrefix, '');
    let currentRoute;
    let isFirstRoute = false;
    appRoutes.forEach(route => {
      if (route.subRoutes) {
        route.subRoutes.forEach(subRoute => {
          if (
            matchPath(pathnameReplace, path.join(route.path, subRoute.path))
              ?.isExact
          ) {
            currentRoute = matchPath(
              pathnameReplace,
              path.join(route.path, subRoute.path),
            );
          }
        });
      } else if (matchPath(pathnameReplace, route.path)?.isExact) {
        currentRoute = matchPath(pathnameReplace, route.path);
        isFirstRoute = route?.firstRoute || false;
      }
    });

    if (currentRoute) {
      const [, ...l] = currentRoute.path.replace('/:id', '').split('/');

      if (l.length === 0) {
        return [''];
      }
      if (isFirstRoute) {
        return [`/${l[0]}`];
      }
      if (l.length === 1) {
        return [`/${l[0]}`];
      }
      return [`/${l[0]}/${l[1]}`];
    }

    return [''];
  };

  const dispatch = useDispatch();

  const getOpenKey = () => {
    const [, ...l] = location.pathname.replace(layoutLinkPrefix, '').split('/');
    if (l.length === 0) {
      return [''];
    }
    return [`/${l[0]}`];
  };

  const getRoutes = (routes: typeof appRoutes) => {
    return routes.map((prop, index) => {
      if (prop.subRoutes) {
        return prop.subRoutes.map((subRoute, _index) => {
          return (
            <Route
              path={path.join(layoutLinkPrefix, prop.path, subRoute.path)}
              component={subRoute.component}
              key={String(_index)}
              exact={subRoute.exact}
            />
          );
        });
      }
      return (
        <Route
          path={path.join(layoutLinkPrefix, prop.path)}
          component={prop.component}
          key={String(index)}
          exact={prop.exact}
        />
      );
    });
  };

  const renderMenu = (route: typeof appRoutes) => {
    return route.map(item => {
      return item.subRoutes
        ? show(
            item.hideInNav,
            <SubMenu key={item.path} icon={item.icon} title={item.name}>
              {item.subRoutes.map(sub =>
                show(
                  sub.hideInNav,
                  <Menu.Item
                    className={path.join(item.path, sub.path)}
                    key={path.join(item.path, sub.path)}
                  >
                    {sub.name}
                    <Link
                      to={path.join(layoutLinkPrefix, item.path, sub.path)}
                    />
                  </Menu.Item>,
                ),
              )}
            </SubMenu>,
          )
        : show(
            item.hideInNav,
            <Menu.Item key={item.path} icon={item.icon}>
              {item.name}
              <Link to={path.join(layoutLinkPrefix, item.path)} />
            </Menu.Item>,
          );
    });
  };

  useEffect(() => {
    if (isRoleDoctor() === true) {
      history.push('/');
    }
  }, []);

  return (
    <Layout style={{ minHeight: '100vh', position: 'fixed', width: '100%' }}>
      <Sider
        collapsible
        theme="light"
        collapsed={collapse}
        onCollapse={() => setCollapse(p => !p)}
        width={300}
        color="black"
      >
        <div className="bg-white h-16 flex justify-center items-center ">
          <div>
            <Link to="/app/">
              <img
                src={Logo}
                alt="Logo"
                className="object-contain"
                style={{ width: '60px', height: '60px', paddingTop: '10px' }}
              />
            </Link>
          </div>
        </div>
        <Menu
          defaultOpenKeys={getOpenKey()}
          selectedKeys={getCurrentRoute()}
          mode="inline"
          style={{
            overflow: 'hidden overlay',
            paddingTop: '24px',
          }}
          className="menu-side-custom"
        >
          {renderMenu(appRoutes)}
        </Menu>
      </Sider>
      <Layout className="site-layout" style={{}}>
        <Header
          className="site-layout-background"
          style={{
            padding: 0,
            backgroundColor: 'white',

            zIndex: 9,
          }}
        >
          <Menu>
            <Menu.Item
              onClick={() => dispatch(authenticateAction.logout())}
              style={{
                float: 'right',
                marginTop: '10px',
                marginRight: 20,
              }}
            >
              <div className="flex items-center">
                <LogoutOutlined className="mr-2" />
                Đăng xuất
              </div>
            </Menu.Item>
          </Menu>
        </Header>

        <Content style={{ margin: '0 16px', height: '90vh' }}>
          <div className="layout-app" id="wrapper-layout-app">
            <div className="layout-content">
              <div className="layout-container">
                <Switch>{getRoutes(appRoutes)}</Switch>
              </div>

              <div className="copy-right">
                <span>© Copyright 2020 - Doctor4U</span>
              </div>
            </div>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default AppLayout;
