import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { ReferralModel } from 'models/store/referral.model';

export interface ReferralReducerState {
  dataReferral: ReferralModel;
  isLoadingReferral: boolean;
  dataAddReferral: any;
  isLoadingAddReferral: boolean;
  dataEditReferral: any;
  isLoadingEditReferral: boolean;
  errorEdit: any;
  errorAdd: any;
  isLoadingLock: any;
  detailReferral: any;
}

const initialState: ReferralReducerState = {
  dataReferral: {} as ReferralModel,
  isLoadingReferral: false,
  dataAddReferral: {},
  isLoadingAddReferral: false,
  dataEditReferral: {},
  isLoadingEditReferral: false,
  errorEdit: '',
  errorAdd: '',
  isLoadingLock: '',
  detailReferral: [],
};

const referralReducer = handleActions<any, ActionModel>(
  {
    GET_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingReferral: true,
        dataReferral: null,
      };
    },

    GET_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataReferral: action.payload,
        isLoadingReferral: false,
      };
    },

    GET_REFERRAL_FAILED: state => {
      return {
        ...state,
        isLoadingReferral: false,
        dataReferral: null,
      };
    },

    HANDLE_CREATE_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddReferral: true,
        dataAddReferral: null,
        errorAdd: '',
      };
    },

    HANDLE_CREATE_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddReferral: action.payload,
        isLoadingAddReferral: false,
        errorAdd: false,
      };
    },

    HANDLE_CREATE_REFERRAL_FAILED: state => {
      return {
        ...state,
        isLoadingAddReferral: false,
        dataAddReferral: null,
        errorAdd: true,
      };
    },
    HANDLE_UPDATE_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingEditReferral: true,
        dataEditReferral: null,
        errorEdit: '',
      };
    },

    HANDLE_UPDATE_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataEditReferral: action.payload,
        isLoadingEditReferral: false,
        errorEdit: false,
      };
    },

    HANDLE_UPDATE_REFERRAL_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingEditReferral: false,
        dataEditReferral: null,
        errorEdit: true,
      };
    },
    HANDLE_DISPLAY_MODAL_EDIT: () => {
      return {
        errorEdit: '',
      };
    },
    HANDLE_DISPLAY_MODAL_ADD: () => {
      return {
        errorAdd: '',
      };
    },
    HANDLE_LOCKED_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingLock: true,
      };
    },
    HANDLE_LOCKED_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },
    HANDLE_LOCKED_REFERRAL_FAILED: state => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },

    GET_DETAIL_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingReferral: true,
        detailReferral: null,
      };
    },

    GET_DETAIL_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        detailReferral: action.payload,
        isLoadingReferral: false,
      };
    },

    GET_DETAIL_REFERRAL_FAILED: state => {
      return {
        ...state,
        isLoadingReferral: false,
      };
    },

    HANDLE_CREATE_LOGO_REFERRAL_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddReferral: true,
      };
    },

    HANDLE_CREATE_LOGO_REFERRAL_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingAddReferral: false,
      };
    },

    HANDLE_CREATE_LOGO_REFERRAL_FAILED: state => {
      return {
        ...state,
        isLoadingAddReferral: false,
      };
    },
  },
  initialState,
  { prefix: 'referral' },
);

export default referralReducer;
