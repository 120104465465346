import { message } from 'antd';
import {
  fireCreateSPSuccessMessage,
  fireDeleteServicePackageSuccessMessage,
  fireLockServicePackageSuccessMessage,
  fireUnlockServicePackageSuccessMessage,
  fireUpdateSPSuccessMessage,
  getMessageFromError,
} from 'helpers/utils';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import authorizedRequest from '../../helpers/request/authorizedRequest';
import * as servicePackageActions from './servicePackage.action';

function* getServicePackageRequested(action) {
  try {
    let params = '';
    if (action.payload !== '' || action.payload !== undefined) {
      params = {
        ...action.payload,
        // isLock: action.payload.isLock !== 'ACTIVE',
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v2/servicePackage', { params }),
    );
    yield put(servicePackageActions.getServicePackageSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.getServicePackageFailed(error));
  }
}

function* handleUploadAvatarServicePackageRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v2/servicePackage/${action.payload.uuid}/image/thumbnail`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );
    yield put(
      servicePackageActions.handleUploadAvatarServicePackageSucceeded(res),
    );
  } catch (error) {
    yield put(
      servicePackageActions.handleUploadAvatarServicePackageFailed(error),
    );
    message.error(getMessageFromError(error));
  }
}

function* handleUploadCoverRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v2/servicePackage/${action.payload.uuid}/image/background`,
        action.payload.form,
        {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        },
      ),
    );

    yield put(
      servicePackageActions.handleUploadCoverServicePackageSucceeded(res),
    );
  } catch (error) {
    yield put(
      servicePackageActions.handleUploadCoverServicePackageFailed(error),
    );
    message.error(getMessageFromError(error));
  }
}

function* getAvatarRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(
        `v2/servicePackage/${action.payload.uuid}/image/${action.payload.fileName}`,
      ),
    );
    yield put(servicePackageActions.getAvatarSucceeded(res));
  } catch (error) {
    yield put(servicePackageActions.getAvatarFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* getCoverRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(
        `v2/servicePackage/${action.payload.uuid}/image/${action.payload.fileName}`,
      ),
    );
    yield put(servicePackageActions.getCoverSucceeded(res));
  } catch (error) {
    yield put(servicePackageActions.getCoverFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* addServicePackageRequested(action: any) {
  try {
    const payload = {
      name: action.payload.name,
      content: action.payload.content,
      termsOfUse: action.payload.termsOfUse,
      originalPrice: action.payload.originalPrice,
      promotionPrice: action.payload.promotionPrice,
      numberOfDays: action.payload.numberOfDays,
      nbMaxOfUse: action.payload.nbMaxOfUse,
      speciality: action.payload.speciality,
      lock: action.payload.lock,
      packageFormatType: action.payload.packageFormatType,
      startDate: action.payload.startDate,
      endDate: action.payload.endDate,
      idsPackageReference: action.payload.idsPackageReference,
      displayInApp: action.payload.displayInApp,
    };
    const res = yield call(() =>
      authorizedRequest.post(`/v2/servicePackage`, {
        ...payload,
      }),
    );

    if (action.payload.formThumbnail) {
      yield call(() =>
        authorizedRequest.post(
          `/v2/servicePackage/${res?.UUID}/image/thumbnail`,
          action.payload.formThumbnail,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }
    if (action.payload.formBackground) {
      yield call(() =>
        authorizedRequest.post(
          `/v2/servicePackage/${res?.UUID}/image/background`,
          action.payload.formBackground,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }
    fireCreateSPSuccessMessage().then(
      () => {
        window.location.href = `/app/service-package`;
      },
      () => {},
    );
    yield put(servicePackageActions.addServicePackageSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.addServicePackageFailed(error));
  }
}

function* editServicePackageRequested(action: any) {
  try {
    const data = action.payload.payload;
    if (data.formThumbnail) {
      yield call(() =>
        authorizedRequest.post(
          `/v2/servicePackage/${action.payload.id}/image/thumbnail`,
          data.formThumbnail,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }
    if (data.formBackground) {
      yield call(() =>
        authorizedRequest.post(
          `/v2/servicePackage/${action.payload.id}/image/background`,
          data.formBackground,
          {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          },
        ),
      );
    }

    delete data.formThumbnail;
    delete data.formBackground;
    const res = yield call(() =>
      authorizedRequest.post(`/v2/servicePackage/${action.payload.id}`, data),
    );

    yield put(servicePackageActions.editServicePackageSucceeded(res));
    fireUpdateSPSuccessMessage().then(
      () => {
        window.location.href = `/app/service-package`;
      },
      () => {},
    );
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.editServicePackageFailed(error));
  }
}
function* getDetailServicePackageRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v2/servicePackage/${action.payload.id}`),
    );
    yield put(servicePackageActions.getDetailServicePackageSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.getDetailServicePackageFailed(error));
  }
}

function* handleDeleteServicePackageRequested(action) {
  try {
    const res = yield call(() => {
      return authorizedRequest.put(`/v2/servicePackage/${action.payload}`);
    });
    fireDeleteServicePackageSuccessMessage().then(
      () => {
        window.location.href = `/app/service-package`;
      },
      () => {},
    );
    yield put(servicePackageActions.handleDeleteServicePackageSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.handleDeleteServicePackageFailed(error));
  }
}

function* handleLockServicePackageRequested(action) {
  try {
    const res = yield call(() => {
      return authorizedRequest.post(
        `/v2/servicePackage/${action.payload.uuid}/_lock`,
        { lock: action.payload.lock },
      );
    });
    yield put(servicePackageActions.handleDeleteServicePackageSucceeded(res));
    if (action.payload.lock) {
      fireLockServicePackageSuccessMessage();
    } else {
      fireUnlockServicePackageSuccessMessage();
    }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(servicePackageActions.handleDeleteServicePackageFailed(error));
  }
}

export default function* servicePackageSaga() {
  yield all([
    takeLatest(
      servicePackageActions.getServicePackageRequested,
      getServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.handleDeleteServicePackageRequested,
      handleDeleteServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.handleLockServicePackageRequested,
      handleLockServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.addServicePackageRequested,
      addServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.editServicePackageRequested,
      editServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.getDetailServicePackageRequested,
      getDetailServicePackageRequested,
    ),
    takeLatest(
      servicePackageActions.handleUploadAvatarServicePackageRequested,
      handleUploadAvatarServicePackageRequested,
    ),
    takeLatest(servicePackageActions.getAvatarRequested, getAvatarRequested),
    takeLatest(servicePackageActions.getCoverRequested, getCoverRequested),
    takeLatest(
      servicePackageActions.handleUploadCoverServicePackageRequested,
      handleUploadCoverRequested,
    ),
  ]);
}
