import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Modal,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import Action from 'components/Share/Table/Action';
import ModalAddVersion from 'components/Version/ModalAddVersion';
import ModalViewVersion from 'components/Version/ModalViewVersion';
import React, { FC, useEffect, useState } from 'react';
import './VersionManagement.scss';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateDayVN } from 'helpers/utils';
import * as _ from 'lodash';
import { VersionModel } from 'models/store/version.model';
import { OPTIONS_LIST_STATUS_VERSION } from './version.const';
import {
  getHightestVersionDoctorRequested,
  getHightestVersionPatientRequested,
  getVersionRequested,
  getVersionSettingsRequested,
  handleDeleteVersionRequested,
  postUpdateVersionSettingsRequested,
} from '../../store/version/version.action';

const VersionManagement: FC = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { confirm } = Modal;

  const [isModalAddVersion, setModalAddVersion] = useState(false);
  const [isModalViewVersion, setModalViewVersion] = useState(false);
  const [paramsVersion, setParamsVersion] = useState({
    applicationType: '',
    page: 1,
    limit: 10,
    versionCreatedDate: null,
    checkedOn: true,
  });
  const [paramsVersionNumberDoctor] = useState({
    applicationType: 'DOCTOR',
  });
  const [paramsVersionNumberPatient] = useState({
    applicationType: 'PATIENT',
  });

  const {
    isAddFished,
    isDeleteFished,
    dataVersion,
    isLoadingVersion,
    dataCheck,
    dataHightestVersionDoctor,
    dataHightestVersionPatient,
    isUpdateFished,
  } = useSelector((state: any) => state.versionReducer);

  const [itemVersion, setItemVersion] = useState<VersionModel>(
    {} as VersionModel,
  );
  const [paramsChecked, setParamsChecked] = useState({
    applicationType: 'DOCTOR',
    updateAble: true,
  });

  const onOpentModaAddVersion = () => {
    setModalAddVersion(true);
  };
  const onCloseModalAddVersion = () => {
    setModalAddVersion(false);
  };
  const onOpenModalViewVersion = item => {
    setItemVersion(item);
    setModalViewVersion(true);
  };
  const oncloseModalViewVersion = () => {
    setModalViewVersion(false);
  };

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      page: current,
      limit: pageSize,
    };
    setParamsVersion({
      ...paramsVersion,
      ...body,
    });
  };

  const onChangeCheckbox = (e, keyValue) => {
    setParamsChecked(e.target.checked);
    dispatch(
      postUpdateVersionSettingsRequested({
        // ...paramsChecked,
        applicationType: keyValue,
        updateAble: e.target.checked,
      }),
    );
  };

  const getcheckbox = () => {
    dispatch(getVersionSettingsRequested(paramsChecked));
  };

  const getVersion = () => {
    dispatch(getVersionRequested(paramsVersion));
  };

  const getDoctorVersionNumber = () => {
    dispatch(getHightestVersionDoctorRequested(paramsVersionNumberDoctor));
  };

  const getPatientVersionNumber = () => {
    dispatch(getHightestVersionPatientRequested(paramsVersionNumberPatient));
  };

  useEffect(() => {
    getDoctorVersionNumber();
    getPatientVersionNumber();
  }, []);

  useEffect(() => {
    getcheckbox();
  }, []);

  useEffect(() => {
    if (isUpdateFished) {
      getcheckbox();
    }
  }, [isUpdateFished]);

  useEffect(() => {
    if (isAddFished) {
      getVersion();
    }
  }, [isAddFished]);

  useEffect(() => {
    getVersion();
  }, [paramsVersion]);

  useEffect(() => {
    if (isDeleteFished) {
      getVersion();
    }
  }, [isDeleteFished]);

  const onBlurSelect = value => {
    setParamsVersion({
      ...paramsVersion,
      applicationType: value,
      page: 1,
    });
  };

  const optionForSelect = OPTIONS_LIST_STATUS_VERSION.map(item => (
    <>
      <Option value={item.value} key={item.value}>
        {item.label}
      </Option>
    </>
  ));

  const handleDeleteVersion = record => {
    const onDelete = () => {
      const id = _.get(record, 'versionId', '');
      dispatch(handleDeleteVersionRequested({ id }));
    };
    confirm({
      title: 'Bạn có chắc muốn xóa phiên bản này?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const columns = [
    {
      title: 'STT',
      width: 80,
      render: (value, row, index) => {
        return (paramsVersion.page - 1) * paramsVersion.limit + index + 1;
      },
    },
    {
      title: 'Loại ứng dụng',
      width: '20%',
      render: value => {
        return _.get(value, 'applicationType', '');
      },
    },
    {
      title: 'Version name',
      width: '15%',
      render: value => {
        return _.get(value, 'versionName', '');
      },
    },
    {
      title: 'Version number',
      width: '15%',
      render: value => {
        return _.get(value, 'versionNumber', '');
      },
    },
    {
      title: 'Ngày tạo',
      width: '20%',
      render: value => {
        return formatDateDayVN(value?.versionCreatedDate);
      },
    },
    {
      title: '',
      width: 150,
      render: value => {
        return (
          <Action
            isCancel={true}
            handleDelete={() => handleDeleteVersion(value)}
            handleDetail={() => onOpenModalViewVersion(value)}
            hasEdit={false}
          />
        );
      },
    },
  ];
  const labelPatient = dataCheck?.[1].updateAble ? 'ON' : 'OFF';
  const labelDoctor = dataCheck?.[0].updateAble ? 'ON' : 'OFF';
  const spanDoctor = dataHightestVersionDoctor?.[0]?.versionNumber || null;
  const spanPatient = dataHightestVersionPatient?.[0]?.versionNumber || null;
  const spanPatientVersionName =
    dataHightestVersionPatient?.[0]?.versionName || null;
  const spanDoctorVersionName =
    dataHightestVersionDoctor?.[0]?.versionName || null;
  return (
    <div className="version-management">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý phiên bản ứng dụng</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onOpentModaAddVersion}>
              Thêm mới
            </Button>
          </Col>
        </Row>

        <h1 style={{ marginTop: 10, fontSize: 'initial' }}>
          Phiên bản hiện tại
        </h1>

        <Row style={{ display: 'flex', marginLeft: 30 }}>
          <Col xs={6}>
            <Row>
              <span>Ứng dụng Doctor4U: </span>
            </Row>
            <Row>
              <span>Ứng dụng Doctor4U Bác Sỹ: </span>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <span>
                {spanPatientVersionName && spanPatient
                  ? `${spanPatientVersionName}(${spanPatient})`
                  : 'Không có'}
              </span>
            </Row>
            <Row>
              <span>
                {spanPatientVersionName && spanPatient
                  ? `${spanDoctorVersionName}(${spanDoctor})`
                  : `Không có`}
              </span>
            </Row>
          </Col>
        </Row>

        <h1 style={{ marginTop: 10, fontSize: 'initial' }}>
          Cấu hình force update
        </h1>

        <Row style={{ display: 'flex', marginLeft: 30 }}>
          <Col xs={6}>
            <Row>
              <span>Bắt buộc cập nhật Doctor4U:</span>
            </Row>
            <Row>
              <span>Bắt buộc cập nhật Doctor4U Bác Sỹ:</span>
            </Row>
          </Col>
          <Col xs={6}>
            <Row>
              <Checkbox
                value={'PATIENT'}
                checked={dataCheck?.[1].updateAble}
                onChange={e => onChangeCheckbox(e, 'PATIENT')}
              >
                {labelPatient}
              </Checkbox>
            </Row>
            <Row>
              <Checkbox
                value={'DOCTOR'}
                checked={dataCheck?.[0].updateAble}
                onChange={e => onChangeCheckbox(e, 'DOCTOR')}
              >
                {labelDoctor}
              </Checkbox>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }}>
          <Col xs={6}>
            <Row>
              <span>Loại ứng dụng</span>
            </Row>
            <Select
              onChange={e => onBlurSelect(e)}
              defaultValue=""
              style={{ width: '100%' }}
            >
              <Option value="" key={0}>
                Tất cả
              </Option>
              {optionForSelect}
            </Select>
          </Col>
        </Row>
      </Card>
      <Row style={{ marginTop: 15 }}>
        <Col xs={24}>
          <Table
            dataSource={
              dataVersion?.data && dataVersion?.data.length > 0
                ? dataVersion?.data
                : []
            }
            columns={columns}
            onChange={onChangeTable}
            loading={{
              spinning: isLoadingVersion,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            pagination={{
              current: paramsVersion?.page,
              pageSize: paramsVersion?.limit,
              total: dataVersion?.numberOfRecord || 10,
              showSizeChanger: true,
            }}
          />
        </Col>
      </Row>

      {isModalAddVersion && (
        <ModalAddVersion
          show={isModalAddVersion}
          onHide={onCloseModalAddVersion}
        />
      )}

      {isModalViewVersion && (
        <ModalViewVersion
          show={isModalViewVersion}
          onHide={oncloseModalViewVersion}
          itemVersion={itemVersion}
        />
      )}
    </div>
  );
};

export default VersionManagement;
