import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { Form, Input, Button, DatePicker } from 'antd';
import * as STATUS from '../../containers/Doctor/doctor.const';

import * as holidayActions from '../../store/holiday/holiday.action';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
}

const ModalAddHoliday: FC<CProps> = ({ show, title, onHide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sDate, setSDate] = useState<any>();
  // const [visible, setVisible] = useState<boolean>(show);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getValStartDate = current => {
    setSDate(current);
  };

  const disabledDate = current => {
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const disabledEndDate = current => {
    return current && current < sDate;
  };

  const dataHoliday = useSelector((state: any) => state.holidayReducer);

  const onFinish = values => {
    const { startDate, endDate, holidayName } = values;

    const holidayNameTrim = `${holidayName}`.trim();
    if (holidayNameTrim.length === 0) {
      return form.setFieldsValue({
        holidayName: '',
      });
    }

    const newStartDate = startDate?.format('YYYY-MM-DD');
    const newEndDate = endDate?.format('YYYY-MM-DD');
    const body = {
      holidayName: holidayNameTrim,
      startDate: newStartDate,
      endDate: newEndDate,
    };
    dispatch(holidayActions.handleCreateHolidayRequested(body));
  };

  if (dataHoliday?.errorAdd === false) {
    onHide();
    dispatch(holidayActions.handleDisplayModalAdd());
  }
  const initialValues = {
    holidayName: '',
    startDate: '',
    endDate: '',
  };

  useEffect(() => {
    form.setFieldsValue({
      endDate: sDate,
    });
  }, [sDate]);

  return (
    <Modal
      title={title || 'Thêm mới kỳ lễ'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
      className="z-9999"
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initialValues}
        form={form}
        requiredMark={false}
      >
        <Form.Item
          label="Tên kỳ lễ"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 300, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="holidayName"
        >
          <Input placeholder="Nhập tên kỳ lễ" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate}
            locale={locale}
            format="DD-MM-YYYY"
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            onChange={getValStartDate}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            defaultValue={sDate}
            locale={locale}
            format="DD-MM-YYYY"
            placeholder="Chọn ngày kết thúc"
            onChange={() => {}}
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddHoliday;
