import { fork, all } from '@redux-saga/core/effects';

import authenticateSaga from './authenticate/authenticate.saga';
import { sagas as appointmentSaga } from './appointment';
import customerSaga from './customer/customer.saga';
import doctorSaga from './doctor/doctor.saga';
import servicePackageSaga from './servicePackage/servicePackage.saga';
import holidaySaga from './holiday/holiday.saga';
import versionSaga from './version/version.saga';
import promotionSaga from './promotion/promotion.saga';
import referralSaga from './referral/referral.saga';
import marketingNotiSaga from './marketingNoti/marketingNoti.saga';
import customSetting from './customSetting/customSetting.saga';
import rating from './rating/rating.saga';
import service from './service/service.saga';

export default function* rootSaga() {
  try {
    yield all([
      fork(authenticateSaga),
      fork(appointmentSaga),
      fork(customerSaga),
      fork(doctorSaga),
      fork(servicePackageSaga),
      fork(holidaySaga),
      fork(versionSaga),
      fork(promotionSaga),
      fork(referralSaga),
      fork(marketingNotiSaga),
      fork(customSetting),
      fork(rating),
      fork(service),
    ]);
  } catch (e) {
    // eslint-disable-next-line no-console
  }
}
