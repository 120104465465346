import { Button, Form, Input, InputNumber, Select } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { regextTrimspace } from 'containers/Doctor/doctor.const';
import {
  OPTIONS_LIST_STATUS_VERSION,
  TYPE_STATUS_VERSION_VALUE,
} from 'containers/Version/version.const';
import StoreState from 'models/store';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postAddVersionRequested } from 'store/version/version.action';
import './ModalAddVersion.scss';

interface VProps {
  show: boolean;
  onHide: () => void;
  title?: string;
}
interface initForm {
  applicationType?: string;
  versionName?: string;
  versionNumber?: string;
  versionNote?: string;
  versionCreatedDate?: any | null;
}

const ModalAddVersion: FC<VProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visible] = useState(show);
  const initForm: initForm = {
    applicationType: TYPE_STATUS_VERSION_VALUE.DOCTOR,
    versionName: '',
    versionNumber: '',
    versionNote: '',
    versionCreatedDate: '',
  };

  const versionReducer = useSelector(
    (state: StoreState) => state.versionReducer,
  );

  const onFinish = values => {
    const { applicationType, versionName, versionNumber, versionNote } = values;
    const isSuccess = () => {
      onHide();
    };
    const data = {
      ...values,
      versionName,
      versionNumber,
      applicationType,
      versionNote,
      versionCreatedDate: null,
      isSuccess,
    };

    dispatch(postAddVersionRequested(data, isSuccess));
  };
  if (versionReducer?.errorAdd === false) {
    onHide();
  }
  const { Option } = Select;
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const optionForSelect = OPTIONS_LIST_STATUS_VERSION.map(item => (
    <>
      <Option value={item.value} key={item.value}>
        {item.label}
      </Option>
    </>
  ));

  return (
    <Modal
      title={'Thêm mới phiên bản ứng dụng'}
      centered
      visible={visible}
      onOk={onHide}
      onCancel={onHide}
      width={550}
      footer={null}
    >
      <Form
        className="modal-add-version"
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
        initialValues={initForm}
      >
        <Form.Item label="Loại ứng dụng:" name="applicationType">
          <Select>{optionForSelect}</Select>
        </Form.Item>
        <Form.Item
          label="Version name:"
          name="versionName"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 50, message: 'Version name không vượt quá 50 ký tự!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Version number"
          name="versionNumber"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <InputNumber min={0} max={1000000} />
        </Form.Item>
        <Form.Item label="Ghi chú:" name="versionNote">
          <Input.TextArea />
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Huỷ
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={versionReducer.isLoadingAddVersion}
          >
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddVersion;
