import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Table, Spin, Input } from 'antd';
import { getExportCodeFile } from 'api/promotion';
import moment from 'moment';

import * as promotionActions from '../../store/promotion/promotion.action';
import ExportFilePromotion from './ExportFilePromotion';

interface CProps {
  show: boolean;
  onHide: () => void;
  onSelect: (value) => void;
  title?: string;
  dataProps: any;
}

interface ParamsPromotionModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  promotionName?: string;
  sortField?: string;
  sortDirection?: string;
  calculated?: boolean;
  length?: any;
  promotionCampaign?: string;
}

const initStartIndex: number = 1;

const ModalCodePromotion: FC<CProps> = ({
  show,
  title,
  onHide,
  onSelect,
  dataProps,
}) => {
  const dispatch = useDispatch();
  const promotionReducer = useSelector((state: any) => state.promotionReducer);
  const [totalOfUses, setTotalOfUses] = useState(0);
  const [paramsPromotion, setParamsPromotion] = useState<ParamsPromotionModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    calculated: true,
    sortDirection: 'DESC',
    promotionCampaign: dataProps.uuid,
  });
  useEffect(() => {
    dispatch(promotionActions.getPromotionCodeRequested(paramsPromotion));
  }, [paramsPromotion]);

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
      calculated: current === 1,
    };
    setParamsPromotion({
      ...paramsPromotion,
      ...body,
    });
  };

  useEffect(() => {
    if (paramsPromotion.startIndex === 1) {
      setTotalOfUses(promotionReducer?.dataPromotionCode?.NumberOfTimesUsed);
    }
  }, [promotionReducer]);

  const columns = [
    {
      title: 'STT',
      key: '1',

      dataIndex: 'key',
      render: (value, row, index) => {
        if (
          paramsPromotion?.startIndex !== undefined &&
          paramsPromotion?.limit !== undefined &&
          paramsPromotion
        ) {
          return (
            (paramsPromotion?.startIndex - 1) * paramsPromotion?.limit +
            index +
            1
          );
        }
      },
    },
    {
      title: 'Mã khuyến mại',
      key: 'name',
      width: '20%',
      render: (value, row, index) => {
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div className="cursor-pointer">{value?.promotionCode}</div>
        );
      },
    },
    {
      key: 'limit',
      title: 'Giới hạn sử dụng',

      render: (value, row, index) => {
        return value?.promotionCampaign?.numberOfUses;
      },
    },
    {
      key: 'usedAmount',
      title: 'Số lượt sử dụng',

      render: (value, row, index) => {
        const numberUsed =
          value?.promotionCampaign?.numberOfUses - value?.numberOfRemainingUses;
        return numberUsed;
      },
    },
    {
      key: 'remainAmount',
      title: 'Số lượt còn lại',

      render: (value, row, index) => {
        return value?.numberOfRemainingUses;
      },
    },
  ];

  const getFileCsv = () => {
    const params = {
      file: 'csv',
      promotionCampaign: dataProps.uuid,
    };
    getExportCodeFile(params).then(result => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Makhuyenmai${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  const getFileExcel = () => {
    const params = {
      file: 'excel',
      promotionCampaign: dataProps.uuid,
    };

    getExportCodeFile(params).then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Makhuyenmai${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <Modal
      title={title || 'Chi tiết sử dụng'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={1000}
      footer={null}
      className="z-9999"
    >
      <div className="information">
        <Row>
          <Col xs={24}>
            <div className=" flex justify-between items-center">
              <div className=" flex items-center">
                Tổng số lượt sử dụng:{' '}
                <Input
                  style={{ width: 60 }}
                  value={totalOfUses || 0}
                  disabled
                  className="mx-2"
                />{' '}
                Lượt
              </div>
              <ExportFilePromotion
                getCsvFile={getFileCsv}
                getExcelFile={getFileExcel}
              />
            </div>
            <Table
              columns={columns}
              rowKey={row => row.uuid}
              loading={{
                spinning: promotionReducer?.isLoadingPromotionCode,
                indicator: <Spin />,
                tip: 'Đang tải...',
              }}
              dataSource={
                promotionReducer?.dataPromotionCode?.Results?.pageOfResults
              }
              pagination={{
                current: paramsPromotion.startIndex,
                pageSize: paramsPromotion.limit,
                total:
                  promotionReducer?.dataPromotionCode?.Results?.totalCount ||
                  10,
                showSizeChanger: true,
                locale: { items_per_page: '/Trang' },
              }}
              rowClassName=" cursor-pointer"
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => {
                    onSelect(record);
                  },
                };
              }}
              onChange={onChangeTable}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalCodePromotion;
