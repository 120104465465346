import React, { FC, useEffect, useState } from 'react';
import { Modal, Input, Button, Form } from 'antd';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import * as doctorActions from '../../store/doctor/doctor.action';
import { regexPreventChar } from '../../containers/Doctor/doctor.const';

type Props = {
  visible: boolean;
  handleCancel: (...args: any[]) => any;
  user: any;
};

type TypeModel = {
  newPassword: string;
};

const ResetPasswordDoctor: FC<Props> = props => {
  const dispatch = useDispatch();
  const { visible, handleCancel, user } = props;
  const [form] = Form.useForm();
  const [userReset, setUserReset] = useState<string>();

  const initValues = {
    name: user?.user?.display,
    newPassword: '',
    confirmPassword: '',
  };

  useEffect(() => {
    const data = user?.person?.wallet?.user?.uuid;
    if (data && data !== undefined) {
      setUserReset(data);
    }
  }, [user]);

  const handleSubmit = valSubmit => {
    const { newPassword } = valSubmit;
    // const newPass = newPassword.replace(regexGetPassConvert, '');
    const data = { ...valSubmit, newPassword };

    dispatch(doctorActions.resetPassword(userReset, get(data, 'newPassword')));
    form.resetFields();
    handleCancel();
  };

  const checkConfirmPassword = (rule, value) => {
    if (value === form.getFieldValue('newPassword')) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error('Mật khẩu mới và Mật khẩu xác nhận không giống nhau!'),
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [user]);
  return (
    <Modal
      title="Thay đổi mật khẩu"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        layout="horizontal"
        id="reset_password"
        onFinish={handleSubmit}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={initValues}
        requiredMark={false}
      >
        <Form.Item label="UserName/SĐT" labelAlign="left" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Mật khẩu mới"
          name="newPassword"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập mật khẩu mới!',
            },
            {
              pattern: regexPreventChar,
              message: 'Mật khẩu không được chứa ký tự đặc biệt',
            },
            // {
            //   pattern: regexGetPassVietLatest,
            //   message: 'Mật khẩu mới phải có ít nhất 6 ký tự!',
            // },
            {
              min: 6,
              message: 'Mật khẩu mới phải có ít nhất 6 ký tự!',
              max: 20,
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Xác nhận mật khẩu mới"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập xác nhận mật khẩu mới!',
              whitespace: true,
            },
            { validator: checkConfirmPassword },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
      <div className="text-center">
        Lưu ý: Mật khẩu mới có chứa ít nhất 6 ký tự
      </div>

      <div className="flex justify-center mt-2">
        <Button onClick={() => handleCancel()}>Đóng</Button>
        <Button
          className="ml-4"
          form="reset_password"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          Lưu
        </Button>
      </div>
    </Modal>
  );
};

export default ResetPasswordDoctor;
