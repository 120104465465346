import React, { FC, useMemo, useState } from 'react';
import { Row, Col, Button, Form, Radio, message } from 'antd';
// import { actions as appointmentActions } from 'store/appointment';

import CreatableSelect from 'react-select/creatable';
import { useDispatch } from 'react-redux';
import * as _ from 'lodash';
import './Diagnostic.scss';
import { CERTAINTY, APPOINTMENT_STATUS } from 'constants/Appointment';
import appointmentActions from 'store/appointment/actions';

const Diagnostic: FC<any> = ({
  dataProps,
  initValues,
  onGetAppointmentDetail,
  isEditAvailability,
}) => {
  const [isEdit, setIsEdit] = useState<any>(false);
  const [isAdd, setIsAdd] = useState<any>(false);
  const [idx, setIdx] = useState<number | null>(null);
  const [uuidSelected, setUuidSelected] = useState<string>('');
  const [diagnosticQuery, setDiagnosticQuery] = useState<string>('');
  const [radioValue, setRadioValue] = useState<CERTAINTY>(
    CERTAINTY.PROVISIONAL,
  );

  const status = _.get(dataProps, 'detail.detail.status', '');

  const dispatch = useDispatch();

  const listDiagnostics = useMemo(() => {
    return _.get(dataProps, 'detail.dataCloned.detail.diagnostic', []);
  }, [dataProps]);

  const rules = [
    {
      required: true,
      message: 'Vui lòng nhập thông tin bắt buộc!',
    },
  ];
  const diagnosticOptions = dataProps?.diagnosis.map(item => ({
    value: item.uuid,
    label: item.display,
  }));

  const onChangeRadio = e => {
    setRadioValue(e.target.value);
  };

  const onEdit = (i, value, item) => {
    setIdx(i);
    setIsEdit(true);
    setIsAdd(false);
    setDiagnosticQuery(item.diagnosis?.coded?.uuid || item.diagnosis.nonCoded);
    setUuidSelected(value);
    setRadioValue(item.status);
  };

  const callbackSuccess = () => {
    onGetAppointmentDetail();
    if (isEdit) {
      setIsEdit(false);
    } else {
      setIsAdd(false);
    }
    setIdx(null);
    setDiagnosticQuery('');
  };

  const onCancel = () => {
    setIsEdit(false);
  };

  const onFinish = values => {
    let params: any;

    if (!diagnosticQuery) {
      return message.error('Chẩn đoán không được để trống');
    }

    const data = {
      certainty: radioValue,
      rank: 0,
      encounter: dataProps.detail.dataCloned.encounters[0].uuid,
    };

    const currentDiagnostic: any = dataProps.diagnosis.find(
      (i: any) => i.uuid === diagnosticQuery,
    );

    if (currentDiagnostic) {
      params = {
        ...data,
        diagnosis: {
          coded: currentDiagnostic.uuid,
          specificName: currentDiagnostic.name.uuid,
        },
      };
    } else {
      params = {
        ...data,
        diagnosis: {
          nonCoded: diagnosticQuery.trim(),
        },
      };
    }
    if (isEdit) {
      dispatch(
        appointmentActions.handleEditDiagnostic(
          uuidSelected,
          params,
          callbackSuccess,
        ),
      );
    } else {
      params = {
        ...params,
        patient: dataProps.detail.patient.uuid,
      };
      dispatch(
        appointmentActions.handleCreateDiagnostic(params, callbackSuccess),
      );
    }
  };

  return (
    <div className="diagnostic-Contaier">
      <div className="diagnostic-content">
        <Row className="diagnostic-title ">
          <span className="pl-6">Chẩn đoán</span>
        </Row>
        <hr />
        {listDiagnostics.length > 0 ? (
          listDiagnostics.map((item, index) => {
            return (
              <Row className="row-1" key={item.id}>
                <Col xs={24}>
                  <div className="diagnostic-section">
                    <Form onFinish={onFinish}>
                      <div className="diagnostic-section__title">
                        <div>
                          <span>{item.title}</span>
                        </div>
                        <div className="btn-edit">
                          {isEditAvailability &&
                            status !== APPOINTMENT_STATUS.WAITING_EXAMINATION &&
                            (isEdit && idx === index ? (
                              <>
                                <Button
                                  size="middle"
                                  onClick={() => onCancel()}
                                >
                                  Hủy
                                </Button>{' '}
                                <Button size="middle" htmlType="submit">
                                  Lưu
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => onEdit(index, item.id, item)}
                                size="middle"
                              >
                                Sửa
                              </Button>
                            ))}
                        </div>
                      </div>

                      <div className="diagnostic-section__content">
                        <div className="diagnostic-section__content_inside">
                          {isEdit && idx === index ? (
                            <>
                              <CreatableSelect
                                isClearable
                                onChange={e => {
                                  if (e) {
                                    setDiagnosticQuery(e.value);
                                  } else {
                                    setDiagnosticQuery('');
                                  }
                                }}
                                options={diagnosticOptions}
                                formatCreateLabel={inputValue =>
                                  `Thêm mới ${inputValue}`
                                }
                                defaultValue={
                                  item.diagnosis.coded
                                    ? diagnosticOptions.find(
                                        i =>
                                          i.value === item.diagnosis.coded.uuid,
                                      )
                                    : {
                                        label: item.diagnosis.nonCoded,
                                        value: item.diagnosis.nonCoded,
                                      }
                                }
                              />

                              <div className="radioButtonWrapper">
                                <Radio.Group
                                  onChange={onChangeRadio}
                                  value={radioValue}
                                >
                                  <Radio value={CERTAINTY.PROVISIONAL}>
                                    Sơ bộ
                                  </Radio>
                                  <Radio value={CERTAINTY.CONFIRMED}>
                                    Xác định
                                  </Radio>
                                </Radio.Group>
                              </div>
                            </>
                          ) : (
                            item.description
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <Row className="row-1">
            <Col xs={24}>
              <div className="diagnostic-section">
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {isEditAvailability && (
        <div className="diagnostic-add-container">
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="diagnostic-input-add">
                <Form.Item name="diagnostic" rules={rules}>
                  <CreatableSelect
                    isClearable
                    onChange={e => {
                      if (e) {
                        setDiagnosticQuery(e.value);
                      } else {
                        setDiagnosticQuery('');
                      }
                    }}
                    formatCreateLabel={inputValue => `Thêm mới ${inputValue}`}
                    placeholder="Nhập chẩn đoán"
                    options={diagnosticOptions}
                    autoFocus
                  />
                </Form.Item>
                <div className="radioButtonWrapper">
                  <Radio.Group onChange={onChangeRadio} value={radioValue}>
                    <Radio value={CERTAINTY.PROVISIONAL}>Sơ bộ</Radio>
                    <Radio value={CERTAINTY.CONFIRMED}>Xác định</Radio>
                  </Radio.Group>
                </div>
              </div>
              <div className="diagnostic-action-add">
                <div className="btn-ok">
                  <Button type="primary" htmlType="submit">
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(!isAdd)}
                  >
                    <span className="btn-add-form-input">Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => {
                  setIsAdd(!isAdd);
                  setIsEdit(false);
                  setRadioValue(CERTAINTY.PROVISIONAL);
                }}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Diagnostic;
