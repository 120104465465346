import React, { FC, useEffect } from 'react';
import { Modal, Input, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { postFeedbackRequested } from 'store/appointment/appointment.actions';
import { STATUS_FEEDBACK } from 'constants/Appointment';

import './ModalSupport.scss';
import StoreState from 'models/store';

const { TextArea } = Input;

interface CProps {
  visible: boolean;
  handleCloseModal: () => void;
  bodyRequest: {
    uuidUser: string;
    appointmentCode: string;
  };
}

const ModalSupport: FC<CProps> = ({
  visible,
  handleCloseModal,
  bodyRequest: { uuidUser, appointmentCode },
}) => {
  const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  const onFinish = values => {
    const cancelReason = `${values?.cancelReason}`.trim();
    // if (cancelReason.length === 0) {
    //   return form.setFieldsValue({
    //     cancelReason: '',
    //   });
    // }
    const body = {
      content: cancelReason,
      status: STATUS_FEEDBACK.OPEN,
      user: uuidUser,
      appointmentCode,
    };

    dispatch(postFeedbackRequested(body));
  };

  const onSuggestion = (text: string) => {
    const cancelReason = form.getFieldValue('cancelReason');

    form.setFieldsValue({
      cancelReason: cancelReason.concat(`${text} `),
    });
  };

  useEffect(() => {
    if (appointmentReducer.isCreateFeedback) {
      handleCloseModal();
    }
  }, [appointmentReducer.isCreateFeedback]);

  return (
    <Modal
      title="Gửi lời nhắn tới Doctor4U"
      centered
      visible={visible}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        onFinish={onFinish}
        className="modal-add-customer"
        form={form}
      >
        <div className="flex test  ">
          <Form.Item
            initialValue=""
            name="cancelReason"
            className="form-items"
            rules={[
              {
                validator: async (_, names) => {
                  if (names.trim().length < 1) {
                    return Promise.reject(
                      new Error('Nội dung không được để trống'),
                    );
                  }
                },
              },
            ]}
          >
            <TextArea
              className="form-items cancel-reason"
              placeholder="Nội dung lời nhắn tới Doctor4U"
              autoSize={{ minRows: 5 }}
            />
          </Form.Item>
        </div>
        <div className="suggestion flex-wrap pb-5">
          <Button
            className="btn-suggestion"
            onClick={() => onSuggestion('Tôi không thể liên lạc với bệnh nhân')}
          >
            Tôi không thể liên lạc với bệnh nhân
          </Button>
          <Button
            className="btn-suggestion"
            onClick={() => onSuggestion('Bệnh nhân nhầm chuyên khoa')}
          >
            Bệnh nhân nhầm chuyên khoa
          </Button>
        </div>
        <div className="button-support-modal-container mr-2">
          <Button
            type="primary"
            className="btn-ok  btn-support-modal"
            htmlType="submit"
            loading={appointmentReducer?.isLoadingFeedback}
          >
            Gửi lời nhắn
          </Button>
          <Button
            className="btn-cancel btn-support-modal ml-2"
            type="default"
            onClick={handleCloseModal}
          >
            Xóa và thoát
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalSupport;
