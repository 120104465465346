import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery() {
  const location = useLocation();
  const searchParams = location.search;

  const queryPage = useMemo(() => {
    const params = new URLSearchParams(searchParams);
    const baseQuery: { startIndex: number; limit: number } = {
      startIndex: 0,
      limit: 20,
    };
    return Object.assign(baseQuery, Object.fromEntries(params));
  }, [searchParams]);
  return queryPage;
}
