import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
// import { HolidayModel } from 'models/store/holiday.model';

export interface RatingReducerState {
  dataRating: any;
  isLoadingRating: boolean;
}

const initialState: RatingReducerState = {
  dataRating: {} as any,
  isLoadingRating: false,
};

const ratingReducer = handleActions<any, ActionModel>(
  {
    GET_RATING_REQUESTED: state => {
      return {
        ...state,
        isLoadingRating: true,
        dataRating: null,
      };
    },

    GET_RATING_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataRating: action.payload,
        isLoadingRating: false,
      };
    },

    GET_RATING_FAILED: state => {
      return {
        ...state,
        isLoadingRating: false,
        dataRating: null,
      };
    },
  },
  initialState,
  { prefix: 'rating' },
);

export default ratingReducer;
