import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Radio, Button } from 'antd';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import {
  regexCheckPhone,
  regexGetPhoneNumber,
  regexGetEmailLatest,
  regextTrimspace,
} from '../../containers/Doctor/doctor.const';
import {
  postAddInformationCustomerRequested,
  handleDisplayModalAdd,
} from '../../store/customer/customer.action';

import './ModalAddInformation.scss';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
}

interface initForm {
  status?: string;
  givenName?: string;
  phoneNumber?: number | string;
  email?: string;
  oganization?: string;
}
const ModalAddInformation: FC<CProps> = ({ show, title, onHide }) => {
  const dispatch = useDispatch();
  const initForm: initForm = {
    status: 'ACTIVE',
    givenName: '',
    phoneNumber: '',
    email: '',
    oganization: '',
  };

  const dataCustomer = useSelector((state: any) => state.customerReducer);

  const onFinish = values => {
    const { phoneNumber, status, email, givenName } = values;
    const phoneLatest = phoneNumber.replace(regexGetPhoneNumber, '');
    const arrName = [{ givenName }];
    const emailLatest = email ? email.replace(regexGetEmailLatest, '') : '';

    const data = {
      user: {
        ...values,
        username: phoneLatest,
        status,
        email: emailLatest,
        person: {
          names: arrName,
        },
      },
    };

    delete data?.user?.oganization;
    delete data?.user?.phoneNumber;
    delete data?.user?.givenName;
    dispatch(postAddInformationCustomerRequested(data));
  };
  if (dataCustomer?.errorAdd === false) {
    onHide();
    dispatch(handleDisplayModalAdd());
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title={title || 'Thêm mới khách hàng'}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initForm}
        requiredMark={false}
      >
        <Form.Item label="Trạng thái" name="status">
          <Radio.Group>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Họ và tên"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 50, message: 'Họ tên không vượt quá 50 ký tự!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="givenName"
        >
          <Input />
        </Form.Item>
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item
          label="Số điện thoại"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            {
              pattern: regexCheckPhone,
              message: 'Số điện thoại ít nhất 10 số và bắt đầu 03|05|07|08|09',
            },
          ]}
          name="phoneNumber"
        >
          <InputMask className="ant-input" mask="9999 999 999" maskChar="" />
        </Form.Item>
        <Form.Item label="Tổ chức" name="oganization">
          <Input />
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={dataCustomer.isLoadingAddInformation}
          >
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddInformation;
