export const formRules = [
  {
    required: true,
    message: 'Nội dung không được để trống',
  },
  {
    max: 500,
    message: 'Vui lòng không nhập quá 500 ký tự',
  },
];
