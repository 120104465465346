import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { ServiceModel } from 'models/store/service.model';

export interface ServiceReducerState {
  dataService: ServiceModel;
  isLoadingService: boolean;
  dataAddService: any;
  isLoadingAddService: boolean;
  dataEditService: any;
  isLoadingEditService: boolean;
  errorEdit: any;
  errorAdd: any;
  isLoadingLock: any;
  detailService: any;
}

const initialState: ServiceReducerState = {
  dataService: {} as ServiceModel,
  isLoadingService: false,
  dataAddService: {},
  isLoadingAddService: false,
  dataEditService: {},
  isLoadingEditService: false,
  errorEdit: '',
  errorAdd: '',
  isLoadingLock: '',
  detailService: [],
};

const serviceReducer = handleActions<any, ActionModel>(
  {
    GET_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingService: true,
        dataService: null,
      };
    },

    GET_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataService: action.payload,
        isLoadingService: false,
      };
    },

    GET_SERVICE_FAILED: state => {
      return {
        ...state,
        isLoadingService: false,
        dataService: null,
      };
    },

    HANDLE_CREATE_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddService: true,
        dataAddService: null,
        errorAdd: '',
      };
    },

    HANDLE_CREATE_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddService: action.payload,
        isLoadingAddService: false,
        errorAdd: false,
      };
    },

    HANDLE_CREATE_SERVICE_FAILED: state => {
      return {
        ...state,
        isLoadingAddService: false,
        dataAddService: null,
        errorAdd: true,
      };
    },
    HANDLE_UPDATE_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingEditService: true,
        dataEditService: null,
        errorEdit: '',
      };
    },

    HANDLE_UPDATE_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataEditService: action.payload,
        isLoadingEditService: false,
        errorEdit: false,
      };
    },

    HANDLE_UPDATE_SERVICE_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingEditService: false,
        dataEditService: null,
        errorEdit: true,
      };
    },
    HANDLE_DISPLAY_MODAL_EDIT: () => {
      return {
        errorEdit: '',
      };
    },
    HANDLE_DISPLAY_MODAL_ADD: () => {
      return {
        errorAdd: '',
      };
    },
    HANDLE_LOCKED_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingLock: true,
      };
    },
    HANDLE_LOCKED_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },
    HANDLE_LOCKED_SERVICE_FAILED: state => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },

    GET_DETAIL_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingService: true,
        detailService: null,
      };
    },

    GET_DETAIL_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        detailService: action.payload,
        isLoadingService: false,
      };
    },

    GET_DETAIL_SERVICE_FAILED: state => {
      return {
        ...state,
        isLoadingService: false,
      };
    },

    HANDLE_CREATE_LOGO_SERVICE_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddService: true,
      };
    },

    HANDLE_CREATE_LOGO_SERVICE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingAddService: false,
      };
    },

    HANDLE_CREATE_LOGO_SERVICE_FAILED: state => {
      return {
        ...state,
        isLoadingAddService: false,
      };
    },
  },
  initialState,
  { prefix: 'service' },
);

export default serviceReducer;
