import React, { FC, useState, useEffect } from 'react';
import { omitBy } from 'lodash';

import { Input, Form, Button, Modal, Radio, InputNumber } from 'antd';
import moment from 'moment';
import './ModalAddFollowUp.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ProviderUUID } from 'constants/AppointmentDetai';
import { formRules } from 'helpers/formUtils';

import StoreState from 'models/store';
import {
  createFollowing,
  updateFollowing,
} from 'store/appointment/appointment.actions';
import appointmentActions from 'store/appointment/actions';
import { ModalTypeEnum } from '../DetailAppointment/FollowUp/FollowUp';

interface Props {
  type: ModalTypeEnum;
  visible: boolean;
  handleCancelModal: () => void;
  followDate: string;
  follower: string;
  followId: string;
  followDescription: string;
  followUpResult: string;
}
const ModalAddFollowUp: FC<Props> = ({
  type,
  visible,
  handleCancelModal,
  followDate,
  follower,
  followId,
  followDescription,
  followUpResult,
}) => {
  const dispatch = useDispatch();
  const [isCreating, setCreating] = useState(false);
  // const [isFirstChange, setIsFirstChange] = useState<boolean>(
  //   type === type.UPDATE_RESULT,
  // );

  const providerUuid = useSelector(
    (store: StoreState) =>
      store.appointment.detailAppointment.selectedData?.provider?.uuid,
  );

  const appointmentUuid = useSelector(
    (store: StoreState) =>
      store.appointment.detailAppointment.selectedData?.dataCloned?.detail
        .uuid || '',
  );

  // const [followResult, setFollowResult] = useState<string>(
  //   followUpResult || '',
  // );
  const [radioValue, setRadioValue] = useState<string>(follower);
  const initValue = {
    followDescription,
    followResult: followUpResult,
    // eslint-disable-next-line no-nested-ternary
    day: followDate
      ? moment(followDate, moment.ISO_8601).diff(
          moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
          'day',
        ) < 0
        ? 0
        : moment(followDate, moment.ISO_8601).diff(
            moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 }),
            'day',
          )
      : 0,
  };

  useEffect(() => {
    if (followDate || follower) {
      setRadioValue(follower);
    }
  }, [followDate, followDate]);

  const formItemLayout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [content] = useState<string>('');

  useEffect(() => {}, []);

  useEffect(() => {
    form.setFieldsValue({
      cancelReason: content,
    });
  }, [content, form]);

  const callbackSuccess = () => {
    setCreating(false);
    handleCancelModal();
    dispatch(appointmentActions.fetchDetailAppointment(appointmentUuid));
  };

  // const rules = [
  //   {
  //     equired: true,
  //     message: 'Vui lòng nhập số ngày trong khoảng 1 đén 29',
  //   },
  // ];

  const callbackFailed = () => {
    setCreating(false);
  };

  const onFinish = values => {
    // if (followDate === '' || radioFollowingActive === '') {
    //   return message.error('Vui lòng điền đủ thông tin');
    // }
    // if (Number(followingDate) <= 0 || Number(followingDate) >= 30) {
    //   throwValidateError('Vui lòng nhập số ngày trong khoảng 1 đén 29');
    //   break;
    // }
    // if (isNaN(Number(followingDate))) {
    //   throwValidateError('Vui lòng nhập số ngày liên hệ');
    //   break;
    // }
    setCreating(true);
    if (followId) {
      const data = {
        follower: radioValue,
        followDescription: values.followDescription.trim(),
        followDate: moment()
          .add(Number(values.day), 'days')
          .set({ hour: 8, minute: 0, second: 0 })
          .format(),
        appointment: appointmentUuid,
        followResult: values?.followResult?.trim() || '',
      };

      const followingData = omitBy(data, paramValue => !paramValue);

      dispatch(
        updateFollowing(
          followId,
          followingData,
          values,
          callbackSuccess,
          callbackFailed,
        ),
      );
    } else {
      const data = {
        follower: radioValue,
        followDescription: values.followDescription.trim(),
        followDate: moment()
          .add(Number(values.day), 'days')
          .set({ hour: 8, minute: 0, second: 0 })
          .format(),
        appointment: appointmentUuid,
      };
      const followingData = omitBy(data, paramValue => !paramValue);
      dispatch(
        createFollowing(
          appointmentUuid,
          followingData,
          callbackSuccess,
          callbackFailed,
        ),
      );
    }
  };

  return (
    <Modal
      title="Yêu cầu theo dõi bệnh"
      centered
      visible={visible}
      onOk={handleCancelModal}
      onCancel={handleCancelModal}
      width={650}
      footer={null}
    >
      <Form
        {...formItemLayout}
        initialValues={initValue}
        onFinish={onFinish}
        className="modal-add-customer"
        form={form}
      >
        <div className="modal-container">
          <div className="flex test">
            <Form.Item
              label=""
              name="followDescription"
              className="form-items m-0 p-0"
              rules={formRules}
            >
              <TextArea
                className="form-items cancel-reason"
                placeholder="Nhập nội dung theo dõi"
                autoSize={{ minRows: 5 }}
                // onChange={e => setDescription(e.target.value)}
                maxLength={500}
                autoFocus={true}
                disabled={type === ModalTypeEnum.UPDATE_RESULT}
              />
            </Form.Item>
          </div>
          <div className="select-box">
            <div className="select-box__title">
              <span>Liên hệ theo dõi sau</span>
            </div>
            <div className="select-box__box">
              <div className="select-box__box-1">
                <Form.Item
                  name="day"
                  className="form-items"
                  rules={
                    type !== ModalTypeEnum.UPDATE_RESULT
                      ? [
                          {
                            type: 'number',
                            min: 1,
                            max: 29,
                            message:
                              'Vui lòng nhập số ngày trong khoảng 1 đến 29',
                          },
                          {
                            required: true,
                            message:
                              'Vui lòng nhập số ngày trong khoảng 1 đến 29',
                          },
                        ]
                      : []
                  }
                >
                  <InputNumber
                    placeholder="0"
                    disabled={type === ModalTypeEnum.UPDATE_RESULT}
                  />
                </Form.Item>
              </div>
              <div className="select-box__box-2">
                <span>Ngày</span>
              </div>
            </div>
          </div>
          <div className="radio-container">
            <Form.Item
              name="radio"
              initialValue={radioValue}
              rules={[
                {
                  required: true,
                  message: 'Người theo dõi không được để trống',
                },
              ]}
            >
              <Radio.Group
                onChange={e => setRadioValue(e.target.value)}
                value={radioValue}
                disabled={type === ModalTypeEnum.UPDATE_RESULT}
              >
                <div className="radio-container__1">
                  <div className="radio-container__box">
                    <Radio value={ProviderUUID.Doctor_4U}>
                      <span>Doctor4U thay mặt bác sỹ liên hệ</span>
                    </Radio>
                  </div>
                </div>
                <div className="radio-container__1">
                  <div className="radio-container__box">
                    <Radio value={providerUuid}>
                      <span>Bác sỹ tự liên hệ</span>
                    </Radio>
                  </div>
                </div>
              </Radio.Group>
            </Form.Item>
          </div>
          <div>
            {type !== ModalTypeEnum.CREATE_FOLLOW && (
              <>
                <div className="result_title">
                  <span>Kết quả theo dõi:</span>
                </div>
                <div>
                  <Form.Item
                    label=""
                    name="followResult"
                    className="form-items m-0 p-0"
                    rules={
                      type === ModalTypeEnum.UPDATE_RESULT ? formRules : []
                    }
                  >
                    <TextArea
                      className="form-items cancel-reason"
                      placeholder="Nhập kết quả theo dõi"
                      autoSize={{ minRows: 5 }}
                      // onChange={e => setFollowResult(e.target.value)}
                      maxLength={500}
                      disabled={type === ModalTypeEnum.UPDATE_FOLLOW}
                    />
                  </Form.Item>
                </div>
              </>
            )}
          </div>

          <div className="button-cancel-add-information-container">
            <Button
              type="primary"
              className="mr-2"
              htmlType="submit"
              loading={isCreating}
            >
              Lưu
            </Button>
            <Button
              type="default"
              className="ml-2"
              onClick={handleCancelModal}
              disabled={isCreating}
            >
              Hủy
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddFollowUp;
