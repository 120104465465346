import React, { FC, useEffect, useState, useRef } from 'react';
import {
  Card,
  Row,
  Col,
  Button,
  Table,
  Input,
  Form,
  DatePicker,
  Modal,
  Spin,
} from 'antd';
import * as _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import locale from 'antd/es/date-picker/locale/vi_VN';
import { formatDateDayVN } from 'helpers/utils';

import ActionTable from '../../components/Share/Table/Action';
import ModalAddHoliday from '../../components/Holiday/ModalAddHoliday';
import ModalDetailHoliday from '../../components/Holiday/ModalDetailHoliday';
import ModalEditHoliday from '../../components/Holiday/ModalEditHoliday';
// import * as STATUS from '../Doctor/doctor.const';
import * as holidayActions from '../../store/holiday/holiday.action';

interface ParamsHolidayModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  holidayName?: string;
  sortField?: string;
  sortDirection?: string;
  length?: any;
}

const Holiday: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const initStartIndex: number = 1;

  const [paramsHoliday, setParamsHoliday] = useState<ParamsHolidayModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    sortField: 'DATE_CREATED',
    sortDirection: 'DESC',
    startDate: null,
    endDate: null,
  });

  const inputSearch = useRef<any>(null);

  const [showAddHolidayModal, setShowAddHolidayModal] = useState<boolean>(
    false,
  );
  const [showDetailHolidayModal, setShowDetailHolidayModal] = useState<boolean>(
    false,
  );
  const [showEditHolidayModal, setShowEditHolidayModal] = useState<boolean>(
    false,
  );
  const [selectedData, setSelectedData] = useState<any>();
  const holidayReducer = useSelector((state: any) => state.holidayReducer);

  const onOpenModalAddHoliday = () => {
    setShowAddHolidayModal(true);
    delete paramsHoliday?.holidayName;
    delete paramsHoliday?.startDate;
    delete paramsHoliday?.endDate;
    form.setFieldsValue({ startDate: '', endDate: '', holidayName: '' });
    getHoliday();
  };

  const onCloseModalAddHoliday = () => {
    setShowAddHolidayModal(false);
  };
  const onOpenDetailAddHoliday = item => {
    setSelectedData(item);
    setShowDetailHolidayModal(true);
  };
  const onOpenEditAddHoliday = item => {
    setSelectedData(item);
    setShowEditHolidayModal(true);
  };

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;

    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsHoliday({
      ...paramsHoliday,
      ...body,
    });
  };

  const onFinish = (value: any) => {
    const { startDate, endDate, holidayName } = value;
    const holidayNameTrim = holidayName.trim();
    const newStartDate = startDate?.format('YYYY-MM-DD');
    const newEndDate = endDate?.format('YYYY-MM-DD');

    setParamsHoliday({
      ...paramsHoliday,
      startDate: newStartDate,
      endDate: newEndDate,
      holidayName: holidayNameTrim,
      startIndex: initStartIndex,
    });
  };

  const onStartDate = value => {
    setParamsHoliday({
      ...paramsHoliday,
      startDate: value,
      startIndex: initStartIndex,
    });
  };

  const onEndDate = value => {
    setParamsHoliday({
      ...paramsHoliday,
      endDate: value,
      startIndex: initStartIndex,
    });
  };

  // eslint-disable-next-line no-shadow
  const handleDeleteHoliday = record => {
    const onDelete = async () => {
      const uuid = _.get(record, 'uuid', '');
      await dispatch(holidayActions.handleDeleteHolidayRequested(uuid));
      await getHoliday();
    };
    confirm({
      title: 'Bạn có chắc chắn xóa ngày lễ này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const getHoliday = () => {
    dispatch(holidayActions.getHolidayRequested(paramsHoliday));
  };

  useEffect(() => {
    getHoliday();
  }, [paramsHoliday]);

  const columns = [
    {
      title: 'STT',
      key: '1',

      dataIndex: 'key',
      render: (value, row, index) => {
        if (
          paramsHoliday?.startIndex !== undefined &&
          paramsHoliday?.limit !== undefined &&
          paramsHoliday
        ) {
          return (
            (paramsHoliday?.startIndex - 1) * paramsHoliday?.limit + index + 1
          );
        }
      },
    },
    {
      title: 'Tên kỳ lễ',
      key: 'name',
      width: '30%',
      render: (value, row, index) => {
        return row?.holidayName;
      },
    },
    {
      key: 'startDay',
      title: 'Ngày bắt đầu',
      render: (value, row, index) => {
        return formatDateDayVN(row?.startDate);
      },
    },
    {
      key: 'endDay',
      title: 'Ngày kết thúc',

      render: (value, row, index) => {
        return formatDateDayVN(row?.endDate);
      },
    },
    {
      key: '5',
      title: 'Số ngày lễ',

      render: (value, index, row) => {
        if (value?.dayOffNumber !== undefined) {
          return _.get(value, 'dayOffNumber', '');
        }
      },
    },
    {
      key: 'Action',

      render: (value: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            handleDelete={() => handleDeleteHoliday(value)}
            handleDetail={() => onOpenDetailAddHoliday(value)}
            handleEdit={() => onOpenEditAddHoliday(value)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="doctor-information">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý ngày lễ</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Button type="primary" onClick={onOpenModalAddHoliday}>
              Thêm mới
            </Button>
          </Col>
        </Row>

        <Form onFinish={onFinish} form={form}>
          <Row style={{ marginTop: 20 }} gutter={[20, 0]}>
            <Col xs={4}>
              <span>Ngày bắt đầu</span>
              <Form.Item name="startDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  locale={locale}
                  className="mt-2"
                  onChange={event => onStartDate(event)}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <span>Ngày kết thúc</span>
              <Form.Item name="endDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  locale={locale}
                  className="mt-2"
                  onChange={event => onEndDate(event)}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col xs={7} style={{ marginTop: 30, paddingRight: 0 }}>
              <Form.Item name="holidayName">
                <Input
                  ref={inputSearch}
                  placeholder="Tìm kiếm theo tên kỳ lễ..."
                />
              </Form.Item>
            </Col>
            <Col xs={4} style={{ marginTop: 30 }}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: holidayReducer?.isLoadingHoliday,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={holidayReducer?.dataHoliday?.results}
            pagination={{
              current: paramsHoliday.startIndex,
              pageSize: paramsHoliday.limit,
              total: holidayReducer?.dataHoliday?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
      {showAddHolidayModal && (
        <ModalAddHoliday
          show={showAddHolidayModal}
          onHide={onCloseModalAddHoliday}
        />
      )}
      {showEditHolidayModal && (
        <ModalEditHoliday
          show={showEditHolidayModal}
          onHide={() => setShowEditHolidayModal(false)}
          dataProps={selectedData}
        />
      )}
      {showDetailHolidayModal && (
        <ModalDetailHoliday
          show={showDetailHolidayModal}
          onHide={() => setShowDetailHolidayModal(false)}
          dataProps={selectedData}
        />
      )}
    </div>
  );
};

export default Holiday;
