import { VersionModel } from 'models/store/version.model';
import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';

export interface VersionReducerState {
  dataVersion: VersionModel;
  isLoadingVersion: boolean;
  dataAddVersion: any;
  isLoadingAddVersion: boolean;
  dataEditVersion: any;
  isLoadingEditVersion: boolean;
  errorEdit: any;
  errorAdd: any;
  isLoadingDelete: any;
  dataDeleteVersion: any;
  isLoadingSettings: boolean;
  isLoadingUpdateSettings: boolean;
  dataUpdateVersion: any;
}

const initialState: VersionReducerState = {
  dataVersion: {} as VersionModel,
  isLoadingVersion: false,
  dataAddVersion: {},
  dataUpdateVersion: {},
  isLoadingAddVersion: false,
  dataEditVersion: {},
  isLoadingEditVersion: false,
  errorEdit: '',
  errorAdd: '',
  isLoadingDelete: '',
  dataDeleteVersion: {} as any,
  isLoadingSettings: false,
  isLoadingUpdateSettings: false,
};

const versionReducer = handleActions<any, ActionModel>(
  {
    GET_VERSION_REQUESTED: state => {
      return {
        ...state,
        isLoadingVersion: true,
        dataVersion: null,
      };
    },
    GET_VERSION_SUCCESSED: (state, action) => {
      return {
        ...state,
        dataVersion: action.payload,
        isLoadingVersion: false,
      };
    },
    GET_VERSION_FAILED: state => {
      return {
        ...state,
        isLoadingVersion: false,
        dataVersion: null,
      };
    },

    GET_HIGHTEST_VERSION_DOCTOR_REQUESTED: state => {
      return {
        ...state,
        isLoadingHightestVersion: true,
      };
    },
    GET_HIGHTEST_VERSION_DOCTOR_SUCCESS: (state, action) => {
      return {
        ...state,
        dataHightestVersionDoctor: action.payload,
        isLoadingHightestVersion: false,
      };
    },
    GET_HIGHTEST_VERSION_DOCTOR_FAILED: state => {
      return {
        ...state,
        isLoadingHightestVersion: false,
        dataHightestVersionDoctor: null,
      };
    },

    GET_HIGHTEST_VERSION_PATIENT_REQUESTED: state => {
      return {
        ...state,
        isLoadingHightestVersion: true,
      };
    },
    GET_HIGHTEST_VERSION_PATIENT_SUCCESS: (state, action) => {
      return {
        ...state,
        dataHightestVersionPatient: action.payload,
        isLoadingHightestVersion: false,
      };
    },
    GET_HIGHTEST_VERSION_PATIENT_FAILED: state => {
      return {
        ...state,
        dataHightestVersionPatient: null,
        isLoadingHightestVersion: false,
      };
    },

    GET_VERSION_SETTINGS_REQUESTED: state => {
      return {
        ...state,
        isLoadingSettings: true,
      };
    },
    GET_VERSION_SETTINGS_SUCCESSED: (state, action) => {
      return {
        ...state,
        isLoadingSettings: false,
        dataCheck: action.payload,
      };
    },
    GET_VERSION_SETTINGS_FAILED: state => {
      return {
        ...state,
        isLoadingSettings: false,
      };
    },

    POST_UPDATE_VERSION_SETTINGS_REQUESTED: state => {
      return {
        ...state,
        // dataUpdateVersion: action.payload,
        isLoadingUpdateSettings: true,
        isUpdateFished: false,
      };
    },
    POST_UPDATE_VERSION_SETTINGS_SUCCESSED: (state, action) => {
      return {
        ...state,
        dataUpdateVersion: action.payload,
        isLoadingUpdateSettings: false,
        isUpdateFished: true,
      };
    },
    POST_UPDATE_VERSION_SETTINGS_FAILED: (state: any) => {
      return {
        ...state,
        isLoadingUpdateSettings: false,
        isUpdateFished: false,
      };
    },

    POST_ADD_VERSION_REQUESTED: (state: VersionReducerState) => {
      return {
        ...state,
        isLoadingAddVersion: true,
        isAddFished: false,
      };
    },
    POST_ADD_VERSION_SUCCESSED: (state: VersionReducerState, action) => {
      return {
        ...state,
        dataAddVersion: action.payload,
        isLoadingAddVersion: false,
        isAddFished: true,
      };
    },
    POST_ADD_VERSION_FAILED: (state: VersionReducerState) => {
      return {
        ...state,
        isLoadingAddVersion: false,
        isAddFished: false,
      };
    },
    HANDLE_DELETE_VERSION_REQUESTED: state => {
      return {
        ...state,
        isDeleteFished: false,
      };
    },
    HANDLE_DELETE_VERSION_SUCCESSED: (state, action) => {
      return {
        ...state,
        isDeleteFished: true,
        // dataDeleteVersion: action.payload,
      };
    },
    HANDLE_DELETE_VERSION_FAILED: state => {
      return {
        ...state,
        isDeleteFished: false,
      };
    },
  },
  initialState,
  { prefix: 'version' },
);

export default versionReducer;
