import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
// import { PromotionModel } from 'models/store/promotion.model';

export interface MarketingNotiParams {
  loading: boolean;
  data: any;
  error: any;
}

const initialState: MarketingNotiParams = {
  loading: false,
  data: [],
  error: [],
};

const marketingNotification = handleActions<any, ActionModel>(
  {
    HANDLE_CREATE_MARKETING_NOTI_REQUESTED: state => {
      return {
        ...state,
        loading: true,
      };
    },

    HANDLE_CREATE_MARKETING_NOTI_SUCCEEDED: (state, action) => {
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    },

    HANDLE_CREATE_MARKETING_NOTI_FAILED: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
  initialState,
  { prefix: 'marketingNoti' },
);

export default marketingNotification;
