import {
  AppstoreAddOutlined,
  AuditOutlined,
  CalendarOutlined,
  ContainerOutlined,
  DashboardOutlined,
  StarOutlined,
  MedicineBoxOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import Dashboard from 'containers/App/Dashboard';
import AppointmentEdit from 'containers/Appointment/Edit';
import Appointment from 'containers/Appointment/index';
import NewAppointmentDetail from 'containers/Appointment/NewDetail';
import Information from 'containers/Customer/Information';
import Transaction from 'containers/Customer/Transaction';
import TransactionDetail from 'containers/Customer/TransactionDetail';
import CustomSetting from 'containers/CustomSetting/CustomSetting';
import SpeciaList from 'containers/Specialist/Specialist';
import AddDoctor from 'containers/Doctor/Actions/AddDoctor';
import DetailDoctor from 'containers/Doctor/Actions/DetailDoctor';
import EditDoctor from 'containers/Doctor/Actions/EditDoctor';
import FeeDetail from 'containers/Doctor/Actions/FeeDetail';
// eslint-disable-next-line import/no-named-as-default
import InformationOfDoctor from 'containers/Doctor/Information';
import TransactionOfDoctor from 'containers/Doctor/Transaction';
import TransactionDetailOfDoctor from 'containers/Doctor/TransactionDetail';
import Holiday from 'containers/Holiday/Information';
import MarketingNoti from 'containers/MarketingNoti/MarketingNoti';
import Promotion from 'containers/Promotion/Information';
import Rating from 'containers/Rating/Rating';
import Referral from 'containers/Referral/Information';
import Service from 'containers/Service/Service';
import VersionManagement from 'containers/Version/VersionManagement';
import withAuthenticate from 'hocs/withAuthenticate';
import Route from 'models/Route';
import React from 'react';
import { ReactComponent as Diamond } from 'assets/images/common/ic_service_package.svg';
import InformationServicePackage from 'containers/ServicePackage/Information';
import AddServicePackage from 'containers/ServicePackage/actions/add';
import EditServicePackage from 'containers/ServicePackage/actions/edit';

const appRoutes: Route[] = [
  {
    exact: true,
    path: '/',
    component: withAuthenticate(Dashboard),
    name: 'Dashboard',
    icon: <DashboardOutlined />,
  },
  {
    exact: true,
    path: '/appointments',
    component: withAuthenticate(Appointment),
    name: 'Danh sách tư vấn',
    icon: <ContainerOutlined />,
  },
  {
    exact: true,
    path: '/appointments/edit/:id',
    component: withAuthenticate(AppointmentEdit),
    name: 'Chỉnh sửa tư vấn',
    firstRoute: true,
    hideInNav: true,
  },
  {
    exact: true,
    path: '/appointments/detail/:id',
    component: withAuthenticate(NewAppointmentDetail),
    name: 'Chi tiết tư vấn',
    firstRoute: true,
    hideInNav: true,
  },

  {
    exact: true,
    path: '/customer',
    name: 'Quản lý khách hàng',
    icon: <UserOutlined />,
    subRoutes: [
      {
        exact: true,
        name: 'Thông tin',
        component: withAuthenticate(Information),
        path: '/information',
      },
      {
        exact: true,
        name: 'Giao dịch và số dư tài khoản',
        component: withAuthenticate(Transaction),
        path: '/transaction',
      },
      {
        hideInNav: true,
        exact: true,
        path: '/transaction/:id',
        component: withAuthenticate(TransactionDetail),
        name: '',
      },
    ],
  },
  {
    exact: true,
    path: '/service-package',
    component: withAuthenticate(InformationServicePackage),
    name: 'Quản lý gói khám dịch vụ',
    icon: <Diamond width={12} height={12} />,
  },
  {
    hideInNav: true,
    exact: true,
    name: '',
    component: withAuthenticate(AddServicePackage),
    path: '/service-package/add',
  },
  {
    hideInNav: true,
    exact: true,
    name: '',
    component: withAuthenticate(EditServicePackage),
    path: '/service-package/edit/:id',
  },
  {
    exact: true,
    path: '/doctor',
    name: 'Quản lý bác sỹ',
    icon: <MedicineBoxOutlined />,
    subRoutes: [
      {
        hideInNav: true,
        exact: true,
        name: 'Xem chi tiết chi phí khám',
        component: withAuthenticate(FeeDetail),
        path: '/information/detail-fee/:id',
      },
      {
        exact: true,
        name: 'Thông tin',
        component: withAuthenticate(InformationOfDoctor),
        path: '/information',
      },

      {
        hideInNav: true,
        exact: true,
        name: '',
        component: withAuthenticate(AddDoctor),
        path: '/information/add-doctor',
      },
      {
        hideInNav: true,
        exact: true,
        name: '',
        component: withAuthenticate(EditDoctor),
        path: '/information/edit-doctor/:id',
      },
      {
        hideInNav: true,
        exact: true,
        name: '',
        component: withAuthenticate(DetailDoctor),
        path: '/information/detail-doctor/:id',
      },
      {
        exact: true,
        name: 'Giao dịch và số dư tài khoản',
        component: withAuthenticate(TransactionOfDoctor),
        path: '/transaction',
      },
      {
        exact: true,
        hideInNav: true,
        name: 'Xem chi tiết',
        component: withAuthenticate(TransactionDetailOfDoctor),
        path: '/transaction/detail-transaction/:id',
      },
    ],
  },
  {
    exact: true,
    path: '/holiday',
    component: withAuthenticate(Holiday),
    name: 'Quản lý ngày lễ',
    icon: <CalendarOutlined />,
  },
  {
    exact: true,
    path: '/promotion',
    name: 'Quản lý chiến dịch khuyến mại',
    component: withAuthenticate(Promotion),
    icon: <ShoppingCartOutlined />,
  },
  {
    exact: true,
    path: '/referral',
    name: 'Quản lý chương trình giới thiệu',
    component: withAuthenticate(Referral),
    icon: <UsergroupAddOutlined />,
  },
  {
    exact: true,
    path: '/version',
    component: withAuthenticate(VersionManagement),
    name: 'Quản lý phiên bản',
    icon: <AppstoreAddOutlined />,
  },
  {
    exact: true,
    path: '/marketing',
    component: withAuthenticate(MarketingNoti),
    name: 'Marketing Promotion Notification',
    icon: <AuditOutlined />,
  },
  {
    exact: true,
    path: '/setting',
    component: withAuthenticate(MarketingNoti),
    name: 'Cấu hình',
    icon: <SettingOutlined />,
    subRoutes: [
      {
        exact: true,
        name: 'Cấu hình thời gian cuộc hẹn',
        component: withAuthenticate(CustomSetting),
        path: '/time-appointment',
      },
      {
        exact: true,
        name: 'Quản lý chuyên khoa',
        component: withAuthenticate(SpeciaList),
        path: '/specialist',
      },
    ],
  },
  {
    exact: true,
    path: '/rating',
    component: withAuthenticate(Rating),
    name: 'Quản lý danh sách đánh giá',
    icon: <StarOutlined />,
  },
  // {
  //   exact: true,
  //   path: '/service',
  //   component: withAuthenticate(Service),
  //   name: 'Quản lý gói dịch vụ',
  //   icon: <Diamond width={12} height={12} />,
  // },
];

export default appRoutes;
