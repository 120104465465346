import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined, FormOutlined } from '@ant-design/icons';

type ActionProps = {
  handleEdit?: (...args: any[]) => any;
  handleDelete?: (...args: any[]) => any;
};

const initProps = {
  handleEdit: () => {},
  handleDelete: () => {},
};

const Action: FC<ActionProps> = (props: ActionProps) => {
  const { handleEdit, handleDelete } = {
    ...initProps,
    ...props,
  };

  return (
    <>
      <Tooltip title="Sửa" overlayClassName="lg:ml-2">
        <Button
          className="lg:ml-2"
          onClick={events => {
            events.stopPropagation();
            handleEdit();
          }}
        >
          <FormOutlined
            style={{
              color: '#1a202c',
              fontSize: '20px',
            }}
          />
        </Button>
      </Tooltip>
      <Tooltip title="Xóa">
        <Button className="lg:ml-2" onClick={() => handleDelete()}>
          <DeleteOutlined style={{ color: '#1a202c', fontSize: '20px' }} />
        </Button>
      </Tooltip>
    </>
  );
};

export default Action;
