import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { HolidayModel } from 'models/store/holiday.model';

export interface HolidayReducerState {
  dataHoliday: HolidayModel;
  isLoadingHoliday: boolean;
  dataAddHoliday: any;
  isLoadingAddHoliday: boolean;
  dataEditHoliday: any;
  isLoadingEditHoliday: boolean;
  errorEdit: any;
  errorAdd: any;
  isLoadingDelete: any;
}

const initialState: HolidayReducerState = {
  dataHoliday: {} as HolidayModel,
  isLoadingHoliday: false,
  dataAddHoliday: {},
  isLoadingAddHoliday: false,
  dataEditHoliday: {},
  isLoadingEditHoliday: false,
  errorEdit: '',
  errorAdd: '',
  isLoadingDelete: '',
};

const holidayReducer = handleActions<any, ActionModel>(
  {
    GET_HOLIDAY_REQUESTED: state => {
      return {
        ...state,
        isLoadingHoliday: true,
        dataHoliday: null,
      };
    },

    GET_HOLIDAY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataHoliday: action.payload,
        isLoadingHoliday: false,
      };
    },

    GET_HOLIDAY_FAILED: state => {
      return {
        ...state,
        isLoadingHoliday: false,
        dataHoliday: null,
      };
    },
    HANDLE_CREATE_HOLIDAY_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddHoliday: true,
        dataAddHoliday: null,
        errorAdd: '',
      };
    },

    HANDLE_CREATE_HOLIDAY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddHoliday: action.payload,
        isLoadingAddHoliday: false,
        errorAdd: false,
      };
    },

    HANDLE_CREATE_HOLIDAY_FAILED: state => {
      return {
        ...state,
        isLoadingAddHoliday: false,
        dataAddHoliday: null,
        errorAdd: true,
      };
    },
    HANDLE_UPDATE_HOLIDAY_REQUESTED: state => {
      return {
        ...state,
        isLoadingEditHoliday: true,
        dataEditHoliday: null,
        errorEdit: '',
      };
    },

    HANDLE_UPDATE_HOLIDAY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataEditHoliday: action.payload,
        isLoadingEditHoliday: false,
        errorEdit: false,
      };
    },

    HANDLE_UPDATE_HOLIDAY_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingEditHoliday: false,
        dataEditHoliday: null,
        errorEdit: true,
      };
    },
    HANDLE_DISPLAY_MODAL_EDIT: () => {
      return {
        errorEdit: '',
      };
    },
    HANDLE_DISPLAY_MODAL_ADD: () => {
      return {
        errorAdd: '',
      };
    },
    HANDLE_DELETE_HOLIDAY_REQUESTED: state => {
      return {
        ...state,
        isLoadingDelete: true,
      };
    },
    HANDLE_DELETE_HOLIDAY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
      };
    },
    HANDLE_DELETE_HOLIDAY_FAILED: state => {
      return {
        ...state,
        isLoadingDelete: false,
      };
    },
  },
  initialState,
  { prefix: 'holiday' },
);

export default holidayReducer;
