import CommonConfig from './common.config';
import StagingConfig from './staging.config';
import ProductionConfig from './production.config';

export interface ConfigModel {
  NAME: string;
  BASE_API_URL: string;
  BASE_CALL_URL: string;
  BASE_IMAGE_URL: string;
}

// eslint-disable-next-line import/no-mutable-exports
let config: ConfigModel = CommonConfig;

const mode = (
  process.env.REACT_APP_STAGE ||
  process.argv[2] ||
  'production'
).trim();
if (mode === 'staging') {
  // staging code
  config = Object.assign(config, StagingConfig);
} else if (mode === 'dev') {
  // dev code
  config = Object.assign(config, CommonConfig);
} else {
  config = Object.assign(config, ProductionConfig);
}
// config = Object.assign(config, CommonConfig);

export default config;

export const COOKIES_NAME = 'auth.cookies';
export const USERNAME = 'userName';
