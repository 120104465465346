/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
export const regexCheckPhone = /^[(]?[09|03|05|07|08]{2}[)]?[0-9]{2}?[-\s.]?[0-9]{3}[-/\s.]?[0-9]{3}$/;
export const regexCheckEmail = /^[\s]*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))[\s]*$/;
export const regexGetEmailLatest = /[\s]+/g;
export const regexGetFeeLatest = /[,]/g;
export const regexGetPassLatest = /^[\s]*([\da-zA-Z]{6,20})+[\s]*$/;
export const regexGetPassVietLatest = /^([\da-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]{6,20})*$/;
export const regexGetPassConvert = /[\s]+/g;
export const regexConvertSearch1 = /^ +/g;
export const regexConvertSearch2 = / +$/g;
export const regexConvertTrim = /\s/g;

export const regexGetPhoneNumber = /[\s]+/g;
export const regexGetContentEdit = /^(Examination fees:[\s]{1}){1}/g;
export const regexGetContentEditFinal = /([\.]{1}[\d\w]*)*$/g;
export const regexDate1 = /T([\d\w\.:+])*/g;
export const regexDate2 = /([\d]{2})*:([\d]{2})*:([\d]{2})*/g;
export const regexTime = /(T([\d]{2})*([:])*([\d]{2})*([:])*([\d]{2})*.)+/g;
export const regexFilterTime = /^[\d]{2}/g;
export const regexPreventChar = /^([\d\w\-_\.ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]*)$/g;
// export const regexPreventCharLatest = /^([\d\w\-_\.ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s]{6,20}*)$/g;
export const regex = /^Working process:([\s]{1})+/g;
export const regexTrainning = /^Training process:([\s]{1})+/g;
export const regexBonus = /^Percent Doctor Profit:([\s]{1})+/g;
export const regexSpecialist = /^Specialist:([\s]{1})+/g;
export const regexFee = /^Examination fees:([\s]{1})+/g;
export const regexFilterFee = /[\.]+([\d\w\s\-_!@#$%^&*())_+,])*/g;
export const mask1 = /[,]?/g;
export const getNumberic = /[\d]*/g;
export const regexRemoveSquareBracketStart = /^\[/g;
export const regexRemoveSquareBracketEnd = /\]$/g;
export const formatDate = /^(0?[1-9]|[12][0-9]|3[01])[\-](0?[1-9]|1[012])[\-]\d{4}$/g;
export const getUrl = /^http:\/\/[\d\w-\.]*(:[0-9]+)?\/?$/g;
export const regextTrimspace = /^([\\[\/\.\],'";\-_~!@#$$%^&*()=_+}{}|":?><,\d\wÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]*([\s]*[\\[\/\.\];,'"=\-_~!@#$$%^&*()_+}{}|":?><,\d\wÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳýỵỷỹ]([\s])*)*)$/g;

// STATUS
export const PAID_STATUS = 'PAID';
export const WAITING_STATUS = 'WAITING';
export const PENDING_STATUS = 'PENDING';
export const CREATE_STATUS = 'CREATE';
export const EDIT_STATUS = 'UPDATE';
export const ACTIVE_STATUS = 'ACTIVE';
export const INACTIVE_STATUS = 'IN_ACTIVE';

// ID FIX
export const ATTRIBUTE_TYPE_PROCESS_PLACE =
  '4cbb3ffd-300d-4ded-b6d1-e7be14f9c74b';
export const ATTRIBUTE_TYPE_FEE = '7d082d60-a53a-426a-98be-700620c4627d';
export const ATTRIBUTE_TYPE_MAJORS = '9a303941-2603-4dcc-aebb-8237038dcbc1';
export const ATTRIBUTE_TYPE_BONUS = 'f53b0cf2-9605-4d39-978e-95fdb73051f9';
export const ATTRIBUTE_TYPE_SPECIALIST = '7eeb5b52-b4de-474f-9d2c-b3577e0d2727';
export const ATTRIBUTE_TYPE_PROCESS_EDU =
  'b1b5c3ab-5f8f-4518-bfd2-85bf219ff7e7';
export const uuidAvatar = '1962dfff-3cf3-420d-b804-f1e096dfb5fd';
export const uuidCover = '1962dghj-3cf3-420d-b804-f1e096dgnaht';

export const getDateIos = /^([\d]{4}-[\d]{2}-[\d]{2})/g;
export const justNumAndChar = /^[a-zA-Z0-9 ]*$/;
