import React, { useState, FC, useEffect } from 'react';
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  InputNumber,
  Upload,
  Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  MinusCircleOutlined,
  PlusOutlined,
  CameraFilled,
} from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import StoreState from 'models/store';
import * as _ from 'lodash';
import jq from 'jquery';
// import authorizedRequest from 'helpers/request/authorizedRequest';
import {
  regexCheckEmail,
  regexCheckPhone,
  regexGetEmailLatest,
  regexGetFeeLatest,
  regexGetPhoneNumber,
  ATTRIBUTE_TYPE_PROCESS_PLACE,
  ATTRIBUTE_TYPE_PROCESS_EDU,
  ATTRIBUTE_TYPE_FEE,
  ATTRIBUTE_TYPE_MAJORS,
  regexPreventChar,
  regextTrimspace,
  ATTRIBUTE_TYPE_BONUS,
  ATTRIBUTE_TYPE_SPECIALIST,
} from '../doctor.const';

import './AddDoctor.scss';

import * as doctorInfoActions from '../../../store/doctor/doctor.action';
// import { DoctorModel } from '../../../models/store/doctor.model';

type AddDoctorModel = {};

interface ParamsInformationDoctorModel {
  v?: string;
  class?: string;
}

const initialValues = {
  attributes: [''],
  status: 'ACTIVE',
  majors: '',
  specialist: [],
  bonus: '',
};

const AddDoctor: FC = type => {
  const { Option } = Select;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const history = useHistory();
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const specialistReducer = useSelector(
    (state: StoreState) => state.doctorReducer.dataSpecialist.results,
  );
  const dataHealth = useSelector(
    (state: StoreState) => state.doctorReducer.dataHealthFacility,
  );

  const [avatarData, setAvatar] = useState(null);
  const [coverData, setCover] = useState(null);
  // const [dataHealth, setDataHealth] = useState<any>();
  const [fileAvatar, setFileAvatar] = useState<any>();
  const [fileCover, setFileCover] = useState<any>();
  const [initialFee, setInitialFee] = useState<any>('');
  const [form] = Form.useForm();

  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
      class: 'Specialist',
    },
  );

  const getSpecialist = () => {
    dispatch(
      doctorInfoActions.getSpecialistRequested({
        ...ParamsInformationDoctorModel,
      }),
    );
  };

  // useEffect(() => {
  //   authorizedRequest
  //     .get('/v1/concept', {
  //       params: {
  //         v: 'full',
  //         class: 'Health facilities',
  //       },
  //     })
  //     .then(res => {
  //       setDataHealth(res);
  //     })
  //     .catch(e => {
  //       console.log(e, 'eeeee');
  //     });
  // }, []);

  useEffect(() => {
    getSpecialist();
  }, []);
  const getHealthFacility = () => {
    dispatch(
      doctorInfoActions.getHealthFacilityRequested({
        v: 'full',
        class: 'Health facilities',
      }),
    );
  };
  useEffect(() => {
    getHealthFacility();
  }, []);
  const dataAddDoctor = useSelector(
    (state: any) => state.doctorReducer.dataAddDoctor,
  );
  const onFinish = values => {
    const {
      email,
      fee,
      attributes,
      majors,
      trainningProcess,
      username,
      status,
      outStanding,
      phoneNumber,
      person,
      avatar,
      cover,
      specialist,
      bonus,
    } = values;
    setFileAvatar(avatar);
    setFileCover(cover);
    const objPlace = attributes.map(item => {
      if (item && item !== '') {
        const placeConvert = {
          attributeType: ATTRIBUTE_TYPE_PROCESS_PLACE,
          value: item,
        };
        return placeConvert;
      }
      return item;
    });

    const phone = phoneNumber
      ? _.replace(phoneNumber, regexGetPhoneNumber, '')
      : '';
    const emailLatest = email ? _.replace(email, regexGetEmailLatest, '') : '';
    const feeLatest = fee ? _.replace(fee, regexGetFeeLatest, '') : '';
    const objFee = {
      attributeType: ATTRIBUTE_TYPE_FEE,
      value: feeLatest,
    };
    specialist.map((item: string) => {
      objPlace.push({
        attributeType: ATTRIBUTE_TYPE_SPECIALIST,
        value: item,
      });
    });

    const objMajors = {
      attributeType: ATTRIBUTE_TYPE_MAJORS,
      value: majors,
    };
    const bonusLatest = _.replace(bonus, regexGetFeeLatest, '');

    const objBonus = {
      attributeType: ATTRIBUTE_TYPE_BONUS,
      value: bonusLatest,
    };

    const objtrainningProcess = {
      attributeType: ATTRIBUTE_TYPE_PROCESS_EDU,
      value: trainningProcess,
    };

    if (objFee.value && objFee.value !== '') {
      objPlace.push(objFee);
    }
    if (objBonus.value && objBonus.value !== '') {
      objPlace.push(objBonus);
    }
    if (objtrainningProcess.value && objtrainningProcess.value !== '') {
      objPlace.push(objtrainningProcess);
    }
    if (objMajors.value && objMajors.value !== '') {
      objPlace.push(objMajors);
    }
    const dataName = [{ givenName: person }];
    const latestUserName = _.replace(username, regexGetPhoneNumber, '');

    const data = {
      ...values,
      email: emailLatest || '',
      attributes: objPlace,
      user: {
        username: latestUserName,
        status,
        outStanding,
        email: emailLatest,
        phoneNumber: phone,
        person: {
          names: dataName,
          gender: 'NON',
        },
      },
    };
    delete data?.person;
    delete data?.fee;
    delete data?.bonus;
    delete data?.trainningProcess;
    delete data?.username;
    delete data?.status;
    delete data?.phoneNumber;
    delete data?.majors;
    delete data?.avatar;
    delete data?.cover;
    delete data?.specialist;

    if (data?.email === '' || data?.email?.length === 0) {
      delete data.user.email;
    }
    delete data?.email;

    dispatch(doctorInfoActions.addDoctorRequested(data));
  };

  // upload image
  if (dataAddDoctor !== undefined) {
    if (fileAvatar !== undefined) {
      const file = fileAvatar?.fileList[0];
      const data = {
        attributeType: '1962dfff-3cf3-420d-b804-f1e096dfb5fd',
      };

      const formData = new FormData();
      if (file !== undefined) {
        formData.append('json', JSON.stringify(data));
        formData.append('file', file?.originFileObj);
        if (Object.keys(dataAddDoctor).length !== 0) {
          dispatch(
            doctorInfoActions.handleUploadAvatarRequested({
              form: formData,
              uuid: dataAddDoctor?.user?.uuid,
            }),
          );
        }
      }
    }
    if (fileCover !== undefined) {
      const file1 = fileCover.fileList[0];
      const data1 = {
        attributeType: '1962dghj-3cf3-420d-b804-f1e096dgnaht',
      };

      const formData1 = new FormData();
      if (file1 !== undefined) {
        formData1.append('json', JSON.stringify(data1));
        formData1.append('file', file1?.originFileObj);
        if (Object.keys(dataAddDoctor).length !== 0) {
          dispatch(
            doctorInfoActions.handleUploadCoverRequested({
              form: formData1,
              uuid: dataAddDoctor?.user?.uuid,
            }),
          );
        }
      }
    }
  }
  // end upload image
  const handleUploadAvatar = (file: any) => {
    setAvatar(file);
    return false;
  };

  const handleRemoveAvatar = () => {
    setAvatar(null);
  };

  const handleUploadCover = (file: any) => {
    setCover(file);
    return false;
  };
  // const getHealthFacility = () => {
  //   dispatch(
  //     doctorInfoActions.getHealthFacilityRequested({
  //       v: 'full',
  //       class: 'Health facilities',
  //     }),
  //   );
  // };
  // useEffect(() => {
  //   getHealthFacility();
  // }, []);
  const handleRemoveCover = () => {
    setCover(null);
  };
  const exceptThisSymbols = ['.'];

  useEffect(() => {
    jq(document).ready(() => {
      jq('.ant-btn').attr('title', '');
    });
  });

  const keyCodeArr = [8, 9, 35, 36, 37, 38, 39, 40];

  return (
    <div className="add-doctor">
      <Row>
        <Col xs={24}>
          <h1 className="mb-10">Thêm mới bác sĩ</h1>
        </Col>
      </Row>

      <Form
        {...formItemLayout}
        name="basic"
        initialValues={initialValues}
        onFinish={onFinish}
        className="modal-add-customer"
        requiredMark={false}
      >
        <div className="flex box-image">
          <div className="w-1/3 box-image_1">
            <Form.Item
              labelAlign="left"
              name="avatar"
              label="Ảnh đại diện"
              className="form-item avatar"
              labelCol={{ span: 12 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('avatar')?.fileList[0]?.size < 10485760 ||
                      getFieldValue('avatar')?.fileList?.length === 0 ||
                      getFieldValue('avatar') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Dung lượng ảnh không vượt quá 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                disabled={false}
                accept="image/*"
                name="image"
                beforeUpload={handleUploadAvatar}
                onRemove={handleRemoveAvatar}
                listType="picture-card"
                className="upload-image"
              >
                {avatarData === null && true ? (
                  <div>
                    <CameraFilled
                      className="camera-icon"
                      style={{
                        fontSize: '30px',
                        opacity: '45%',
                        position: 'relative',
                      }}
                    />
                  </div>
                ) : (
                  ''
                )}
              </Upload>
            </Form.Item>
          </div>

          <div className="w-1/3">
            <div className="box-image_2">
              <Form.Item
                labelAlign="left"
                name="cover"
                label="Ảnh bìa"
                className="form-item cover"
                labelCol={{ span: 12 }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (
                        getFieldValue('cover')?.fileList[0]?.size < 10485760 ||
                        getFieldValue('cover')?.fileList?.length === 0 ||
                        getFieldValue('cover') === undefined
                      ) {
                        return Promise.resolve();
                      }
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise.reject(
                        'Vui lòng nhập kích thước ảnh nhỏ hơn 10MB!',
                      );
                    },
                  }),
                ]}
              >
                <Upload
                  disabled={false}
                  accept="image/*"
                  name="cover"
                  beforeUpload={handleUploadCover}
                  onRemove={handleRemoveCover}
                  listType="picture-card"
                  className="upload-image"
                >
                  {coverData === null && true ? (
                    <div>
                      <CameraFilled
                        style={{ fontSize: '30px', opacity: '45%' }}
                      />
                    </div>
                  ) : null}
                </Upload>
              </Form.Item>
            </div>
          </div>
        </div>
        <Form.Item label="Trạng thái" name="status" className="form-item">
          <Radio.Group>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Bác sĩ tiêu biểu"
          name="outstanding"
          className="form-item"
          labelAlign="left"
        >
          <Radio.Group>
            <Radio value={true}>Có</Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          className="form-item"
          rules={[
            {
              pattern: regexPreventChar,
              message: 'Username không được chứa ký tự đặc biệt!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item
          label="Số điện thoại"
          name="phoneNumber"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            {
              pattern: regexCheckPhone,
              message: 'Số điện thoại ít nhất 10 số và bắt đầu 03|05|07|08|09',
            },
          ]}
          className="form-item"
        >
          <InputMask
            className="ant-input"
            mask="9999 999 999"
            maskChar=""
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              pattern: regexCheckEmail,
              message: 'Vui lòng nhập email hợp lệ!',
            },
          ]}
          className="form-item"
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item
          label="Họ và tên"
          name="person"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            { max: 150, message: 'Họ tên không vượt quá 50 ký tự!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
          className="form-item"
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Học hàm, học vị"
          name="rank"
          rules={[
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Nơi công tác"
          name="workPlace"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Quá trình công tác"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
          name="attributes"
        >
          <Form.List name="attributes">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <Form.Item required={true} key={field.key}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng điền thông tin bắt buộc!',
                            },
                            {
                              pattern: regextTrimspace,
                              message: 'Vui lòng điền thông tin bắt buộc!',
                            },
                          ]}
                          className="form-nested"
                          style={{
                            position: 'relative',
                          }}
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          noStyle
                        >
                          <Input style={{ width: '50%' }} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    );
                  })}
                  <Form.Item className="wrap-btn-add">
                    <Button
                      type="primary"
                      style={{
                        position: 'absolute',
                        top: -56,
                        right: '41%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className="addPlace"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined style={{ fontSize: '15px' }} />
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Thông tin mô tả"
          name="description"
        >
          <TextArea autoSize={true || { maxRows: 5 }} />
        </Form.Item>

        <Form.Item
          className="form-item"
          label="Số năm kinh nghiệm"
          name="yearExperience"
          rules={[
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  (getFieldValue('yearExperience') > 0 &&
                    getFieldValue('yearExperience')?.length <= 2) ||
                  !getFieldValue('yearExperience')
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Vui lòng nhập đúng thông tin!');
              },
            }),
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <Input type="number" min="0" />
            </Col>
            <Col xs={4}>Năm</Col>
          </Row>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Cơ sở y tế"
          name="majors"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Select style={{ width: '40%' }}>
            {dataHealth?.results?.map((item, index) => (
              <Option value={item.uuid} key={item.uuid}>
                {item.display}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Quá trình đào tạo"
          name="trainningProcess"
        >
          <TextArea
            style={{ width: '100%' }}
            autoSize={true || { maxRows: 5 }}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Phí khám"
          name="fee"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            ({ getFieldValue, setFieldsValue }) => ({
              validator(rule, value) {
                const reg = new RegExp('^[a-zA-Z]+$');
                if (!value) {
                  return Promise.resolve();
                }
                if (reg.test(value)) {
                  setInitialFee('');
                  setFieldsValue({ fee: '' });
                }
                const validateNumber = value.replace(/[,]/g, '');
                const isNumberInteger = (item: number) =>
                  Number.isInteger(+item);
                if (
                  // !value ||
                  Number(validateNumber) >= 0 &&
                  Number(validateNumber) <= 20000000 &&
                  isNumberInteger(validateNumber)
                ) {
                  return Promise.resolve();
                }

                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Phí khám không hợp lệ!');
              },
            }),
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <InputNumber
                formatter={(value: any) =>
                  `${value.toString()}`
                    .replace(/[\D\s\._\-]+/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                style={{ width: '100%' }}
                onKeyDown={e => {
                  const keys = /[0-9]/g;
                  if (
                    !String(e.key).match(keys) &&
                    !keyCodeArr.includes(e.keyCode) &&
                    !(e.keyCode === 65 && e.ctrlKey === true)
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e: any) => {
                  setInitialFee(e);
                }}
                maxLength={11}
                value={initialFee}
                type="text"
                keyboard={false}
                name="fee"
              />
            </Col>
            <Col xs={4}>
              <span className="title-money">VND</span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label={<p>Phần trăm được hưởng</p>}
          name="bonus"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  (Number(value) >= 0 && Number(value) <= 200) ||
                  getFieldValue('bonus') === ''
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(
                  'Phần trăm được hưởng không hợp lệ! Min: 0%, Max: 200%',
                );
              },
            }),
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <InputNumber type="number" className="input-type" maxLength={4} />
            </Col>
            <span className="title-money">%</span>
          </Row>
        </Form.Item>

        <Form.Item
          className="form-item specialist add-doctor-details"
          label="Chuyên khoa"
          name="specialist"
        >
          <Checkbox.Group>
            <Row>
              {specialistReducer?.map(item => (
                <Col span={24} key={item.uuid}>
                  <Checkbox className="specialist-checkbox" value={item.uuid}>
                    {item?.display}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
          {/* </div> */}
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={() => history.goBack()}>
            Quay lại
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddDoctor;
// function e(e: any) {
//   throw new Error('Function not implemented.');
// }
