import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
import * as _ from 'lodash';
import { actions as appointmentActions } from 'store/appointment';
import { useDispatch, useSelector } from 'react-redux';
import './CheckBefore.scss';
import StoreState from 'models/store';
import { formRules } from 'helpers/formUtils';

const CheckBefore: FC<any> = props => {
  const [isEdit, setIsEdit] = useState<any>();
  const [i, setI] = useState<any>();
  const [isAdd, setIsAdd] = useState<any>(false);
  const originTime = new Date();
  const dispatch = useDispatch();
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );
  const [isSubmit, setIsSubmit] = useState(false);

  const { initValues, dataTranfer, isEditAvailability } = props;

  const arrPhysicalExamination = dataTranfer?.detail?.encounters[0]?.obs?.filter(
    item => item?.display?.includes('PHYSICAL EXAMINATION'),
  );
  const handleEdit = _.debounce(key => {
    setIsEdit(true);
    setI(key);
  }, 50);

  const handleCancel = _.debounce(key => {
    setIsEdit(false);
    setI(key);
  }, 50);

  const onEdit = values => {
    const { value } = values;

    const uuidPhysicalExamination = arrPhysicalExamination[i].uuid;
    const paramsEditPhysicalExamination = {
      value,
    };

    dispatch(
      appointmentActions.handleEditPhysicalExamination({
        uuidPhysicalExamination,
        paramsEditPhysicalExamination,
      }),
    );
    setIsEdit(false);
  };

  const onFinish = values => {
    setIsSubmit(true);

    const { value } = values;
    const paramsEditPhysicalExamination = {
      value,
      concept: 'd7681547-408a-4ced-b21f-45f0ce1307a7',
      obsDatetime: originTime?.toISOString(),
      person: dataTranfer?.detail?.uuidPerson,
      encounter: dataTranfer?.detail?.encounters[0]?.uuid,
    };

    dispatch(
      appointmentActions.handleCreatePhysicalExamination(
        paramsEditPhysicalExamination,
      ),
    );
  };

  useEffect(() => {
    if (
      isSubmit === true &&
      appointmentReducer?.isAddPhysicalExamination === true
    ) {
      setIsAdd(false);
    }
  }, [appointmentReducer?.isAddPhysicalExamination]);

  return (
    <div className="check-before-container">
      <div className="__title">
        <span>Khám lâm sàng</span>
      </div>
      {initValues?.physicalExamination?.length > 0 ? (
        initValues?.physicalExamination.map((item, key) => {
          return (
            <Row className="row-11" key={key}>
              <Col xs={24}>
                <div className="check-before-section">
                  <Form onFinish={onEdit}>
                    <div className="check-before-section__title">
                      <div>
                        <span> Khám lâm sàng {key + 1} </span>
                      </div>
                      <div className="btn-edit">
                        {isEditAvailability &&
                          (isEdit && key === i ? (
                            <>
                              <Button
                                size="middle"
                                onClick={() => handleCancel(key)}
                              >
                                Hủy
                              </Button>{' '}
                              <Button size="middle" htmlType="submit">
                                Lưu
                              </Button>
                            </>
                          ) : (
                            <Button
                              onClick={() => handleEdit(key)}
                              size="middle"
                            >
                              Sửa
                            </Button>
                          ))}
                      </div>
                    </div>
                    <div className="check-before-section__content">
                      <div className="check-before-section__content_inside">
                        {isEdit && key === i ? (
                          <Form.Item
                            name="value"
                            preserve={false}
                            rules={formRules}
                            initialValue={item}
                          >
                            <Input />
                          </Form.Item>
                        ) : (
                          <p className="text-have-color-#555555">{item}</p>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          );
        })
      ) : (
        <Row className="row-11">
          <Col xs={24}>
            <div className="not-found-content">
              {/* <div className="not-found-content__title">
                <div>
                  <span>Khám lâm sàng</span>
                </div>
              </div> */}
              <div className="no-content">
                <span className="no-content__text">Chưa có thông tin</span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {isEditAvailability && (
        <div className="check-before-add-container">
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="check-before-input-add">
                <Form.Item name="value" rules={formRules}>
                  <Input
                    size="large"
                    placeholder="Thêm khám lâm sàng"
                    autoFocus
                  />
                </Form.Item>
              </div>
              <div className="check-before-action-add">
                <div className="btn-ok">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={
                      appointmentReducer?.isLoadingAddPhysicalExamination
                    }
                  >
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(!isAdd)}
                  >
                    <span>Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => setIsAdd(!isAdd)}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CheckBefore;
