import React, { FC, useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import { useParams } from 'react-router-dom';
import Calender from 'assets/images/common/calender.svg';
import Clock from 'assets/images/common/clock.svg';
import Avatar from 'assets/images/common/avatar.svg';
import { isRoleDoctor, formatTime24h, formatPhoneNumber } from 'helpers/utils';

import config from '../../../../configs';
import { formatDateVNDay } from '../../../../helpers/utils';

import './InforUser.scss';

interface initValues {
  initValues: any;
}

const InforUser: FC<initValues> = props => {
  const { initValues } = props;

  const [urlAvatar, setUrlAvatar] = useState<any>();
  const urlCall = config.BASE_CALL_URL;
  const id: any = useParams();

  const uuidAvatar = useMemo(() => {
    if (initValues) {
      const arrAvatar =
        initValues?.arrPetient?.user?.attributes &&
        initValues?.arrPetient?.user?.attributes?.find(item =>
          item?.display?.includes('Avatar'),
        );

      const uuid = arrAvatar && arrAvatar?.uuid;
      return uuid;
    }
  }, [initValues, id?.id]);

  useEffect(() => {
    if (uuidAvatar) {
      setUrlAvatar(
        `${urlCall}/openmrs/ws/rest/v1/userAttribute/${uuidAvatar}/value`,
      );
    }
  }, [uuidAvatar, id]);

  const renderDate = date => {
    const diff = moment(date)
      .startOf('date')
      .diff(moment().startOf('date'), 'day');
    switch (diff) {
      case 1:
        return `Ngày Mai - ${formatDateVNDay(initValues?.detail?.startDate)}`;
      case 0:
        return `Hôm nay - ${formatDateVNDay(initValues?.detail?.startDate)}`;

      default:
        return formatDateVNDay(initValues?.detail?.startDate);
    }
  };

  return (
    <div className="infoUser-Contaier">
      <div className="title-container">
        <Row className="row-1 m-0 p-0">
          <Col xs={18}>
            <div className="flex">
              <div className="pt-1 pl-6">
                <div className="wrap-calender-icon">
                  <img src={Calender} alt="" />
                </div>
              </div>
              <div className="p-2">
                <span>{renderDate(initValues?.detail?.startDate)}</span>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="flex justify-end pr-4">
              <div className="pt-1 pr-2">
                <div className="wrap-clock-icon">
                  <img src={Clock} alt="" />
                </div>
              </div>
              <div className="pt-2 pr-2">
                {formatTime24h(initValues?.detail?.startDate)}
              </div>
            </div>
          </Col>
        </Row>
        <hr />
        <div className="info-basic">
          <div className="avatar">
            {uuidAvatar && urlAvatar ? (
              <img src={urlAvatar} alt="ảnh đại diện" className="avatar-user" />
            ) : (
              <img src={Avatar} className="image-default" alt="" />
            )}
          </div>
          <div className="info-detail">
            <div className="info-name pt-2">
              {initValues?.dataCloned?.patient?.name}
            </div>
            <div className="info-body pt-2">
              <ul className="flex ">
                {initValues?.dataCloned?.patient?.gender && (
                  <li className="mr-1">
                    {initValues?.dataCloned?.patient?.gender}{' '}
                  </li>
                )}

                {initValues?.dataCloned?.patient?.age && (
                  <li className="mr-1">
                    • {initValues?.dataCloned?.patient?.age} tuổi{' '}
                  </li>
                )}

                {initValues?.patient?.height && (
                  <li className="mr-1">• {initValues?.patient?.height} cm</li>
                )}

                {initValues?.patient?.height && (
                  <li>• {initValues?.patient?.weight?.value} kg</li>
                )}
              </ul>
            </div>
            <div className="info-contact pt-2">
              <ul className="flex">
                {initValues?.patient?.carrer && (
                  <li className="mr-1">{initValues?.patient?.carrer} •</li>
                )}
                <li className="mr-1">
                  {initValues?.arrPetient?.person?.addresses[0]?.address1 &&
                    `${initValues?.patient?.city},`}
                  {initValues?.arrPetient?.person?.addresses[0]?.cityVillage &&
                    `${initValues?.arrPetient?.person?.addresses[0]?.cityVillage} •`}
                </li>
                {isRoleDoctor() === false && initValues?.phonePatient && (
                  <li>{formatPhoneNumber(initValues?.phonePatient)}</li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InforUser;
