import { get, upperFirst, replace, indexOf } from 'lodash';
import 'antd/dist/antd.css';
import { message } from 'antd';
import Message from 'constants/Message';
import numeral from 'numeral';
import moment from 'moment';
import { ROLES_SYSTEM } from 'store/authenticate/authenticate.saga';

import Error from '../constants/Error';
import {
  regexRemoveSquareBracketStart,
  regexRemoveSquareBracketEnd,
} from '../containers/Doctor/doctor.const';

const TOAST_POPUP_TIME = 0.5;
export function getMessageFromError(error: any) {
  if (typeof error === 'string') {
    return error;
  }

  if (error.response) {
    if (error?.response?.data?.error?.fieldErrors) {
      const fields = Object.getOwnPropertyNames(
        error.response.data.error.fieldErrors,
      );
      if (error?.response?.data?.error?.fieldErrors?.numberOfPromotionCodes) {
        return get(error, 'response.data.error.message', Error.COMMON);
      }
      if (fields.length > 0) {
        return get(
          error,
          `response.data.error.fieldErrors.[${fields[0]}][0].message`,
          error?.response.data.error.fieldErrors[fields[0]][0].message,
        );
      }
    } else if (error?.response?.data?.message) {
      return get(error, 'response.data.message', Error.COMMON);
    }

    const err = get(error, 'response.data.error.message', Error.COMMON);
    const newErr1 = replace(err, regexRemoveSquareBracketStart, '');
    const newErr2 = replace(newErr1, regexRemoveSquareBracketEnd, '');
    return newErr2;
  }
  return error.message || Error.COMMON;
}

export function fireResetPasswordSuccessMessage() {
  return message.success({
    content: Message.RESET_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCancelAppointmentSuccessMessage() {
  return message.success({
    content: Message.CANCEL_APPOINTMENT_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUploadSuccessMessage() {
  return message.success({
    content: Message.UPLOAD_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireDeleteDoctorSuccessMessage() {
  return message.success({
    content: Message.DELETE_DOCTOR_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireDeleteServicePackageSuccessMessage() {
  return message.success({
    content: Message.DELETE_SERVICE_PACKAGE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireLockServicePackageSuccessMessage() {
  return message.success({
    content: Message.LOCKED_SERVICE_PACKAGE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireUnlockServicePackageSuccessMessage() {
  return message.success({
    content: Message.UNLOCKED_SERVICE_PACKAGE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireCheckoutSuccessMessage() {
  return message.success({
    content: Message.CHECKOUT_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireDeleteSuccessMessage() {
  return message.success({
    content: Message.DELETE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUpdateSuccessMessage() {
  return message.success({
    content: Message.UPDATE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCreateSuccessMessage() {
  return message.success({
    content: Message.CREATE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireUpdateSPSuccessMessage() {
  return message.success({
    content: Message.UPDATE_SERVICE_PACKAGE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCreateSPSuccessMessage() {
  return message.success({
    content: Message.CREATE_SERVICE_PACKAGE_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCreateHolidaySuccessMessage() {
  return message.success({
    content: Message.CREATE_HOLIDAY_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUpdateHolidaySuccessMessage() {
  return message.success({
    content: Message.UPDATE_HOLIDAY_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireDeleteHolidaySuccessMessage() {
  return message.success({
    content: Message.DELETE_HOLIDAY_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCreatePromotionSuccessMessage() {
  return message.success({
    content: Message.CREATE_PROMOTION_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireCreatePromotionMarketingSuccessMessage() {
  return message.success({
    content: Message.PROMOTION_MARKETING_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireConfigTimeAppointmentSuccessMessage() {
  return message.success({
    content: Message.CONFIG_TIME_APPOINTMENT_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireConfigTimeAppointmentFailMessage() {
  return message.error({
    content: Message.CONFIG_TIME_APPOINTMENT_FAIL,
    duration: TOAST_POPUP_TIME,
  });
}
export function fireUpdatePromotionSuccessMessage() {
  return message.success({
    content: Message.UPDATE_PROMOTION_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireLockPromotionSuccessMessage() {
  return message.success({
    content: Message.LOCKED_PROMOTION_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUnLockPromotionSuccessMessage() {
  return message.success({
    content: Message.UNLOCKED_PROMOTION_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

// referral

export function fireCreateReferralSuccessMessage() {
  return message.success({
    content: Message.CREATE_REFERRAL_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUpdateReferralSuccessMessage() {
  return message.success({
    content: Message.UPDATE_REFERRAL_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireLockReferralSuccessMessage() {
  return message.success({
    content: Message.LOCKED_REFERRAL_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireUnLockReferralSuccessMessage() {
  return message.success({
    content: Message.UNLOCKED_REFERRAL_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireDeleteCustomerSuccessMessage() {
  return message.success({
    content: Message.DELETE_CUSTOMER_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}

export function fireDeleteSpecialistSuccessMessage() {
  return message.success({
    content: Message.DELETE_SPECIALIST_SUCCESS,
    duration: TOAST_POPUP_TIME,
  });
}
export function transformCommonData(text: string) {
  return upperFirst(replace(text, new RegExp('_', 'g'), ' '));
}

export function formatMoney(money: string | number) {
  return numeral(money).format('0,0');
}

export function formatMoneyVND(money: string | number) {
  if (money > 0) {
    return `${numeral(money).format('0,0')} VND`;
  }

  if (money === 0) {
    return `0 VND`;
  }
  return null;
}

export function formatPhoneNumber(phone: string) {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{4})(\d{3})(\d{3})$/);
  if (match) {
    return `${match[1]} ${match[2]} ${match[3]}`;
  }
  return '';
}

export function formatDateVN(date: string) {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  }
  return null;
}

export function formatDateVNTime(date: string) {
  if (date) {
    return moment(date).format('HH:mm DD/MM/YYYY ');
  }
  return null;
}

export function formatDateDayVN(date: string) {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return null;
}

export function formatDateLL(date: string) {
  if (date) {
    return `${capitalizeFirstLetter(
      moment(date).format('dddd'),
    )}, ngày ${moment(date).format('LL')}`;
  }
  return null;
}

export function formatDateVNDay(date: string) {
  if (date) {
    return `${capitalizeFirstLetter(moment(date).format('dddd'))},${moment(
      date,
    ).format('DD/MM/YYYY')}`;
  }
  return null;
}

export function formatTime24h(date: string) {
  if (date) {
    return moment(date).format('HH:mm');
  }
  return null;
}

const keyCookie = 'auth.cookies';
const keyAuthInfo = 'auth.info';
export function setCookie(value) {
  localStorage.setItem(keyCookie, value);
}

export function getCookie() {
  return localStorage.getItem(keyCookie) || '';
}

export function removeCookie() {
  return localStorage.removeItem(keyCookie);
}

export function setAuthInfo(value) {
  localStorage.setItem(keyAuthInfo, JSON.stringify(value));
}

export function getAuthInfo() {
  const authInfo = localStorage.getItem(keyAuthInfo);
  if (authInfo) {
    return JSON.parse(authInfo);
  }
  return false;
}

export function removeAuthInfo() {
  return localStorage.removeItem(keyAuthInfo);
}

export function getUuidDoctor() {
  const authInfo = getAuthInfo();
  if (isRoleDoctor()) {
    return get(authInfo, 'provider.uuid', null);
  }
  return null;
}

export function pathRouteWithRole(): string {
  if (isRoleDoctor()) {
    return '/doctor';
  }
  return '/app';
}

export function isRoleDoctor(): boolean {
  const authInfo = getAuthInfo();
  const roles = get(authInfo, 'userInfo.roles', []);
  return (
    indexOf(roles, ROLES_SYSTEM.SYSTEM_DEVELOPER) === -1 &&
    indexOf(roles, ROLES_SYSTEM.PROVIDER) > -1
  );
}

export function isAuthorization() {
  const cookie = getCookie();
  // eslint-disable-next-line no-extra-boolean-cast
  if (Boolean(cookie)) {
    return true;
  }
  return false;
}

export function cleanKeyNull(objClean) {
  for (const propName in objClean) {
    if (objClean[propName] === null || objClean[propName] === undefined) {
      // eslint-disable-next-line no-param-reassign
      delete objClean[propName];
    }
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const renderDateTitle = (date: number) => {
  switch (date) {
    case 2:
      return 'Thứ Hai';
    case 3:
      return 'Thứ Ba';
    case 4:
      return 'Thứ Tư';
    case 5:
      return 'Thứ Năm';
    case 6:
      return 'Thứ Sáu';
    case 7:
      return 'Thứ Bảy';
    case 8:
      return 'Chủ Nhật';
    default:
      break;
  }
};

export const formatDDDD = (date: string) => {
  return `${renderDateTitle(moment(date).isoWeekday() + 1)}, ${moment(
    date,
  ).date()} tháng ${moment(date).month() + 1}, ${moment(date).year()}`;
};
