import { createAsyncAction, createAction } from 'typesafe-actions';

import { UserInfoModel } from 'models/store/authenticate.model';

export interface LoginPayload {
  userName: string;
  password: string;
}

export interface LoginSuccessPayload {
  uuid?: string;
  sessionId: string;
  userInfo: UserInfoModel;
  provider: {
    uuid: string;
  };
}

export const login = createAsyncAction(
  'LOGIN',
  'LOGIN_SUCCEEDED',
  'LOGIN_FAILED',
)<LoginPayload, LoginSuccessPayload, string>();

export const logout = createAction('LOGOUT')();

export const logoutSuccess = createAction('LOGOUT_SUCCESS')();

export const doLogout = createAction('DO_LOGOUT')();
