import { useEffect } from 'react';
import { isAuthorization, isRoleDoctor } from 'helpers/utils';
import { useHistory } from 'react-router';

const SwitchLayout = () => {
  const history = useHistory();
  useEffect(() => {
    if (isAuthorization()) {
      if (isRoleDoctor()) {
        return history.push('/doctor');
      }
      history.push('/app/');
    } else {
      history.push('/auth/login');
    }
  }, []);
  return null;
};

export default SwitchLayout;
