/* eslint-disable no-useless-escape */
export const formatDate = /\s\([\d]{2}:[\d]{2}\s-\s[\d]{2}:[\d]{2}\)/g;
export const filterDay = /^[\d]{2}/g;
export const filterYear = /[\d]{4}/g;
export const filterSlash = /\//g;
export const getRangeTime = /[\d]{2}:[\d]{2}\s\-\s[\d]{2}:[\d]{2}/g;
export const getStartTime = /^[\d]{2}:[\d]{2}/g;
export const getHours = /^[\d]{2}/g;
export const removeMonth = /^(\-[\d]{2}\-)/g;

export const getDayA = /(\-[\d]{2}$)/g;
export const removeHyphen = /\-/g;
export const removeYearHyphen = /(^[\d]{4}\-)/g;
