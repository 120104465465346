import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import {
  fireCreateReferralSuccessMessage,
  fireUpdateReferralSuccessMessage,
  fireLockReferralSuccessMessage,
  fireUnLockReferralSuccessMessage,
  getMessageFromError,
  cleanKeyNull,
} from 'helpers/utils';
import moment from 'moment';

import * as serviceAction from './service.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

let paramsService;

function* getServiceRequested(action) {
  try {
    cleanKeyNull(action.payload);
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    if (params?.startDate) {
      params = {
        ...params,
        startDate: moment(params?.startDate).format('YYYY-MM-DD'),
      };
    }

    if (params?.endDate) {
      params = {
        ...params,
        endDate: moment(params?.endDate).format('YYYY-MM-DD'),
      };
    }

    if (`${params?.ReferralName}`.trim().length === 0) {
      delete params.ReferralName;
    }
    paramsService = params;

    const res = yield call(() =>
      authorizedRequest.get('/v1/referralCampaign?v=full', { params }),
    );
    yield put(serviceAction.getServiceSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(serviceAction.getServiceFailed(error));
  }
}

function* handleCreateServiceRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post('/v1/referralCampaign', action.payload),
    );
    if (res.error) {
      message.error(
        getMessageFromError(res.error?.fieldErrors?.referralCode[0]),
      );
      yield put(serviceAction.handleCreateServiceFailed(res.error));
      return;
    }

    const params = {
      ...paramsService,
      startIndex: paramsService?.startIndex
        ? paramsService?.startIndex / 10 + 1
        : 0,
    };
    fireCreateReferralSuccessMessage();
    yield put(serviceAction.handleCreateServiceSucceeded(res));
    yield put(serviceAction.getServiceRequested(params));
    // }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(serviceAction.handleCreateServiceFailed(error));
  }
}

function* handleUpdateServiceRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(`/v1/referralCampaign`, action.payload),
    );
    fireUpdateReferralSuccessMessage();
    const params = {
      ...paramsService,
      startIndex: paramsService?.startIndex
        ? paramsService?.startIndex / 10 + 1
        : 0,
    };
    yield put(serviceAction.handleUpdateServiceSucceeded(res));
    yield put(serviceAction.getServiceRequested(params));
  } catch (error) {
    // message.error(getMessageFromError(error));
    yield put(serviceAction.handleUpdateServiceFailed(error));
  }
}

function* handleLockedServiceRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.post(
        `/v1/referralCampaign/${action.payload.uuid}`,
        action.payload.params,
      ),
    );
    yield put(serviceAction.handleLockedServiceSucceeded(res));
    const params = {
      ...paramsService,
      startIndex: paramsService?.startIndex
        ? paramsService?.startIndex / 10 + 1
        : 0,
    };

    yield put(serviceAction.getServiceRequested(params));
    if (action.payload.params && action.payload.params.status === 'LOCKED') {
      fireLockReferralSuccessMessage();
    } else {
      fireUnLockReferralSuccessMessage();
    }
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(serviceAction.handleLockedServiceFailed(error));
  }
}

function* getDetailServiceRequested(action) {
  try {
    let params = {
      v: action.payload.v,
      startIndex: action.payload.startIndex,
      totalCount: action.payload.totalCount,
      limit: action.payload.limit,
      referralCode: action.payload.referralCode,
    };
    if (params.startIndex) {
      if (params.startIndex) {
        params = {
          ...params,
          startIndex: (params.startIndex - 1) * params.limit,
        };
      }
    }
    const res = yield call(() =>
      authorizedRequest.get('/v1/referralHistory', { params }),
    );
    yield put(serviceAction.getDetailServiceSucceeded(res));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(serviceAction.getDetailServiceFailed(error));
  }
}

function* handleCreateLogoServiceRequested(action) {
  try {
    console.log(action);
  } catch (error) {
    console.log(error);
  }
}
export default function* serviceSaga() {
  yield all([
    takeLatest(serviceAction.getServiceRequested, getServiceRequested),
    takeLatest(
      serviceAction.handleCreateServiceRequested,
      handleCreateServiceRequested,
    ),
    takeLatest(
      serviceAction.handleUpdateServiceRequested,
      handleUpdateServiceRequested,
    ),
    takeLatest(
      serviceAction.handleLockedServiceRequested,
      handleLockedServiceRequested,
    ),
    takeLatest(
      serviceAction.getDetailServiceRequested,
      getDetailServiceRequested,
    ),
    takeLatest(
      serviceAction.handleCreateLogoServiceRequested,
      handleCreateLogoServiceRequested,
    ),
  ]);
}
