import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { ReferralModel } from 'models/store/referral.model';

export const {
  getReferralRequested,
  getReferralSucceeded,
  getReferralFailed,
  handleCreateReferralRequested,
  handleCreateReferralSucceeded,
  handleCreateReferralFailed,
  handleUpdateReferralRequested,
  handleUpdateReferralSucceeded,
  handleUpdateReferralFailed,
  handleDisplayModalEdit,
  handleDisplayModalAdd,
  handleLockedReferralRequested,
  handleLockedReferralSucceeded,
  handleLockedReferralFailed,
  getDetailReferralRequested,
  getDetailReferralSucceeded,
  getDetailReferralFailed,
  handleCreateLogoReferralRequested,
  handleCreateLogoReferralSucceeded,
  handleCreateLogoReferralFailed,
} = createActions(
  {
    GET_REFERRAL_REQUESTED: (payload: QueryString) => payload,
    GET_REFERRAL_SUCCEEDED: (payload: ReferralModel) => payload,
    GET_REFERRAL_FAILED: (error: any) => error,
    HANDLE_CREATE_REFERRAL_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_REFERRAL_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_REFERRAL_FAILED: (error: any) => error,
    HANDLE_UPDATE_REFERRAL_REQUESTED: (payload: QueryString) => payload,
    HANDLE_UPDATE_REFERRAL_SUCCEEDED: (payload: ReferralModel) => payload,
    HANDLE_UPDATE_REFERRAL_FAILED: (error: any) => error,
    HANDLE_DISPLAY_MODAL_EDIT: (error: any) => error,
    HANDLE_DISPLAY_MODAL_ADD: (error: any) => error,
    HANDLE_LOCKED_REFERRAL_REQUESTED: (payload: any) => payload,
    HANDLE_LOCKED_REFERRAL_SUCCEEDED: (payload: any) => payload,
    HANDLE_LOCKED_REFERRAL_FAILED: (error: any) => error,
    GET_DETAIL_REFERRAL_REQUESTED: (payload: QueryString) => payload,
    GET_DETAIL_REFERRAL_SUCCEEDED: (payload: ReferralModel) => payload,
    GET_DETAIL_REFERRAL_FAILED: (error: any) => error,
    HANDLE_CREATE_LOGO_REFERRAL_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_LOGO_REFERRAL_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_LOGO_REFERRAL_FAILED: (error: any) => error,
  },
  {
    prefix: 'referral',
  },
);
