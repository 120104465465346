// import { LogoutOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { pathRouteWithRole } from 'helpers/utils';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import './ModalAddDetail.scss';

interface DProps {
  show: boolean;
  onHide: () => void;
  // isBlocking: any;
  // isSaving: boolean | any;
  // onSave: any;
}

// const useCallbackPrompt = when => {
//   const history = useHistory();
//   const [showPrompt, setShowPrompt] = useState(false);
//   const [lastLocation, setLastLocation] = useState(null);
//   const [confirmedNavigation, setComfirmedNavigation] = useState(false);

//   const cancelNavigation = () => {
//     setShowPrompt(false);
//   };

//   const handleBlockedNavigation = useCallback(
//     nextLocation => {
//       if (!confirmedNavigation) {
//         setShowPrompt(true);
//         setLastLocation(nextLocation);
//         return false;
//       }
//       return true;
//     },
//     [confirmedNavigation],
//   );

//   const confirmNavigation = useCallback(() => {
//     setShowPrompt(false);
//     setComfirmedNavigation(true);
//   }, []);

//   useEffect(() => {
//     if (confirmNavigation && lastLocation) {
//       history.push(lastLocation!.pathname);
//     }
//   },[confirmedNavigation, lastLocation]);

//   usePrompt(handleBlockedNavigation, when);

//   return [showPrompt, confirmedNavigation, cancelNavigation];
// };

// const usePrompt = (message, when) => {
//   const history = useHistory();
//   const unblock = useRef(null as any);

//   useEffect(() => {
//     if (when) {
//       unblock.current = history.block(message);
//     } else {
//       unblock.current = null;
//     }
//     return () => {
//       if (unblock.current) {
//         unblock.current();
//       }
//     };
//   }, [when, history, message]);
// };

const ModalAddDetail: FC<DProps> = ({
  show,
  onHide,
  // isBlocking,
  // isSaving,
  // onSave,
}) => {
  // const [confirmedNavigation, cancelNavigation, confirmNavigation] = useCallbackPrompt(
  //   isBlocking,
  // );
  const [visible] = useState(show);
  return (
    <Modal
      title={'Bạn có muốn lưu thông tin đã thay đổi không? '}
      centered
      visible={visible}
      onOk={onHide}
      onCancel={onHide}
      width={470}
      footer={[
        // <Button
        //   key="cancel"
        //   disabled={isSaving}
        //   type="default"
        //   onClick={cancelNavigation}
        // >
        //   Cancel
        // </Button>,
        // <Button
        //   key="discard"
        //   disabled={!isBlocking || isSaving}
        //   type="danger"
        //   onClick={confirmNavigation}
        // >
        //   Discard changes & Exit
        // </Button>,
        // <Button
        //   key="save"
        //   disabled={!isBlocking || isSaving}
        //   type="primary"
        //   icon={isSaving ? <LogoutOutlined spin /> : null}
        //   onClick={() => {
        //     onSave().then(() => confirmNavigation());
        //   }}
        // >
        //   {isSaving ? "Saving changes" : "Save changes & Exit"}
        // </Button>
        null,
      ]}
    >
      <Form className="modal-add-detail">
        <div className="button-container">
          <Link to={`${pathRouteWithRole()}/appointments`}>
            <Button type="default" onClick={onHide}>
              Thoát
            </Button>
          </Link>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddDetail;
