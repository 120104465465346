import { Button, Card, Col, Form, InputNumber, Row, Space } from 'antd';
import { fireConfigTimeAppointmentFailMessage } from 'helpers/utils';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleCustomSettingRequested,
  handleGetTimeCustomsRequested,
} from 'store/customSetting/customSetting.action';
import './CustomSetting.scss';

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const CustomSetting: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => state.marketingNoti.loading);
  const uuid = useSelector((state: any) => state.authenticate.userInfo);

  const timeDefault = useSelector(
    (state: any) => state.customSetting.getTimeAlready,
  );
  useEffect(() => {
    dispatch(handleGetTimeCustomsRequested());
  }, [timeDefault]);
  const onFinish = (values: any) => {
    if (values.propertyValue === timeDefault) {
      fireConfigTimeAppointmentFailMessage();
      return;
    }
    const params = {
      uuid: uuid?.id,
      propertyValue: JSON.stringify(values.propertyValue),
    };
    dispatch(handleCustomSettingRequested(params));
  };

  const onReset = () => {
    form.resetFields();
  };
  const textLabel = 'Thời gian được phép đặt' + '\n' + 'trước cuộc hẹn (Phút)';
  return (
    <div className="version-management">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Cấu hình thời gian cuộc hẹn</h1>
          </Col>
        </Row>

        <div style={{ marginTop: 50 }}>
          <Form
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            layout={'horizontal'}
            labelCol={{ span: 16 }}
            wrapperCol={{ span: 8 }}
            form={form}
            requiredMark={false}
            fields={[
              {
                name: ['propertyValue'],
                value: timeDefault,
              },
            ]}
            style={{
              minWidth: '500px',
              maxWidth: '800px',
            }}
          >
            <Form.Item
              label={textLabel}
              name="propertyValue"
              rules={[
                {
                  required: true,
                  message: 'Thời gian không được để trống',
                },
                // {
                //   type: 'number',
                //   message: 'Thời gian không được để trốngss',
                // },
              ]}
            >
              <InputNumber
                formatter={value =>
                  `${value}`.replace(/[.,-]/g, '').replace(/[a-zA-Z]+/g, '')
                }
                // type="number"
                className="input-number-custom"
                // parser={value => value.replace(/\$\s?|(,*)/g, '')}
                // onChange={onChange}
              />
            </Form.Item>
            <Form.Item {...tailLayout} style={{ marginLeft: 150 }}>
              <Space>
                <Button htmlType="button" onClick={onReset}>
                  Hủy
                </Button>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Lưu
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default CustomSetting;
