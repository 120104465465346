import { all, call, put, takeLatest } from 'redux-saga/effects';
import { message } from 'antd';
import * as api from 'api/customer';
import {
  fireDeleteCustomerSuccessMessage,
  getMessageFromError,
} from 'helpers/utils';
import * as customerActions from './customer.action';
import authorizedRequest from '../../helpers/request/authorizedRequest';

function* getTransactionCustomerRequested(action) {
  try {
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/patient', { params }),
    );
    yield put(customerActions.getTransactionCustomerSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.getTransactionCustomerFailed(error));
  }
}

function* getTransactionCustomerDetailRequested(action) {
  try {
    let params = { ...action.payload.params };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get(`v1/patient/${action.payload.id}`, {
        params,
      }),
    );
    yield put(customerActions.getTransactionCustomerDetailSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.getTransactionCustomerDetailFailed(error));
  }
}

function* getTransactionCustomerSearchRequested(action) {
  try {
    let params = { ...action.payload };
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: 0,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/patient', { params }),
    );
    yield put(customerActions.getTransactionCustomerSearchSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.getTransactionCustomerSearchFailed(error));
  }
}

function* postAddTransactionCustomerRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.post('/v1/transaction', action.payload),
    );
    yield put(customerActions.postAddTransactionCustomerSucceeded(response));
    message.success('Thêm mới giao dịch thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.postAddTransactionCustomerFailed(error));
  }
}

function* postAddInformationCustomerRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.post('/v1/patient', action.payload),
    );
    // console.log(response);
    yield put(customerActions.postAddInformationCustomerSucceeded(response));
    message.success('Thêm mới khách hàng thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.postAddInformationCustomerFailed(error));
  }
}

function* postEditInformationCustomerRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `/v1/patient/${action.payload.uuid}`,
        action.payload.data,
      ),
    );
    yield put(customerActions.postEditInformationCustomerSucceeded(response));
    message.success('Chỉnh sửa khách hàng thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.postEditInformationCustomerFailed(error));
  }
}

function* postEditTransactionDetailRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `/v1/transaction/${action.payload.uuid}`,
        action.payload.data,
      ),
    );
    yield put(customerActions.postEditTransactionDetailSucceeded(response));
    message.success('Chỉnh sửa giao dịch thành công.');
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.postEditTransactionDetailFailed(error));
  }
}

function* getListTransactionRequested(action) {
  try {
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }
    const response = yield call(() =>
      authorizedRequest.get('/v1/transaction', { params }),
    );
    yield put(customerActions.getListTransactionSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.getListTransactionFailed(error));
  }
}

function* getPatientBookAppointmentRequested(action) {
  try {
    let params = { ...action.payload };
    if (`${params.q}`.trim().length === 0) {
      delete params.q;
    }
    if (params.startIndex) {
      params = {
        ...params,
        startIndex: (params.startIndex - 1) * params.limit,
      };
    }

    const response = yield call(() =>
      authorizedRequest.get('/v1/transaction', { params }),
    );

    yield put(customerActions.getPatientBookAppointmentSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.getPatientBookAppointmentFailed(error));
  }
}

function* getListAppointmentRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.get('/v1/appointmentscheduling/appointment', {
        params: action.payload,
      }),
    );
    yield put(customerActions.getListAppointmentSucceeded(response));
  } catch (error) {
    // message.error(getMessageFromError(error));
    yield put(customerActions.getListAppointmentFailed(error));
  }
}

function* postChangePasswordCustomerRequested(action) {
  try {
    const response = yield call(() =>
      authorizedRequest.post(
        `/v1/password/${action?.payload?.userUuid}`,
        action?.payload?.body,
      ),
    );
    message.success('Thay đổi mật khẩu thành công.');
    yield put(customerActions.postChangePasswordCustomerSucceeded(response));
  } catch (error) {
    message.error(getMessageFromError(error));
    yield put(customerActions.postChangePasswordCustomerFailed(error));
  }
}

function* handleDeleteCustomerRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.delete(`/v1/patient/${action.payload}`),
    );
    fireDeleteCustomerSuccessMessage();
    yield put(customerActions.handleDeleteCustomerSucceeded(res));
  } catch (error) {
    yield put(customerActions.handleDeleteCustomerFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleCallTest(action: any) {
  try {
    window.open(
      `https://meet.doctor4u.vn/${action.payload.identifier}`,
      '_blank',
      'rel="noreferrer"',
    );
    const params = {
      patientUuid: action.payload.patientUuid,
    };
    yield call(api.handleCallTestApi, params);
  } catch (error) {
    message.error(getMessageFromError(error));
  }
}

function* handleGetListProvinceRequested(action) {
  try {
    const res = yield call(() => authorizedRequest.get(`/v1/provinces`));
    yield put(customerActions.getListProvinceSucceeded(res));
  } catch (error) {
    yield put(customerActions.getListProvinceFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleGetListDistrictRequested(action: any) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/provinces/${action.payload.idProvince}`),
    );
    // fireDeleteCustomerSuccessMessage();
    yield put(customerActions.getListDistrictsSucceeded(res));
  } catch (error) {
    yield put(customerActions.getListDistrictsFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleGetListJobRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/concept?v=full&class=job`),
    );
    yield put(customerActions.getListJobSucceeded(res.results));
  } catch (error) {
    yield put(customerActions.getListJobFailed(error));
    message.error(getMessageFromError(error));
  }
}

function* handleGetListInsuranceRequested(action) {
  try {
    const res = yield call(() =>
      authorizedRequest.get(`/v1/concept?v=full&class=Insurance Company`),
    );
    yield put(customerActions.getListInsuranceSucceeded(res.results));
  } catch (error) {
    yield put(customerActions.getListInsuranceFailed(error));
    message.error(getMessageFromError(error));
  }
}
export default function* customerSaga() {
  yield all([
    takeLatest(
      customerActions.getTransactionCustomerRequested,
      getTransactionCustomerRequested,
    ),
    takeLatest(
      customerActions.getTransactionCustomerDetailRequested,
      getTransactionCustomerDetailRequested,
    ),
    takeLatest(
      customerActions.getTransactionCustomerSearchRequested,
      getTransactionCustomerSearchRequested,
    ),
    takeLatest(
      customerActions.postAddTransactionCustomerRequested,
      postAddTransactionCustomerRequested,
    ),
    takeLatest(
      customerActions.getListTransactionRequested,
      getListTransactionRequested,
    ),
    takeLatest(
      customerActions.getListAppointmentRequested,
      getListAppointmentRequested,
    ),
    takeLatest(
      customerActions.getPatientBookAppointmentRequested,
      getPatientBookAppointmentRequested,
    ),
    takeLatest(
      customerActions.postChangePasswordCustomerRequested,
      postChangePasswordCustomerRequested,
    ),
    takeLatest(
      customerActions.postAddInformationCustomerRequested,
      postAddInformationCustomerRequested,
    ),
    takeLatest(
      customerActions.postEditInformationCustomerRequested,
      postEditInformationCustomerRequested,
    ),
    takeLatest(
      customerActions.postEditTransactionDetailRequested,
      postEditTransactionDetailRequested,
    ),
    takeLatest(
      customerActions.handleDeleteCustomerRequested,
      handleDeleteCustomerRequested,
    ),
    takeLatest(customerActions.handleCallTest, handleCallTest),
    takeLatest(
      customerActions.getListProvinceRequested,
      handleGetListProvinceRequested,
    ),
    takeLatest(
      customerActions.getListDistrictsRequested,
      handleGetListDistrictRequested,
    ),
    takeLatest(customerActions.getListJobRequested, handleGetListJobRequested),
    takeLatest(
      customerActions.getListInsuranceRequested,
      handleGetListInsuranceRequested,
    ),
  ]);
}
