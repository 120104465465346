import React, { useState, useEffect, FC } from 'react';
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  InputNumber,
  Upload,
  Checkbox,
} from 'antd';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import {
  MinusCircleOutlined,
  PlusOutlined,
  CameraFilled,
} from '@ant-design/icons';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import StoreState from 'models/store';
import jq from 'jquery';
import {
  regex,
  regexTrainning,
  regexFee,
  regexFilterFee,
  regexCheckEmail,
  regexCheckPhone,
  regexGetEmailLatest,
  regexGetFeeLatest,
  regexGetPhoneNumber,
  ATTRIBUTE_TYPE_PROCESS_PLACE,
  ATTRIBUTE_TYPE_PROCESS_EDU,
  ATTRIBUTE_TYPE_FEE,
  ATTRIBUTE_TYPE_MAJORS,
  regexPreventChar,
  regextTrimspace,
  uuidAvatar,
  uuidCover,
  regexBonus,
  ATTRIBUTE_TYPE_BONUS,
  ATTRIBUTE_TYPE_SPECIALIST,
} from '../doctor.const';
import config from '../../../configs';
import * as doctorInfoActions from '../../../store/doctor/doctor.action';

import './EditDoctor.scss';

interface ParamsInformationDoctorModel {
  v?: string;
  class?: string;
}
interface IAttributes {
  attributeType: string;
  value: string;
  uuid: string;
}
const EditDoctor: FC = type => {
  const { Option } = Select;
  const { TextArea } = Input;
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const [ParamsInformationDoctorModel] = useState<ParamsInformationDoctorModel>(
    {
      v: 'full',
      class: 'Specialist',
    },
  );

  const [urlAvatar, setUrlAvatar] = useState<any>('');
  const [urlCover, setUrlCover] = useState<any>('');
  // const [dataCheckbox, setDataCheckbox] = useState<any>();
  const [fileListAvatar, setFileListAvatar] = useState<any>();
  // const [imgTest, setTestImg] = useState<any>('');
  const [fileListCover, setFileListCover] = useState<any>();
  const [urlCall] = useState<any>(config.BASE_IMAGE_URL);
  const [initialFee, setInitialFee] = useState<any>('');
  const [initialBonus, setInitialBonus] = useState<number>();

  const [initVal] = useState<any>({
    status: '',
    outstanding: false,
    username: '',
    phone: '',
    email: '',
    attributes: [''],
    name: '',
    unit: '',
    description: '',
    yearExperience: '',
    trainningProcess: '',
    workPlace: '',
    majors: '',
    fee: '',
    bonus: '',
  });
  const specialistReducer = useSelector(
    (state: StoreState) => state.doctorReducer.dataSpecialist.results,
  );

  const healthFacilityData = useSelector(
    (state: StoreState) => state.doctorReducer.dataHealthFacility.results,
  );

  const dataDetail = useSelector(
    (state: any) => state.doctorReducer.dataDetailDoctor,
  );

  const dataUploadAvatar = useSelector(
    (state: any) => state.doctorReducer.dataUploadAvatar,
  );
  const dataUploadCover = useSelector(
    (state: any) => state.doctorReducer.dataUploadCover,
  );

  useEffect(() => {
    setFileListAvatar([
      {
        uid: '1',
        name: 'avatar',
        status: 'done',
        url: urlAvatar,
      },
    ]);
  }, [urlAvatar, dataUploadAvatar]);
  useEffect(() => {
    setFileListCover([
      {
        uid: '2',
        name: 'cover',
        status: 'done',
        url: urlCover,
      },
    ]);
  }, [urlCover, dataUploadCover]);

  const getImage = () => {
    if (dataDetail !== undefined) {
      const arrAvatar = dataDetail?.user?.attributes.filter(item =>
        item?.display?.includes('Avatar'),
      );

      const arrCover = dataDetail?.user?.attributes.filter(item =>
        item?.display?.includes('Cover'),
      );

      if (arrCover !== undefined) {
        const idCover = arrCover[0]?.uuid;

        if (idCover !== undefined) {
          setUrlCover(`${urlCall}/ws/rest/v1/userAttribute/${idCover}/view`);
        }
      }

      if (arrAvatar !== undefined) {
        const idAvatar = arrAvatar[0]?.uuid;
        if (idAvatar !== undefined) {
          setUrlAvatar(`${urlCall}/ws/rest/v1/userAttribute/${idAvatar}/view`);
        }
      }
    }
  };

  useEffect(() => {
    getImage();
  }, [dataUploadAvatar, dataUploadCover, form, dataDetail]);

  const getDetailDoctor = () => {
    dispatch(
      doctorInfoActions.getDetailDoctorRequested(
        id,
        ParamsInformationDoctorModel,
      ),
    );
  };

  useEffect(() => {
    if (dataDetail !== undefined) {
      const trainningProcess = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Working process'),
      );

      const trainning = trainningProcess?.map(item => {
        return {
          value: item?.display?.replace(regex, ''),
          uuid: item?.uuid,
        };
      });

      const work = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Training process'),
      );
      const workFinal = work?.map(item =>
        item?.display?.replace(regexTrainning, ''),
      );

      const fee = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Examination fee'),
      );

      const feeFinal = fee?.map(item => item?.display?.replace(regexFee, ''));
      const bonus = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Percent Doctor Profit:'),
      );
      const bonusValue = bonus?.map(item =>
        item?.display?.replace(regexBonus, ''),
      );

      const getSpecialist = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Specialist:'),
      );

      const newData =
        getSpecialist
          ?.map(item => {
            const dataValue = item?.display?.split(': ');
            if (dataValue?.length > 1) {
              return dataValue[1];
            }
            return '';
          })
          ?.filter(i => i) || [];
      const dataCheckBoxId = specialistReducer
        ?.filter(item => newData?.includes(item.display))
        ?.map(i => i.uuid);
      const getMajors = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Health facilities:'),
      );
      if (feeFinal !== undefined) {
        const feeS = feeFinal[0]?.replace(regexFilterFee, '');
        setInitialFee(feeS);
        setInitialBonus(bonusValue[0]);
        form.setFieldsValue({
          status: dataDetail?.user?.status,
          outstanding: dataDetail?.outstanding,
          username: dataDetail?.user?.display,
          attributes:
            trainning?.map(item => item.value)?.length !== 0
              ? trainning?.map(item => item.value)
              : [''],
          phoneNumber: dataDetail?.user?.phoneNumber,
          email: dataDetail?.user?.email,
          name: dataDetail?.person?.display,
          rank: dataDetail?.rank,
          description: dataDetail?.description,
          yearExperience: String(dataDetail?.yearExperience),
          trainningProcess: workFinal[0],
          workPlace: dataDetail?.workPlace,
          majors: getMajors[0]?.value?.display,
          // fee: String(feeS),
          fee: feeS,
          bonus: bonusValue[0],
          specialist: dataCheckBoxId,
        });
      }
    }
  }, [form, dataDetail, specialistReducer]);

  useEffect(() => {
    getDetailDoctor();
  }, [id, dataUploadAvatar, dataUploadCover]);
  const getSpecialist = () => {
    dispatch(
      doctorInfoActions.getSpecialistRequested({
        ...ParamsInformationDoctorModel,
      }),
    );
  };
  const getHealthFacility = () => {
    dispatch(
      doctorInfoActions.getHealthFacilityRequested({
        v: 'full',
        class: 'Health facilities',
      }),
    );
  };

  // useEffect(() => {
  //   getHealthFacility();
  // }, []);
  useEffect(() => {
    getSpecialist();
    getHealthFacility();
  }, []);

  const listOption = healthFacilityData?.map((item, index) => {
    return (
      <>
        <Option value={item.uuid} key={index}>
          {item.display}
        </Option>
      </>
    );
  });

  useEffect(() => {
    jq(document).ready(() => {
      jq('.ant-btn ').attr('title', '');
    });
  });

  const onFinish = values => {
    const {
      email,
      attributes,
      fee,
      majors,
      trainningProcess,
      username,
      status,
      name,
      phoneNumber,
      bonus,
      specialist,
    } = values;

    const arrWorkingProcess = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Working process:'),
    );

    const dataToCompare = arrWorkingProcess.map((item, key) => {
      return {
        attributeType: ATTRIBUTE_TYPE_PROCESS_PLACE,
        value: item?.value,
        uuid: item?.uuid,
      };
    });

    const objPlaceAtt = attributes?.map(item => {
      if (item && item !== '') {
        const placeConvert = {
          attributeType: ATTRIBUTE_TYPE_PROCESS_PLACE,
          value: item,
        };

        return placeConvert;
      }
    });

    const vsd = dataToCompare.map((item, key) => {
      // eslint-disable-next-line no-param-reassign
      delete objPlaceAtt[key];
      const daa = { ...item, value: attributes[key] };
      return daa;
    });

    const objPlace = objPlaceAtt.filter(item => item !== undefined);

    const arrdMajors = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Health facilities:'),
    );
    const uuidMajors = arrdMajors[0]?.uuid;
    const dataSpecialist = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Specialist:'),
    );
    const arrSpecialist: any = [];

    for (let i = 0; i < specialist.length; i++) {
      let add = false;
      for (let j = 0; j < dataSpecialist.length; j++) {
        if (specialist[i] === dataSpecialist[j].value.uuid) {
          // các phần tử trùng nhau
          arrSpecialist.push({
            attributeType: ATTRIBUTE_TYPE_SPECIALIST,
            value: specialist[i],
            uuid: dataSpecialist[j].uuid,
          });
          add = true;
          break;
        }
      }
      if (!add) {
        // các phần tử không trung nhau
        arrSpecialist.push({
          attributeType: ATTRIBUTE_TYPE_SPECIALIST,
          value: specialist[i],
        });
      }
    }
    const arrdFee = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Examination fees:'),
    );
    const uuidFee = arrdFee[0]?.uuid;
    const arrdBonus = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Percent Doctor Profit:'),
    );
    const uuidBonus = arrdBonus[0]?.uuid;

    const arrTrainningProcess = dataDetail?.attributes?.filter(item =>
      item?.display?.includes('Training process:'),
    );
    const uuidTrainingProcess = arrTrainningProcess[0]?.uuid;

    const phone = phoneNumber
      ? _.replace(phoneNumber, regexGetPhoneNumber, '')
      : '';
    const emailLatest = email ? _.replace(email, regexGetEmailLatest, '') : '';
    const feeLatest = fee ? _.replace(fee, regexGetFeeLatest, '') : '';
    const bonusLatest = _.replace(bonus, regexGetFeeLatest, '');

    const objFee = {
      attributeType: ATTRIBUTE_TYPE_FEE,
      value: feeLatest,
      uuid: uuidFee,
    };

    const objMajors = {
      attributeType: ATTRIBUTE_TYPE_MAJORS,
      value: majors,
      uuid: uuidMajors,
    };
    const objBonus = {
      attributeType: ATTRIBUTE_TYPE_BONUS,
      value: bonusLatest,
      uuid: uuidBonus,
    };
    const objtrainningProcess = {
      attributeType: ATTRIBUTE_TYPE_PROCESS_EDU,
      value: trainningProcess,
      uuid: uuidTrainingProcess,
    };

    if (objFee?.value && objFee?.value !== '') {
      objPlace.push(objFee);
    }
    if (objtrainningProcess?.value && objtrainningProcess?.value !== '') {
      objPlace.push(objtrainningProcess);
    }
    if (objMajors?.value && objMajors.value !== '') {
      objPlace.push(objMajors);
    }
    if (objBonus?.value && objBonus.value !== '') {
      objPlace.push(objBonus);
    }

    const dataName = [
      {
        givenName: name,
        uuid: dataDetail?.person?.preferredName?.uuid,
      },
    ];

    const newData = [...dataToCompare, ...objPlace, ...arrSpecialist];

    const filterWorkingProcess = newData?.filter(
      item => item.attributeType === ATTRIBUTE_TYPE_PROCESS_PLACE,
    );
    const filterNotWorkingProcess = newData?.filter(
      item => item.attributeType !== ATTRIBUTE_TYPE_PROCESS_PLACE,
    );

    // const getDataWorkingProcess = _.uniqBy(filterWorkingProcess, 'value');

    const filterX = filterWorkingProcess.filter(
      item => !item?.hasOwnProperty('uuid'),
    );

    const latestAttr = [...vsd, ...filterX, ...filterNotWorkingProcess];

    const filterAttr = _.filter(latestAttr, val => {
      return val?.value === undefined;
    });

    const addData = filterAttr.map(item => {
      return { ...item, voided: true };
    });

    // edit image
    if (dataDetail !== undefined) {
      const arrCover = dataDetail?.user?.attributes.filter(
        item => item?.attributeType?.uuid === uuidCover,
      );
      if (arrCover !== undefined) {
        const idCover = arrCover[0]?.uuid;
        const file1 = values?.cover?.fileList[1];
        const dataCover = {
          attributeType: '1962dghj-3cf3-420d-b804-f1e096dgnaht',
          uuid: idCover,
        };

        const formData1 = new FormData();
        if (file1 !== undefined) {
          formData1.append('json', JSON.stringify(dataCover));
          formData1.append('file', file1.originFileObj);
          dispatch(
            doctorInfoActions.handleUpdateCoverRequested({
              form: formData1,
              uuid: dataDetail?.user?.uuid,
            }),
          );
        }
      }
    }

    if (dataDetail !== undefined) {
      const arrAvatar = dataDetail?.user?.attributes.filter(
        item => item?.attributeType?.uuid === uuidAvatar,
      );

      if (arrAvatar !== undefined) {
        const idAvatar = arrAvatar[0]?.uuid;

        const file = values?.avatar?.fileList[1];
        const dataAva = {
          attributeType: '1962dfff-3cf3-420d-b804-f1e096dfb5fd',
          uuid: idAvatar,
        };

        const formData = new FormData();
        if (file !== undefined) {
          formData.append('json', JSON.stringify(dataAva));
          formData.append('file', file.originFileObj);
          dispatch(
            doctorInfoActions.handleUploadAvatarRequested({
              form: formData,
              uuid: dataDetail?.user?.uuid,
            }),
          );
        }
      }
    }

    const finalList = [...latestAttr, ...addData];
    // const finalList1 = _.uniqBy(finalList?.reverse(), 'uuid');
    // const finalList2 = _.uniqBy(finalList1?.reverse(), 'value');
    const latestUserName = _.replace(username, regexGetPhoneNumber, '');
    const data = {
      ...values,
      email: emailLatest || '',
      attributes: finalList,
      user: {
        username: latestUserName,
        status,
        email: emailLatest,
        phoneNumber: phone,
        person: {
          uuid: dataDetail?.person?.uuid,
          names: dataName,
          gender: 'NON',
        },
      },
    };

    delete data?.person;

    delete data?.fee;
    delete data?.trainningProcess;
    delete data?.username;
    delete data?.status;
    delete data?.phoneNumber;
    delete data?.majors;
    delete data?.name;
    if (data?.email === '' || data?.email?.length === 0) {
      delete data.user.email;
    }
    delete data?.email;
    delete data?.avatar;
    delete data?.cover;
    delete data?.bonus;
    delete data?.specialist;
    // delete data?.description;
    // eslint-disable-next-line no-console
    dispatch(doctorInfoActions.editDoctorRequested(id, data));
  };

  const handleUploadAvatar = (file: any) => {
    setFileListAvatar([
      {
        uid: '1',
        name: 'avatar',
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);
    return false;
  };

  const handleRemoveAvatar = () => {
    setFileListAvatar('');
  };

  const handleUploadCover = (file: any) => {
    setFileListCover([
      {
        uid: '1',
        name: 'cover',
        status: 'done',
        url: URL.createObjectURL(file),
      },
    ]);

    return false;
  };

  const handleRemoveCover = () => {
    setFileListCover('');
  };
  const keyCodeArr = [8, 9, 35, 36, 37, 38, 39, 40];

  return (
    <div className="edit-doctor">
      <Row>
        <Col xs={24}>
          <h1 className="mb-10">Chỉnh sửa thông tin bác sỹ</h1>
        </Col>
      </Row>
      {/* <img src={imgTest} alt="test" /> */}
      <Form
        {...formItemLayout}
        name="basic"
        initialValues={initVal}
        onFinish={onFinish}
        className="modal-edit-doctor"
        form={form}
        requiredMark={false}
      >
        <div className="flex w-full pb-5">
          <div className="w-1/3 box_image_1 ">
            <Form.Item
              labelAlign="left"
              name="avatar"
              label="Ảnh đại diện"
              className="form-item avatar"
              labelCol={{ span: 12 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('avatar')?.fileList[1]?.size < 10485760 ||
                      getFieldValue('avatar')?.fileList?.length === 0 ||
                      getFieldValue('avatar') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Dung lượng ảnh không vượt quá 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                fileList={fileListAvatar}
                disabled={false}
                accept="image/*"
                beforeUpload={handleUploadAvatar}
                onRemove={handleRemoveAvatar}
                listType="picture-card"
                className="upload-image"
              >
                {/* {fileListAvatar === '' ? ( */}
                <CameraFilled style={{ fontSize: '30px', opacity: '45%' }} />
              </Upload>
            </Form.Item>
          </div>

          <div className="w-1/3 box_image_2">
            <Form.Item
              labelAlign="left"
              name="cover"
              label="Ảnh bìa"
              className="form-item cover"
              labelCol={{ span: 12 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (
                      getFieldValue('cover')?.fileList[1]?.size < 10485760 ||
                      getFieldValue('cover')?.fileList?.length === 0 ||
                      getFieldValue('cover') === undefined
                    ) {
                      return Promise.resolve();
                    }
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise.reject(
                      'Dung lượng ảnh không vượt quá 10MB!',
                    );
                  },
                }),
              ]}
            >
              <Upload
                fileList={fileListCover}
                disabled={false}
                accept="image/*"
                beforeUpload={handleUploadCover}
                onRemove={handleRemoveCover}
                listType="picture-card"
                className="upload-image"
              >
                {/* {fileListCover === '' ? (
                  <div> */}
                <CameraFilled style={{ fontSize: '30px', opacity: '45%' }} />
                {/* </div>
                ) : null} */}
              </Upload>
              {/* )} */}
            </Form.Item>
          </div>
        </div>
        <Form.Item
          label="Trạng thái"
          name="status"
          className="form-item"
          labelAlign="left"
        >
          <Radio.Group>
            <Radio value="ACTIVE">Hoạt động</Radio>
            <Radio value="IN_ACTIVE">Khóa</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Bác sĩ tiêu biểu"
          name="outstanding"
          className="form-item"
          labelAlign="left"
        >
          <Radio.Group>
            <Radio value={true}>Có</Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          className="form-item"
          labelAlign="left"
          rules={[
            {
              pattern: regexPreventChar,
              message: 'Username không được chứa ký tự đặc biệt!',
            },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Input disabled style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Số điện thoại"
          name="phoneNumber"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            {
              pattern: regexCheckPhone,
              message: 'Số điện thoại ít nhất 10 số và bắt đầu 03|05|07|08|09',
            },
          ]}
          className="form-item"
        >
          <InputMask
            className="ant-input"
            mask="9999 999 999"
            maskChar=""
            style={{ width: '50%' }}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Email"
          name="email"
          rules={[
            {
              pattern: regexCheckEmail,
              message: 'Vui lòng nhập email hợp lệ!',
            },
          ]}
          className="form-item"
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item
          labelAlign="left"
          label="Họ và tên"
          name="name"
          rules={[
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            { max: 150, message: 'Họ tên không vượt quá 50 ký tự!' },
          ]}
          className="form-item"
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Học hàm, học vị"
          name="rank"
          rules={[
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Nơi công tác"
          name="workPlace"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Input style={{ width: '50%' }} />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Quá trình công tác"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
          name="attributes"
        >
          <Form.List name="attributes">
            {(fields: any, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <Form.Item required={false} key={field.key}>
                        <Form.Item
                          style={{
                            position: 'relative',
                          }}
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              message: 'Vui lòng điền thông tin bắt buộc!',
                            },
                            {
                              pattern: regextTrimspace,
                              message: 'Vui lòng điền thông tin bắt buộc!',
                            },
                          ]}
                          className="element"
                          noStyle
                        >
                          <Input style={{ width: '50%' }} />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ margin: '0 8px' }}
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    );
                  })}
                  <Form.Item className="wrap-btn-add">
                    <Button
                      type="primary"
                      style={{
                        color: '#fff important',
                        position: 'absolute',
                        top: -56,
                        right: '41%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className="addPlace"
                      onClick={() => {
                        add();
                      }}
                    >
                      <PlusOutlined
                        style={{ fontSize: '15px', color: '#fff important' }}
                      />
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Thông tin mô tả"
          name="description"
        >
          <TextArea autoSize={{ maxRows: 5, minRows: 3 }} />
        </Form.Item>
        <div className="flex exp">
          <Form.Item
            labelAlign="left"
            className="form-item test"
            label="Số năm kinh nghiệm"
            name="yearExperience"
            rules={[
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    (getFieldValue('yearExperience') > 0 &&
                      getFieldValue('yearExperience')?.length <= 2) ||
                    !getFieldValue('yearExperience')
                  ) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject('Vui lòng nhập đúng thông tin!');
                },
              }),
              {
                required: true,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
            ]}
          >
            <Input className="input-edit-year" type="number" min="0" />
          </Form.Item>
          <span className="title-year">Năm</span>
        </div>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Cơ sở y tế"
          name="majors"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
          ]}
        >
          <Select style={{ width: '40%' }}>{listOption}</Select>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="form-item"
          label="Quá trình đào tạo"
          name="trainningProcess"
        >
          <TextArea
            autoSize={{ maxRows: 5, minRows: 3 }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Phí khám"
          name="fee"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            ({ getFieldValue, setFieldsValue }) => ({
              validator(rule, value) {
                // if (getFieldValue('fee') >= 0 || getFieldValue('fee') === '') {
                //   return Promise.resolve();
                // }
                const reg = new RegExp('^[a-zA-Z]+$');
                if (reg.test(value)) {
                  setInitialFee('');
                  setFieldsValue({ fee: '' });
                }
                const validateNumber = value.replace(/[,]/g, '');
                const isNumberInteger = (item: number) =>
                  Number.isInteger(+item);

                if (
                  !value ||
                  (Number(validateNumber) >= 0 &&
                    Number(validateNumber) <= 20000000 &&
                    isNumberInteger(validateNumber))
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject('Phí khám không hợp lệ!');
              },
            }),
            {
              // pattern: regextTrimspace,
              pattern: new RegExp(/\d+/g),
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <InputNumber
                className="input-type"
                formatter={(value: any) =>
                  `${value.toString()}`
                    .replace(/[\D\s\._\-]+/g, '')
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                style={{ width: '100%' }}
                onKeyDown={e => {
                  const keys = /[0-9]/g;
                  if (
                    !String(e.key).match(keys) &&
                    !keyCodeArr.includes(e.keyCode) &&
                    !(e.keyCode === 65 && e.ctrlKey === true)
                  ) {
                    e.preventDefault();
                  }
                }}
                onChange={(e: any) => {
                  setInitialFee(e);
                }}
                maxLength={11}
                value={initialFee}
                type="text"
                keyboard={false}
                name="fee"
              />
            </Col>
            <Col xs={4}>
              <span className="title-money">VND</span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item
          labelAlign="left"
          label="Phần trăm được hưởng"
          name="bonus"
          className="form-item fee-test"
          rules={[
            { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (
                  (Number(value) >= 0 && Number(value) <= 200) ||
                  getFieldValue('bonus') === ''
                ) {
                  return Promise.resolve();
                }
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject(
                  'Phần trăm được hưởng không hợp lệ! Min: 0%, Max: 200%',
                );
              },
            }),
            {
              pattern: regextTrimspace,
              message: 'Vui lòng điền thông tin bắt buộc!',
            },
          ]}
        >
          <Row gutter={[10, 0]} align="middle">
            <Col xs={6}>
              <InputNumber
                className="input-type"
                maxLength={11}
                type="number"
                style={{ width: '100%' }}
                onChange={(e: any) => setInitialBonus(e)}
                value={initialBonus}
              />
            </Col>
            <Col xs={4}>
              <span className="title-money">%</span>
            </Col>
          </Row>
        </Form.Item>
        {/* <div className="flex fee">
          <Form.Item
            labelAlign="left"
            label="Phần trăm được hưởng"
            name="bonus"
            className="form-item fee-test"
            rules={[
              { required: true, message: 'Vui lòng điền thông tin bắt buộc!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (
                    (Number(value) >= 0 && Number(value) <= 200) ||
                    getFieldValue('bonus') === ''
                  ) {
                    return Promise.resolve();
                  }
                  // eslint-disable-next-line prefer-promise-reject-errors
                  return Promise.reject(
                    'Phần trăm được hưởng không hợp lệ! Min: 0%, Max: 200%',
                  );
                },
              }),
              {
                pattern: regextTrimspace,
                message: 'Vui lòng điền thông tin bắt buộc!',
              },
            ]}
          >
            <InputNumber className="input-type" maxLength={11} type="number" />
          </Form.Item>
          <span className="title-money">%</span>
        </div> */}
        <Form.Item
          className="form-item specialist edit-doctor"
          label="Chuyên khoa"
          name="specialist"
        >
          <Checkbox.Group>
            <Row>
              {specialistReducer?.map(item => (
                <Col span={24} key={item.uuid}>
                  <Checkbox className="specialist-checkbox" value={item.uuid}>
                    {item?.display}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
          {/* </div> */}
        </Form.Item>
        <div className="button-container">
          <Button
            className="mr-2"
            type="default"
            onClick={() => history.push(`/app/doctor/information`)}
          >
            Quay lại
          </Button>
          <Button className="ml-2" type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EditDoctor;
