import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
  CloseOutlined,
  PhoneTwoTone,
  LockOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { isRoleDoctor } from 'helpers/utils';

type ActionProps = {
  hasEdit?: boolean;
  hasDetail?: boolean;
  hasLock?: boolean;
  hasResetPassword?: boolean;
  hasChangeStatus?: boolean;
  isLock?: boolean;
  isEdit?: boolean;
  isDeleted?: boolean;
  hasDelete?: boolean;
  isCancel?: boolean;
  hasCancel?: boolean;
  isCallTest?: boolean;
  hasCallTest?: boolean;
  isChangeStatus?: boolean;
  handleDetail?: (...args: any[]) => any;
  handleEdit?: (...args: any[]) => any;
  handleDelete?: (...args: any[]) => any;
  handleResetPassword?: (...args: any[]) => any;
  handleCancelAppointment?: (...args: any[]) => any;
  handleCallTest?: (...args: any[]) => any;
  handleChangeStatus?: (...args: any[]) => any;
  handleLock?: (...args: any[]) => any;
};

const initProps = {
  hasDetail: true,
  hasEdit: true,
  hasDelete: true,
  hasLock: false,
  hasResetPassword: false,
  hasChangeStatus: false,
  isCancel: false,
  isDeleted: false,
  isEdit: false,
  isCallTest: false,
  isLock: false,
  hasCallTest: false,
  isChangeStatus: false,
  handleDetail: () => {},
  handleEdit: () => {},
  handleDelete: () => {},
  handleResetPassword: () => {},
  handleCancelAppointment: () => {},
  handleCallTest: () => {},
  handleChangeStatus: () => {},
  handleLock: () => {},
};

const Action: FC<ActionProps> = (props: ActionProps) => {
  const {
    hasDetail,
    hasEdit,
    hasDelete,
    hasLock,
    hasResetPassword,
    hasChangeStatus,
    isDeleted,
    isLock,
    isEdit,
    isCancel,
    hasCancel,
    isCallTest,
    hasCallTest,
    isChangeStatus,
    handleDetail,
    handleEdit,
    handleDelete,
    handleResetPassword,
    handleCancelAppointment,
    handleCallTest,
    handleChangeStatus,
    handleLock,
  } = {
    ...initProps,
    ...props,
  };

  return (
    <>
      {hasDetail ? (
        <Tooltip title="Xem">
          <Button onClick={() => handleDetail()}>
            <EyeOutlined style={{ color: '#1a202c' }} />
          </Button>
        </Tooltip>
      ) : null}
      {hasChangeStatus ? (
        <Tooltip title="Đổi trạng thái" overlayClassName="lg:ml-2">
          <Button
            className="lg:ml-2"
            onClick={() => handleChangeStatus()}
            disabled={isChangeStatus}
          >
            <EditOutlined style={{ color: '#1a202c' }} />
          </Button>
        </Tooltip>
      ) : null}
      {hasLock ? (
        <Tooltip title={isLock ? 'Mở khóa' : 'Khoá'}>
          <Button
            className="lg:ml-2"
            onClick={events => {
              events.stopPropagation();
              handleLock();
            }}
          >
            {isLock ? (
              <LockOutlined style={{ color: '#1a202c' }} />
            ) : (
              <UnlockOutlined style={{ color: '#1a202c' }} />
            )}
          </Button>
        </Tooltip>
      ) : null}
      {hasEdit ? (
        <Tooltip title="Sửa" overlayClassName="lg:ml-2">
          <Button
            className="lg:ml-2"
            onClick={events => {
              events.stopPropagation();
              handleEdit();
            }}
            disabled={isEdit}
          >
            <EditOutlined style={{ color: '#1a202c' }} />
          </Button>
        </Tooltip>
      ) : null}
      {hasDelete ? (
        <Tooltip title="Xóa">
          <Button
            className="lg:ml-2"
            onClick={() => handleDelete()}
            disabled={isDeleted}
          >
            <DeleteOutlined style={{ color: '#1a202c' }} />
          </Button>
        </Tooltip>
      ) : null}
      {hasCallTest ? (
        <Tooltip title="Tạo cuộc gọi thử">
          <Button
            className="lg:ml-2"
            onClick={() => handleCallTest()}
            disabled={isCallTest}
          >
            <PhoneTwoTone />
          </Button>
        </Tooltip>
      ) : null}
      {hasCancel
        ? isRoleDoctor() === false && (
            <Tooltip title="Hủy cuộc hẹn">
              <Button
                className="lg:ml-2 xs:mt-4"
                onClick={() => handleCancelAppointment()}
                disabled={isCancel}
              >
                <CloseOutlined style={{ color: '#1a202c' }} />
              </Button>
            </Tooltip>
          )
        : null}

      {hasResetPassword ? (
        <Tooltip title="Reset mật khẩu">
          <Button
            className="lg:ml-2"
            onClick={() => handleResetPassword()}
            disabled={isDeleted}
          >
            <KeyOutlined style={{ color: '#1a202c' }} />
          </Button>
        </Tooltip>
      ) : null}
    </>
  );
};

export default Action;
