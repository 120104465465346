import React, { FC, useEffect, useState } from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Input,
} from 'antd';

import * as _ from 'lodash';
import moment from 'moment';
import StoreState from 'models/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ActionTable from '../../components/Share/Table/Action';
import { formatMoney, formatDateDayVN } from '../../helpers/utils';
import * as servicePackageActions from '../../store/servicePackage/servicePackage.action';
import './Information.scss';
import * as STATUS from './servicePackage.const';

interface ParamsServicePackageModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  isLock?: string | null;
  packageName: string | null;
  packageStatus: string | null;
}

const { Search } = Input;
// const onSearch = (value: string) => console.log(value);

const InformationServicePackage: FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [paramsDoctor, setParamsDoctor] = useState<ParamsServicePackageModel>({
    isLock: '',
    packageName: '',
    packageStatus: '',
    // v: 'full',
    // startIndex: 1,
    // totalCount: true,
    // limit: 10,
    // sortField: 'DATE_CREATED',
    // sortDirection: 'DESC',
  });
  const [reloadList, setReloadList] = useState(false);
  const { Option } = Select;
  const { confirm } = Modal;

  const initFormSearch = {
    isLock: '',
  };
  const servicePackageReducer = useSelector(
    (state: StoreState) => state.servicePackageReducer,
  );

  const handleLock = record => {
    const lockParam = _.get(record, 'lock', '');

    const onLock = async () => {
      const uuid = _.get(record, 'uuid', '');
      await dispatch(
        servicePackageActions.handleLockServicePackageRequested({
          uuid,
          lock: !lockParam,
        }),
      );
      setTimeout(() => {
        getServicePackageInfo();
      }, 600);
    };
    confirm({
      title: `Bạn có chắc chắn ${
        !lockParam ? 'khóa' : 'mở khóa'
      } gói khám dịch vụ này không ?`,
      icon: <ExclamationCircleOutlined />,
      onOk: () => onLock(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const getServicePackageInfo = () => {
    dispatch(
      servicePackageActions.getServicePackageRequested({
        ...paramsDoctor,
      }),
    );
  };

  useEffect(() => {
    getServicePackageInfo();
  }, [paramsDoctor]);

  // eslint-disable-next-line no-shadow
  const handleDeleteServicePackage = record => {
    const onDelete = () => {
      const uuid = _.get(record, 'uuid', '');
      dispatch(servicePackageActions.handleDeleteServicePackageRequested(uuid));
    };
    confirm({
      title: 'Bạn có chắc chắn xóa gói khám dịch vụ này không ?',
      icon: <ExclamationCircleOutlined />,
      onOk: () => onDelete(),
      okText: 'Có',
      cancelText: 'Đóng',
    });
  };

  const onSearchServicePackage = valueSearch => {
    // if (valueSearch !== undefined && valueSearch !== null) {
    //   const searchInput = valueSearch?.q;
    //   const searchInput1 = _.replace(
    //     searchInput,
    //     STATUS.regexConvertSearch1,
    //     '',
    //   );
    //   const searchInput2 = _.replace(
    //     searchInput1,
    //     STATUS.regexConvertSearch2,
    //     '',
    //   );
    //   const paramsSearch = {
    //     ...valueSearch,
    //     q: searchInput2,
    //     attributeType: STATUS.ATTRIBUTE_TYPE_MAJORS,
    //   };
    //   // getDoctorInfo(paramsSearch);
    //   setParamsDoctor({
    //     ...paramsDoctor,
    //     ...paramsSearch,
    //     startIndex: 1,
    //   });
    // }
    // search by name only
    // if (valueSearch !== '') {
    //   setParamsDoctor({
    //     startIndex: 1,
    //     packageName: valueSearch,
    //   });
    // }
  };

  const onBlurSelect1 = val => {
    setParamsDoctor(pre => ({ ...pre, isLock: val }));
  };

  const columns = [
    {
      title: 'STT',
      width: '5%',
      key: '1',
      render: (value, row, index) => {
        return index + 1;
      },
    },
    {
      title: 'ID',
      key: '2',
      render: (value, row, index) => {
        return value?.identifier;
      },
    },
    {
      key: '3',
      title: 'Hình thức gói khám',
      render: (value, row, index) => {
        if (value?.packageFormatType == 'ON') {
          return 'Online';
        }
        if (value?.packageFormatType == 'OFF') {
          return 'Offline';
        }
      },
    },
    {
      title: 'Tên gói',
      key: '4',
      render: (value, row, index) => {
        return value?.name || '';
      },
    },
    // {
    //   key: '5',
    //   title: 'Chuyên khoa',
    //   render: (value, row, index) => {
    //     return value?.speciality || '';
    //   },
    // },
    {
      key: '6',
      title: 'Giá gốc',
      render: (value, row, index) => {
        if (value && value.originalPrice) {
          return formatMoney(value.originalPrice);
        }
      },
    },
    {
      key: '7',
      title: 'Giá ưu đãi',
      render: (value, index, row) => {
        if (value && value.promotionPrice) {
          return formatMoney(value.promotionPrice);
        }
      },
    },
    // {
    //   key: '8',
    //   title: 'Hạn sử dụng (ngày)',
    //   render: (value, row, index) => {
    //     return value?.numberOfDays || '';
    //   },
    // },
    {
      key: 'startDate',
      title: 'Ngày bắt đầu',
      render: (value, row, index) => {
        return formatDateDayVN(value?.startDate);
      },
    },
    {
      key: 'endDate',
      title: 'Ngày kết thúc',

      render: (value, row, index) => {
        return formatDateDayVN(value?.endDate);
      },
    },
    {
      key: 'createDate',
      title: 'Ngày tạo',
      render: (value, row, index) => {
        return formatDateDayVN(value?.createDate);
      },
    },
    {
      key: '8',
      title: 'Trạng thái',
      width: 150,
      render: (value, row, index) => {
        if (value?.lock) {
          return 'Khóa';
        }
        if (!value?.lock) {
          return 'Hoạt động';
        }
      },
    },
    {
      key: 'Action',
      title: 'Tác vụ',
      width: 110,
      render: (row: any) => (
        <div className="add-doctor-action-table">
          <ActionTable
            hasLock={true}
            hasDetail={false}
            isLock={row.lock}
            handleDelete={() => handleDeleteServicePackage(row)}
            handleLock={() => handleLock(row)}
            handleDetail={() => {
              history.push(
                `/appx/service-package/edit/${_.get(row, 'uuid')}?type=view`,
              );
            }}
            handleEdit={() => {
              history.push(`/app/service-package/edit/${_.get(row, 'uuid')}`);
            }}
          />
        </div>
      ),
    },
  ];
  const onSearchFinish = (valueSearch: any) => {
    if (valueSearch !== undefined && valueSearch !== null) {
      setParamsDoctor({
        startIndex: 1,
        packageName: valueSearch?.search_input,
        packageStatus: valueSearch?.package_type,
      });
    }
  };
  return (
    <div className="doctor-information">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Quản lý gói khám dịch vụ</h1>
          </Col>
          <Col xs={12} style={{ textAlign: 'right' }}>
            <Link type="button" to="/app/service-package/add">
              <Button type="primary">Thêm mới</Button>
            </Link>
          </Col>
        </Row>

        <Row style={{ marginTop: 20 }} gutter={[20, 0]}>
          <Col>
            <Form
              name="search"
              onFinish={onSearchFinish}
              autoComplete="on"
              initialValues={{ package_type: 'ON' }}
              className="search-tool"
            >
              <Form.Item name="search_input" className="search-input">
                <Input placeholder="Nhập tên để tìm kiếm" />
              </Form.Item>

              <Form.Item
                label="Hình thức gói dịch vụ"
                name="package_type"
                className="package-type"
              >
                <Select>
                  <Option value="ON">Online</Option>
                  <Option value="OFF">Offline</Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: servicePackageReducer?.isLoadingServicePackage,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={servicePackageReducer?.dataServicePackage?.datas || []}
            // pagination={false}
            pagination={{
              current: paramsDoctor.startIndex,
              total: servicePackageReducer?.dataServicePackage?.datas.length,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50],
              locale: {
                items_per_page: '/Trang',
                jump_to: 'Chuyển tới trang',
                page: '',
              },
              showQuickJumper: true,
              showTotal: (total, range) => (
                <span>
                  Showing {range[0]}-{range[1]} of {total}
                </span>
              ),
            }}
            // onChange={onChangeTable}
          />
        </Col>
      </Row>
    </div>
  );
};

export default InformationServicePackage;
