import { createActions } from 'redux-actions';

export const {
  postAllergyRequested,
  postAllergySucceeded,
  postAllergyFailed,
  postConditionRequested,
  postConditionSucceeded,
  postConditionFailed,
  postFeedbackRequested,
  postFeedbackSucceeded,
  postFeedbackFailed,
  postUpdateStatusAppointmentRequested,
  postUpdateStatusAppointmentSucceeded,
  postUpdateStatusAppointmentFailed,
  editAllergyRequest,
  editAllergySucceeded,
  editAllergyFailed,
  fetchDiagnosis,
  fetchDiagnosisSucceeded,
  fetchDiagnosisFailed,
  searchDiagnosis,
  searchDiagnosisSucceeded,
  searchDiagnosisFailed,
  createFollowing,
  createFollowingSucceeded,
  createFollowingFailed,
  updateFollowing,
  updateFollowingSucceeded,
  updateFollowingFailed,
} = createActions(
  {
    POST_ALLERGY_REQUESTED: payload => payload,
    POST_ALLERGY_SUCCEEDED: payload => payload,
    POST_ALLERGY_FAILED: error => error,
    POST_CONDITION_REQUESTED: payload => payload,
    POST_CONDITION_SUCCEEDED: payload => payload,
    POST_CONDITION_FAILED: error => error,
    POST_FEEDBACK_REQUESTED: payload => payload,
    POST_FEEDBACK_SUCCEEDED: payload => payload,
    POST_FEEDBACK_FAILED: error => error,
    POST_UPDATE_STATUS_APPOINTMENT_REQUESTED: payload => payload,
    POST_UPDATE_STATUS_APPOINTMENT_SUCCEEDED: payload => payload,
    POST_UPDATE_STATUS_APPOINTMENT_FAILED: error => error,
    EDIT_ALLERGY_REQUEST: payload => payload,
    EDIT_ALLERGY_SUCCEEDED: payload => payload,
    EDIT_ALLERGY_FAILED: () => {},
    FETCH_DIAGNOSIS: (q: string) => q,
    FETCH_DIAGNOSIS_SUCCEEDED: payload => payload,
    FETCH_DIAGNOSIS_FAILED: payload => payload,
    SEARCH_DIAGNOSIS: (q: string) => q,
    SEARCH_DIAGNOSIS_SUCCEEDED: payload => payload,
    SEARCH_DIAGNOSIS_FAILED: payload => payload,
    CREATE_FOLLOWING: (
      id: string,
      form: {
        follower: string;
        followDescription: string;
        followDate: string;
        appointment: string;
      },
      callbackSuccess,
      callbackFailed,
    ) => ({
      id,
      form,
      callbackSuccess,
      callbackFailed,
    }),
    CREATE_FOLLOWING_SUCCEEDED: payload => payload,
    CREATE_FOLLOWING_FAILED: () => {},
    UPDATE_FOLLOWING: (
      followUuid: string,
      form: {
        follower: string;
        followDescription: string;
        followDate: string;
        appointment: string;
      },
      callbackSuccess,
      callbackFailed,
    ) => ({
      followUuid,
      form,
      callbackSuccess,
      callbackFailed,
    }),
  },
  {
    prefix: 'APPOINTMENT',
  },
);
