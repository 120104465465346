import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { Row, Col, Button, message } from 'antd';
// import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appointmentActions } from 'store/appointment';

import './DrugOrder.scss';
import StoreState from 'models/store';
import DrugForm from './DrugForm/DrugForm';
import { usageTimeList, eatingStatus } from './drugOrder.constant';

const DrugOrder: FC<any> = props => {
  const { dataProps, isEditAvailability } = props;
  const [selectedConceptDrug, setSelectedConceptDrug] = useState<any>({});
  const [drugActiveIngredientValue, setDrugActiveIngredientValue] = useState<
    string
  >('');

  const [drugConceptValue, setDrugConceptValue] = useState<string>('');
  const [DrugActiveIngredient, setDrugActiveIngredient] = useState<any>({});
  const [isEdit, setEdit] = useState<boolean>(false);
  const [selectDrugEdit, setSelectDrugEdit] = useState<any>({});
  const [indexEdit, setIndexEdit] = useState<number>(0);
  const [isAdd, setIsAdd] = useState<any>(false);
  const dispatch = useDispatch();

  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    dispatch(
      appointmentActions.getConceptDrugActiveIngredient(
        drugActiveIngredientValue,
      ),
    );
  }, [drugActiveIngredientValue]);

  useEffect(() => {
    if (isSubmit === true && appointmentReducer?.isAddOrderDrug === true) {
      setIsAdd(false);
    }
  }, [appointmentReducer?.isAddOrderDrug]);

  useEffect(() => {
    if (DrugActiveIngredient?.uuid && DrugActiveIngredient.value !== 'Other') {
      dispatch(appointmentActions.getSelectDrugConcept(DrugActiveIngredient));
    } else if (drugConceptValue) {
      dispatch(appointmentActions.getSearchDrugConcept(drugConceptValue));
    } else {
      dispatch(appointmentActions.getSearchDrugConcept());
    }
  }, [DrugActiveIngredient, drugConceptValue]);

  const arrOrderDrug = dataProps && dataProps?.detail?.encounters[0]?.orders;

  const arrFilterOrderDrug =
    arrOrderDrug && arrOrderDrug?.filter(item => item?.type === 'drugordernew');

  const dataConceptDrugActiveIngredient = useSelector(
    (state: any) => state.appointment.dataConceptDrugActiveIngredient,
  );

  const conceptDrug = useSelector(
    (state: any) => state.appointment.conceptDrug,
  );

  const arrDiagnostic =
    dataProps && dataProps?.detail?.dataCloned?.detail?.diagnostic;

  const uuidProvider = dataProps && dataProps?.detail?.provider?.uuid;
  const uuidEncounter = dataProps && dataProps?.detail?.encounters[0]?.uuid;
  const uuidPerson = dataProps && dataProps?.detail?.arrPetient?.person?.uuid;

  const onFinish = values => {
    const basic = {
      encounter: uuidEncounter,
      orderType: '30cb964e-63b2-401e-bc62-a8aee0611fff',
      type: 'drugordernew',

      patient: uuidPerson,
      careSetting: '6f0c9a92-6f24-11e3-af88-005056821db0',

      orderer: uuidProvider,
      drug: selectedConceptDrug.uuid,
      drugNonCode: '',
      quantity: Number(values.amount),
      dosingInstructions: values.usage,

      eatingStatus: values.eatingStatus,
      timeUses: values.timeUses,
      drugUnits: values.unit,
    };
    const params = selectedConceptDrug.uuid
      ? basic
      : {
          ...basic,
          drug: 'AA5a964e-33aa-11bb-bc62-a8aee0630aaa',
          drugNonCode: values.ConceptDrug,
          drugUnits: values.unit,
        };

    setIsSubmit(true);
    setDrugActiveIngredientValue('');
    setDrugConceptValue('');
    if (params) {
      dispatch(appointmentActions.handleCreateOrderDrug(params));
    }
    setSelectedConceptDrug({});
  };

  const onFinishEdit = values => {
    const params = {
      drug: selectedConceptDrug.uuid || 'AA5a964e-33aa-11bb-bc62-a8aee0630aaa',
      drugNonCode: selectedConceptDrug.uuid ? undefined : values.ConceptDrug,
      quantity: Number(values.amount),
      dosingInstructions: values.usage,

      eatingStatus: values.eatingStatus,
      timeUses: values.timeUses,
      drugUnits: values.unit,
    };
    if (selectDrugEdit.uuid) {
      const body = {
        uuid: selectDrugEdit.uuid,
        params,
      };
      setIsSubmit(true);
      dispatch(appointmentActions.handleEditDrug(body));
      setEdit(false);
      setDrugActiveIngredientValue('');
      setDrugConceptValue('');
    }
  };

  const listOptionDrugActiveIngredient = useMemo(() => {
    return (
      dataConceptDrugActiveIngredient &&
      dataConceptDrugActiveIngredient?.map(item => ({
        value: item.display,
        uuid: item.uuid,
      }))
    );
  }, [dataConceptDrugActiveIngredient]);

  const listOptionDrug = useMemo(() => {
    return (
      conceptDrug.data &&
      conceptDrug.data?.map(item => ({
        value: item.display,
        uuid: item.uuid,
        key: item.uuid,
        detail: item,
      }))
    );
  }, [conceptDrug, DrugActiveIngredient]);

  const onChooseConceptDrug = (value, option) => {
    setDrugConceptValue(value?.trim() || '');
    setSelectedConceptDrug(option);
  };

  const onChooseDrugActiveIngredient = (value, option) => {
    setDrugActiveIngredientValue(value?.trim() || '');
    setDrugActiveIngredient(option);
  };

  const onClickEditItem = (item, key) => {
    if (!arrDiagnostic.length) {
      return message.error(
        'Bác sỹ cần đưa ra chẩn đoán trước khi sửa đơn thuốc',
      );
    }
    setEdit(true);
    setIsAdd(false);
    setIndexEdit(key);
    setSelectDrugEdit(item);

    const isDrugNonCode = item?.drugNonCode || item?.drug?.display === 'Other';

    if (isDrugNonCode) {
      onChooseDrugActiveIngredient('', {});
      onChooseConceptDrug(item?.drugNonCode, {});
    } else {
      const detailDrug = {
        ...item.drug,
        drugNonCode: item?.drugNonCode,
        drugUnits: item?.drugUnits,
      };
      onChooseDrugActiveIngredient(item?.concept?.display, {
        value: item?.concept?.display,
        uuid: item?.concept?.uuid,
      });
      onChooseConceptDrug(item?.drug?.display, {
        value: item?.drug?.display,
        uuid: item?.drug?.uuid,
        detail: detailDrug,
      });
    }
  };

  const displayDoctorRemind = useCallback(
    (data: any) => {
      const timeUsesDisplay =
        data?.timeUses?.map((item: string) => {
          return usageTimeList.find(time => time.value === item)?.display;
        }) || [];

      const eatingStatusDisplay =
        eatingStatus.find(item => item.value === data?.eatingStatus)?.display ||
        [];

      return timeUsesDisplay.length || eatingStatusDisplay.length
        ? `${data?.dosingInstructions} (${timeUsesDisplay
            .concat(eatingStatusDisplay)
            .join(', ')})`
        : data?.dosingInstructions;
    },
    [arrFilterOrderDrug],
  );

  const onClose = () => {
    setEdit(false);
    setIsAdd(false);
    setSelectDrugEdit({});
    onChooseDrugActiveIngredient('', {});
    onChooseConceptDrug('', {});
  };

  const selectUnit = useMemo(() => {
    return selectedConceptDrug?.detail?.doseLimitUnits?.display;
  }, [selectedConceptDrug]);

  return (
    <div className="drugOrder-Contaier">
      <div className="drugOrder-content">
        <Row className="drugOrder-title ">
          <span className="pl-6">Đơn thuốc</span>
        </Row>
        <hr />
        {arrFilterOrderDrug && arrFilterOrderDrug?.length > 0 ? <hr /> : ''}
        {arrFilterOrderDrug?.length > 0 ? (
          arrFilterOrderDrug.map((item, key) => {
            return (
              <Row className="row-1 py-2" key={String(key)}>
                <Col xs={24}>
                  <div className="drugOrder-section">
                    <div className="drugOrder-section__title ">
                      <span>Đơn thuốc {key + 1}</span>
                    </div>
                    {!isEdit && isEditAvailability && (
                      <Button
                        className="rounded-md mr-2"
                        type="default"
                        onClick={() => onClickEditItem(item, key)}
                      >
                        Sửa
                      </Button>
                    )}
                  </div>
                  <div className="drugOrder-section__content">
                    <div className="drugOrder-section__content_inside">
                      {isEdit && key === indexEdit ? (
                        <DrugForm
                          onFinish={onFinishEdit}
                          initData={item}
                          listOptionDrugActiveIngredient={
                            listOptionDrugActiveIngredient
                          }
                          onClose={onClose}
                          onChooseDrugActiveIngredient={
                            onChooseDrugActiveIngredient
                          }
                          loading={appointmentReducer?.isLoadingOrderDrug}
                          listOptionDrug={listOptionDrug}
                          onChooseConceptDrug={onChooseConceptDrug}
                          unit={selectUnit}
                        />
                      ) : (
                        <div>
                          <div className=" m-0 mb-1 flex justify-between">
                            <span className="text-have-color-#555555 flex-1">
                              {item?.drug?.display !== 'Other'
                                ? `${
                                    item?.drug?.concept?.display
                                  } (${item?.drugNonCode ||
                                    item?.drug?.display})`
                                : item?.drugNonCode || item?.drug?.display}
                            </span>
                            <span className="text-have-color-#555555">
                              {item?.quantity} x{' '}
                              {item?.drugUnits ||
                                item?.drug?.doseLimitUnits?.display}
                            </span>
                          </div>
                          {item.dosingInstructions && (
                            <div className="pb-3">
                              <span className="text-have-color-#555555">
                                {displayDoctorRemind(item)}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <Row className="row-1">
            <Col xs={24}>
              <div className="anamnesis-section">
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {isEditAvailability && !isEdit && (
        <div className="drugOrder-add-container">
          {isAdd ? (
            <DrugForm
              onFinish={onFinish}
              listOptionDrugActiveIngredient={listOptionDrugActiveIngredient}
              onClose={onClose}
              onChooseDrugActiveIngredient={onChooseDrugActiveIngredient}
              loading={appointmentReducer?.isLoadingOrderDrug}
              listOptionDrug={listOptionDrug}
              onChooseConceptDrug={onChooseConceptDrug}
              unit={selectUnit}
            />
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => {
                  if (!arrDiagnostic.length) {
                    return message.error(
                      'Bác sỹ cần đưa ra chẩn đoán trước khi thêm đơn thuốc',
                    );
                  }
                  setIsAdd(!isAdd);
                }}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DrugOrder;
