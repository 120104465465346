export const regexTransactionCode = /^[a-zA-Z0-9]*$/;
export const TYPE_TRANSACTION_VALUE = {
  ADMIN_ADD: 'ADMIN_ADD',
  ADMIN_DEDUCT: 'ADMIN_DEDUCT',
  PATIENT_BOOK_APPOINTMENT: 'PATIENT_BOOK_APPOINTMENT',
  REFUND_PATIENT: 'REFUND_PATIENT',
};

export const TYPE_TRANSACTION = {
  [TYPE_TRANSACTION_VALUE.ADMIN_ADD]: 'Cộng tiền',
  [TYPE_TRANSACTION_VALUE.ADMIN_DEDUCT]: 'Trừ tiền',
  [TYPE_TRANSACTION_VALUE.PATIENT_BOOK_APPOINTMENT]: 'Trừ tiền',
  [TYPE_TRANSACTION_VALUE.REFUND_PATIENT]: 'Hoàn tiền',
};

export const TYPE_TRANSACTION_REQUEST_VALUE = {
  BASE_TRANSACTION: 'basetransaction',
  APPOINTMENT_TRANSACTION: 'appointmenttransaction',
};

export const OPTIONS_TRANSACTION = [
  {
    value: TYPE_TRANSACTION_VALUE.ADMIN_ADD,
    label: 'Cộng tiền',
  },
  {
    value: TYPE_TRANSACTION_VALUE.ADMIN_DEDUCT,
    label: 'Trừ tiền',
  },
];

export const FORM_TRANSACTION = {
  TRANSFER: 'Chuyển khoản',
  VIMO: 'Vimo',
  // WALLET_D4U: 'Chuyển khoản',
};

export const FORM_TRANSACTION_VALUE = {
  TRANSFER: 'TRANSFER',
  VIMO: 'VIMO',
};

export const OPTIONS_FORM_TRANSACTION = [
  {
    value: FORM_TRANSACTION_VALUE.TRANSFER,
    label: 'Chuyển khoản',
  },
  // {
  //   value: FORM_TRANSACTION.VIMO,
  //   label: 'Vimo',
  // },
];

export const TYPE_STATUS_CUSTOMER_VALUE = {
  ACTIVE: 'ACTIVE',
  IN_ACTIVE: 'IN_ACTIVE',
};

export const OPTIONS_STATUS_CUSTOMER = {
  [TYPE_STATUS_CUSTOMER_VALUE.ACTIVE]: 'Hoạt động',
  [TYPE_STATUS_CUSTOMER_VALUE.IN_ACTIVE]: 'Khóa',
};

export const OPTIONS_LIST_STATUS_CUSTOMER = [
  {
    label: 'Hoạt động',
    value: TYPE_STATUS_CUSTOMER_VALUE.ACTIVE,
  },
  {
    label: 'Khóa',
    value: TYPE_STATUS_CUSTOMER_VALUE.IN_ACTIVE,
  },
];
