import React, { FC, useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
// import * as _ from 'lodash';
import { Form, Input, Button, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import * as holidayActions from '../../store/holiday/holiday.action';
import './ModalEditHoliday.scss';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps: any;
}

const ModalEditHoliday: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [idSelected] = useState<any>(
    dataProps !== undefined ? dataProps?.uuid : '',
  );

  const [sDate, setSDate] = useState<any>();

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const disabledStartDate = current => {
    return current && current < moment().startOf('day');
  };

  const disabledEndDate = current => {
    return (
      (current && current < moment().startOf('day')) ||
      (current && current < sDate)
    );
  };

  const dataHoliday = useSelector((state: any) => state.holidayReducer);

  const getValStartDate = current => {
    setSDate(current);
  };

  useEffect(() => {
    form.setFieldsValue({
      holidayName: dataProps?.holidayName,
      startDate: moment(dataProps?.startDate),
      endDate: moment(dataProps?.endDate),
    });
  }, [form, dataProps]);

  useEffect(() => {
    setSDate(moment(dataProps?.startDate));
  }, [dataProps]);

  const onFinish = (value: any) => {
    const { startDate, endDate, holidayName } = value;
    const newSD = startDate?.format('YYYY-MM-DD');
    const newED = endDate?.format('YYYY-MM-DD');
    const holidayNameTrim = holidayName.trim();
    const body = {
      uuid: idSelected,
      params: {
        ...value,
        holidayName: holidayNameTrim,
        startDate: newSD,
        endDate: newED,
      },
    };
    dispatch(holidayActions.handleUpdateHolidayRequested(body));
  };

  if (dataHoliday?.errorEdit === false) {
    onHide();
    dispatch(holidayActions.handleDisplayModalEdit());
  }

  return (
    <Modal
      title={title || 'Chỉnh sửa kỳ lễ'}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        form={form}
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-edit-holiday"
        // initialValues={initVal}
        requiredMark={false}
      >
        <Form.Item
          label="Tên kỳ lễ"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 300, message: 'Vui lòng nhập đúng thông tin!' },
          ]}
          name="holidayName"
        >
          <Input placeholder="Nhập tên kỳ lễ" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledStartDate}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            onChange={getValStartDate}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            format="DD-MM-YYYY"
            locale={locale}
            placeholder="Chọn ngày kết thúc"
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalEditHoliday;
