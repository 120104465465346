import React, { FC, useState, useEffect } from 'react';
import { Modal, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import numeral from 'numeral';
import * as doctorActions from '../../../store/doctor/doctor.action';
import './ModalCheckout.scss';

interface dataDetail {
  month?: string;
  total?: number;
  amount?: string;
  username?: string;
  status?: string;
}
type Props = {
  visible: boolean;
  handleCancel: (...args: any[]) => any;
  dataDetail: dataDetail;
};

const ModalCheckout: FC<Props> = props => {
  const dispatch = useDispatch();
  const { visible, handleCancel, dataDetail } = props;
  const [date, setDate] = useState<any>();

  const convertDate = () => {
    const dateCheckout = dataDetail?.month?.slice(5, 7).replace('0', '');
    setDate(dateCheckout);
  };

  useEffect(() => {
    convertDate();
  }, [dataDetail]);

  const handleSubmit = () => {
    dispatch(
      doctorActions.checkoutFeeRequested(get(dataDetail, 'userId'), {
        month: get(dataDetail, 'month'),
      }),
    );
    handleCancel();
  };

  return (
    <Modal
      title="Thanh toán"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      className="modal-checkout"
    >
      <div className="confirm-modal-checkout">
        <span>
          Công ty đã chắc chắn thanh toán doanh thu tháng {date} cho bác sĩ{' '}
          {dataDetail?.username} số tiền:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {numeral(dataDetail?.amount).format('0,0')}
          </span>
          <span> VND</span>
        </span>
      </div>
      <div className="flex justify-center mt-2">
        <Button onClick={() => handleCancel()}>Đóng</Button>
        <Button
          className="ml-4"
          form="reset_password"
          key="submit"
          htmlType="submit"
          type="primary"
          onClick={() => handleSubmit()}
        >
          Đã thanh toán
        </Button>
      </div>
    </Modal>
  );
};

export default ModalCheckout;
