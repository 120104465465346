import { Image } from 'antd';
import config from 'configs';
import React, { FC } from 'react';
import './RequestImage.scss';

interface Props {
  images?: { id: string; name: string; orderId: string }[];
}

const RequestImage: FC<Props> = ({ images = [] }) => {
  const getImageUrl = (uuid: string) => {
    return `${config.BASE_API_URL}/v1/obs/${uuid}/value?t=${Date.now()}`;
  };
  const listImages = images.map(i => ({
    id: i.id,
    uri: getImageUrl(i.id),
    fileName: i.name,
    name: i.name,
    type: 'image',
  }));

  return (
    <Image.PreviewGroup
      preview={{
        current: 2,
        // @ts-ignore
        mask: <p>324</p>,
      }}
    >
      {listImages.map(item => (
        <Image
          className="imageStyle"
          width="100px"
          height="138px"
          src={item.uri}
          key={item.id}
        />
      ))}
    </Image.PreviewGroup>
  );
};

export default RequestImage;
