import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { IServicePackage } from 'models/store/servicePackage.model';

export interface ServicePackageReducerState {
  dataServicePackage: {
    totalPages: number;
    totalRecords: number;
    datas: IServicePackage[];
  };
  isLoadingServicePackage: boolean;
  isLoading: boolean;
  dataServicePackageDetail: IServicePackage;
  dataAddServicePackage: IServicePackage;
  dataEditServicePackage: IServicePackage;
  isLoadingUploadAvatar: boolean;
  dataUploadAvatar: any;
  isLoadingLock: boolean;
  isLoadingUploadCover: boolean;
  dataUploadCover: any;
  dataDeleteServicePackage: any;
  isLoadingDelete: boolean;
}

const initialState: ServicePackageReducerState = {
  dataServicePackage: {
    totalPages: 0,
    totalRecords: 0,
    datas: [] as IServicePackage[],
  },
  isLoadingServicePackage: false,
  isLoading: false,
  dataServicePackageDetail: {} as IServicePackage,
  isLoadingUploadAvatar: false,
  dataUploadAvatar: {} as any,
  dataAddServicePackage: {} as IServicePackage,
  dataEditServicePackage: {} as IServicePackage,
  isLoadingUploadCover: false,
  dataUploadCover: {} as any,
  isLoadingDelete: false,
  isLoadingLock: false,
  dataDeleteServicePackage: {} as any,
};

const servicePackageReducer = handleActions<any, ActionModel>(
  {
    GET_SERVICE_PACKAGE_REQUESTED: state => {
      return {
        ...state,
        isLoadingServicePackage: true,
      };
    },

    GET_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataServicePackage: action.payload,
        isLoadingServicePackage: false,
      };
    },

    GET_SERVICE_PACKAGE_FAILED: state => {
      return {
        ...state,
        isLoadingServicePackage: false,
      };
    },
    GET_DETAIL_SERVICE_PACKAGE_REQUESTED: state => {
      return {
        ...state,
        isLoading: true,
      };
    },

    GET_DETAIL_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataServicePackageDetail: action.payload,
        isLoading: false,
      };
    },

    GET_DETAIL_SERVICE_PACKAGE_FAILED: state => {
      return {
        ...state,
        isLoading: false,
      };
    },

    ADD_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    ADD_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddServicePackage: action.payload,
      };
    },
    ADD_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    EDIT_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    EDIT_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataEditServicePackage: action.payload,
      };
    },
    EDIT_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return { ...state, dataUploadAvatar: action.payload };
    },
    HANDLE_UPLOAD_AVATAR_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return { ...state, dataUploadCover: action.payload };
    },
    HANDLE_UPLOAD_COVER_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    GET_AVATAR_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    GET_AVATAR_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return { ...state, avatar: action.payload };
    },
    GET_AVATAR_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    GET_COVER_SERVICE_PACKAGE_REQUESTED: state => {
      return state;
    },
    GET_COVER_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return { ...state, cover: action.payload };
    },
    GET_COVER_SERVICE_PACKAGE_FAILED: error => {
      return error;
    },
    HANDLE_DELETE_SERVICE_PACKAGE_REQUESTED: state => {
      return { ...state, isLoadingDelete: true };
    },
    HANDLE_DELETE_SERVICE_PACKAGE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
        dataDeleteServicePackage: action.payload,
      };
    },
    HANDLE_DELETE_SERVICE_PACKAGE_FAILED: state => {
      return { ...state, isLoadingDelete: false };
    },
    HANDLE_LOCK_SERVICE_PACKAGE_REQUESTED: state => {
      return { ...state, isLoadingLock: true };
    },
    HANDLE_LOCK_SERVICE_PACKAGE_SUCCEEDED: state => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },
    HANDLE_LOCK_SERVICE_PACKAGE_FAILED: state => {
      return { ...state, isLoadingLock: false };
    },
  },
  initialState,
  { prefix: 'servicePackage' },
);

export default servicePackageReducer;
