import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
// import { PromotionModel } from 'models/store/promotion.model';
import { DoctorModel } from 'models/store/doctor.model';
import { ResponseSpecialist } from 'models/store/specialist.model';

export interface CustomSettingParams {
  loading: boolean;
  error: any;
  getTimeAlready: any;
  dataDeleteSpecialist: any;
  isLoadingAddInformation: boolean;
  isLoadingEditInformation: boolean;
  dataAddInformation: ResponseSpecialist;
  dataSpecialist: DoctorModel;
  isLoadingSpecialist: boolean;
}

const initialState: CustomSettingParams = {
  loading: false,
  error: [],
  getTimeAlready: '',
  dataDeleteSpecialist: {} as any,
  isLoadingAddInformation: false,
  isLoadingEditInformation: false,
  dataSpecialist: {} as DoctorModel,
  dataAddInformation: {} as ResponseSpecialist,
  isLoadingSpecialist: false,
};

const customSetting = handleActions<any, ActionModel>(
  {
    HANDLE_CUSTOM_SETTING_REQUESTED: state => {
      return {
        ...state,
        loading: true,
      };
    },

    HANDLE_CUSTOM_SETTING_SUCCEEDED: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },

    HANDLE_CUSTOM_SETTING_FAILED: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },

    HANDLE_GET_TIME_CUSTOMS_REQUESTED: state => {
      return {
        ...state,
        loading: true,
      };
    },
    HANDLE_GET_TIME_CUSTOMS_SUCCEEDED: (state, action) => {
      return {
        ...state,
        loading: false,
        getTimeAlready: action.payload,
      };
    },
    HANDLE_DELETE_SPECIALIST_REQUESTED: state => {
      return {
        ...state,
        isLoadingDelete: true,
      };
    },
    HANDLE_GET_TIME_CUSTOMS_FAILED: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    GET_SPECIALIST_CUSTOMS_REQUESTED: state => {
      return {
        ...state,
        isLoadingSpecialist: true,
      };
    },
    GET_SPECIALIST_CUSTOMS_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataSpecialist: action.payload,
        isLoadingSpecialist: false,
      };
    },
    GET_SPECIALIST_CUSTOMS_FAILED: state => {
      return {
        ...state,
        isLoadingSpecialist: false,
      };
    },
    HANDLE_DELETE_SPECIALIST_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
        dataDeleteSpecialist: action.payload,
      };
    },
    HANDLE_DELETE_SPECIALIST_FAILED: state => {
      return {
        ...state,
        isLoadingDelete: false,
      };
    },
    HANDLE_EDIT_SPECIALIST_REQUESTED: (state: any) => {
      return {
        ...state,
        isLoadingEditInformation: true,
        errorAdd: '',
      };
    },
    HANDLE_EDIT_SPECIALIST_SUCCEEDED: (state: any, action) => {
      return {
        ...state,
        isLoadingEditInformation: false,
      };
    },

    HANDLE_EDIT_SPECIALIST_FAILED: (state: any) => {
      return {
        ...state,
        isLoadingEditInformation: false,
      };
    },
    HANDLE_ADD_SPECIALIST_REQUESTED: (state: any) => {
      return {
        ...state,
        isLoadingAddInformation: true,
      };
    },
    HANDLE_ADD_SPECIALIST_SUCCEEDED: (state: any, action) => {
      return {
        ...state,
        dataAddInformation: action.payload,
        isLoadingAddInformation: false,
      };
    },

    HANDLE_ADD_SPECIALIST_FAILED: (state: any) => {
      return {
        ...state,
        isLoadingAddInformation: false,
        errorAdd: true,
      };
    },
  },

  initialState,
  { prefix: 'customSetting' },
);

export default customSetting;
