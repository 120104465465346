import React, { useState, FC, useEffect } from 'react';
import {
  Input,
  Form,
  Button,
  Row,
  Col,
  Radio,
  Select,
  // Tooltip,
  InputNumber,
  Upload,
  Checkbox,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  regex,
  regexTrainning,
  regexFee,
  regexFilterFee,
  regexBonus,
  regexSpecialist,
  // getUrl,
} from '../doctor.const';
import * as doctorInfoActions from '../../../store/doctor/doctor.action';
import './DetailDoctor.scss';
// import CONFIG from '../../../configs/staging.config';
import config from '../../../configs';

type DetailDoctor = {};

interface ParamsTransactionDoctor {
  v?: string;
}
// const CheckboxGroup = Checkbox.Group;
// const defaultCheckedList = ['Apple', 'Orange'];

const DetailDoctor: FC = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<any>();
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };
  const [ParamsTransactionDoctor] = useState<ParamsTransactionDoctor>({
    v: 'full',
  });
  const [urlAvatar, setUrlAvatar] = useState<any>('');
  const [checkList, setCheckList] = useState<any>([]);
  const [urlCover, setUrlCover] = useState<any>('');
  const [fileListAvatar, setFileListAvatar] = useState<any>();
  const [fileListCover, setFileListCover] = useState<any>();
  const [baseUrl, setBaseUrl] = useState<any>(config.BASE_IMAGE_URL);
  const [initVal] = useState<any>({
    status: '',
    username: '',
    place: [''],
    phone: '',
    email: '',
    name: '',
    unit: '',
    description: '',
    yearExperience: '',
    trainningProcess: '',
    workPlace: '',
    majors: '',
    fee: '',
    specialist: [],
  });
  const dataDetail = useSelector(
    (state: any) => state.doctorReducer.dataDetailDoctor,
  );

  const getDetailDoctor = () => {
    dispatch(
      doctorInfoActions.getDetailDoctorRequested(id, ParamsTransactionDoctor),
    );
  };

  useEffect(() => {
    getDetailDoctor();
  }, [id]);
  useEffect(() => {
    if (dataDetail !== undefined) {
      const trainningProcess = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Working process'),
      );
      const trainning = trainningProcess?.map(item =>
        item?.display?.replace(regex, ''),
      );
      const work = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Training process'),
      );
      const workFinal = work?.map(item =>
        item?.display?.replace(regexTrainning, ''),
      );

      const fee = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Examination fee'),
      );

      const feeFinal = fee?.map(item => item?.display.replace(regexFee, ''));

      const getMajors = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Health facilities:'),
      );
      const bonus = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Percent Doctor Profit:'),
      );
      const bonusValue = bonus?.map(item =>
        item?.display?.replace(regexBonus, ''),
      );

      const getSpecialist = dataDetail?.attributes?.filter(item =>
        item?.display?.includes('Specialist:'),
      );
      const dataSpecialist = getSpecialist?.map(item =>
        item?.display?.replace(regexSpecialist, ''),
      );

      setCheckList(dataSpecialist);

      if (feeFinal !== undefined) {
        const feeS = feeFinal[0]?.replace(regexFilterFee, '');
        form.setFieldsValue({
          status: dataDetail?.user?.status,
          outstanding: dataDetail?.outstanding,
          username: dataDetail?.user?.display,
          place: trainning,
          phone: dataDetail?.user?.phoneNumber,
          email: dataDetail?.user?.email,
          name: dataDetail?.person?.display,
          unit: dataDetail?.rank,
          description: dataDetail?.description,
          yearExperience: dataDetail?.yearExperience,
          trainningProcess: workFinal[0],
          workPlace: dataDetail?.workPlace,
          majors: getMajors[0]?.value?.display,
          fee: feeS,
          bonus: bonusValue[0],
          // specialist: getSpecialist,
        });
      }
    }
  }, [form, dataDetail]);

  useEffect(() => {
    setBaseUrl(config?.BASE_IMAGE_URL);
  }, [config]);

  const getDoctorDetail = () => {
    dispatch(
      doctorInfoActions.getDoctorDetailByIdRequested(
        id,
        ParamsTransactionDoctor,
      ),
    );
  };

  const getImage = () => {
    const arrAvatar = dataDetail?.user?.attributes.filter(item =>
      item?.display?.includes('Avatar'),
    );

    const arrCover = dataDetail?.user?.attributes.filter(item =>
      item?.display?.includes('Cover'),
    );
    if (arrCover !== undefined) {
      const idCover = arrCover[0]?.uuid;

      setUrlCover(`${baseUrl}/ws/rest/v1/userAttribute/${idCover}/view`);
    }
    if (arrAvatar !== undefined) {
      const idAvatar = arrAvatar[0]?.uuid;
      setUrlAvatar(`${baseUrl}/ws/rest/v1/userAttribute/${idAvatar}/view`);
    }
  };

  useEffect(() => {
    if (urlAvatar !== undefined) {
      setFileListAvatar([
        {
          uid: '1',
          name: 'avatar',
          status: 'done',
          url: urlAvatar,
        },
      ]);
    }
  }, [urlAvatar]);

  useEffect(() => {
    setFileListCover([
      {
        uid: '2',
        name: 'cover',
        status: 'done',
        url: urlCover,
      },
    ]);
  }, [urlCover]);

  useEffect(() => {
    getDoctorDetail();
  }, [id]);

  useEffect(() => {
    getImage();
  }, [dataDetail]);
  return (
    <div className="detail-doctor">
      <Row>
        <Col xs={24}>
          <h1 className="mb-5">Xem chi tiết thông tin bác sĩ</h1>
        </Col>
      </Row>
      <div className="flex image-container m-0 pb-5 box-border">
        <div className="box-avatar w-2/5 ">
          {fileListAvatar && (
            <Form name="basic">
              <Form.Item
                labelAlign="left"
                name="avatar"
                label="Ảnh đại diện"
                className="form-item avatar"
              >
                {fileListAvatar[0]?.url?.includes(undefined) === false ? (
                  <Upload
                    fileList={fileListAvatar}
                    disabled={true}
                    accept="image/*"
                    listType="picture-card"
                    className="upload-image pb-5"
                  />
                ) : (
                  <div className="blank_image" />
                )}
              </Form.Item>
            </Form>
          )}
        </div>
        <div className="box-cover w-2/5" style={{ paddingLeft: 20 }}>
          {fileListCover !== undefined && (
            <Form name="basic" className="m-0 p-0">
              <Form.Item
                labelAlign="left"
                name="cover"
                label="Ảnh bìa"
                className="form-item cover"
              >
                {fileListCover[0]?.url?.includes(undefined) === false ? (
                  <Upload
                    fileList={fileListCover}
                    disabled={true}
                    accept="image/*"
                    name="image"
                    listType="picture-card"
                    className="upload-image pb-5"
                  />
                ) : (
                  <div className="blank_image" />
                )}
              </Form.Item>
            </Form>
          )}
        </div>
      </div>

      <Form
        {...formItemLayout}
        name="basic"
        initialValues={initVal}
        className="modal-detail-customer"
        form={form}
        style={{ paddingTop: 30 }}
      >
        <Form.Item label="Trạng thái" name="status" className="form-item">
          <Radio.Group disabled className="ant-radio-wrapper">
            <Radio value="ACTIVE">
              <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Hoạt động</span>
            </Radio>
            <Radio value="IN_ACTIVE">
              <span style={{ color: 'rgba(0, 0, 0, 0.65)' }}>Khóa</span>
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Bác sĩ tiêu biểu"
          name="outstanding"
          className="form-item"
          labelAlign="left"
        >
          <Radio.Group disabled className="ant-radio-wrapper">
            <Radio value={true}>Có</Radio>
            <Radio value={false}>Không</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item label="Username" name="username" className="form-item">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item label="Số điện thoại" name="phone" className="form-item">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item label="Email" name="email" className="form-item">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item label="Họ và tên" name="name" className="form-item">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item className="form-item" label="Học hàm, học vị" name="unit">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item className="form-item" label="Nơi công tác" name="workPlace">
          <Input disabled className="input-default" />
        </Form.Item>
        <Form.Item className="form-item" label="Quá trình công tác">
          <Form.List name="place">
            {(fields, { add, remove }) => {
              return (
                <div className="mb-0">
                  {fields.map((field, index) => (
                    <Form.Item required={false} key={field.key}>
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        noStyle
                      >
                        <Input disabled className="input-default" />
                      </Form.Item>
                    </Form.Item>
                  ))}
                </div>
              );
            }}
          </Form.List>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Thông tin mô tả"
          name="description"
        >
          <TextArea disabled autoSize={{ maxRows: 5, minRows: 3 }} />
        </Form.Item>
        <div className="flex exp">
          <Form.Item
            labelAlign="left"
            className="form-item test"
            label="Số năm kinh nghiệm"
            name="yearExperience"
          >
            <Input className="input-default" disabled type="number" />
          </Form.Item>
          <span className="title-year">Năm</span>
        </div>

        <Form.Item className="form-item" label="Cơ sở y tế" name="majors">
          <Select disabled className="input-default">
            <Option value="">{dataDetail?.majors}</Option>
          </Select>
        </Form.Item>
        <Form.Item
          className="form-item"
          label="Quá trình đào tạo"
          name="trainningProcess"
        >
          <TextArea
            disabled
            style={{ width: '100%' }}
            autoSize={{ maxRows: 5, minRows: 3 }}
          />
        </Form.Item>
        <div className="flex fee">
          <Form.Item label="Phí khám" name="fee" className="form-item fee-test">
            <InputNumber
              disabled
              formatter={(value: any) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              maxLength={10}
              className="input-default"
            />
          </Form.Item>
          <span className="title-money">VND</span>
          <Link
            onClick={() =>
              history.push(`/app/doctor/information/detail-fee/${id}`)
            }
            to="#"
          >
            <span className="label-view-detail">Xem chi tiết</span>
          </Link>
        </div>
        <div className="flex fee">
          <Form.Item
            label="Phần trăm được hưởng"
            name="bonus"
            className="form-item fee-test"
          >
            <InputNumber disabled maxLength={10} className="input-default" />
          </Form.Item>
          <span className="title-money">%</span>
        </div>
        <div className="wrapListCheckbox">
          <Form.Item
            className="form-item"
            label="Chuyên khoa"
            name="specialist"
            labelCol={{ span: 6 }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              className="checkbox-cp"
            >
              {checkList?.map((item, index) => (
                <Checkbox checked={true} disabled={true} key={index}>
                  {item}
                </Checkbox>
              ))}
            </div>
          </Form.Item>
        </div>
        <div className="button-container">
          <Button
            type="default"
            className="mr-2"
            onClick={() => history.push(`/app/doctor/information`)}
          >
            Quay lại
          </Button>

          <Button
            type="primary"
            className="ml-2"
            onClick={() =>
              history.push(`/app/doctor/information/edit-doctor/${id}`)
            }
          >
            Sửa
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DetailDoctor;
