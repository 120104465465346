import { Spin, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import moment from 'moment';
import { getReferralFile } from 'api/promotion';
import ExportFilePromotion from 'components/Promotion/ExportFilePromotion';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailReferralRequested } from 'store/referral/referral.action';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps: any;
}
const initStartIndex: number = 1;
interface ParamsReferralModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  q?: string;
  sortField?: string;
  sortDirection?: string;
  length?: any;
  referralCode?: any;
  calculated?: any;
}

const ModalViewReferral: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const dispatch = useDispatch();
  const dataDetail = useSelector((state: any) => state.referralReducer);
  const [paramsReferral, setParamsReferral] = useState<ParamsReferralModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    calculated: true,
    sortDirection: 'DESC',
    referralCode: dataProps.referralCode,
  });
  const columns = [
    {
      title: 'STT',
      dataIndex: 'STT',
      key: 'STT',
      render: (value, row, index) => {
        if (
          paramsReferral?.startIndex !== undefined &&
          paramsReferral?.limit !== undefined &&
          paramsReferral
        ) {
          return (
            (paramsReferral?.startIndex - 1) * paramsReferral?.limit + index + 1
          );
        }
      },
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Khách hàng',
      dataIndex: 'username',
      key: 'username',
    },

    {
      title: 'Mã giới thiệu',
      dataIndex: 'referralCode',
      key: 'referralCode',
    },
    {
      title: 'Ngày sử dụng',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (value, row, index) => {
        return moment(value).format('DD-MM-YYYY');
      },
    },
  ];
  useEffect(() => {
    dispatch(getDetailReferralRequested(paramsReferral));
  }, [paramsReferral]);

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
      calculated: current === 1,
    };
    setParamsReferral({
      ...paramsReferral,
      ...body,
    });
  };
  const getFileCsv = () => {
    const params = {
      file: 'csv',
      referralCode: dataProps.referralCode,
    };
    getReferralFile(params).then(result => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `LichSuGioiThieu${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  const getFileExcel = () => {
    const params = {
      file: 'excel',
      referralCode: dataProps.referralCode,
    };
    getReferralFile(params).then(result => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `LichSuGioiThieu${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  console.log(dataDetail);

  return (
    <div>
      <Modal
        title={title || 'Chi tiết khách hàng sử dụng'}
        centered
        visible={show || true}
        onOk={onHide}
        onCancel={onHide}
        width={1000}
        footer={null}
        className="z-9999"
      >
        <ExportFilePromotion
          getCsvFile={getFileCsv}
          getExcelFile={getFileExcel}
        />
        <Table
          dataSource={dataDetail?.detailReferral?.results}
          columns={columns}
          pagination={{
            current: paramsReferral.startIndex,
            pageSize: paramsReferral.limit,
            total: dataDetail?.detailReferral?.totalCount || 10,
            showSizeChanger: true,
            locale: { items_per_page: '/Trang' },
          }}
          onChange={onChangeTable}
          loading={{
            spinning: dataDetail?.isLoadingReferral,
            indicator: <Spin />,
            tip: 'Đang tải...',
          }}
        />
      </Modal>
    </div>
  );
};
export default ModalViewReferral;
