import React, { FC, useState, useEffect } from 'react';
import { Card, Form, Row, Col, Input, Table, InputNumber } from 'antd';
import './TransactionDetail.scss';
import { useParams } from 'react-router';

import { Link } from 'react-router-dom';
import { formatMoney } from 'helpers/utils';
import * as _ from 'lodash';
import ModalCheckout from 'components/Doctor/Transaction/ModalCheckout';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import * as doctorInfoActions from '../../store/doctor/doctor.action';

import * as STATUS from './doctor.const';

type TransactionDetailOfDoctor = {
  code?: string;
  name?: string;
  username?: string;
  phone?: string;
};

interface initParams {
  status?: string;
}

interface passDoctorDetail {
  month?: string;
  amount?: number;
  total?: number;
  status?: string;
  name?: string;
}

interface ParamsInformationDoctorModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  q?: string;
}

const TransactionDetailOfDoctor: FC = () => {
  const { id } = useParams<any>();
  const [form] = Form.useForm();
  const [selectedDoctor, setSelectedDoctor] = useState<any>();
  const [showModalCheckout, setShowModalCheckout] = useState<boolean>(false);
  const [init] = useState<any>({
    fee: '',
    username: '',
    name: '',
    phone: '',
  });
  const [idChoose, setIdChoose] = useState<string>();

  const dispatch = useDispatch();

  const dataDetailWalletDoctor = useSelector(
    (state: any) => state.doctorReducer.dataDetailWalletDoctor,
  );

  const dataDetailTransactionDoctor = useSelector(
    (state: any) => state.doctorReducer.dataDetailDoctor,
  );

  const [ParamsTransactionDoctor] = useState<ParamsInformationDoctorModel>({
    v: 'full',
  });

  useEffect(() => {
    if (dataDetailTransactionDoctor !== undefined) {
      form.setFieldsValue({
        username: _.get(dataDetailTransactionDoctor, 'user.display', ''),
        name: _.get(dataDetailTransactionDoctor.person, 'display', ''),
        phone: _.get(dataDetailTransactionDoctor, 'user.phoneNumber', ''),
        fee: _.get(dataDetailTransactionDoctor, 'person.wallet.balance', ''),
      });
    }
  }, [
    dataDetailTransactionDoctor?.person?.wallet?.balance,
    form,
    dataDetailWalletDoctor,
  ]);

  const getDoctorTransactionDetail = () => {
    dispatch(
      doctorInfoActions.getDetailDoctorRequested(id, ParamsTransactionDoctor),
    );
  };

  useEffect(() => {
    getDoctorTransactionDetail();
  }, [id, dataDetailTransactionDoctor?.person?.wallet?.balance]);

  useEffect(() => {
    if (
      dataDetailTransactionDoctor &&
      dataDetailTransactionDoctor !== undefined
    ) {
      setIdChoose(dataDetailTransactionDoctor?.user?.uuid);
    }
  }, [dataDetailTransactionDoctor]);

  const getDetailWalletDoctorEachMonth = () => {
    dispatch(
      doctorInfoActions.getDetailWalletDoctorRequested({
        idChoose,
      }),
    );
  };

  useEffect(() => {
    if (idChoose && idChoose !== undefined) {
      getDetailWalletDoctorEachMonth();
    }
  }, [idChoose, form]);

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  const handleCheckout = record => {
    setSelectedDoctor(record);
    setShowModalCheckout(true);
  };
  const columns = [
    {
      title: 'STT',
      render: (value, row, index) => {
        if (value && value !== undefined) {
          return index + 1;
        }
      },
    },
    {
      title: 'Thu thập tháng',
      render: value => {
        const monthChoose = value?.month;
        if (monthChoose && monthChoose !== undefined) {
          return monthChoose
            .split('-')
            .reverse()
            .join('-');
        }
      },
    },
    {
      title: 'Doanh thu cần thanh toán(VND)',
      render: (value, row, index) => {
        return formatMoney(_.get(value, 'amount', 0));
      },
    },
    {
      title: 'Số cuộc hẹn hoàn thành(Cuộc hẹn)',
      render: (value, row, index) => {
        return value?.total;
      },
    },
    {
      title: 'Trạng thái',
      render: (value, row, index) => {
        if (value && value !== undefined) {
          if (value?.status === STATUS.PAID_STATUS) {
            return 'Đã thanh toán';
          }
          if (value?.status === STATUS.WAITING_STATUS) {
            return 'Chờ thanh toán';
          }
          if (value?.status === STATUS.PENDING_STATUS) {
            return 'Sắp thanh toán';
          }
        }
      },
    },
    {
      render: (value, row, index) => {
        if (value && value !== undefined) {
          if (value?.status === STATUS.WAITING_STATUS) {
            return (
              <Link to="#" onClick={() => handleCheckout(row)}>
                Thanh toán
              </Link>
            );
          }
        }
      },
    },
  ];

  return (
    <div className="doctor-transaction">
      <Card>
        <Row>
          <Col xs={24} className="text-center ">
            <h1>Xem chi tiết</h1>
          </Col>
        </Row>
        <Row className="pt-10">
          <Col xs={20}>
            <Form {...formItemLayout} initialValues={init} form={form}>
              <Form.Item
                labelAlign="left"
                label="Số dư"
                name="fee"
                className="form-item"
              >
                <InputNumber
                  disabled
                  formatter={(value: any) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  className="input"
                />
              </Form.Item>
              <div className="label-money">
                <span>VND</span>
              </div>

              <Form.Item
                labelAlign="left"
                label="Username"
                name="username"
                className="form-item"
              >
                <Input className="input" disabled />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Họ và tên "
                name="name"
                className="form-item"
              >
                <Input className="input" disabled />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                label="Số điện thoại"
                name="phone"
                className="form-item"
              >
                <InputMask
                  disabled
                  className="ant-input"
                  mask="9999 999 999"
                  maskChar=""
                  style={{ width: '50%' }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>

      <Row>
        <div className="title-table">
          <h1 className="mb-2 mt-2">Danh sách thanh toán</h1>
        </div>
        <Col xs={24}>
          <Table
            columns={columns}
            pagination={false}
            dataSource={dataDetailWalletDoctor}
          />
          {selectedDoctor && (
            <ModalCheckout
              visible={showModalCheckout}
              handleCancel={() => setShowModalCheckout(false)}
              dataDetail={{
                ...selectedDoctor,
                username: init?.name,
                userId: idChoose,
              }}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TransactionDetailOfDoctor;
