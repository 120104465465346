export const SORT_FIELD = {
  DATE_CREATED: 'DATE_CREATED',
  DATE_STARTED: 'DATE_STARTED',
  DATE_CHANGED: 'DATE_CHANGED',
};
export enum AppointmentFiled {
  reasonExamination = 'reasonExamination',
  reason = 'reason',
  historyExamination = 'historyExamination',
  askSick = 'askSick',
  medicalExamination = 'medicalExamination',
  diagnostic = 'diagnostic',
  request = 'request',
  prescription = 'prescription',
  usage = 'usage',
  follow = 'follow',
  followResult = 'followResult',
  pulse = 'pulse',
  temperature = 'temperature',
  bloodPressur = 'bloodPressure',
  breathing = 'breathing',
  weight = 'weight',
  clinicalExamination = 'clinicalExamination',
  allergy = 'allergy',
  systolicBloodPressure = 'systolicBloodPressure',
  diastolicBloodPressure = 'diastolicBloodPressure',
  drugOrders = 'drugOrders',
  height = 'height',
  oldExaminationResult = 'oldExaminationResult',
}
export enum askSickEnum {
  symptom = 'symptom',
  anamnesis = 'anamnesis',
  familyHistory = 'familyHistory',
  chronicDiseases = 'chronicDiseases',
  wine = 'wine',
  cigarette = 'cigarette',
  allergy = 'allergy',
  usedDrug = 'usedDrug',
}
export enum Concept {
  clinicalExamination = 'd7681547-408a-4ced-b21f-45f0ce1307a7',
  usage = '6ddca7a8-8d6a-45a8-ab1b-743fc5c4058a',
  weight = '5089AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  temperature = '5088AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  pulse = '5087AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  height = '5090AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  breathing = '5242AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  systolicBloodPressure = '5085AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  diastolicBloodPressure = '5086AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  symptom = '7e6f278c-b950-437b-9a63-018dc1f0dee9',
  anamnesis = '',
  familyHistory = 'ae6b4504-3f98-4387-a34d-29a7cfb43152',
  chronicDiseases = '',
  wine = '7240bd9c-cef3-4fef-9188-a135f0b4ad2a',
  cigarette = 'd7ea7a47-33a6-4f44-b53e-3fdc178f5218',
  allergy = '',
  usedDrug = 'c4e14c9b-6a71-4ae3-afa1-3ce34ba89f34',
}
export enum Status {
  PRELIMINARY = 'PRELIMINARY',
}
export enum Interpretation {
  NORMAL = 'NORMAL',
}

export const AttributePrefix = {
  TELEPHONE: 'Telephone Number = ',
  AVATAR: 'Avatar: ',
};

export const GENDER = {
  M: 'Nam',
  F: 'Nữ',
  Other: 'Khác',
};

export enum OBS_PREFIX {
  TEMPERATURE = 'TEMPERATURE (C): ',
  WEIGHT = 'WEIGHT (KG): ',
  HEIGHT = 'HEIGHT (CM): ',
  PULSE = 'PULSE: ',
  BLOOD_OXYGEN_SATURATION = 'BLOOD OXYGEN SATURATION: ',
  DIASTOLIC_BLOOD_PRESSURE = 'DIASTOLIC BLOOD PRESSURE: ',
  SYSTOLIC_BLOOD_PRESSURE = 'SYSTOLIC BLOOD PRESSURE: ',
  RESPIRATORY_RATE = 'Respiratory rate: ',
  PHYSICAL_EXAMINATION = 'PHYSICAL EXAMINATION: ',
  ALCOHOL = 'ALCOHOL: ',
  CIGARETTE = 'CIGARETTE: ',
  INSTRUCTION = 'INSTRUCTION: ',
  SYMPTOM = 'SYMPTOM: ',
  DRUGS_ARE_IN_USE = 'DRUGS ARE IN USE: ',
  HISTORY_OF_FAMILY = 'HISTORY OF FAMILY: ',
  QUESTION = 'Group A&Q:',
  IMAGE = 'ATT IMAGE ATTACHMENT: ',
  OLD_EXAMINATION_IMAGE = 'Old Examination Result Image: ',
}
export enum CERTAINTY {
  CONFIRMED = 'CONFIRMED',
  PROVISIONAL = 'PROVISIONAL',
}
export enum APPOINTMENT_STATUS {
  SCHEDULED = 'SCHEDULED',
  INCONSULTATION = 'INCONSULTATION',
  WAITING_EXAMINATION = 'WAITING_EXAMINATION',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  MISSED = 'MISSED',
}
export enum ORDER_TYPE {
  REQUEST = '52a447d3-a64a-11e3-9aeb-50e549534c5e',
}
export enum REQUEST_TYPE {
  REQUEST = 'testorder',
}
export enum SETTING {
  REQUEST_CARE_SETTING = '6f0c9a92-6f24-11e3-af88-005056821db0',
}
export enum ProviderUUID {
  Doctor_4U = '41ede420-1d72-466b-b2a7-8c242e604320',
}

export enum APPOINTMENT_CODE {
  ALLERGEN = '5622AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
  CONDITION = '5622AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
}

export enum STATUS_FEEDBACK {
  OPEN = 'OPEN',
}
