import React, { FC, useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Table, Spin } from 'antd';
import { formatDateVNTime } from 'helpers/utils';
import { getExportHistoryFile } from 'api/promotion';
import moment from 'moment';

import * as promotionActions from '../../store/promotion/promotion.action';
import ExportFilePromotion from './ExportFilePromotion';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps: any;
}

interface ParamsPromotionModel {
  v?: string;
  totalCount?: boolean;
  startIndex?: number;
  limit?: number;
  startDate?: string | null;
  endDate?: string | null;
  promotionName?: string;
  sortField?: string;
  sortDirection?: string;
  length?: any;
  promotionCode?: string;
}

const initStartIndex: number = 1;

const ModalUserPromotion: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const dispatch = useDispatch();
  const promotionReducer = useSelector((state: any) => state.promotionReducer);

  const [paramsPromotion, setParamsPromotion] = useState<ParamsPromotionModel>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    sortDirection: 'DESC',
    promotionCode: dataProps.promotionCode,
  });
  useEffect(() => {
    dispatch(promotionActions.getPromotionHistoryRequested(paramsPromotion));
  }, [paramsPromotion]);

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;
    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsPromotion({
      ...paramsPromotion,
      ...body,
    });
  };
  const columns = [
    {
      title: 'STT',
      key: '1',

      dataIndex: 'key',
      render: (value, row, index) => {
        if (
          paramsPromotion?.startIndex !== undefined &&
          paramsPromotion?.limit !== undefined &&
          paramsPromotion
        ) {
          return (
            (paramsPromotion?.startIndex - 1) * paramsPromotion?.limit +
            index +
            1
          );
        }
      },
    },
    {
      title: 'Mã khuyến mại',
      key: 'name',
      width: '20%',
      render: (value, row, index) => {
        return value?.promotionCode;
      },
    },
    {
      key: 'date',
      title: 'Ngày sử dụng',

      render: (value, row, index) => {
        return formatDateVNTime(value?.dateCreated);
      },
    },
    {
      key: 'user',
      title: 'Khách hàng',

      render: (value, row, index) => {
        const display = value?.creator?.person?.display?.split('-');
        return display[display.length - 1];
      },
    },
    {
      key: 'codeAppointment',
      title: 'Mã đăng ký khám',

      render: (value, row, index) => {
        return value?.appointmentCode;
      },
    },
    {
      key: 'phoneNumber',
      title: 'Số điện thoại',

      render: (value, row, index) => {
        return value?.phoneNumber;
      },
    },
    {
      key: 'discountMoney',
      title: 'Giá trị sử dụng(VND)',

      render: (value, row, index) => {
        return value?.discountMoney;
      },
    },
  ];
  const getFileCsv = () => {
    const params = {
      file: 'csv',
      promotionCode: dataProps.promotionCode,
    };
    getExportHistoryFile(params).then(result => {
      const url = window.URL.createObjectURL(new Blob([result]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Lichsukhuyenmai${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.csv`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  const getFileExcel = () => {
    const params = {
      file: 'excel',
      promotionCode: dataProps.promotionCode,
    };

    getExportHistoryFile(params).then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Lichsukhuyenmai${moment(dataProps.dateCreated).format(
          'YYYY-MM-DD-HH-mm-ss',
        )}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };
  return (
    <Modal
      title={title || 'Chi tiết khách hàng'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={1000}
      footer={null}
      className="z-9999"
    >
      <div className="information">
        <Row>
          <Col xs={24}>
            <ExportFilePromotion
              getCsvFile={getFileCsv}
              getExcelFile={getFileExcel}
            />
            <Table
              columns={columns}
              rowKey={row => row.uuid}
              loading={{
                spinning: promotionReducer?.isLoadingPromotionHistory,
                indicator: <Spin />,
                tip: 'Đang tải...',
              }}
              dataSource={promotionReducer?.dataPromotionHistory?.results}
              pagination={{
                current: paramsPromotion.startIndex,
                pageSize: paramsPromotion.limit,
                total: promotionReducer?.dataPromotionHistory?.totalCount || 10,
                showSizeChanger: true,
                locale: { items_per_page: '/Trang' },
              }}
              onChange={onChangeTable}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalUserPromotion;
