import React, { FC, useState, useEffect } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import {
  Form,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Space,
  Upload,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as STATUS from '../../containers/Doctor/doctor.const';
import './ModalAddReferral.scss';
import * as referralAction from '../../store/referral/referral.action';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
}

const ModalAddReferral: FC<CProps> = ({ show, title, onHide }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const referralReducer = useSelector((state: any) => state.referralReducer);
  const [fileList, setfileList] = useState<any>([]);
  const [sDate, setSDate] = useState<any>();
  const [eDate, setEDate] = useState<any>();

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const getValStartDate = current => {
    if (eDate && eDate < current) {
      form.setFieldsValue({
        endDate: '',
      });
      setEDate(null);
    }
    setSDate(current);
  };

  const getValEndDate = current => {
    setEDate(current);
  };

  const disabledDate = current => {
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const disabledEndDate = current => {
    if (sDate) return current && current < sDate;
    return (
      current &&
      current <
        moment()
          .add(1, 'day')
          .startOf('day')
    );
  };

  const onFinish = values => {
    const { startDate, endDate, name, value, referralCode } = values;

    const nameTrim = `${name}`.trim();
    const codeTrim = `${referralCode}`.trim();
    if (nameTrim.length === 0) {
      return form.setFieldsValue({
        name: '',
      });
    }
    if (codeTrim.length === 0) {
      return form.setFieldsValue({
        referralCode: '',
      });
    }
    const newStartDate = startDate?.format('YYYY-MM-DD');
    const newEndDate = endDate?.format('YYYY-MM-DD');

    const body = {
      name: nameTrim,
      referralCode: codeTrim,
      value,
      startDate: newStartDate,
      endDate: newEndDate,
    };
    if (fileList.length != 0) {
      Object.assign(body, {
        attribute: { attributeType: '1b1eee4f-fc0b-40a9-955a-659cd92a6894' },
      });
    }
    const formData = new FormData();
    formData.append('parent', JSON.stringify(body));
    formData.append('file', fileList);

    dispatch(
      referralAction.handleCreateReferralRequested(
        fileList.length != 0 ? formData : body,
      ),
    );
  };

  useEffect(() => {
    if (referralReducer?.errorAdd === false) {
      onHide();
      dispatch(referralAction.handleDisplayModalAdd());
    }
  }, [referralReducer]);

  const initialValues = {
    name: '',
    startDate: '',
    endDate: '',
    numberOfUses: '',
  };

  return (
    <Modal
      title={title || 'Thêm mới chương trình giới thiệu'}
      centered
      visible={show || true}
      onOk={onHide}
      onCancel={onHide}
      width={800}
      footer={null}
      className="z-9999"
    >
      <Form
        {...formItemLayout}
        name="basic"
        onFinish={onFinish}
        className="modal-add-information"
        initialValues={initialValues}
        form={form}
        requiredMark={false}
      >
        <Form.Item
          label="Tên chương trình"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
          ]}
          name="name"
        >
          <Input placeholder="Nhập tên chương trình" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item
          label="Mã giới thiệu"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
            { max: 255, message: 'Vui lòng nhập đúng thông tin!' },
            {
              pattern: STATUS.regextTrimspace,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            {
              pattern: STATUS.justNumAndChar,
              message: 'Vui lòng nhập đúng thông tin!',
            },
            { whitespace: true, message: 'Nội dung không được để trống' },
          ]}
          name="referralCode"
        >
          <Input placeholder="Nhập mã giới thiệu" style={{ width: '80%' }} />
        </Form.Item>
        <Form.Item label="Logo">
          {/* <Input placeholder="Nhập mã giới thiệu" style={{ width: '80%' }} /> */}
          <Upload
            onRemove={(file): any => {
              return setfileList([]);
            }}
            beforeUpload={(file): any => {
              setfileList(file);
              return false;
            }}
            // onChange: (file) => {
            //   return setfileList(file);
            // },
            listType="picture"
            name="logo"
            maxCount={1}
            multiple={true}
            accept=".jpg , .png, .jpge"
            className="upload-list-inline"
          >
            <Button icon={<UploadOutlined />} />
          </Upload>
        </Form.Item>
        <Form.Item label="Số tiền" required>
          <Space>
            <Form.Item
              noStyle
              rules={[
                {
                  required: true,
                  message: 'Vui lòng điền thông tin bắt buộc!',
                },
                {
                  pattern: /^[\d]{0,10}$/,
                  message: 'Vui lòng nhập ít hơn 10 ký tự',
                },
                // ({ getFieldValue }) => ({
                //   validator(rule, value) {
                //     if (
                //       (getFieldValue('value') >= 0 &&
                //         Number.isInteger(getFieldValue('value'))) ||
                //       getFieldValue('value') === ''
                //     ) {
                //       // eslint-disable-next-line prefer-promise-reject-errors
                //       return Promise.resolve();
                //     }
                //     // eslint-disable-next-line prefer-promise-reject-errors
                //     return Promise.reject('Giá trị không hợp lệ!');
                //   },
                // }),
              ]}
              name="value"
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value: any) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                className="input-type mr-2"
                placeholder="Nhập số tiền"
              />
            </Form.Item>
            <div className="">VND</div>
          </Space>
        </Form.Item>
        <Form.Item
          label="Ngày bắt đầu"
          name="startDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledDate}
            locale={locale}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày bắt đầu"
            style={{ width: '80%' }}
            onChange={getValStartDate}
            showToday={false}
          />
        </Form.Item>
        <Form.Item
          label="Ngày kết thúc"
          name="endDate"
          rules={[
            { required: true, message: 'Vui lòng nhập thông tin bắt buộc!' },
          ]}
        >
          <DatePicker
            disabledDate={disabledEndDate}
            locale={locale}
            format="DD/MM/YYYY"
            placeholder="Chọn ngày kết thúc"
            onChange={getValEndDate}
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-add-container button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
          <Button className="exit-button" type="primary" htmlType="submit">
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalAddReferral;
