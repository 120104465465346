export interface FormProps {
  handleCancel: (...args: any[]) => any;
  handleSubmit: (...args: any[]) => any;
  formId: string;
  initValue?: any;
  isEdit?: boolean;
  isEditable?: boolean;
  className?: string;
}

export const initFormProps: FormProps = {
  handleCancel: () => {},
  handleSubmit: () => {},
  initValue: {},
  formId: '',
  isEditable: false,
  isEdit: false,
  className: '',
};
