import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appointmentActions } from 'store/appointment';

import * as _ from 'lodash';
import StoreState from 'models/store';
import { formRules } from 'helpers/formUtils';
import AppointmentCard from '../../AppointmentCard/AppointmentCard';

const Cigratte: FC<any> = props => {
  const [isEdit, setIsEdit] = useState<any>();
  const [i, setI] = useState<any>();
  const [isAdd, setIsAdd] = useState<any>(false);
  const {
    initValues,
    dataProps,
    onGetAppointmentDetail,
    isEditAvailability,
  } = props;
  const originTime = new Date();
  const dispatch = useDispatch();
  const arrCigarette = dataProps?.detail?.encounters[0]?.obs?.filter(item =>
    item?.display?.includes('CIGARETTE'),
  );
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );
  const [isSubmit, setIsSubmit] = useState(false);

  const handleEdit = _.debounce(key => {
    setIsEdit(true);
    setI(key);
  }, 50);

  const handleCancel = _.debounce(key => {
    setIsEdit(false);
    setI(key);
  }, 50);

  // const handleSave = _.debounce(key => {
  //   setIsEdit(false);
  //   setI(key);
  // }, 50);

  const onEdit = values => {
    const { value } = values;
    const uuidCigarette = arrCigarette[i]?.uuid;
    const paramsEditCigarette = {
      value,
    };
    dispatch(
      appointmentActions.handleEditCigarette({
        uuidCigarette,
        paramsEditCigarette,
      }),
    );
    setIsEdit(false);
  };

  const onFinish = values => {
    setIsSubmit(true);
    const { cigratte } = values;
    const paramsCreateCigratte = {
      value: cigratte,
      concept: 'd7ea7a47-33a6-4f44-b53e-3fdc178f5218',
      obsDatetime: originTime?.toISOString(),
      person: dataProps?.detail?.uuidPerson,
      encounter: dataProps?.detail?.encounters[0]?.uuid,
    };
    dispatch(
      appointmentActions.handleCreateCigarette({
        paramsCreateCigratte,
        onSucceeded: onGetAppointmentDetail,
      }),
    );
  };

  useEffect(() => {
    if (
      isSubmit === true &&
      appointmentReducer?.isAddCreateCigarette === true
    ) {
      setIsAdd(false);
    }
  }, [appointmentReducer?.isAddCreateCigarette]);

  return (
    <div className="drug-in-use">
      {initValues?.length > 0 ? (
        <AppointmentCard title="Thuốc lá">
          {initValues?.map((item, key) => {
            return (
              <Row className="row-1" key={key}>
                <Col xs={24}>
                  <div className="anamnesis-section">
                    <Form onFinish={onEdit}>
                      <div className="anamnesis-section__title">
                        <div>
                          <span> </span>
                        </div>
                        <div className="btn-edit">
                          {isEditAvailability &&
                            (isEdit && key === i ? (
                              <>
                                <Button
                                  size="middle"
                                  onClick={() => handleCancel(key)}
                                >
                                  Hủy
                                </Button>{' '}
                                <Button
                                  size="middle"
                                  htmlType="submit"
                                  // onClick={() => handleSave(key)}
                                >
                                  Lưu
                                </Button>
                              </>
                            ) : (
                              <Button
                                onClick={() => handleEdit(key)}
                                size="middle"
                              >
                                Sửa
                              </Button>
                            ))}
                        </div>
                      </div>
                      <div className="anamnesis-section__content">
                        <div className="anamnesis-section__content_inside">
                          {isEdit && key === i ? (
                            <Form.Item
                              name="value"
                              preserve={false}
                              rules={formRules}
                              initialValue={item}
                            >
                              <Input />
                            </Form.Item>
                          ) : (
                            <span className="text-have-color-#555555">
                              {item}
                            </span>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            );
          })}
        </AppointmentCard>
      ) : (
        <Row className="row-1">
          <Col xs={24}>
            <div className="anamnesis-section">
              <div className="anamnesis-section__title">
                <div>
                  <span>Thuốc lá</span>
                </div>
              </div>
              <div className="no-content">
                <span className="no-content__text">Chưa có thông tin</span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {isEditAvailability && (
        <div className="anamnesis-add-container">
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="anamnesis-input-add">
                <Form.Item name="cigratte" rules={formRules}>
                  <Input
                    size="large"
                    placeholder="Thêm thông tin thuốc lá"
                    ref={input => input && input.focus()}
                  />
                </Form.Item>
              </div>
              <div className="anamnesis-action-add">
                <div className="btn-ok">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={appointmentReducer?.isLoadingCreateCigarette}
                  >
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(!isAdd)}
                  >
                    <span>Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => setIsAdd(!isAdd)}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cigratte;
