import React, { FC, useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, AutoComplete, Checkbox, Input } from 'antd';
import { formRules } from 'helpers/formUtils';
import './RequestForm.scss';
import {
  OTHER_CLS_CODE,
  OTHER_CLS_IMAGE_DISPLAY,
  OTHER_CLS_LABEL,
  OTHER_CLS_DISPLAY,
} from '../request.const';

const RequestForm: FC<any> = ({
  onFinish,
  selectDefault,
  loading,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [clsGroup, setClsGroup] = useState<any>();
  const [selectedCls, setSelectedCls] = useState<any[]>([]);
  const [isOrther, setIsOrther] = useState(false);
  const { Option } = AutoComplete;

  const dataConcept = useSelector(
    (state: any) => state.appointment.dataCLSConcept,
  );

  const orderSetMembers = selectDefault?.orderSet?.orderSetMembers;

  const listOptionCls = useMemo(() => {
    const data = [
      ...dataConcept,
      {
        uuid: OTHER_CLS_CODE,
        setMembers: [],
        display: OTHER_CLS_DISPLAY,
      },
    ];
    return data.map(item => {
      return (
        <Option key={item.uuid} value={item.display} data={item}>
          {item?.display}
        </Option>
      );
    });
  }, []);

  const listCls = useMemo(() => {
    return (
      clsGroup?.setMembers ||
      dataConcept?.find(i => selectDefault?.concept?.uuid === i?.uuid)
        ?.setMembers ||
      []
    );
  }, [clsGroup, dataConcept]);

  const onChangeClsGroup = (value, option) => {
    setClsGroup(option.data);
    setSelectedCls([]);
    setIsOrther(false);
    form.setFieldsValue({
      CLS: [],
      other: null,
      otherGroup: null,
    });
  };

  // convert cls list data
  const checkboxData = useMemo(() => {
    const filterData = listCls?.filter(
      item => item?.display !== OTHER_CLS_IMAGE_DISPLAY,
    );
    const result = filterData?.map(item => ({
      display: item?.display,
      name: item?.display,
      uuid: item.uuid,
    }));
    return [
      ...result,
      {
        display: OTHER_CLS_LABEL,
        name: OTHER_CLS_LABEL,
        uuid: OTHER_CLS_CODE,
      },
    ];
  }, [listCls]);

  const handleChangeChecked = values => {
    const newProps: any[] = [];
    // eslint-disable-next-line no-unused-expressions
    checkboxData?.forEach(item => {
      if (values?.includes(item?.uuid)) {
        newProps.push(item);
      }
    });
    if (values?.includes(OTHER_CLS_CODE)) {
      setIsOrther(true);
    } else {
      setIsOrther(false);
    }
    setSelectedCls(newProps);
  };

  const convertDefault = useMemo(() => {
    if (selectDefault) {
      const displayClsList = checkboxData?.map(item => item.display);
      const selectData = orderSetMembers?.map(clsItem => ({
        uuid: clsItem?.uuid,
        display: clsItem?.display,
        name: clsItem?.display,
      }));
      return selectData?.map(clsItem =>
        !displayClsList?.includes(clsItem.display)
          ? {
              uuid: OTHER_CLS_CODE,
              name: clsItem?.display,
              display: OTHER_CLS_LABEL,
            }
          : clsItem,
      );
    }
    return [];
  }, [selectDefault]);

  // default value for edit

  const defaultValue = useMemo(() => {
    const selectNonCode = convertDefault?.find((clsItem, index) => {
      return clsItem.display === OTHER_CLS_LABEL;
    });

    const displayClsList = checkboxData?.map(item => item.display);

    const selectDisplay = orderSetMembers?.map(clsItem =>
      !displayClsList?.includes(clsItem.display)
        ? OTHER_CLS_LABEL
        : clsItem?.display,
    );

    const dataCls = checkboxData?.map(item =>
      item?.display === OTHER_CLS_LABEL ? selectNonCode : item,
    );

    const checkedCls = dataCls?.filter(clsItem => {
      return selectDisplay?.includes(clsItem?.display);
    });

    return {
      CLS: checkedCls?.map(i => i?.uuid),
      other: selectNonCode?.name || null,
    };
  }, []);

  const handleFinish = useCallback(
    value => {
      if (clsGroup) {
        onFinish(value, clsGroup);
      } else {
        form.setFields([
          {
            name: 'CLSGroup',
            errors: ['Không có chỉ định này'],
          },
        ]);
      }
    },
    [onFinish, clsGroup],
  );

  const initValues = {
    CLSGroup:
      selectDefault?.concept?.uuid === OTHER_CLS_CODE
        ? OTHER_CLS_DISPLAY
        : selectDefault?.concept?.display,
    CLS: defaultValue.CLS,
    other: defaultValue.other,
    otherGroup: selectDefault?.orderSet?.orderSetMembers[0]?.display,
  };

  useEffect(() => {
    setSelectedCls(convertDefault);
  }, [convertDefault]);

  useEffect(() => {
    if (defaultValue?.CLS.includes(OTHER_CLS_CODE)) {
      setIsOrther(true);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (selectDefault) {
      const data = [
        ...dataConcept,
        {
          uuid: OTHER_CLS_CODE,
          setMembers: [],
          display: OTHER_CLS_DISPLAY,
        },
      ];
      const resultDefault = data.find(
        item => item.uuid === selectDefault?.concept?.uuid,
      );
      setClsGroup(resultDefault);
    }
  }, [selectDefault]);

  return (
    <Form
      onFinish={handleFinish}
      requiredMark={false}
      initialValues={initValues}
      labelAlign="left"
      form={form}
    >
      <div className="form-cls">
        <Form.Item
          name="CLSGroup"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          label="Thêm chỉ định"
          rules={formRules}
        >
          <AutoComplete
            size="large"
            style={{ width: '100%' }}
            onChange={onChangeClsGroup}
            filterOption={(inputValue, option) =>
              option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            placeholder="Chỉ định cận lâm sàng"
          >
            {listOptionCls}
          </AutoComplete>
        </Form.Item>
        {listCls?.length && clsGroup ? (
          <Form.Item
            name="CLS"
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Nội dung không được để trống',
              },
            ]}
          >
            <Checkbox.Group
              className="w-full"
              value={selectedCls}
              onChange={handleChangeChecked}
            >
              <div className="flex flex-wrap">
                {checkboxData?.map((clsItem, index) => {
                  return clsItem.uuid !== OTHER_CLS_CODE ? (
                    <Checkbox
                      key={String(index)}
                      className=" w-1/3 ml-0 py-1 "
                      value={clsItem.uuid}
                    >
                      {clsItem.display}
                    </Checkbox>
                  ) : (
                    <div className="flex w-full" key={String(index)}>
                      <Checkbox className=" ml-0 py-2" value={OTHER_CLS_CODE}>
                        Khác
                      </Checkbox>
                      <Form.Item
                        name="other"
                        className=" py-1 flex-1"
                        rules={[
                          {
                            required: isOrther,
                            message: 'Nội dung không được để trống',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
            </Checkbox.Group>
          </Form.Item>
        ) : null}

        {clsGroup && clsGroup?.uuid === OTHER_CLS_CODE ? (
          <Form.Item
            name="otherGroup"
            className=" py-1 flex-1"
            rules={[
              {
                required: true,
                message: 'Nội dung không được để trống',
              },
            ]}
          >
            <Input placeholder="Nhập chỉ định cận lâm sàng khác" />
          </Form.Item>
        ) : null}
      </div>
      <div className="flex justify-between cls-action-add">
        <div className="btn-ok">
          <Button htmlType="submit" type="primary" loading={loading}>
            Lưu
          </Button>
        </div>
        <div className="btn-cancel">
          <a className="no-underline" href="#" onClick={onClose}>
            <span className="btn-add-form-input">Hủy</span>
          </a>
        </div>
      </div>
    </Form>
  );
};

export default RequestForm;
