import React, { FunctionComponent } from 'react';
import { Card } from 'antd';

const Dashboard: FunctionComponent = () => {
  return (
    <Card>
      <div>Dashboard</div>
    </Card>
  );
};

export default Dashboard;
