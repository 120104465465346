import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
import moment from 'moment';
import { getEvaluateFile } from 'api/promotion';
import { formatDateDayVN } from 'helpers/utils';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as ratingActions from '../../store/rating/rating.action';
import './Rating.scss';

const Rating: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const initStartIndex: number = 1;

  const [paramsRate, setParamsRate] = useState<any>({
    v: 'full',
    startIndex: initStartIndex,
    totalCount: true,
    limit: 10,
    startDate: null,
    endDate: null,
  });
  const { Option } = Select;

  const inputSearch = useRef<any>(null);

  const rateReducer = useSelector((state: any) => state.rating);

  const onChangeTable = (pagination: any) => {
    const { current, pageSize } = pagination;

    const body = {
      startIndex: current,
      limit: pageSize,
    };
    setParamsRate({
      ...paramsRate,
      ...body,
    });
  };

  const onFinish = (value: any) => {
    const { startDate, endDate, rateName, evaluate } = value;
    const rateNameTrim = rateName && rateName.trim();
    const newStartDate = startDate?.format('YYYY-MM-DD');
    const newEndDate = endDate?.format('YYYY-MM-DD');

    setParamsRate({
      ...paramsRate,
      startDate: newStartDate,
      endDate: newEndDate,
      q: rateNameTrim,
      startIndex: initStartIndex,
      evaluate,
    });
  };

  const onStartDate = value => {
    setParamsRate({
      ...paramsRate,
      startDate: value,
      startIndex: initStartIndex,
    });
  };

  const onEndDate = value => {
    setParamsRate({
      ...paramsRate,
      endDate: value,
      startIndex: initStartIndex,
    });
  };

  const getRate = () => {
    dispatch(ratingActions.getRatingRequested(paramsRate));
  };

  useEffect(() => {
    getRate();
  }, [paramsRate]);

  const columns = [
    {
      title: 'STT',
      key: '1',

      dataIndex: 'key',
      render: (value, row, index) => {
        if (
          paramsRate?.startIndex !== undefined &&
          paramsRate?.limit !== undefined &&
          paramsRate
        ) {
          return (paramsRate?.startIndex - 1) * paramsRate?.limit + index + 1;
        }
      },
    },
    {
      title: 'Mã cuộc hẹn',
      key: 'name',
      render: (value, row, index) => {
        return row?.appointment?.appointmentCode;
      },
    },
    {
      title: 'Họ tên người đánh giá',
      key: 'providerName',
      render: (value, row, index) => {
        return row.appointment?.patient?.person?.display;
      },
    },
    {
      title: 'Ngày đánh giá',
      key: 'endDay',

      render: (value, row, index) => {
        return formatDateDayVN(row?.dateCreated);
      },
    },
    {
      title: 'Bác sĩ tư vấn',
      key: 'provider',
      render: (value, row, index) => {
        return row.appointment?.timeSlot?.appointmentBlock?.provider?.person
          ?.display;
      },
    },
    {
      title: 'Kết quả đánh giá',
      key: 'evaluate',

      render: (value, row, index) => {
        return row.evaluate == 1 ? 'Hài lòng' : 'Không hài lòng';
      },
    },
    {
      title: 'Ý kiến của người đánh giá',
      key: 'evaluateContent',
      render: (value, row, index) => {
        return row.evaluateContent;
      },
    },
  ];

  const getFileExcel = () => {
    const params = {
      file: 'excel',
    };
    Object.assign(params, paramsRate);
    getEvaluateFile(params).then(response => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `DanhSachDanhGia${moment().format('YYYY-MM-DD-HH-mm-ss')}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="version-management">
      <Card>
        <Row>
          <Col xs={12}>
            <h1>Danh sách đánh giá</h1>
          </Col>
        </Row>
        <Form onFinish={onFinish} form={form}>
          <Row style={{ marginTop: 20 }} gutter={[20, 0]}>
            <Col xs={4}>
              <span>Ngày bắt đầu</span>
              <Form.Item name="startDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  locale={locale}
                  className="mt-2"
                  onChange={event => onStartDate(event)}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <span>Ngày kết thúc</span>
              <Form.Item name="endDate">
                <DatePicker
                  format="DD-MM-YYYY"
                  locale={locale}
                  className="mt-2"
                  onChange={event => onEndDate(event)}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col xs={4}>
              <span>Kết quả đánh giá</span>
              <Form.Item
                name="evaluate"
                style={{ marginTop: 10, paddingRight: 0 }}
              >
                <Select
                  // onChange={e => onBlurSelect(e)}
                  defaultValue=""
                  style={{ width: '100%' }}
                >
                  <Option value="" key={0}>
                    Tất cả
                  </Option>
                  <Option value="1" key={1}>
                    Hài lòng
                  </Option>
                  <Option value="5" key={2}>
                    Không hài lòng
                  </Option>
                  {/* {optionForSelect} */}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={7}>
              <span>Từ khóa</span>
              <Form.Item
                name="rateName"
                style={{ marginTop: 10, paddingRight: 0 }}
              >
                <Input
                  ref={inputSearch}
                  placeholder="Tìm kiếm theo mã cuộc hẹn, họ tên người đánh giá, bác sỹ"
                  className="input-search"
                />
              </Form.Item>
            </Col>

            <Col xs={4} style={{ marginTop: 30 }}>
              <Button type="primary" htmlType="submit">
                Tìm kiếm
              </Button>
            </Col>
          </Row>
        </Form>
        <Button
          type="primary"
          onClick={getFileExcel}
          style={{ float: 'right' }}
        >
          Xuất Excel
        </Button>
      </Card>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24}>
          <Table
            columns={columns}
            rowKey={row => row.uuid}
            loading={{
              spinning: rateReducer?.isLoadingRating,
              indicator: <Spin />,
              tip: 'Đang tải...',
            }}
            dataSource={rateReducer?.dataRating?.results}
            pagination={{
              current: paramsRate.startIndex,
              pageSize: paramsRate.limit,
              total: rateReducer?.dataRating?.totalCount || 10,
              showSizeChanger: true,
              locale: { items_per_page: '/Trang' },
            }}
            onChange={onChangeTable}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Rating;
