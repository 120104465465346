import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { DoctorModel, DetailWalletDoctor } from 'models/store/doctor.model';

export const {
  getDoctorRequested,
  getDoctorSucceeded,
  getDoctorFailed,
  getSpecialistRequested,
  getSpecialistSucceeded,
  getSpecialistFailed,
  getHealthFacilityRequested,
  getHealthFacilitySucceeded,
  getHealthFacilityFailed,
  resetPassword,
  getDoctorDetailByIdRequested,
  getDoctorDetailByIdSucceeded,
  getDoctorDetailByIdFailed,
  getDetailFeeHistoryRequested,
  getDetailFeeHistorySucceeded,
  getDetailFeeHistoryFailed,
  getDetailWalletDoctorRequested,
  getDetailWalletDoctorSucceeded,
  getDetailWalletDoctorFailed,
  checkoutFeeRequested,
  checkoutFeeSucceeded,
  checkoutFeeFailed,
  editFeeRequested,
  editFeeSucceeded,
  editFeeFailed,
  addDoctorRequested,
  addDoctorSucceeded,
  addDoctorFailed,
  editDoctorRequested,
  editDoctorSucceeded,
  editDoctorFailed,
  getDetailDoctorSucceeded,
  getDetailDoctorRequested,
  getDetailDoctorFailed,
  handleUploadAvatarRequested,
  handleUploadAvatarSucceeded,
  handleUploadAvatarFailed,
  handleUploadCoverRequested,
  handleUploadCoverSucceeded,
  handleUploadCoverFailed,
  handleUpdateAvatarRequested,
  handleUpdateAvatarSucceeded,
  handleUpdateAvatarFailed,
  handleUpdateCoverRequested,
  handleUpdateCoverSucceeded,
  handleUpdateCoverFailed,
  getAvatarRequested,
  getAvatarSucceeded,
  getAvatarFailed,
  getCoverRequested,
  getCoverSucceeded,
  getCoverFailed,
  handleDeleteDoctorRequested,
  handleDeleteDoctorSucceeded,
  handleDeleteDoctorFailed,
} = createActions(
  {
    GET_DOCTOR_REQUESTED: (payload: QueryString) => payload,
    GET_DOCTOR_SUCCEEDED: (payload: DoctorModel) => payload,
    GET_DOCTOR_FAILED: (error: Error) => error,
    GET_DETAIL_DOCTOR_REQUESTED: (id: string, params: any) => ({ id, params }),
    GET_DETAIL_DOCTOR_SUCCEEDED: (payload: DoctorModel) => payload,
    GET_DETAIL_DOCTOR_FAILED: (error: Error) => error,
    GET_SPECIALIST_REQUESTED: (payload: DoctorModel) => payload,
    GET_SPECIALIST_SUCCEEDED: (payload: DoctorModel) => payload,
    GET_SPECIALIST_FAILED: (error: Error) => error,
    GET_HEALTH_FACILITY_REQUESTED: (payload: DoctorModel) => payload,
    GET_HEALTH_FACILITY_SUCCEEDED: (payload: DoctorModel) => payload,
    GET_HEALTH_FACILITY_FAILED: (error: Error) => error,
    RESET_PASSWORD: (id: string, newPassword: string) => ({
      id,
      newPassword,
    }),
    GET_DOCTOR_DETAIL_BY_ID_REQUESTED: (id: string, params: string) => ({
      id,
      params,
    }),
    GET_DOCTOR_DETAIL_BY_ID_SUCCEEDED: (payload: any) => payload,
    GET_DOCTOR_DETAIL_BY_ID_FAILED: (error: any) => error,
    GET_DETAIL_FEE_HISTORY_REQUESTED: (id, params) => ({
      id,
      params,
    }),
    GET_DETAIL_FEE_HISTORY_SUCCEEDED: (payload: any) => payload,
    GET_DETAIL_FEE_HISTORY_FAILED: (error: string) => error,
    GET_DETAIL_WALLET_DOCTOR_REQUESTED: (id: string) => ({
      id,
    }),
    GET_DETAIL_WALLET_DOCTOR_SUCCEEDED: (payload: DetailWalletDoctor) =>
      payload,
    GET_DETAIL_WALLET_DOCTOR_FAILED: (error: string) => error,
    CHECKOUT_FEE_REQUESTED: (id: string, month: any) => ({
      id,
      month,
    }),
    CHECKOUT_FEE_SUCCEEDED: (payload: string) => payload,
    CHECKOUT_FEE_FAILED: (error: string) => error,
    EDIT_FEE_REQUESTED: (id: string, params: any) => ({ id, params }),
    EDIT_FEE_SUCCEEDED: (payload: any) => payload,
    EDIT_FEE_FAILED: (error: any) => error,
    ADD_DOCTOR_REQUESTED: (payload: any) => payload,
    ADD_DOCTOR_SUCCEEDED: (payload: any) => payload,
    ADD_DOCTOR_FAILED: (error: string) => error,
    EDIT_DOCTOR_REQUESTED: (id: string, payload: any) => ({ id, payload }),
    EDIT_DOCTOR_SUCCEEDED: (payload: any) => payload,
    EDIT_DOCTOR_FAILED: (error: string) => error,
    HANDLE_UPLOAD_AVATAR_REQUESTED: (payload: any) => payload,
    HANDLE_UPLOAD_AVATAR_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPLOAD_AVATAR_FAILED: (error: any) => error,
    HANDLE_UPLOAD_COVER_REQUESTED: (payload: any) => payload,
    HANDLE_UPLOAD_COVER_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPLOAD_COVER_FAILED: (error: any) => error,
    HANDLE_UPDATE_AVATAR_REQUESTED: (payload: any) => payload,
    HANDLE_UPDATE_AVATAR_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPDATE_AVATAR_FAILED: (error: any) => error,
    HANDLE_UPDATE_COVER_REQUESTED: (payload: any) => payload,
    HANDLE_UPDATE_COVER_SUCCEEDED: (payload: any) => payload,
    HANDLE_UPDATE_COVER_FAILED: (error: any) => error,
    GET_AVATAR_REQUESTED: (payload: any) => payload,
    GET_AVATAR_SUCCEEDED: (payload: any) => payload,
    GET_AVATAR_FAILED: (error: any) => error,
    GET_COVER_REQUESTED: (payload: any) => payload,
    GET_COVER_SUCCEEDED: (payload: any) => payload,
    GET_COVER_FAILED: (error: any) => error,
    HANDLE_DELETE_DOCTOR_REQUESTED: (payload: any) => payload,
    HANDLE_DELETE_DOCTOR_SUCCEEDED: (payload: any) => payload,
    HANDLE_DELETE_DOCTOR_FAILED: (error: any) => error,
  },
  {
    prefix: 'doctor',
  },
);
