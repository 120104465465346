import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Router } from 'react-router';
import { router } from 'helpers/service/history';
import { isAuthorization } from 'helpers/utils';

import AppLayout from './layout/app.layout';
import AuthLayout from './layout/auth.layout';
import DoctorLayout from './layout/doctor.layout';
import SwitchLayout from './layout/switch.layout';

const App: FC = () => {
  useEffect(() => {
    if (!isAuthorization()) {
      router.push('/auth/login');
    }
  }, []);

  return (
    <Router history={router}>
      <Switch>
        <Route component={AuthLayout} path="/auth" exact={false} />
        <Route component={DoctorLayout} path="/doctor" exact={false} />
        <Route component={AppLayout} path="/app" exact={false} />
        <Route component={SwitchLayout} path="/" exact={false} />
      </Switch>
    </Router>
  );
};

export default App;
