import {
  AppointmentFiled,
  askSickEnum,
  Concept,
} from '../constants/Appointment';

export function getAppointmentConceptData(key: string): string {
  switch (key) {
    case AppointmentFiled.clinicalExamination:
      return Concept.clinicalExamination;
    case AppointmentFiled.usage:
      return Concept.usage;
    case AppointmentFiled.weight:
      return Concept.weight;
    case AppointmentFiled.temperature:
      return Concept.temperature;
    case AppointmentFiled.pulse:
      return Concept.pulse;
    case AppointmentFiled.breathing:
      return Concept.breathing;
    case AppointmentFiled.systolicBloodPressure:
      return Concept.systolicBloodPressure;
    case AppointmentFiled.diastolicBloodPressure:
      return Concept.diastolicBloodPressure;
    case AppointmentFiled.height:
      return Concept.height;
    case askSickEnum.familyHistory:
      return Concept.familyHistory;
    case askSickEnum.wine:
      return Concept.wine;
    case askSickEnum.cigarette:
      return Concept.cigarette;
    case askSickEnum.usedDrug:
      return Concept.usedDrug;
    case askSickEnum.symptom:
      return Concept.symptom;
    default:
      return '';
  }
}
