import React from 'react';
import Route from 'models/Route';
import withAuthenticate from 'hocs/withAuthenticate';
import { ContainerOutlined } from '@ant-design/icons';

import Appointment from 'containers/Appointment';
import AppointmentEdit from 'containers/Appointment/Edit';
import NewAppointmentDetail from 'containers/Appointment/NewDetail';
import { Redirect } from 'react-router-dom';

const doctorRoutes: Route[] = [
  {
    exact: true,
    path: '/',
    component: function renderRedirect() {
      return <Redirect to="/doctor/appointments" />;
    },
    name: 'Doctor',
    hideInNav: true,
  },
  {
    exact: true,
    path: '/appointments',
    component: withAuthenticate(Appointment),
    name: 'Danh sách tư vấn',
    icon: <ContainerOutlined />,
  },
  {
    exact: true,
    path: '/appointments/edit/:id',
    component: withAuthenticate(AppointmentEdit),
    name: 'Chỉnh sửa tư vấn',
    firstRoute: true,
    hideInNav: true,
  },
  {
    exact: true,
    path: '/appointments/detail/:id',
    component: withAuthenticate(NewAppointmentDetail),
    name: 'Chi tiết tư vấn',
    firstRoute: true,
    hideInNav: true,
  },
];

export default doctorRoutes;
