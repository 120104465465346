import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
// import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { postConditionRequested } from 'store/appointment/appointment.actions';
import { APPOINTMENT_CODE } from 'constants/Appointment';
import moment from 'moment';
import StoreState from 'models/store';
import { formRules } from 'helpers/formUtils';
import AppointmentCard from '../../AppointmentCard/AppointmentCard';

const Chronic: FC<any> = props => {
  const { dataProps, isEditAvailability } = props;

  const dispatch = useDispatch();
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  // const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [i, setI] = useState<any>();
  const [isAdd, setIsAdd] = useState<any>(false);

  const [isSubmit, setIsSubmit] = useState(false);

  // const handleEdit = _.debounce(key => {
  //   setIsEdit(true);
  //   setI(key);
  // }, 50);

  // const handleCancel = _.debounce(key => {
  //   setIsEdit(false);
  //   setI(key);
  // }, 50);

  // const handleSave = _.debounce(key => {
  //   setIsEdit(false);
  //   setI(key);
  // }, 50);

  const onEdit = () => {};

  const onFinish = values => {
    setIsSubmit(true);
    const { value } = values;

    const body = {
      condition: {
        coded: APPOINTMENT_CODE.CONDITION,
        nonCoded: value,
      },
      patient: dataProps?.detail?.patient?.uuid,
      clinicalStatus: 'CHRONIC',
      verificationStatus: 'CONFIRMED',
      onsetDate: moment()
        .utc()
        .format(),
      endDate: moment()
        .utc()
        .format(),
      additionalDetail: '',
    };

    dispatch(postConditionRequested(body));
  };

  useEffect(() => {
    if (isSubmit === true && appointmentReducer?.isAddChronic === true) {
      setIsAdd(false);
    }
  }, [appointmentReducer?.isAddChronic]);

  return (
    <div className="drug-in-use">
      {dataProps?.conditionHistory?.chronicDiseases?.length > 0 ? (
        <AppointmentCard title="Bệnh mãn tính">
          {dataProps?.conditionHistory?.chronicDiseases?.map((item, key) => {
            return (
              <Row className="row-1" key={key}>
                <Col xs={24}>
                  <div className="anamnesis-section">
                    <Form onFinish={onEdit}>
                      <div className="anamnesis-section__content">
                        <div className="anamnesis-section__content_inside">
                          <span className="text-have-color-#555555">
                            {item.description}
                          </span>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            );
          })}
        </AppointmentCard>
      ) : (
        <Row className="row-1">
          <Col xs={24}>
            <div className="anamnesis-section">
              <div className="anamnesis-section__title">
                <div>
                  <span>Bệnh mãn tính</span>
                </div>
              </div>
              <div className="no-content">
                <span className="no-content__text">Chưa có thông tin</span>
              </div>
            </div>
          </Col>
        </Row>
      )}

      {isEditAvailability && (
        <div className="anamnesis-add-container">
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="anamnesis-input-add">
                <Form.Item name="value" rules={formRules}>
                  <Input
                    size="large"
                    placeholder="Thêm thông tin bệnh mãn tính"
                    ref={input => input && input.focus()}
                  />
                </Form.Item>
              </div>
              <div className="anamnesis-action-add">
                <div className="btn-ok">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={appointmentReducer?.isLoadingAddChronic}
                  >
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(!isAdd)}
                  >
                    <span>Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={() => setIsAdd(!isAdd)}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Chronic;
