/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { actions as appointmentActions, selectors } from 'store/appointment';
import { useParams, Link } from 'react-router-dom';
import useQuery from 'helpers/useQuery';
import { Row, Col, Button, Modal } from 'antd';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// import { Link as LinkRS, Element, animateScroll as scroll } from 'react-scroll';
import {
  ExclamationCircleOutlined,
  LeftOutlined,
  PhoneTwoTone,
} from '@ant-design/icons';
import * as _ from 'lodash';
import FollowUp from 'components/Appointment/DetailAppointment/FollowUp/FollowUp';
import Instruction from 'components/Appointment/DetailAppointment/Instruction/Instruction';
import DrugOrder from 'components/Appointment/DetailAppointment/DrugOrder/DrugOrder';
import Requested from 'components/Appointment/DetailAppointment/Requested/Requested';
import Diagnostic from 'components/Appointment/DetailAppointment/Diagnostic/Diagnostic';
import CheckAppointment from 'components/Appointment/DetailAppointment/Check/Check';
import Anamnesis from 'components/Appointment/DetailAppointment/Anamnesis/Anamnesis';
import HistoryCheck from 'components/Appointment/DetailAppointment/HistoryCheck/HistoryCheck';
import Question from 'components/Appointment/DetailAppointment/Question/Question';
import ReasonCheck from 'components/Appointment/DetailAppointment/ReasonCheck/ReasonCheck';
import { pathRouteWithRole, isRoleDoctor } from 'helpers/utils';
import StoreState from 'models/store';
import { APPOINTMENT_STATUS } from 'constants/Appointment';
import {
  fetchDiagnosis,
  postUpdateStatusAppointmentRequested,
} from 'store/appointment/appointment.actions';
import CancelReason from 'components/Appointment/DetailAppointment/CancelReason/CancelReason';

import ModalAddDetail from 'components/Appointment/Modal/ModalAddDetail';
import { StartCall } from 'types/action/appointment/StartCall';
import InforUser from '../../components/Appointment/DetailAppointment/InforUser/InforUser';
import ModalSupport from '../../components/Appointment/Modal/ModalSupport';

import './NewDetail.scss';

const Scroll = require('react-scroll');

const { Link: LinkRS, Element } = Scroll;

const { confirm } = Modal;

const NewAppointmentDetail: FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [visibleSpModal, setVisibleSpModal] = useState<boolean>(false);
  const [isModalAddDetail, setModalAddDetail] = useState(false);
  const appointmentId = _.get(params, 'id');
  const query = useQuery();
  const [dataMenu] = useState([
    { href: 'reason-check', value: 'Lý do khám', active: true },
    { href: 'history-check', value: 'Lịch sử khám', active: false },
    { href: 'question', value: 'Hỏi bệnh', active: false },
    { href: 'anamnesis', value: 'Tiền sử bệnh', active: false },
    { href: 'check', value: 'Khám bệnh', active: false },
    { href: 'diagnostic', value: 'Chẩn đoán', active: false },
    { href: 'requested', value: 'Chỉ định cận lâm sàng', active: false },
    { href: 'drug-order', value: 'Đơn thuốc', active: false },
    { href: 'instruction', value: 'Lời dặn bác sỹ', active: false },
    { href: 'followup', value: 'Theo dõi', active: false },
  ]);

  const appointment = useSelector(state =>
    selectors.getSelectedAppointment(state, appointmentId),
  );

  const dataEditDrugsPresent = useSelector(
    (state: any) => state.appointment.dataEditDrugsPresent,
  );

  const dataEditPhysicalExamination = useSelector(
    (state: any) => state.appointment.dataEditPhysicalExamination,
  );
  const dataEditWeight = useSelector(
    (state: any) => state.appointment.dataEditWeight,
  );

  const dataEditTemperature = useSelector(
    (state: any) => state.appointment.dataEditTemperature,
  );
  const dataEditSystom = useSelector(
    (state: any) => state.appointment.dataEditSystom,
  );

  const dataEditRespiratoryRate = useSelector(
    (state: any) => state.appointment.dataEditRespiratoryRate,
  );

  const dataEditInstructions = useSelector(
    (state: any) => state.appointment.dataEditInstructions,
  );
  const dataEditHistoryOfFamily = useSelector(
    (state: any) => state.appointment.dataEditHistoryOfFamily,
  );

  const dataEditCigarette = useSelector(
    (state: any) => state.appointment.dataEditCigarette,
  );

  const dataEditAlcohol = useSelector(
    (state: any) => state.appointment.dataEditAlcohol,
  );
  const dataAddDrugsPresent = useSelector(
    (state: any) => state.appointment.dataAddDrugsPresent,
  );

  const dataAddPhysicalExamination = useSelector(
    (state: any) => state.appointment.dataAddPhysicalExamination,
  );

  const dataAddRespiratoryRate = useSelector(
    (state: any) => state.appointment.dataAddRespiratoryRate,
  );

  const dataAddSystom = useSelector(
    (state: any) => state.appointment.dataAddSystom,
  );
  const dataAddTemperature = useSelector(
    (state: any) => state.appointment.dataAddTemperature,
  );

  const dataAddWeight = useSelector(
    (state: any) => state.appointment.dataAddWeight,
  );

  const dataAddCigarette = useSelector(
    (state: any) => state.appointment.dataAddCigarette,
  );

  const dataAddHeight = useSelector(
    (state: any) => state.appointment.dataAddHeight,
  );

  const dataAddInstructions = useSelector(
    (state: any) => state.appointment.dataAddInstructions,
  );

  const dataAddAlcohol = useSelector(
    (state: any) => state.appointment.dataAddAlcohol,
  );

  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );

  const onGetAppointmentDetail = useCallback(() => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentId));
  }, [appointmentId]);

  useEffect(() => {
    dispatch(appointmentActions.fetchDetailAppointment(appointmentId));
  }, [
    dispatch,
    appointmentId,
    dataEditDrugsPresent,
    dataEditPhysicalExamination,
    dataEditHistoryOfFamily,
    dataAddAlcohol,
    dataAddInstructions,
    dataAddHeight,
    dataAddCigarette,
    dataAddWeight,
    dataAddTemperature,
    dataAddSystom,
    dataAddRespiratoryRate,
    dataAddPhysicalExamination,
    dataEditWeight,
    dataEditCigarette,
    dataAddDrugsPresent,
    dataEditAlcohol,
    dataEditAlcohol,
    dataEditTemperature,
    dataEditInstructions,
    dataEditInstructions,
    dataEditSystom,
    dataEditRespiratoryRate,
    appointmentReducer?.isAddAlcohol,
    appointmentReducer?.isAddCreateHistoryOfFamily,
    appointmentReducer?.dataAddOrder,
    appointmentReducer?.isEditCLSOrder,
    appointmentReducer?.dataAddOrderDrug,
    appointmentReducer?.dataAddInstructions,
    appointmentReducer?.isUpdateStatusAppointment,
  ]);

  useEffect(() => {
    dispatch(
      appointmentActions.fetchExaminationHistory({
        patientId: query.patientId,
        provider: '',
      }),
    );
    dispatch(appointmentActions.fetchAllergy(query.patientId));
    dispatch(appointmentActions.fetchRelationship(query.patientId));
    dispatch(
      appointmentActions.fetchConditionHistory({
        patientId: query.patientId,
      }),
    );
    dispatch(
      fetchDiagnosis({
        q: '',
      }),
    );
  }, [query.patientId]);

  const appointmentCode = useMemo(() => {
    return appointment?.detail?.appointmentCode;
  }, [appointment?.detail?.appointmentCode?.length]);

  const getDataCheckFromStore: any = useSelector((store: StoreState) => ({
    relationship: store.appointment.relationship,
    allergy: store.appointment.allergy,
    conditionHistory: store.appointment.conditionHistory,
    examinationHistory: store.appointment.examinationHistory,
    detail: appointment,
    diagnosis: store.appointment.diagnosis,
    isExaminationHistoryLoading: store.appointment.isExaminationHistoryLoading,
  }));

  const examinationHistory = useSelector(
    (state: any) => state.appointment.examinationHistory,
  );

  const initAppointmentValue = useMemo(() => {
    const arrQuestionGroup = appointment?.encounters[0]?.obs?.filter(val =>
      val?.display?.includes('Group A&Q: Number'),
    );

    const arrQuestionGroup1 =
      arrQuestionGroup !== undefined
        ? arrQuestionGroup?.map(item => item?.groupMembers)
        : [''];
    const res1 = arrQuestionGroup1?.map((item: any) => {
      const res2 =
        item && item?.find(val => val?.display?.includes('Question'));
      return res2;
    });

    const finalQuestion = res1?.map(value => value?.value);

    const arrPulse = getDataCheckFromStore?.detail?.encounters[0]?.obs?.find(
      item => item?.display?.includes('PULSE'),
    );

    const arrTemperature = getDataCheckFromStore.detail?.encounters[0]?.obs?.find(
      item => item?.display?.includes('TEMPERATURE'),
    );

    const arrHeight = getDataCheckFromStore.detail?.encounters[0]?.obs?.find(
      item => item?.display?.includes('HEIGHT'),
    );

    const arrWeight = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('WEIGHT'),
    );
    const arrBloodPressureSystolic = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('SYSTOLIC BLOOD PRESSURE'),
    );

    const arrBloodPressureDiastolic = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('DIASTOLIC BLOOD PRESSURE'),
    );

    const arrRespiratoryRate = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('Respiratory rate'),
    );

    const arrSystom = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('SYMPTOM'),
    );

    const arrDrugsPresent = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('DRUGS ARE IN USE'),
    );

    const arrHistoryOfFamily = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('HISTORY OF FAMILY'),
    );

    const arrAlcohol = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('ALCOHOL'),
    );

    const arrCigarette = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('CIGARETTE'),
    );

    const arrPhysicalExamination = getDataCheckFromStore?.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('PHYSICAL EXAMINATION'),
    );

    const arrDiagnosticConfirmed = getDataCheckFromStore.detail?.detail?.diagnostic?.filter(
      item => item?.status?.includes('CONFIRMED'),
    );

    const arrDiagnosticProvisional = getDataCheckFromStore.detail?.detail?.diagnostic?.filter(
      item => item?.status?.includes('PROVISIONAL'),
    );

    const arrInstructions = getDataCheckFromStore.detail?.encounters[0]?.obs?.filter(
      item => item?.display?.includes('INSTRUCTION'),
    );

    const arrRequests = getDataCheckFromStore.detail?.encounters[0]?.orders
      .filter((i: any) => i.type === 'testorder')
      .map((item: any) => ({
        id: item.uuid,
        description: item.display,
        date: item.dateActivated,
      }));

    const arrFollowUp = getDataCheckFromStore.detail?.detail?.appointmentFollows?.map(
      item => {
        return {
          id: item.uuid,
          reason: item.followDescription,
          result: item.followResult,
          followDate: item.followDate,
          follower: item.follower.uuid,
        };
      },
    );

    if (appointment?.detail) {
      return {
        physicalExamination:
          arrPhysicalExamination?.length > 0
            ? arrPhysicalExamination?.map(item => item?.value)
            : [],
        followUps: arrFollowUp?.length > 0 ? arrFollowUp : [],
        paymentStatus: '------',
        appointmentCode: appointment?.detail?.appointmentCode,
        reason: appointment.detail.reason,
        reasonDetail: _.get(appointment, 'detail.reasonDetail', ''),
        bloodPressureDiastolic:
          arrBloodPressureDiastolic?.length > 0
            ? arrBloodPressureDiastolic[0]?.value
            : '',
        bloodPressureSystolic:
          arrBloodPressureSystolic?.length > 0
            ? arrBloodPressureSystolic[0]?.value
            : '',
        symptom: arrSystom ? arrSystom?.map(item => item?.value) : [],
        alcohol:
          arrAlcohol?.length > 0 ? arrAlcohol?.map(item => item?.value) : [],
        cigarette:
          arrCigarette?.length > 0
            ? arrCigarette?.map(item => item?.value)
            : [],
        familyHistory:
          arrHistoryOfFamily?.length > 0
            ? arrHistoryOfFamily?.map(item => item?.value)
            : [],

        // relationship: appointment.relationship.relationship,

        diagnostic1:
          arrDiagnosticConfirmed?.length > 0
            ? arrDiagnosticConfirmed?.map(item => item?.description)
            : [],

        diagnostic:
          arrDiagnosticProvisional?.length !== 0
            ? arrDiagnosticProvisional?.map(item => item?.description)
            : [],

        requests:
          arrRequests?.length > 0
            ? arrRequests.map(item => item.description)
            : [],

        drugsPresent:
          arrDrugsPresent?.length > 0
            ? arrDrugsPresent?.map(item => item?.value)
            : [],

        drugOrders: appointment
          ? appointment?.detail?.detail?.drugOrder?.map(
              item => item.description,
            )
          : [],

        instructions:
          arrInstructions !== undefined
            ? arrInstructions?.map(item => item.value)
            : [],
        questionsByPatient: finalQuestion?.length > 0 ? finalQuestion : [],
        temperature: arrTemperature?.value,
        breathing:
          arrRespiratoryRate !== undefined ? arrRespiratoryRate[0]?.value : '',
        pulse: arrPulse?.value,
        weight: arrWeight[0]?.value,
        height: arrHeight?.value,
      };
    }
  }, [appointment, dataEditDrugsPresent]);

  const groupData = { ...initAppointmentValue, ...getDataCheckFromStore };

  const handleWaitingExamination = () => {
    confirm({
      title: 'Khi nhấn "Chờ xét nghiệm" thông tin sẽ được gửi tới bệnh nhân',
      okText: 'Chờ xét nghiệm',
      cancelText: 'Tiếp tục chỉnh sửa',
      okButtonProps: {
        loading: false,
      },
      onOk() {
        const bodyRequest = {
          uuid: appointmentId,
          body: {
            status: APPOINTMENT_STATUS.WAITING_EXAMINATION,
          },
        };
        dispatch(postUpdateStatusAppointmentRequested(bodyRequest));
      },
    });
  };

  const onCompleteExamination = () => {
    confirm({
      title: 'Khi nhấn "Hoàn thành" thông tin sẽ được gửi tới bệnh nhân',
      okText: 'Hoàn thành',
      cancelText: 'Tiếp tục chỉnh sửa',
      onOk() {
        const bodyRequest = {
          uuid: appointmentId,
          body: {
            status: APPOINTMENT_STATUS.COMPLETED,
          },
        };
        dispatch(postUpdateStatusAppointmentRequested(bodyRequest));
      },
    });
  };

  const closeOpenAddDetail = () => {
    setModalAddDetail(false);
  };

  const handleCloseModal = () => {
    setVisibleSpModal(false);
  };

  const handleOpenModal = () => {
    setVisibleSpModal(true);
  };

  const isCompleteRequest = (images: any, requests: any) => {
    const imagesId = images.map((i: any) => i.orderId);

    for (const req of requests) {
      if (!imagesId.includes(req.uuid)) return false;
    }
    return true;
  };

  const renderWaitingExamination = () => {
    const status = _.get(
      appointmentReducer,
      'detailAppointment.selectedData.detail.status',
      '',
    );
    const requests = _.get(
      appointmentReducer,
      'detailAppointment.selectedData.dataCloned.encounters[0].orders',
      [],
    ).filter(item => item.type === 'clsorder');

    const diagnostic = _.get(
      appointmentReducer,
      'detailAppointment.selectedData.detail.diagnostic',
      [],
    );

    switch (status) {
      case APPOINTMENT_STATUS.WAITING_EXAMINATION: {
        const { images } = appointment?.dataCloned?.detail;

        if (requests?.length > 0 && isCompleteRequest(images, requests)) {
          return (
            <Button
              type="primary"
              onClick={onCompleteExamination}
              loading={appointmentReducer?.isLoadingUpdateStatusAppointment}
            >
              Hoàn thành
            </Button>
          );
        }
        return null;
      }

      case APPOINTMENT_STATUS.INCONSULTATION: {
        const { images } = appointment?.dataCloned?.detail;
        const arrImage: any = [];
        images.find(e => {
          arrImage.push(e.orderId);
        });
        let hasRed = true;
        for (const z of requests) {
          hasRed = arrImage.includes(z.uuid);
        }

        if (diagnostic?.length > 0 || requests?.length > 0) {
          if (hasRed === true) {
            if (diagnostic?.length > 0) {
              return (
                <Button
                  className="btn-1 mr-3"
                  type="primary"
                  onClick={onCompleteExamination}
                  loading={appointmentReducer?.isLoadingUpdateStatusAppointment}
                >
                  Hoàn thành
                </Button>
              );
            }
          } else {
            return (
              <Button
                className="btn-1 mr-3"
                type="primary"
                onClick={handleWaitingExamination}
                loading={appointmentReducer?.isLoadingUpdateStatusAppointment}
              >
                Chờ xét nghiệm
              </Button>
            );
          }
        }
        return null;
      }

      default:
        break;
    }

    return null;
  };

  const handleStartCall = () => {
    const {
      detail,
      patient,
    } = appointmentReducer.detailAppointment.selectedData.dataCloned;
    const { provider } = appointmentReducer.detailAppointment.selectedData;

    const p: StartCall = {
      appointmentId: detail.uuid,
      appointmentCode: detail.appointmentCode,
      receiveId: [patient.uuid, provider.uuid],
    };
    confirm({
      title: 'Bạn có muốn thực hiện cuộc gọi này?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Có',
      width: '500px',
      cancelText: 'Không',
      onOk() {
        dispatch(appointmentActions.startCall(p));
      },
    });
  };

  const isEditAvailability = useMemo(() => {
    if (getDataCheckFromStore.detail) {
      const { detail } = getDataCheckFromStore.detail.dataCloned;

      switch (detail.status) {
        case APPOINTMENT_STATUS.WAITING_EXAMINATION:
        case APPOINTMENT_STATUS.INCONSULTATION:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }, [getDataCheckFromStore]);

  const isEditInconsultation = useMemo(() => {
    if (getDataCheckFromStore.detail) {
      const { detail } = getDataCheckFromStore.detail.dataCloned;

      switch (detail.status) {
        case APPOINTMENT_STATUS.INCONSULTATION:
          return true;
        default:
          return false;
      }
    } else {
      return false;
    }
  }, [getDataCheckFromStore]);

  const isFollowEditAvailability = useMemo(() => {
    if (getDataCheckFromStore.detail) {
      const { detail } = getDataCheckFromStore.detail.dataCloned;

      switch (detail.status) {
        case APPOINTMENT_STATUS.MISSED:
        case APPOINTMENT_STATUS.SCHEDULED:
        case APPOINTMENT_STATUS.CANCELLED:
          return false;
        default:
          return true;
      }
    } else {
      return false;
    }
  }, [getDataCheckFromStore]);

  const isEditDiagnostic = useMemo(() => {
    if (getDataCheckFromStore.detail) {
      const { detail } = getDataCheckFromStore.detail.dataCloned;
      switch (detail.status) {
        case APPOINTMENT_STATUS.MISSED:
        case APPOINTMENT_STATUS.SCHEDULED:
        case APPOINTMENT_STATUS.CANCELLED:
        case APPOINTMENT_STATUS.COMPLETED:
          return false;
        default:
          return true;
      }
    } else {
      return false;
    }
  }, [getDataCheckFromStore]);

  const isCompletedCall = useMemo(() => {
    const followUps = _.get(
      getDataCheckFromStore,
      'detail.dataCloned.detail.followUps',
      [],
    );
    return (
      followUps
        ?.filter(
          vals =>
            moment(vals.followDate).diff(moment(), 'days') >= 0 && !vals.result,
        )
        ?.map(i => i.reason).length > 0
    );
  }, [getDataCheckFromStore]);

  const renderCallButton = () => {
    const status = _.get(getDataCheckFromStore, 'detail.detail.status', '');
    if (getDataCheckFromStore.detail) {
      if (
        (status === APPOINTMENT_STATUS.SCHEDULED &&
          moment(getDataCheckFromStore.detail.detail.startDate).diff(
            moment(),
            'm',
          ) >= 5) ||
        (status === APPOINTMENT_STATUS.COMPLETED && !isCompletedCall) ||
        status === APPOINTMENT_STATUS.CANCELLED
      ) {
        return null;
      }
      return (
        <Button
          className="btn-2  mr-3 button-call"
          onClick={handleStartCall}
          icon={<PhoneTwoTone />}
        />
      );
    }
    return null;
  };

  return (
    <div className="detail-appointment-container">
      <div className="detail-container__nav">
        <Row className="row-1 border-solid border-1 border-black m-0 p-0">
          <Col className="col-1 m-0 p-0" xs={8}>
            <div className="col-1__container">
              <div className="col-1__container_icon pl-2">
                <Link to={`${pathRouteWithRole()}/appointments`}>
                  <LeftOutlined className="icon-left-row" />
                </Link>
              </div>
              <div className="col-1__container_span pt-1">
                <Link
                  to={`${pathRouteWithRole()}/appointments`}
                  onClick={closeOpenAddDetail}
                >
                  <span className="text-back">Quay lại danh sách</span>
                </Link>
              </div>
            </div>
          </Col>
          <Col className="col-2" xs={8}>
            <span className="title-appointment__code">{appointmentCode}</span>
          </Col>
          <Col className="col-3 pr-5" xs={8}>
            <div className="col-3__group-button">
              {renderCallButton()}

              {renderWaitingExamination()}

              {isRoleDoctor() === true && (
                <Button
                  className="btn-2 bg-gray-600 mr-3 "
                  onClick={handleOpenModal}
                >
                  Hỗ trợ
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <hr />

        <Row className="row-2 flex justify-center" id="tabs">
          <ul className="flex ul-tab ">
            {dataMenu?.length
              ? dataMenu.map((item, key) => {
                  return (
                    <li key={item?.href}>
                      <LinkRS
                        activeClass="label-active"
                        smooth={true}
                        spy={true}
                        hashSpy={true}
                        offset={-115}
                        duration={500}
                        containerId="wrapper-layout-app"
                        to={item.href}
                        className={'header-label'}
                      >
                        {item.value}
                      </LinkRS>
                    </li>
                  );
                })
              : null}
          </ul>
        </Row>
      </div>
      <Element id="containerElement">
        <div className="detail-container__content">
          <div className="detail-container__content_inside">
            <div id="info-user" className="detail-container__content_insideBox">
              <InforUser initValues={appointment} />
            </div>
            {getDataCheckFromStore?.detail?.dataCloned?.detail
              ?.cancelReason && (
              <div
                id="cancel-reason"
                className="detail-container__content_insideBox"
              >
                <CancelReason
                  reason={
                    getDataCheckFromStore?.detail?.dataCloned?.detail
                      ?.cancelReason
                  }
                />
              </div>
            )}
            <div>
              <Element
                name="reason-check"
                className="detail-container__content_insideBox"
              >
                <ReasonCheck
                  initValues={initAppointmentValue}
                  appointment={appointment}
                  dataProps={getDataCheckFromStore}
                />
              </Element>
              <Element
                name="history-check"
                className="detail-container__content_insideBox"
              >
                <HistoryCheck
                  initValues={examinationHistory}
                  loading={getDataCheckFromStore.isExaminationHistoryLoading}
                />
              </Element>
              <Element
                id="question"
                name="question"
                className="detail-container__content_insideBox"
              >
                <Question
                  initValues={initAppointmentValue}
                  data={getDataCheckFromStore}
                  isEditAvailability={isEditInconsultation}
                />
              </Element>
              <Element
                id="anamnesis"
                name="anamnesis"
                className="detail-container__content_insideBox"
              >
                <Anamnesis
                  dataStore={groupData}
                  data={getDataCheckFromStore}
                  onGetAppointmentDetail={onGetAppointmentDetail}
                  isEditAvailability={isEditInconsultation}
                />
              </Element>
              <Element
                id="check"
                name="check"
                className="detail-container__content_insideBox"
              >
                <CheckAppointment
                  initValues={initAppointmentValue}
                  dataProps={getDataCheckFromStore}
                  isEditAvailability={isEditInconsultation}
                />
              </Element>
              <Element
                id="diagnostic"
                name="diagnostic"
                className="detail-container__content_insideBox"
              >
                <Diagnostic
                  initValues={initAppointmentValue}
                  dataProps={getDataCheckFromStore}
                  onGetAppointmentDetail={onGetAppointmentDetail}
                  isEditAvailability={isEditDiagnostic}
                />
              </Element>
              <Element
                id="requested"
                name="requested"
                className="detail-container__content_insideBox"
              >
                <Requested
                  initValues={initAppointmentValue}
                  dataProps={getDataCheckFromStore}
                  isEditAvailability={isEditDiagnostic}
                />
              </Element>
              <Element
                id="drug-order"
                name="drug-order"
                className="detail-container__content_insideBox"
              >
                <DrugOrder
                  initValues={initAppointmentValue}
                  dataProps={getDataCheckFromStore}
                  isEditAvailability={isEditAvailability}
                />
              </Element>
              <Element
                id="instruction"
                name="instruction"
                className="detail-container__content_insideBox instruction"
              >
                <Instruction
                  initValues={initAppointmentValue}
                  data={getDataCheckFromStore}
                  isEditAvailability={isEditAvailability}
                />
              </Element>
              <Element
                id="followup"
                name="followup"
                className="detail-container__content_insideBox"
              >
                <FollowUp
                  initValues={initAppointmentValue}
                  dataProps={getDataCheckFromStore}
                  isEditAvailability={isFollowEditAvailability}
                />
              </Element>
            </div>
          </div>
        </div>
      </Element>

      {visibleSpModal && (
        <ModalSupport
          visible={visibleSpModal}
          handleCloseModal={handleCloseModal}
          bodyRequest={{
            uuidUser: _.get(
              appointmentReducer,
              'detailAppointment.selectedData.provider.allProvider.user.uuid',
              '',
            ),
            appointmentCode: appointmentId,
          }}
        />
      )}

      {isModalAddDetail && (
        <ModalAddDetail show={isModalAddDetail} onHide={closeOpenAddDetail} />
      )}
    </div>
  );
};

export default NewAppointmentDetail;
