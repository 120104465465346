import { handleActions } from 'redux-actions';
import { ActionModel } from 'types/ActionModel';
import { PromotionModel } from 'models/store/promotion.model';

export interface PromotionReducerState {
  dataPromotion: PromotionModel;
  dataPromotionCode: PromotionModel;
  dataPromotionHistory: PromotionModel;
  isLoadingPromotion: boolean;
  isLoadingPromotionCode: boolean;
  isLoadingPromotionHistory: boolean;
  dataAddPromotion: any;
  isLoadingAddPromotion: boolean;
  dataEditPromotion: any;
  isLoadingEditPromotion: boolean;
  errorEdit: any;
  errorAdd: any;
  isLoadingLock: any;
}

const initialState: PromotionReducerState = {
  dataPromotion: {} as PromotionModel,
  dataPromotionCode: {} as PromotionModel,
  dataPromotionHistory: {} as PromotionModel,
  isLoadingPromotion: false,
  isLoadingPromotionCode: false,
  isLoadingPromotionHistory: false,
  dataAddPromotion: {},
  isLoadingAddPromotion: false,
  dataEditPromotion: {},
  isLoadingEditPromotion: false,
  errorEdit: '',
  errorAdd: '',
  isLoadingLock: '',
};

const promotionReducer = handleActions<any, ActionModel>(
  {
    GET_PROMOTION_REQUESTED: state => {
      return {
        ...state,
        isLoadingPromotion: true,
        dataPromotion: null,
      };
    },

    GET_PROMOTION_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataPromotion: action.payload,
        isLoadingPromotion: false,
      };
    },

    GET_PROMOTION_FAILED: state => {
      return {
        ...state,
        isLoadingPromotion: false,
        dataPromotion: null,
      };
    },
    GET_PROMOTION_CODE_REQUESTED: state => {
      return {
        ...state,
        isLoadingPromotionCode: true,
        dataPromotionCode: null,
      };
    },

    GET_PROMOTION_CODE_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataPromotionCode: action.payload,
        isLoadingPromotionCode: false,
      };
    },

    GET_PROMOTION_CODE_FAILED: state => {
      return {
        ...state,
        isLoadingPromotionCode: false,
        dataPromotionCode: null,
      };
    },
    GET_PROMOTION_HISTORY_REQUESTED: state => {
      return {
        ...state,
        isLoadingPromotionHistory: true,
        dataPromotionHistory: null,
      };
    },

    GET_PROMOTION_HISTORY_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataPromotionHistory: action.payload,
        isLoadingPromotionHistory: false,
      };
    },

    GET_PROMOTION_HISTORY_FAILED: state => {
      return {
        ...state,
        isLoadingPromotionHistory: false,
        dataPromotionHistory: null,
      };
    },
    HANDLE_CREATE_PROMOTION_REQUESTED: state => {
      return {
        ...state,
        isLoadingAddPromotion: true,
        dataAddPromotion: null,
        errorAdd: '',
      };
    },

    HANDLE_CREATE_PROMOTION_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataAddPromotion: action.payload,
        isLoadingAddPromotion: false,
        errorAdd: false,
      };
    },

    HANDLE_CREATE_PROMOTION_FAILED: state => {
      return {
        ...state,
        isLoadingAddPromotion: false,
        dataAddPromotion: null,
        errorAdd: true,
      };
    },
    HANDLE_UPDATE_PROMOTION_REQUESTED: state => {
      return {
        ...state,
        isLoadingEditPromotion: true,
        dataEditPromotion: null,
        errorEdit: '',
      };
    },

    HANDLE_UPDATE_PROMOTION_SUCCEEDED: (state, action) => {
      return {
        ...state,
        dataEditPromotion: action.payload,
        isLoadingEditPromotion: false,
        errorEdit: false,
      };
    },

    HANDLE_UPDATE_PROMOTION_FAILED: (state, action) => {
      return {
        ...state,
        isLoadingEditPromotion: false,
        dataEditPromotion: null,
        errorEdit: true,
      };
    },
    HANDLE_DISPLAY_MODAL_EDIT: () => {
      return {
        errorEdit: '',
      };
    },
    HANDLE_DISPLAY_MODAL_ADD: () => {
      return {
        errorAdd: '',
      };
    },
    HANDLE_LOCKED_PROMOTION_REQUESTED: state => {
      return {
        ...state,
        isLoadingLock: true,
      };
    },
    HANDLE_LOCKED_PROMOTION_SUCCEEDED: (state, action) => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },
    HANDLE_LOCKED_PROMOTION_FAILED: state => {
      return {
        ...state,
        isLoadingLock: false,
      };
    },
  },
  initialState,
  { prefix: 'promotion' },
);

export default promotionReducer;
