import React, { FC, useState, useEffect } from 'react';
import { Row, Col, Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import { actions as appointmentActions } from 'store/appointment';
import * as _ from 'lodash';

import { formRules } from 'helpers/formUtils';
import './Question.scss';
import { CONST_ID } from 'containers/Appointment/appointment.const';
import StoreState from 'models/store';
import AppointmentCard from '../AppointmentCard/AppointmentCard';

interface Props {
  initValues: any;
  data: any;
  isEditAvailability: boolean;
}

const Question: FC<Props> = props => {
  const dispatch = useDispatch();
  const { initValues, data, isEditAvailability } = props;

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [symptom, setSymptom] = useState<{
    id: string;
    description: string;
  }>({
    id: '',
    description: '',
  });
  const originTime = new Date();
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const symptoms = data.detail
    ? data.detail.dataCloned.detail.askDisease.symptom
    : [];
  const appointmentReducer = useSelector(
    (state: StoreState) => state.appointment,
  );
  const [isSubmit, setIsSubmit] = useState(false);

  const handleEdit = _.debounce((item: { id: string; description: string }) => {
    setIsEdit(true);
    setSymptom(item);
  }, 50);

  const handleCancel = _.debounce(index => {
    setIsEdit(false);
    setSymptom({
      id: '',
      description: '',
    });
  }, 50);

  // const handleSave = _.debounce(index => {
  //   setIsEdit(false);
  //   setIdx(index);
  // }, 50);

  const onFinish = values => {
    setIsSubmit(true);
    const { systom } = values;
    const paramsCreateSystom = {
      value: systom,
      concept: CONST_ID.CONCEPT,
      obsDatetime: originTime?.toISOString(),
      person: data?.detail?.uuidPerson,
      encounter: data?.detail?.encounters[0]?.uuid,
      status: 'PRELIMINARY',
      interpretation: 'NORMAL',
    };
    dispatch(appointmentActions.handleCreateSystom(paramsCreateSystom));
  };

  const onSave = values => {
    setIsSubmit(true);
    const { systom } = values;
    const paramsEditSystom = {
      value: systom,
      concept: CONST_ID.CONCEPT,
      obsDatetime: originTime?.toISOString(),
      person: data?.detail?.uuidPerson,
      encounter: data?.detail?.encounters[0]?.uuid,
      status: 'PRELIMINARY',
      interpretation: 'NORMAL',
    };
    setIsEdit(false);
    dispatch(appointmentActions.handleEditSystom(symptom.id, paramsEditSystom));
  };

  const onAddQuestion = () => {
    setIsAdd(true);
    setIsEdit(false);
  };

  useEffect(() => {
    if (isSubmit === true && appointmentReducer?.isCreateSystem === true) {
      setIsAdd(false);
    }
  }, [appointmentReducer?.isCreateSystem]);

  return (
    <div className="question-Contaier">
      <div className="question-content">
        <Row className="question-content__title">
          <span className="pl-6">Hỏi bệnh</span>
        </Row>
        {initValues?.symptom ? <hr /> : ''}
        {symptoms.length > 0 ? (
          <AppointmentCard title="Triệu chứng">
            {symptoms?.map((item, index) => {
              return (
                <Row className="row-1" key={index as number}>
                  <Col xs={24}>
                    <Form onFinish={onSave}>
                      <div className="question-section">
                        <div className="question-section__title">
                          <div>
                            <span> </span>
                          </div>
                          <div className="btn-edit">
                            {isEditAvailability &&
                              (isEdit && item.id === symptom.id ? (
                                <>
                                  <Button
                                    size="middle"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Hủy
                                  </Button>{' '}
                                  <Button size="middle" htmlType="submit">
                                    Lưu
                                  </Button>
                                </>
                              ) : (
                                <Button
                                  onClick={() => handleEdit(item)}
                                  size="middle"
                                >
                                  Sửa
                                </Button>
                              ))}
                          </div>
                        </div>
                        <div className="question-section__content">
                          <div className="question-section__content_inside">
                            {isEdit && item.id === symptom.id ? (
                              <Form.Item
                                name="systom"
                                className="form-item-appointment-detail"
                                rules={formRules}
                                preserve={false}
                                initialValue={symptom.description}
                              >
                                <Input
                                  size="large"
                                  placeholder="Thêm triệu chứng"
                                  autoFocus
                                />
                              </Form.Item>
                            ) : (
                              <span className="text-have-color-#555555">
                                {item.description}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              );
            })}
          </AppointmentCard>
        ) : (
          <Row className="row-1">
            <Col xs={24}>
              <div className="question-section">
                <div className="question-section__title">
                  <span>Triệu chứng</span>
                </div>
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {isEditAvailability && (
        <div className="question-add-container">
          <Prompt when={isAdd} message="Your changes will not saved." />
          {isAdd ? (
            <Form onFinish={onFinish}>
              <div className="question-input-add">
                <Form.Item
                  name="systom"
                  className="form-item-appointment-detail"
                  rules={formRules}
                >
                  <Input
                    size="large"
                    placeholder="Thêm triệu chứng"
                    ref={input => input && input.focus()}
                  />
                </Form.Item>
              </div>
              <div className="question-action-add">
                <div className="btn-ok">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={appointmentReducer?.isLoadingCreateSystem}
                  >
                    Lưu
                  </Button>
                </div>
                <div className="btn-cancel">
                  <a
                    className="no-underline"
                    href="#"
                    onClick={() => setIsAdd(false)}
                  >
                    <span className="btn-add-form-input">Hủy</span>
                  </a>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <Button
                className="rounded-md"
                type="primary"
                onClick={onAddQuestion}
              >
                Thêm thông tin
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
