import { Button, Form, Input, Modal } from 'antd';
import _ from 'lodash';
import { VersionModel } from 'models/store/version.model';
import React, { FC } from 'react';
import './ModalViewVersion.scss';

interface VProps {
  show: boolean;
  onHide: () => void;
  itemVersion: VersionModel;
}
const ModalViewVersion: FC<VProps> = ({ show, itemVersion, onHide }) => {
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <Modal
      title="Chi tiết phiên bản ứng dụng"
      centered
      visible={show}
      onCancel={onHide}
      width={450}
      footer={null}
    >
      <Form className="modal-view-version" {...formItemLayout}>
        <Form.Item label="Loại ứng dụng:">
          <Input disabled value={_.get(itemVersion, 'applicationType', '')} />
        </Form.Item>
        <Form.Item label="Version name:">
          <Input disabled value={_.get(itemVersion, 'versionName', '')} />
        </Form.Item>
        <Form.Item label="Version number:">
          <Input disabled value={_.get(itemVersion, 'versionNumber', '')} />
        </Form.Item>
        <Form.Item label="Ghi chú:">
          <Input.TextArea
            disabled
            value={_.get(itemVersion, 'versionNote', '')}
          />
        </Form.Item>
        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Huỷ
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalViewVersion;
