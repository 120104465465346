import { createActions } from 'redux-actions';
import { QueryString } from 'types/Common';
import { HolidayModel } from 'models/store/holiday.model';

export const {
  getHolidayRequested,
  getHolidaySucceeded,
  getHolidayFailed,
  handleCreateHolidayRequested,
  handleCreateHolidaySucceeded,
  handleCreateHolidayFailed,
  handleUpdateHolidayRequested,
  handleUpdateHolidaySucceeded,
  handleUpdateHolidayFailed,
  handleDisplayModalEdit,
  handleDisplayModalAdd,
  handleDeleteHolidayRequested,
  handleDeleteHolidaySucceeded,
  handleDeleteHolidayFailed,
} = createActions(
  {
    GET_HOLIDAY_REQUESTED: (payload: QueryString) => payload,
    GET_HOLIDAY_SUCCEEDED: (payload: HolidayModel) => payload,
    GET_HOLIDAY_FAILED: (error: any) => error,
    HANDLE_CREATE_HOLIDAY_REQUESTED: (payload: QueryString) => payload,
    HANDLE_CREATE_HOLIDAY_SUCCEEDED: (payload: any) => payload,
    HANDLE_CREATE_HOLIDAY_FAILED: (error: any) => error,
    HANDLE_UPDATE_HOLIDAY_REQUESTED: (payload: QueryString) => payload,
    HANDLE_UPDATE_HOLIDAY_SUCCEEDED: (payload: HolidayModel) => payload,
    HANDLE_UPDATE_HOLIDAY_FAILED: (error: any) => error,
    HANDLE_DISPLAY_MODAL_EDIT: (error: any) => error,
    HANDLE_DISPLAY_MODAL_ADD: (error: any) => error,
    HANDLE_DELETE_HOLIDAY_REQUESTED: (payload: any) => payload,
    HANDLE_DELETE_HOLIDAY_SUCCEEDED: (payload: any) => payload,
    HANDLE_DELETE_HOLIDAY_FAILED: (error: any) => error,
  },
  {
    prefix: 'holiday',
  },
);
