import React, { FC, useEffect } from 'react';
import moment from 'moment';
import Modal from 'antd/lib/modal/Modal';
import { Form, Input, Button, DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/vi_VN';
// import * as _ from 'lodash';

interface CProps {
  show: boolean;
  onHide: () => void;
  title?: string;
  dataProps?: any;
}

const ModalDetailHoliday: FC<CProps> = ({ show, title, onHide, dataProps }) => {
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  useEffect(() => {
    form.setFieldsValue({
      holidayName: dataProps?.holidayName,
      startDate: moment(dataProps?.startDate),
      endDate: moment(dataProps?.endDate),
    });
  }, [form, dataProps]);
  return (
    <Modal
      title={title || 'Xem chi tiết kỳ lễ'}
      centered
      visible={show}
      onOk={onHide}
      onCancel={onHide}
      width={650}
      footer={null}
    >
      <Form
        form={form}
        {...formItemLayout}
        name="basic"
        onFinish={() => {}}
        className="modal-add-information"
        initialValues={() => {}}
      >
        <Form.Item label="Tên kỳ lễ" name="holidayName">
          <Input
            disabled
            placeholder="Nhập tên kỳ lễ"
            style={{ width: '80%' }}
          />
        </Form.Item>
        <Form.Item label="Ngày bắt đầu" name="startDate">
          <DatePicker
            format="MM-DD-YYYY"
            locale={locale}
            disabled
            placeholder="Chọn ngày bắt đầu"
            onChange={() => {}}
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>
        <Form.Item label="Ngày kết thúc" name="endDate">
          <DatePicker
            format="MM-DD-YYYY"
            locale={locale}
            disabled
            placeholder="Chọn ngày kết thúc"
            onChange={() => {}}
            style={{ width: '80%' }}
            showToday={false}
          />
        </Form.Item>

        <div className="button-container">
          <Button type="default" onClick={onHide}>
            Đóng
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalDetailHoliday;
