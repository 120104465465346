import React, { FC, useState, useMemo } from 'react';
import { Row, Col, Button, Form } from 'antd';
import moment from 'moment';
import * as _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import ModalAddFollowUp from 'components/Appointment/Modal/ModalAddFollowUp';

import './FollowUp.scss';
import { APPOINTMENT_STATUS } from 'constants/AppointmentDetai';

export enum ModalTypeEnum {
  UPDATE_RESULT = 'UPDATE_RESULT',
  UPDATE_FOLLOW = 'UPDATE_FOLLOW',
  CREATE_FOLLOW = 'CREATE_FOLLOW',
}

const FollowUp: FC<any> = props => {
  const [modalType, setModalType] = useState<ModalTypeEnum>(
    ModalTypeEnum.CREATE_FOLLOW,
  );
  const [display, setDisplay] = useState<any>(false);
  const { dataProps, isEditAvailability } = props;

  const [itemSelected, setItemSelected] = useState<{
    id: string;
    reason: string;
    followDate: string;
    appointment: string;
    follower: string;
    result: string;
  } | null>(null);

  const data = _.get(dataProps, 'detail.dataCloned.detail.followUps', []);
  const status = dataProps.detail?.dataCloned?.detail?.status;
  const handleDisplay = item => {
    setModalType(ModalTypeEnum.CREATE_FOLLOW);
    if (item) {
      setItemSelected(item);
    } else {
      setItemSelected(null);
    }
    setDisplay(true);
  };

  const onShowResult = item => {
    setModalType(ModalTypeEnum.UPDATE_RESULT);
    setItemSelected(item);
    setDisplay(true);

    // setIsAddResult(true);
  };
  const handleNoneDisplay = () => {
    setDisplay(false);
  };

  const onEdit = values => {};

  const isAddAvailability = useMemo(() => {
    if (data?.length === 0) return isEditAvailability;
    const isFullResult = data.findIndex(item => !item.result) < 0;
    return isFullResult && isEditAvailability;
  }, [isEditAvailability, data]);

  return (
    <div className="followUp-Contaier">
      <div className="followUp-content">
        <Row className="followUp-title ">
          <span className="pl-6">Theo dõi</span>
        </Row>
        <hr />
        {data.length > 0 ? (
          data.map((item, key) => {
            return (
              <Row className="row-1" key={item.id}>
                <Col xs={24}>
                  <div className="followUp-section">
                    <Form onFinish={onEdit}>
                      <div className="followUp-section__title">
                        <div>
                          <span>Theo dõi {key + 1}</span>
                        </div>
                        <div className="btn-edit">
                          {!item.result &&
                          status !== APPOINTMENT_STATUS.CANCELLED &&
                          moment(item.followDate, moment.ISO_8601).diff(
                            moment(),
                            'day',
                          ) >= 0 ? (
                            <Button
                              onClick={() => handleDisplay(item)}
                              size="middle"
                            >
                              Sửa
                            </Button>
                          ) : (
                            <span>
                              {moment(item.followDate).day() === 0
                                ? 'Chủ Nhật, '
                                : `Thứ ${moment(item.followDate).day() + 1}, `}
                              {moment(item.followDate, moment.ISO_8601).format(
                                'DD/MM/YYYY',
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                      {/* <div className="followUp-section__timeEdit"></div> */}
                      <div className="followUp-section__content">
                        <div className="followUp-section__content_inside">
                          <span className="text-have-color-#555555">
                            {item.reason}
                          </span>
                        </div>
                        <div>
                          <div className="follow-result-container">
                            <div className="followUp-section__subTitle">
                              <span>Kết quả theo dõi {key + 1}</span>
                            </div>
                            <div className="btn-edit">
                              {item.result &&
                              moment(item.followDate, moment.ISO_8601).diff(
                                moment(),
                                'day',
                              ) >= 0 ? (
                                <Button
                                  onClick={() => onShowResult(item)}
                                  size="middle"
                                >
                                  Sửa
                                </Button>
                              ) : null}
                            </div>
                          </div>
                          <div className="followUp-section__content_inside">
                            <span className="text-have-color-#555555">
                              {item.result || 'Chưa có kết quả theo dõi'}
                            </span>
                          </div>
                          {!item.result &&
                            status !== APPOINTMENT_STATUS.CANCELLED && (
                              <div className="followUp-btn-add-result">
                                <Button
                                  type="primary"
                                  onClick={() => onShowResult(item)}
                                >
                                  Thêm kết quả
                                </Button>
                              </div>
                            )}
                        </div>
                      </div>
                    </Form>
                  </div>
                </Col>
              </Row>
            );
          })
        ) : (
          <Row className="noContentContainer">
            <Col xs={24}>
              <div className="anamnesis-section">
                {/* <div className="anamnesis-section__title">
                  <div>
                    <span>Theo dõi</span>
                  </div>
                </div> */}
                <div className="no-content">
                  <span className="no-content__text">Chưa có thông tin</span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {isAddAvailability && (
        <div className="followUp-btn-add-info">
          <Button type="primary" onClick={handleDisplay}>
            Thêm thông tin
          </Button>
        </div>
      )}

      {display && (
        <ModalAddFollowUp
          type={modalType}
          visible={display}
          handleCancelModal={handleNoneDisplay}
          followDescription={itemSelected?.reason || ''}
          followDate={itemSelected?.followDate || ''}
          follower={itemSelected?.follower || ''}
          followId={itemSelected?.id || ''}
          followUpResult={itemSelected?.result || ''}
        />
      )}
    </div>
  );
};

export default FollowUp;
